import { Injectable } from '@angular/core';

@Injectable()
export class ModalScrollService {
	private scrollContainer =
		document.scrollingElement || document.documentElement;
	private scrollTop = null;
	private disableClass = 'disable-scroll';

	public disableScroll(): void {
		this.scrollTop = this.scrollContainer.scrollTop;
		document.body.classList.add(this.disableClass);
		document.body.style.cssText = `
			overflow: hidden;
			width: 100%;
			top: ${-this.scrollTop}px;
		`;
	}

	public enableScroll(): void {
		document.body.style.cssText = '';
		document.body.classList.remove(this.disableClass);
		this.scrollContainer.scrollTop = this.scrollTop;
	}
}
