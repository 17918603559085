import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';
import {
	catchError,
	filter,
	first,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
} from 'rxjs/operators';

import { DocumentsResource } from '@ccap/au-pair/data-access/documents';
import { DocumentsSandboxService } from '../documents-sandbox.service';
import {
	acceptAgreement,
	addDocuments,
	downloadDocument,
	fetchAgreements,
	fetchAgreementsFail,
	fetchDisplayedDocumentIds,
	fetchDisplayedDocumentIdsFail,
	fetchDocumentsByKey,
	fetchDocumentsByKeyFail,
	fetchMatchDocuments,
	fetchMatchDocumentsFail,
	updateAgreement,
	updateAgreements,
	updateDisplayedDocumentIds,
	updateMatchDocuments,
} from './documents.action';
import { buildCompoundAgreementId } from './documents.reducer';

@Injectable({
	providedIn: 'root',
})
export class DocumentsEffect {
	public fetchAgreements$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAgreements),
			switchMap(() =>
				this.documentsResourceService.getAgreements().pipe(
					map(agreements => updateAgreements({ agreements })),
					catchError(error => of(fetchAgreementsFail({ error }))),
				),
			),
		),
	);

	public acceptAgreement$ = createEffect(() =>
		this.actions$.pipe(
			ofType(acceptAgreement),
			mergeMap(action => {
				const sameAction$ = this.actions$.pipe(
					ofType(acceptAgreement),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.documentsResourceService
					.acceptAgreement(action.matchId, action.agreementType)
					.pipe(takeUntil(sameAction$));
			}),
			switchMap(updatedAgreement =>
				this.documentsSandboxService
					.getAgreementById(buildCompoundAgreementId(updatedAgreement))
					.pipe(
						first(),
						map(oldAgreement => ({
							...oldAgreement,
							...updatedAgreement,
						})),
					),
			),
			map(agreement => updateAgreement({ agreement })),
		),
	);

	public fetchDocumentsByKey$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchDocumentsByKey),
			mergeMap(({ key }) =>
				this.documentsResourceService.getDocumentsByKey(key).pipe(
					map(documents => addDocuments({ documents })),
					catchError(error => of(fetchDocumentsByKeyFail({ error }))),
				),
			),
		),
	);

	public fetchMatchDocuments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchMatchDocuments),
			switchMap(() =>
				this.documentsResourceService.getMatchDocuments().pipe(
					map(documents => updateMatchDocuments({ documents })),
					catchError(error => of(fetchMatchDocumentsFail({ error }))),
				),
			),
		),
	);

	public downloadDocument$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(downloadDocument),
				mergeMap(({ url, name }) =>
					this.documentsResourceService.downloadDocument(url, name),
				),
			),
		{ dispatch: false },
	);

	public fetchDisplayedDocumentIds$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchDisplayedDocumentIds),
			switchMap(() =>
				this.documentsResourceService.getDisplayedDocumentIds().pipe(
					map(documentIds => {
						return updateDisplayedDocumentIds({ documentIds });
					}),
					catchError(error => of(fetchDisplayedDocumentIdsFail({ error }))),
				),
			),
		),
	);

	constructor(
		private actions$: Actions,
		private documentsResourceService: DocumentsResource,
		private documentsSandboxService: DocumentsSandboxService,
	) {}
}
