import { AppEnvironmentBase } from './environment.model';

export const staticEnv = {
	isLocal: false,
};

class Environment extends AppEnvironmentBase {
	public isLocal = staticEnv.isLocal;
}

export const environment = new Environment();
