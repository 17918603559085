import { DateValue } from '@ccap/interfaces';

export interface AirportDetails {
	city: string;
	code: string;
	name: string;
	state: string | null;
}
export interface FlightLeg {
	departureDateTimeLocal: string;
	arrivalDateTimeLocal: string;
	departureAirportDetails: AirportDetails;
	arrivalAirportDetails: AirportDetails;
	airline: string;
	airlineName: string;
	flightNumber: string;
	bookingConfirmationNumber: string;
}

export interface FlightDto {
	type: string;
	legs: FlightLeg[];
}

interface TravelInsurancePlan {
	type: string;
	description: string;
	startDate: string;
	endDate: string;
}

export interface TravelInsuranceDto {
	policyNumber: string;
	referenceNumber: string;
	plans: TravelInsurancePlan[];
}

export interface ReturnFlightDto {
	returnFlightUrl: string;
	isAvailable: boolean;
}

export interface ChangeReturnFlightDto {
	returnFlightChangeUrl: string;
	isAvailable: boolean;
}

export enum VisaAppointmentStatus {
	AppointmentPending = 'appointmentPending',
	AppointmentBooked = 'appointmentBooked',
	VisaPending = 'visaPending',
	VisaRejected = 'visaRejected',
	VisaReceived = 'visaReceived',
	AppointmentCancelled = 'appointmentCancelled',
}

export interface EmbassyAppointment {
	status: VisaAppointmentStatus;
	statusDate: DateValue;
}

export interface EmbassyAppointmentApi {
	status: VisaAppointmentStatus;
	statusDate: string;
}

export interface FetchVisaDto {
	sevisNumber: string;
	embassyAppointment: EmbassyAppointment;
}
export interface FetchVisaApiDto extends EmbassyAppointmentApi {
	sevisNumber: string;
}

export interface SetEmbassyAppointmentDto {
	embassyAppointment: EmbassyAppointment;
}

export type SetEmbassyAppointmentApiDto = EmbassyAppointmentApi;
