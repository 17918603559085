import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgComponent } from './svg.component';

@NgModule({
	imports: [CommonModule, PipesModule],
	declarations: [SvgComponent],
	exports: [SvgComponent],
})
export class SvgModule {}
