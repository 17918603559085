import { Action } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { Observable, ObservedValueOf, OperatorFunction } from 'rxjs';
import { filter, mergeMap, take, takeUntil } from 'rxjs/operators';

export type ActionGetter<T> = (value: T) => Action;

const baseActionGetter: ActionGetter<unknown> = (value: Action) => value;

export const actionMergeMap = <T, O extends Observable<any>>(
	project: (value: T) => O,
	actionGetter: ActionGetter<T> = baseActionGetter,
): OperatorFunction<T, ObservedValueOf<O>> => (source: Observable<T>) => {
	return source.pipe(
		mergeMap(data => {
			const action = actionGetter(data);

			const sameAction$ = source.pipe(
				take(1),
				filter(newAction => isEqual(action, newAction)),
			);

			return project(data).pipe(takeUntil(sameAction$));
		}),
	);
};
