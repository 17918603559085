<dl *ngIf="acceptedDate$ | async as acceptedDate">
	<ng-container *ngIf="question.answer.smokes === 'Yes'">
		<dt>Smoking cessation confirmation</dt>
		<dd>
			I, {{ auPairName$ | async }}, hereby confirm that I have quit smoking
			today or that I have stopped smoking prior to signing this agreement,
			effective {{ acceptedDate | date: 'dd MMMM yyyy' }}. I also agree that I
			will not start smoking during my time as an au pair.
		</dd>
	</ng-container>

	<ng-container *ngIf="question.answer.smokes === 'No'">
		<dt>Currently smoking</dt>
		<dd>No. Confirmed {{ acceptedDate | date: 'dd MMMM yyyy' }}</dd>
	</ng-container>
</dl>
