<dl class="tiles">
	<dt>{{ question.group.label }}</dt>

	<dd>
		<ng-container *ngIf="selectedChoices; else noItems">
			<p>{{ selectedChoices }}</p>
		</ng-container>

		<ng-template #noItems>
			<p class="no-answer no-answer--center">Not specified</p>
		</ng-template>
	</dd>
</dl>
