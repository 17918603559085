import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IAnswers } from '@ccap/interfaces';

export const fetchExtensionAnswers = createAction('[Extension] Get Answers');
export const fetchExtensionAnswersFail = createAction(
	'[Extension] Get Answers Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setExtensionAnswers = createAction(
	'[Extension] Set Answers',
	props<{ answers: Partial<IAnswers> }>(),
);

export const setExtensionAnswersFail = createAction(
	'[Extension] Set Answers Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateExtensionAnswers = createAction(
	'[Extension] Update Answers',
	props<{ answers: IAnswers }>(),
);

/**
 * Updates the whole state
 */
export const rollbackExtensionAnswers = createAction(
	'[Extension] Rollback Answers',
	props<{ answers: IAnswers }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchExtensionAnswers = createAction(
	'[Extension] Patch Answers',
	props<{ answers: Partial<IAnswers> }>(),
);
