import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { AuPairElement, AuPairElementId } from '@ccap/interfaces';

export const fetchElements = createAction('[Elements] Get');

export const fetchElementsSuccess = createAction(
	'[Elements] Get Elements Success',
	props<{ elements: AuPairElement[] }>(),
);

export const fetchElementsError = createAction(
	'[Elements] Get Error',
	props<{ error: HttpErrorResponse }>(),
);

export const fetchElement = createAction(
	'[Elements] Get Element',
	props<{ id: AuPairElementId }>(),
);

export const fetchElementSuccess = createAction(
	'[Elements] Get Element Success',
	props<{ element: AuPairElement }>(),
);

export const fetchElementError = createAction(
	'[Elements] Get Element Error',
	props<{ error: HttpErrorResponse }>(),
);
