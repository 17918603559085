import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SummaryCards } from '../../../interfaces/summary-cards';

@Component({
	selector: 'app-summary-cards',
	templateUrl: './summary-cards.component.html',
	styleUrls: ['./summary-cards.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryCardsComponent {
	@Input() public summaryCards?: SummaryCards;

	public trackByFn(index) {
		return index;
	}

	public summaryCardKeys(): string[] {
		return this.summaryCards ? Object.keys(this.summaryCards) : [];
	}
}
