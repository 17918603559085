<div
	[ccapE2EClass]="'validation-message'"
	class="validation-container"
	*ngIf="errorMessage && visible"
>
	<div class="validation-container__icon-wrapper">
		<ef-icon name="info_circle"></ef-icon>
	</div>
	<p class="ef-text-caption" [innerHTML]="errorMessage"></p>
</div>
