import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuPairDataAccessElementsModule } from '@ccap/au-pair/data-access/elements';
import { AuPairLegalModule } from '@ccap/au-pair/legal';
import { AuthConfig, AuthModule } from '@ccap/auth';
import { FeatureToggleConfig, FeatureToggleModule } from '@ccap/feature-toggle';
import { NotificationModule } from '@ccap/shared/ui/popups';
import { MonitoringModule } from '@ccap/shared/utils';
import { environment /* staticEnv */ } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { UserIdentification } from './user-identification';
// import { ServiceWorkerModule } from '@angular/service-worker';

export function authConfig(): AuthConfig {
	const userIdentification = new UserIdentification();
	userIdentification.initLocalStorage();

	const isStaff = ['staff', 'lcc'].includes(userIdentification.userType());

	return {
		clientOptions: {
			clientID: userIdentification.getClientID(),
			domain: environment.AUTH0_CLIENT_DOMAIN,
		},
		lockOptions: {
			languageDictionary: {
				forgotPasswordAction: 'Forgot your password?',
				signUpTerms: `I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>.`,
				title: '',
				error: {
					forgotPassword: {
						'lock.fallback':
							"We're sorry, something went wrong when requesting the password change. <br/>Please check your internet connection and then try again.",
					},
					login: {
						'blocked_user': 'Your account has been restricted, please contact Cultural Care support for assistance.',
						'lock.fallback':
							"We're sorry, something went wrong when attempting to log in. <br/>Please check your internet connection and then try again.",
					},
					signUp: {
						'lock.fallback':
							"We're sorry, something went wrong when attempting to sign up. <br/>Please check your internet connection and then try again.",
					},
				},
			},
			configurationBaseUrl: 'https://cdn.auth0.com',
			allowSignUp: false,
			allowedConnections: isStaff
				? ['salesforce', 'salesforce-community', 'salesforce-sandbox']
				: ['Username-Password-Authentication'],
		},
		unauthenticatedUrls: ['restcountries.com'],
		showSocialMediaLogin: !isStaff,
		socialMediaLoginUrl: environment.SOCIAL_MEDIA_LOGIN_URL,
		matchLoginUrl: environment.MATCH_LOGIN_URL,
		title: 'Welcome',
		subTitleDescription: 'Please log in again to view the au pair profile.',
	};
}

export function features(): FeatureToggleConfig {
	return {
		features: [] as never,
		endpoint: environment.API_ENDPOINT,
	};
}

export const APP_IMPORTS = [
	BrowserModule,
	BrowserAnimationsModule,
	StoreModule.forRoot({}),
	EffectsModule.forRoot([]),
	MonitoringModule.forRoot(),
	HttpClientModule,
	NotificationModule,
	AuPairLegalModule,
	AuthModule.forRoot(authConfig),
	AppRoutingModule,
	CoreModule.forRoot(),
	FeatureToggleModule.forRoot(features),
	AuPairDataAccessElementsModule,
	// ServiceWorkerModule.register('ngsw-worker.js', {
	// 	enabled: !staticEnv.isLocal,
	// }),
];
