import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FooterItem } from './models/footer-item.interface';

@Component({
	selector: 'ccap-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
	@Input()
	public footerItems?: FooterItem[];
}
