import {
	AbstractControl,
	FormControl,
	ValidationErrors,
	Validators,
} from '@angular/forms';

import { PhoneNumber } from '@ccap/interfaces';

export const phoneNumberRequiredValidator = (
	control: AbstractControl,
): ValidationErrors | null => {
	const value: PhoneNumber = control.value;

	const countryCodeErrors = Validators.required(
		new FormControl(value?.countryCode),
	);

	const numberErrors = Validators.required(new FormControl(value?.number));
	return countryCodeErrors ?? numberErrors ?? null;
};
