import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[ccapE2EClass]',
})
export class E2eClassDirective {
	private selector?: string;

	constructor(private elementRef: ElementRef) {}

	@Input()
	public get ccapE2EClass(): string {
		return this.selector;
	}

	public set ccapE2EClass(selector: string) {
		this.selector = selector;
		this.addClass(selector);
	}

	private get element(): HTMLElement {
		return this.elementRef.nativeElement;
	}

	private addClass(selector: string): void {
		this.element.setAttribute('data-e2e-class', selector);
	}
}
