import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { CoursesAvailabilityDto } from '@ccap/au-pair/data-access/models';
import { updateCoursesAvailability } from './courses.actions';

export interface CoursesState {
	availability: CoursesAvailabilityDto | null;
}

const reducer = combineReducers<CoursesState>({
	availability: createReducer<CoursesAvailabilityDto | null>(
		null,
		on(updateCoursesAvailability, (_, { availability }) => availability),
	),
});

export function coursesReducer(
	state: CoursesState,
	action: Action,
): CoursesState {
	return reducer(state, action);
}
