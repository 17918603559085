import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ParentsState } from './parents.reducer';

export const getParents = createFeatureSelector<ParentsState>('parents');

export const getParentById = (parentId: string) =>
	createSelector(getParents, parents =>
		parents ? parents.find(({ id }) => id === parentId) : null,
	);
