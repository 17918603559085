import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	AddEmergencyContactDto,
	EmergencyContactDto,
	UpdateEmergencyContactDto,
} from '@ccap/au-pair/data-access/models';

export const fetchEmergencyContacts = createAction('[Emergency Contacts] Get');

export const fetchEmergencyContactsError = createAction(
	'[Emergency Contacts] Get Error',
	props<{ error: HttpErrorResponse }>(),
);

export const fetchEmergencyContactsSuccess = createAction(
	'[Emergency Contacts] Fetch Emergency Contacts Success',
	props<{ contacts: EmergencyContactDto[] }>(),
);

export const addEmergencyContactLocally = createAction(
	'[Emergency Contacts] Add Emergency Contact Locally',
);

export const addOrUpdateEmergencyContact = createAction(
	'[Emergency Contacts] Add or Update Emergency Contact',
	props<{
		contactId: string;
		contact: AddEmergencyContactDto | UpdateEmergencyContactDto;
	}>(),
);

export const addEmergencyContact = createAction(
	'[Emergency Contacts] Add',
	props<{ contact: AddEmergencyContactDto }>(),
);

export const addEmergencyContactSuccess = createAction(
	'[Emergency Contacts] Add Success',
	props<{ contact: EmergencyContactDto }>(),
);

export const addEmergencyContactError = createAction(
	'[Emergency Contacts] Add Error',
	props<{ error: HttpErrorResponse }>(),
);

export const updateEmergencyContact = createAction(
	'[Emergency Contacts] Update Emergency Contact',
	props<{ contactId: string; contact: UpdateEmergencyContactDto }>(),
);

export const updateEmergencyContactSuccess = createAction(
	'[Emergency Contacts] Update Emergency Contact Success',
	props<{ contact: EmergencyContactDto }>(),
);

export const updateEmergencyContactError = createAction(
	'[Emergency Contacts] Update Emergency Contact Error',
	props<{ error: HttpErrorResponse }>(),
);

export const deleteEmergencyContact = createAction(
	'[Emergency Contacts] Delete',
	props<{ contactId: string }>(),
);

export const deleteEmergencyContactSuccess = createAction(
	'[Emergency Contacts] Delete Success',
);

export const deleteEmergencyContactError = createAction(
	'[Emergency Contacts] Delete Error',
	props<{ error: HttpErrorResponse }>(),
);

export const deleteEmergencyContactLocally = createAction(
	'[Emergency Contacts] Delete Locally',
);
