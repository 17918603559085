import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { compose } from '@ngrx/store';

const removeAccessTokenFromString = (data: string): string =>
	data.replace(/access_token.+?(&|$)/i, '');

const removeIdTokenFromString = (data: string): string =>
	data.replace(/id_token.+?(&|$)/i, '');

export const removeSensitiveData = compose(
	removeAccessTokenFromString,
	removeIdTokenFromString,
);

export const sensitiveDataFilter = (item: ITelemetryItem): boolean => {
	const url = removeSensitiveData(location.href);

	if (item.baseData.uri) {
		item.baseData.uri = url;
	}

	if (item.baseData.refUri) {
		item.baseData.refUri = url;
	}

	if (item.baseData.properties?.refUri) {
		item.baseData.properties.refUri = url;
	}

	return true;
};
