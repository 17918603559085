<ng-container *ngIf="answers.length; else noItems">
	<ng-container *ngFor="let selection of answers">
		<app-abstract-list-item [labeledFields]="getLabelsForFields(selection)">
		</app-abstract-list-item>
	</ng-container>
</ng-container>

<ng-template #noItems>
	<p class="no-answer no-answer--center">
		No {{ question.content.subtitle }} Added
	</p>
</ng-template>
