import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from './button';
import { CheckboxModule } from './checkbox';
import { FormGroupModule } from './group';
import { InputModule } from './input';
import { RadioModule } from './radio';

@NgModule({
	declarations: [],
	exports: [
		InputModule,
		ButtonModule,
		RadioModule,
		FormGroupModule,
		CheckboxModule,
	],
	imports: [CommonModule, ReactiveFormsModule],
})
export class FormModule {}
