<ng-container *ngIf="isInitialised">
	<div
		class="status-indicator__container--{{
			type
		}} status-indicator__container--{{ statusName }}"
	>
		<span class="status-indicator__dot status-indicator__dot--{{ type }}">
			<svg viewBox="0 0 16 16" width="100%" height="100%">
				<circle cx="8" cy="8" r="8" fill="currentColor"></circle>
			</svg>
		</span>

		<div *ngIf="!minimal">{{ status }}</div>
	</div>
</ng-container>
