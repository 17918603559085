import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@ccap/ef-ui/components';
import { PrintButtonComponent } from './print-button.component';

@NgModule({
	imports: [CommonModule, ButtonModule],
	declarations: [PrintButtonComponent],
	exports: [PrintButtonComponent],
})
export class PrintButtonModule {}
