import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { initTagManager } from '@ccap/shared/utils';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const xhttp = new XMLHttpRequest();
xhttp.open('GET', 'assets/env-config.json', true);
xhttp.onreadystatechange = function () {
	if (this.readyState === 4 && this.status === 200) {
		const config = JSON.parse(this.responseText);
		window.config = config;

		if (environment.production) {
			enableProdMode();
			initTagManager(environment.TAG_MANAGER_ID);
		}

		// WORKAROUND: this is a workaround for service worker not working with Angular CLI 7.3.* https://stackoverflow.com/questions/53329924/angular-7-service-worker-not-registered
		platformBrowserDynamic()
			.bootstrapModule(AppModule)
			// .then(() => {
			// 	if ('serviceWorker' in navigator && !environment.isLocal) {
			// 		navigator.serviceWorker.register('/ngsw-worker.js');
			// 	}
			// })
			// eslint-disable-next-line no-console
			.catch(err => console.error(err));
	}
};
xhttp.send();
