import {
	ChangeDetectionStrategy,
	Component,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'ccap-content-wrapper',
	templateUrl: './content-wrapper.component.html',
	styleUrls: ['./content-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None, // we override styling of dynamic HTML
})
export class ContentWrapperComponent {}
