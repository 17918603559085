import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { AuPairPage, AuPairPageId } from '@ccap/interfaces';

export const fetchPages = createAction('[Pages] Get');

export const fetchPagesSuccess = createAction(
	'[Pages] Get Pages Success',
	props<{ pages: AuPairPage[] }>(),
);

export const fetchPagesError = createAction(
	'[Pages] Get Error',
	props<{ error: HttpErrorResponse }>(),
);

export const fetchPage = createAction(
	'[Pages] Get Page',
	props<{ id: AuPairPageId }>(),
);

export const fetchPageSuccess = createAction(
	'[Pages] Get Page Success',
	props<{ page: AuPairPage }>(),
);

export const fetchPageError = createAction(
	'[Pages] Get Page Error',
	props<{ error: HttpErrorResponse }>(),
);
