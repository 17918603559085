import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IMediaFile } from '@ccap/interfaces';

export const fetchImages = createAction('[Image] Get All');
export const fetchImagesFail = createAction(
	'[Image] Get All Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const uploadImage = createAction(
	'[Image] Upload',
	props<{ key: string; image: File }>(),
);

export const uploadImageFail = createAction(
	'[Image] Upload Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteImage = createAction(
	'[Image] Delete',
	props<{ key: string; imageId: string }>(),
);

export const deleteImageFail = createAction(
	'[Image] Delete Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches value in state **and on server**.
 */
export const setImageMetadata = createAction(
	'[Image] Set Metadata',
	props<{ key: string; imageId: string; metadata: Record<string, any> }>(),
);

export const setImageMetadataFail = createAction(
	'[Image] Set Metadata Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateImages = createAction(
	'[Image] Update',
	props<{ images: IMediaFile[] }>(),
);

/**
 * Updates the whole state
 */
export const rollbackImages = createAction(
	'[Image] Rollback',
	props<{ images: IMediaFile[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchImage = createAction(
	'[Image] Patch',
	props<{ image: IMediaFile }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchImageMetadata = createAction(
	'[Image] Patch Metadata',
	props<{ imageId: string; metadata: Record<string, any> }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addImage = createAction(
	'[Image] Add',
	props<{ image: IMediaFile }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeImage = createAction(
	'[Image] Remove',
	props<{ imageId: string }>(),
);
