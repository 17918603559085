import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InCountryState } from './in-country.reducer';

export interface InCountryStore {
	inCountry: InCountryState;
}

export const getInCountry = createFeatureSelector<InCountryState>('inCountry');

export const getIsInExtension = createSelector(getInCountry, inCountry =>
	inCountry ? Boolean(inCountry.extension) : null,
);

export const getIsInTransition = createSelector(getInCountry, inCountry =>
	inCountry ? Boolean(inCountry.transition) : null,
);

export const getIsInExtensionOrTransition = createSelector(
	getIsInExtension,
	getIsInTransition,
	(extension, transition) =>
		extension === null
			? null
			: transition === null
			? null
			: extension || transition,
);
