import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconComponent } from './icon.component';

export interface SvgMetadata {
	typeName: string;
	typePath: string;
	data: string;
}

@NgModule({
	imports: [CommonModule],
	declarations: [IconComponent],
	exports: [IconComponent],
})
export class IconModule {
	constructor(
		private iconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {
		this.loadIcons();
	}

	private async loadIcons(): Promise<void> {
		const { default: iconManifest }: { default: SvgMetadata[] } = await import(
			'@ef-global/web-ui/assets/svg/meta/icon-manifest.json'
		);

		for (const icon of iconManifest) {
			const regex = /^data:image\/svg\+xml,(.*)$/;
			const [, data] = icon.data.match(regex);

			const sanitizedSvg = this.domSanitizer.bypassSecurityTrustHtml(
				decodeURIComponent(data),
			);

			this.iconRegistry.addSvgIconLiteral(icon.typeName, sanitizedSvg);
		}
	}
}
