import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { CreatePersonalReferencePayload } from '@ccap/au-pair/data-access/models';
import { IReferenceBody } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class PersonalReferenceResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getPersonalReferences(): Observable<IReferenceBody[]> {
		return this.httpService.authGet<IReferenceBody[]>(
			this.endpoints.personalReferences,
		);
	}

	public createPersonalReference(
		reference: CreatePersonalReferencePayload,
	): Observable<IReferenceBody> {
		return this.httpService.authPost<IReferenceBody>(
			this.endpoints.personalReferences,
			reference,
		);
	}

	public updatePersonalReference(
		referenceId: string,
		reference: CreatePersonalReferencePayload,
	): Observable<IReferenceBody> {
		return this.httpService.authPatch<IReferenceBody>(
			`${this.endpoints.personalReferences}/${referenceId}`,
			reference,
		);
	}

	public deletePersonalReference(referenceId: string): Observable<void> {
		return this.httpService.authDelete<void>(
			`${this.endpoints.personalReferences}/${referenceId}`,
		);
	}
}
