import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { ChangeEmailPayload, IAccount } from '@ccap/au-pair/data-access/models';

export const getAccountDetailsAction = createAction('[Account] Get Details');

export const getAccountDetailsSuccessAction = createAction(
	'[Account] Get Details Success',
	props<{ payload: IAccount }>(),
);

export const getAccountDetailsFailAction = createAction(
	'[Account] Get Details Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const createAccountAction = createAction('[Account] Create');

export const createAccountSuccessAction = createAction(
	'[Account] Create Success',
	props<{ payload: Omit<IAccount, 'accountExists' | 'isApproved'> }>(),
);

export const createAccountFailAction = createAction(
	'[Account] Create Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateAccountEmailAction = createAction(
	'[Account] Update Email',
	props<{ payload: ChangeEmailPayload }>(),
);

export const updateAccountEmailFailAction = createAction(
	'[Account] Update Email Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateAccountEmailSuccessAction = createAction(
	'[Account] Update Email Success',
);

export const resetPasswordRequestAction = createAction(
	'[Account] Reset Password',
);

export const resetPasswordRequestSuccessAction = createAction(
	'[Account] Reset Password Success',
);

export const resetPasswordRequestFailAction = createAction(
	'[Account] Reset Password Fail',
	props<{ error: HttpErrorResponse }>(),
);
