import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'ccap-question-answer',
	templateUrl: './question-answer.component.html',
	styleUrls: ['./question-answer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionAnswerComponent {
	public questionLines?: string[];

	@Input()
	public set question(question: string) {
		this.questionLines = question.split('\\n');
	}
}
