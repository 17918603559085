import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProfileCompletionState } from './profile-completion.reducer';

export interface ProfileCompletionStore {
	profileCompletion: ProfileCompletionState;
}

export const getProfileCompletion =
	createFeatureSelector<ProfileCompletionState>('profileCompletion');

export const getProfileCompletionPercentage = createSelector(
	getProfileCompletion,
	({ percentage }) => percentage,
);

export const getHasCompletedProfile = createSelector(
	getProfileCompletionPercentage,
	percentage =>
		(percentage && Number.isInteger(percentage) && percentage === 100) || false,
);
