<dl class="tiles">
	<dt>{{ label }}</dt>

	<dd>
		<ng-container *ngIf="answer; else noAnswer">
			<p>{{ answer }}</p>
		</ng-container>

		<ng-template #noAnswer>
			<p class="no-answer no-answer--center">Not specified</p>
		</ng-template>
	</dd>
</dl>
