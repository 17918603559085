import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { ChangeEmailPayload, IAccount } from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class AccountResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getAuPairAccount(): Observable<IAccount> {
		return this.httpService.authGet<IAccount>(this.endpoints.accountsAupairs);
	}

	public updateAccountEmail(
		payload: ChangeEmailPayload,
	): Observable<ChangeEmailPayload> {
		return this.httpService.authPatch<ChangeEmailPayload>(
			this.endpoints.accountsAupairs,
			payload,
		);
	}

	public resetAccountPassword(): Observable<void> {
		return this.httpService.authPost<void>(this.endpoints.passwordReset);
	}
}
