import { SummaryCard } from '../../../../interfaces/summary-cards';

function englishLevelAnswer(englishProficiency: string): string {
	return englishProficiency && englishProficiency !== 'NotSet'
		? englishProficiency
		: null;
}

export function englishLevelResolver(
	englishProficiency: string,
): Record<'englishProficiency', SummaryCard> {
	return {
		englishProficiency: {
			title: 'English level',
			icon: 'English level',
			answer: englishLevelAnswer(englishProficiency),
		},
	};
}
