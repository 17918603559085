import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { AuthService } from '../auth.service';

export const INTENDED_ROUTE_KEY = 'intended_route';

@Component({
	templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {}

	public async ngOnInit(): Promise<void> {
		await this.authService.handleAuthentication();

		const intendedRoute = sessionStorage.getItem(INTENDED_ROUTE_KEY);

		if (intendedRoute) {
			sessionStorage.removeItem(INTENDED_ROUTE_KEY);
			this.document.location.href = intendedRoute;
		} else {
			await this.router.navigate(['/']);
		}
	}
}
