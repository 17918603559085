import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FeatureToggleResource } from '../feature-toggle-resource.service';
import { FeatureFlagState } from '../interfaces';
import {
	getFlags,
	getFlagsFail,
	getFlagsSuccess,
} from './feature-toggle.actions';

@Injectable()
export class FeatureToggleEffects {
	public fetchFeatureFlags$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(getFlags),
			switchMap(() => {
				return this.featureToggleResource.getFeatureFlags().pipe(
					map((res: FeatureFlagState) => {
						return getFlagsSuccess({ payload: res });
					}),
					catchError((err: HttpErrorResponse) =>
						of(getFlagsFail({ httpErrorResponse: err })),
					),
				);
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private featureToggleResource: FeatureToggleResource,
	) {}
}
