<dl>
	<dt>{{ question.content.question }}</dt>

	<dd>
		<ng-container *ngIf="!answers.length; else tiles">
			<p class="no-answer no-answer--center">Not specified</p>
		</ng-container>

		<ng-template #tiles>
			<div class="tileset tileset--has-wide-tiles">
				<ng-container *ngFor="let selection of answers">
					<div class="tile tile--wide">
						<div class="tile__label">
							<div class="tile__column--left">
								<ccap-svg [title]="selection.id"></ccap-svg>

								<div class="tile__rating">
									<ccap-svg
										*ngFor="let r of 5 | fill; let i = index"
										[title]="'star'"
										[classList]="
											'star ' +
											(selection.rating > i ? 'star--full' : 'star--empty')
										"
									>
									</ccap-svg>
								</div>

								<div class="tile__sub-title ef-text-caption">
									{{ ratingDescription(selection.rating) }}
								</div>
							</div>

							<div class="tile__column--right">
								<div class="tile__title">{{ selection.label }}</div>

								<div class="tile__content ef-text-caption">
									{{ selection.ratingExplanation }}
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-template>
	</dd>
</dl>
