import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormModule } from '@ccap/ef-ui/components';
import { CustomControlModule } from '../custom-control';
import { CustomControlValidationModule } from '../custom-control-validation';
import { NeoDropdownComponent } from './dropdown.component';

@NgModule({
	imports: [
		CommonModule,
		CustomControlModule,
		CustomControlValidationModule,
		FormModule,
	],
	declarations: [NeoDropdownComponent],
	exports: [NeoDropdownComponent],
})
export class NeoDropdownModule {}
