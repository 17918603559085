import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Injector,
	Input,
	OnInit,
} from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomControl } from '../custom-control';

@Component({
	selector: 'ccap-neo-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @angular-eslint/no-forward-ref
			useExisting: forwardRef(() => TextareaComponent),
			multi: true,
		},
	],
})
export class TextareaComponent extends CustomControl implements OnInit {
	@Input()
	public maxlength?: number;

	@Input()
	public id?: string;

	@Input()
	public placeholder?: number;

	@Input()
	public exactName?: boolean;

	@Input()
	public autofocus?: boolean;

	public ngControl?: NgControl;

	constructor(
		private injector: Injector,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		super();
	}

	public ngOnInit(): void {
		this.ngControl = this.injector.get(NgControl);
	}

	public writeValue(value: string): void {
		super.writeValue(value);
		this.changeDetectorRef.markForCheck();
	}

	public onInputChange(event: Event): void {
		const target = event.target as HTMLInputElement;

		this.writeValue(target.value);
		this.onChange(target.value);
		this.onTouched();
	}
}
