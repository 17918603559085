import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	UpdateWorkExperiencePayload,
	UpdateWorkExperienceReferencePayload,
	WorkExperienceDto,
	WorkExperienceReference,
} from '@ccap/au-pair/data-access/models';

export const fetchWorkExperience = createAction('[Work Experience] Get');
export const fetchWorkExperienceFail = createAction(
	'[Work Experience] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setWorkExperience = createAction(
	'[Work Experience] Set',
	props<{ experience: UpdateWorkExperiencePayload }>(),
);

export const setWorkExperienceFail = createAction(
	'[Work Experience] Set Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateWorkExperience = createAction(
	'[Work Experience] Update',
	props<{ experience: WorkExperienceDto }>(),
);

/**
 * Updates the whole state
 */
export const rollbackWorkExperience = createAction(
	'[Work Experience] Rollback',
	props<{ experience: WorkExperienceDto }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchWorkExperience = createAction(
	'[Work Experience] Patch',
	props<{ experience: UpdateWorkExperiencePayload }>(),
);

// References

/**
 * Patches values in state **and updates on server**.
 */
export const createWorkExperienceReference = createAction(
	'[Work Experience] Reference Create',
	props<{
		reference: WorkExperienceReference;
		/*
			We need to dispatch a saveAnswersLocal action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the reference without any ids.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
	}>(),
);

export const createWorkExperienceReferenceFail = createAction(
	'[Work Experience] Reference Create Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setWorkExperienceReference = createAction(
	'[Work Experience] Reference Set',
	props<{
		referenceId: string;
		reference: UpdateWorkExperienceReferencePayload;
	}>(),
);

export const setWorkExperienceReferenceFail = createAction(
	'[Work Experience] Reference Set Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteWorkExperienceReference = createAction(
	'[Work Experience] Reference Delete',
	props<{ referenceId: string }>(),
);

export const deleteWorkExperienceReferenceFail = createAction(
	'[Work Experience] Reference Delete Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addWorkExperienceReference = createAction(
	'[Work Experience] Reference Add',
	props<{ reference: WorkExperienceReference }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchWorkExperienceReference = createAction(
	'[Work Experience] Reference Patch',
	props<{ referenceId: string; reference: WorkExperienceReference }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeWorkExperienceReference = createAction(
	'[Work Experience] Reference Remove',
	props<{ referenceId: string }>(),
);
