import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerAbstractListItemModule } from '../answer-abstract-list-item/answer-abstract-list-item.module';
import { AnswerExperienceListComponent } from './answer-experience-list.component';

@NgModule({
	imports: [CommonModule, PipesModule, AnswerAbstractListItemModule, SvgModule],
	declarations: [AnswerExperienceListComponent],
	exports: [AnswerExperienceListComponent],
})
export class AnswerExperienceListModule {}
