import { IAbstractListValue, Option } from '@ccap/interfaces';

export const mapAnswersToQuestionOptionsLabel = (
	answer: Record<string, boolean>,
	options: Option[],
): Option[] => {
	return Object.keys(answer)
		.filter(key => answer[key])
		.reduce((filtered, answerKey) => {
			const option = options.find(({ value }) => String(value) === answerKey);

			if (option) {
				filtered.push({
					value: answerKey,
					label: option.label,
				});
			}
			return filtered;
		}, []);
};

export const mapTileListAnswersToQuestionOptionsLabel = <
	Q extends IAbstractListValue
>(
	answer: Q[],
	options: Option[],
): Q[] => {
	return answer.reduce((filtered, _answer) => {
		const option = options.find(({ value }) => value === _answer.id);
		if (option) {
			filtered.push({
				..._answer,
				label: option.label,
			});
		}
		return filtered;
	}, []);
};
