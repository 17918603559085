import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	OnDestroy,
	Output,
	QueryList,
} from '@angular/core';
import { EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { TabComponent } from './tab/tab.component';
import { TabsTheme } from './tabs.theme';

/**
 * @example:
 *   <ccap-tabs>
 * 		  <ccap-tab title="Extension Detail">
 *          Lorem Ipsum
 *      </ccap-tab>
 *
 * 		  <ccap-tab title="Perspectives">
 *          Lorem Ipsum
 *      </ccap-tab>
 *
 * 	    <ccap-tab title="Transition History">
 *          Lorem Ipsum
 *      </ccap-tab>
 * 	 </ccap-tabs>
 */
@Component({
	selector: 'ccap-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterViewInit, OnDestroy {
	@Input()
	public theme: TabsTheme = 'default';

	@ContentChildren(TabComponent, { descendants: true })
	public tabs?: QueryList<TabComponent>;
	public tabTitles: string[] = [];

	@Input()
	public activeTabIndex: number | null = null;

	@Output()
	public activeTabChange = new EventEmitter<number | null>();

	private onDestroy$ = new Subject<void>();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	public setActiveTab(index: number | null): void {
		this.activeTabIndex = index;
		this.activeTabChange.emit(index);
	}

	public ngAfterViewInit(): void {
		this.tabs.changes
			.pipe(startWith(this.tabs), takeUntil(this.onDestroy$))
			.subscribe((tabs: TabComponent[]) => {
				tabs.forEach(tab => {
					tab.showLess
						.pipe(takeUntil(this.onDestroy$), takeUntil(this.tabs.changes))
						.subscribe(() => {
							this.setActiveTab(null);
							this.changeDetectorRef.markForCheck();
						});
				});

				this.changeDetectorRef.markForCheck();
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	public trackByIndex(index: number): number {
		return index;
	}
}
