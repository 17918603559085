<ng-container *ngIf="answers.length; else noExperiences">
	<ng-container *ngFor="let reference of answers">
		<app-abstract-list-item [labeledFields]="getLabelsForFields(reference)">
		</app-abstract-list-item>
	</ng-container>
</ng-container>

<ng-template #noExperiences>
	<p class="no-answer no-answer--center">No Work Experiences Added</p>
</ng-template>
