import { Action, createReducer, on } from '@ngrx/store';

import { ParentDto } from '@ccap/au-pair/data-access/models';
import {
	addParent,
	patchParents,
	removeParent,
	updateParents,
} from './parents.actions';

export type ParentsState = ParentDto[];

const reducer = createReducer<ParentsState>(
	null,
	on(updateParents, (_, { parents }) => parents),
	on(addParent, (state, { parent }) => [...state, parent as ParentDto]),
	on(patchParents, (state, { parent }) =>
		state ? state.map(ref => (ref.id === parent.id ? parent : ref)) : state,
	),
	on(removeParent, (state, { parentId }) =>
		state ? state.filter(ref => ref.id !== parentId) : state,
	),
);

export function parentsReducer(
	state: ParentsState,
	action: Action,
): ParentsState {
	return reducer(state, action);
}
