import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

@Pipe({
	name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}

	public transform(
		value: string,
		options: marked.MarkedOptions = {},
	): SafeHtml {
		return this.sanitized.bypassSecurityTrustHtml(
			marked.parse(value || '', options),
		);
	}
}
