import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth0DecodedHash, Auth0Error } from 'auth0-js';

import {
	Auth0ScreenQueryParams,
	Auth0ShowScreenEvent,
} from './auth0-interfaces';
import { Auth0LockFactory } from './auth0-lock-factory.service';

/**
 * Auth0Service is a facade over the Auth0 Lock widget
 */
/** @dynamic */
@Injectable()
export class Auth0Service {
	private lock?: Auth0LockStatic;

	constructor(
		private auth0Factory: Auth0LockFactory,
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	/**
	 * Display the Auth0 Lock widget to allow user to sign in
	 */
	public async show() {
		const errorMessage =
			this.activatedRoute.snapshot.queryParamMap.get('error_description');
		const action = this.activatedRoute.snapshot.queryParamMap.get('action');

		const lockConstructorOptions: Auth0LockConstructorOptions = {
			initialScreen: action as Auth0ScreenQueryParams,
		};

		const lockShowOptions: Auth0LockShowOptions = {
			flashMessage: (errorMessage
				? { type: 'error', text: errorMessage }
				: undefined) as Auth0LockFlashMessageOptions,
		};

		await this.ensureLock(lockConstructorOptions);
		this.lock.show(lockShowOptions);
	}

	/**
	 * Resume authentication; i.e. handle the auth0 callback that contains the ID JWT in the
	 * URL fragment.
	 */
	public async resumeAuthentication(): Promise<Auth0DecodedHash> {
		const hash = this.document.location.hash;

		await this.ensureLock();

		return new Promise<Auth0DecodedHash>((resolve, reject) => {
			return this.lock.resumeAuth(
				hash,
				(err: Auth0Error, res: Auth0DecodedHash) => {
					if (err) {
						reject(err);
					} else if (!res) {
						reject(new Error('Auth0 lock failed to yield a response.'));
					} else {
						resolve(res);
					}
				},
			);
		});
	}

	public async renewAuthToken(): Promise<Auth0DecodedHash> {
		await this.ensureLock();

		return new Promise<Auth0DecodedHash>((resolve, reject) => {
			// checkSession() is not checking session, but tries to fetch a new token
			// The error callback is present when there's no Auth0 server session
			return this.lock.checkSession(
				{},
				(err: Auth0Error, res: Auth0DecodedHash) => {
					if (err) {
						reject(
							`Could not get a new token (${err.error}: ${err.error_description}).`,
						);
					} else {
						resolve(res);
					}
				},
			);
		});
	}

	/**
	 * Ensure auth0 lock object has been created.
	 */
	private async ensureLock(
		dynamicOptions: Auth0LockConstructorOptions = {},
	): Promise<void> {
		this.lock = this.lock || (await this.createLock(dynamicOptions));
	}

	private async createLock(
		dynamicOptions: Auth0LockConstructorOptions,
	): Promise<Auth0LockStatic> {
		const lock = await this.auth0Factory.create(dynamicOptions);

		lock.on(Auth0ShowScreenEvent.Login, () => {
			this.setRouteAction(Auth0ScreenQueryParams.Login);
		});

		lock.on(Auth0ShowScreenEvent.SignUp, () => {
			this.setRouteAction(Auth0ScreenQueryParams.SignUp);
		});

		lock.on(Auth0ShowScreenEvent.ForgotPassword, () => {
			this.setRouteAction(Auth0ScreenQueryParams.ForgotPassword);
		});

		return lock;
	}

	private setRouteAction(action: Auth0ScreenQueryParams): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParamsHandling: 'merge',
			queryParams: {
				action,
			},
		});
	}
}
