import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnswerAbstractListItemModule } from '../../answer-abstract-list-item/answer-abstract-list-item.module';
import { AnswerInterviewComponent } from './answer-interview.component';

@NgModule({
	imports: [CommonModule, AnswerAbstractListItemModule],
	declarations: [AnswerInterviewComponent],
	exports: [AnswerInterviewComponent],
	providers: [DatePipe],
})
export class AnswerInterviewModule {}
