import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { IAnswers } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';
import { FeatureToggleSandboxService } from '@ccap/feature-toggle';
import {
	CreateExtensionCourseDto,
	ExtensionCourseDocument,
	ExtensionCourseDto,
	ExtensionPaymentDetailsDto,
	ExtensionPaymentTransactionDto,
	IExtensionStatus,
	UpdateExtensionCourseDto,
	ExtensionCreatePaymentFormTokenDto,
} from './interfaces';

@Injectable({
	providedIn: 'root',
})
export class ExtensionResourceService {
	private paymentTokenRequestMap = {
		hostedPaymentReturnOptions: {
			showReceipt: false,
			cancelUrlText: 'Cancel',
		},
		hostedPaymentButtonOptions: { text: 'Pay' },
		hostedPaymentStyleOptions: { bgColor: 'blue' },
	};

	constructor(
		private httpClient: HttpClient,
		private endpoints: Endpoints,
		private httpService: HttpService,
		private featureToggleSandboxService: FeatureToggleSandboxService,
	) {}

	public getExtensionAnswers(): Observable<IAnswers> {
		return this.httpService.authGet<IAnswers>(this.endpoints.extension);
	}

	public updateExtensionAnswers(answers: IAnswers): Observable<IAnswers> {
		return this.httpService.authPatch<IAnswers>(
			this.endpoints.extension,
			answers,
		);
	}

	public getExtensionStatus(): Observable<IExtensionStatus> {
		return this.httpService.authGet<IExtensionStatus>(
			this.endpoints.extensionStatus,
		);
	}

	public createPaymentFormToken(): Observable<ExtensionCreatePaymentFormTokenDto> {
		return this.featureToggleSandboxService
			.isFeatureEnabled('extensionpaymentpayload')
			.pipe(
				map(isEnabled => (isEnabled ? {} : this.paymentTokenRequestMap)),
				switchMap(payload => {
					return this.httpClient.post<ExtensionCreatePaymentFormTokenDto>(
						`${this.endpoints.extensionPayment}/form`,
						payload,
					);
				}),
			);
	}

	public getExtensionPaymentDetails(): Observable<ExtensionPaymentDetailsDto> {
		return this.httpService.authGet<ExtensionPaymentDetailsDto>(
			this.endpoints.extensionPayment,
		);
	}

	public createPaymentTransaction(
		paymentTransaction: ExtensionPaymentTransactionDto,
	): Observable<ExtensionPaymentDetailsDto> {
		return this.httpService.authPost<ExtensionPaymentDetailsDto>(
			this.endpoints.extensionPayment,
			paymentTransaction,
		);
	}

	public getExtensionCourses(): Observable<ExtensionCourseDto[]> {
		return this.httpClient
			.get<ExtensionCourseDto[]>(this.endpoints.courses)
			.pipe(
				map(courses =>
					courses.map(course => ({
						...course,
						courseDocuments: course.courseDocuments.map(
							addDownloadUrlToCourseDocument(this.endpoints, course.id),
						),
					})),
				),
			);
	}

	public createExtensionCourse(
		course: CreateExtensionCourseDto,
	): Observable<ExtensionCourseDto> {
		return this.httpClient.post<ExtensionCourseDto>(
			this.endpoints.courses,
			course,
		);
	}

	public updateExtensionCourse(
		courseId: string,
		course: UpdateExtensionCourseDto,
	): Observable<ExtensionCourseDto> {
		return this.httpClient.patch<ExtensionCourseDto>(
			`${this.endpoints.courses}/${courseId}`,
			course,
		);
	}

	public deleteExtensionCourse(courseId: string): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.endpoints.courses}/${courseId}`,
		);
	}

	// Course Documents
	public uploadExtensionCourseDocument(
		courseId: string,
		documentType: string,
		document: File,
	): Observable<ExtensionCourseDocument> {
		const formData: FormData = new FormData();
		formData.append('files', document, document.name);
		formData.append('type', documentType);

		return this.httpService
			.authPost<ExtensionCourseDocument[]>(
				`${this.endpoints.courses}/${courseId}/documents`,
				formData,
			)
			.pipe(
				map(([file]) => file),
				map(addDownloadUrlToCourseDocument(this.endpoints, courseId)),
			);
	}

	public deleteExtensionCourseDocument(
		courseId: string,
		documentId: string,
	): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.endpoints.courses}/${courseId}/documents/${documentId}`,
		);
	}
}

const addDownloadUrlToCourseDocument =
	(endpoints: Endpoints, courseId: string) =>
	(document: ExtensionCourseDocument) => ({
		...document,
		url: `${endpoints.courses}/${courseId}/documents/${document.id}`,
	});
