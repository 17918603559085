import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { ProfileApiResponseDto } from '@ccap/au-pair/data-access/models';
import {
	IAnswer,
	IAnswers,
	ICheckboxListApiValue,
	ICheckboxListWithOtherApiValue,
	InterviewBody,
} from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';
import { arrayToCheckboxListValue } from '@ccap/shared/utils';

type ProfileAppResponseDto = Record<string, IAnswer>;

const mapApiResponseToAppResponse = () =>
	map<ProfileApiResponseDto, ProfileAppResponseDto>(response => {
		if (!response) {
			return null;
		}

		let mappedResponse = {
			...response,
		} as ProfileAppResponseDto;

		if (mappedResponse.specialDiet) {
			mappedResponse = {
				...mappedResponse,
				specialDiet: {
					...(mappedResponse.specialDiet as ICheckboxListWithOtherApiValue),
					choices: arrayToCheckboxListValue(
						(mappedResponse.specialDiet as ICheckboxListWithOtherApiValue)
							.choices,
					),
				},
			};
		}

		if (mappedResponse.preferredAgeRangeOfChildren) {
			mappedResponse = {
				...mappedResponse,
				preferredAgeRangeOfChildren: arrayToCheckboxListValue(
					mappedResponse.preferredAgeRangeOfChildren as ICheckboxListApiValue,
				),
			};
		}

		return mappedResponse;
	});

@Injectable({
	providedIn: 'root',
})
export class AnswerResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getInterview(): Observable<InterviewBody> {
		return this.httpService.authGet<InterviewBody>(this.endpoints.interview);
	}

	public getProfile(): Observable<IAnswers> {
		return this.httpService
			.authGet<IAnswers>(this.endpoints.profile)
			.pipe(mapApiResponseToAppResponse());
	}
}
