import { ControlValueAccessor } from '@angular/forms';

export abstract class CustomControl<T = string>
	implements ControlValueAccessor {
	public value?: T;
	public onChange?: (value: T) => void;
	public onTouched?: () => void;

	public writeValue(value: T): void {
		this.value = value;
	}

	public registerOnChange(fn: (value: T) => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
