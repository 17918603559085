import { createSelector } from '@ngrx/store';

import { getTravel } from '../../store/travel.selectors';

export const getReturnFlight = createSelector(
	getTravel,
	({ returnFlight }) => returnFlight.returnFlight,
);

export const getChangeReturnFlight = createSelector(
	getTravel,
	({ returnFlight }) => returnFlight.changeReturnFlight,
);
