import { createSelector } from '@ngrx/store';

import { getExtension } from '../extension.selectors';

export const getExtensionPayment = createSelector(
	getExtension,
	({ payment }) => payment,
);

export const getExtensionPaymentDetails = createSelector(
	getExtensionPayment,
	({ details }) => details,
);

export const getExtensionPaymentTransaction = createSelector(
	getExtensionPayment,
	({ transaction }) => transaction,
);
