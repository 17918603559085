import { SummaryCard } from '../../../../interfaces/summary-cards';

function careForSpecialNeedsChildAnswer(
	willingToBeSpecialNeedsCarer: string,
	isSpecialNeedsQualified: boolean,
): string {
	const positiveAnswer = ['Yes', 'Maybe'].includes(
		willingToBeSpecialNeedsCarer,
	);

	return isSpecialNeedsQualified && positiveAnswer
		? 'Reference provided'
		: null;
}

export function careForSpecialNeedsChildResolver(
	willingToBeSpecialNeedsCarer: string,
	isSpecialNeedsQualified: boolean,
): Record<'careForSpecialNeedsChild', SummaryCard> {
	return {
		careForSpecialNeedsChild: {
			title: 'Care for special needs child',
			icon: 'special-needs-qualified',
			answer: careForSpecialNeedsChildAnswer(
				willingToBeSpecialNeedsCarer,
				isSpecialNeedsQualified,
			),
		},
	};
}
