<ng-container
	*ngIf="
		simpleType(question.type) ||
			(question.answer | selected: 'Not specified') === 'Not specified';
		else complexType
	"
>
	<dl>
		<dt>{{ question.group.label }}</dt>
		<dd [innerHTML]="answer | selected: 'Not specified'"></dd>
	</dl>
</ng-container>

<ng-template #complexType>
	<ng-container *ngIf="question.type === 'questionList'">
		<app-answer-question-list [question]="question"> </app-answer-question-list>
	</ng-container>

	<ng-container *ngIf="question.type === 'parentList'">
		<app-answer-question-list [question]="question"> </app-answer-question-list>
	</ng-container>

	<ng-container *ngIf="question.type === 'experienceList'">
		<app-answer-experience-list [question]="question">
		</app-answer-experience-list>
	</ng-container>

	<ng-container *ngIf="question.type === 'workExperienceList'">
		<app-answer-work-experience-list [question]="question">
		</app-answer-work-experience-list>
	</ng-container>

	<ng-container *ngIf="question.type === 'modalQuestionList'">
		<ng-container [ngSwitch]="question.id">
			<ng-container *ngSwitchCase="'languages'">
				<app-answer-tile-list-languages [question]="question">
				</app-answer-tile-list-languages>
			</ng-container>

			<ng-container *ngSwitchCase="'interests'">
				<app-answer-tile-list-interests [question]="question">
				</app-answer-tile-list-interests>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<app-answer-tile-list-household [question]="question">
				</app-answer-tile-list-household>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="question.type === 'conditionalAgreement'">
		<ng-container *ngIf="question.id === 'smoking'">
			<app-answer-smoking [question]="question"> </app-answer-smoking>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="question.type === 'checkbox'">
		<app-answer-checkbox [question]="question"> </app-answer-checkbox>
	</ng-container>

	<ng-container *ngIf="question.type === 'checkboxListWithOther'">
		<app-answer-checkbox-list-with-other [question]="question">
		</app-answer-checkbox-list-with-other>
	</ng-container>

	<ng-container *ngIf="question.type === 'interview'">
		<app-answer-interview [question]="question"> </app-answer-interview>
	</ng-container>

	<ng-container *ngIf="question.type === 'addressAutocomplete'">
		<app-answer-address [question]="question"></app-answer-address>
	</ng-container>

	<ng-container *ngIf="question.type === 'dropdown'">
		<app-answer-dropdown
			[label]="question.group.label"
			[value]="question.answer"
			[options]="question.options"
		></app-answer-dropdown>
	</ng-container>

	<ng-container *ngIf="question.type === 'radioTile'">
		<app-answer-radio-tile
			[label]="question.group.label"
			[value]="question.answer"
			[options]="question.options"
		></app-answer-radio-tile>
	</ng-container>
</ng-template>
