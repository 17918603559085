import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BusyStatusIndicatorComponent } from './busy-status-indicator.component';

@NgModule({
	imports: [CommonModule],
	declarations: [BusyStatusIndicatorComponent],
	exports: [BusyStatusIndicatorComponent],
})
export class BusyStatusIndicatorModule {}
