export enum Auth0ScreenQueryParams {
	Login = 'login',
	SignUp = 'signUp',
	ForgotPassword = 'forgotPassword',
}

export enum Auth0ShowScreenEvent {
	Login = 'signin ready',
	SignUp = 'signup ready',
	ForgotPassword = 'forgot_password ready',
}
