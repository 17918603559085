import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpSandboxService } from '../../core/services/http-sandbox.service';
import {
	SummaryCardsAggregated,
	SummaryCards,
} from '../../../interfaces/summary-cards';
import { groupArrivalResolver } from './resolver/arrival';
import { childcareReferencesResolver } from './resolver/childcare-references';
import { specialDietResolver } from './resolver/diet';
import { drivingResolver } from './resolver/driving';
import { englishLevelResolver } from './resolver/english-proficiency';
import { siblingsResolver } from './resolver/family';
import { preferredAgeRangeOfChildrenResolver } from './resolver/preferences';
import { careForSpecialNeedsChildResolver } from './resolver/special-needs';

@Injectable({
	providedIn: 'root',
})
export class SummaryCardsService {
	constructor(private httpSandboxService: HttpSandboxService) {}

	public summaryCards$(): Observable<SummaryCards> {
		return this.httpSandboxService
			.getSummaryCards$()
			.pipe(map(summaryCardsResolver), filterHiddenTiles);
	}
}

function summaryCardsResolver(answers: SummaryCardsAggregated): SummaryCards {
	return {
		...careForSpecialNeedsChildResolver(
			answers.willingToBeSpecialNeedsCarer,
			answers.isSpecialNeedsQualified,
		),
		...specialDietResolver(answers.hasSpecialDiet, answers.specialDiet),
		...englishLevelResolver(answers.englishProficiency),
		...childcareReferencesResolver(answers.childcareReferences),
		...drivingResolver(
			answers.hasDrivingLicense,
			answers.drivingFrequency,
			answers.anticipatedDrivingLicenseDate,
			answers.drivingTrainingSinceDate,
			answers.drivingSinceDate,
			answers.drivingTransmission,
			answers.drivingConditions,
		),
		...siblingsResolver(answers.siblings),
		...preferredAgeRangeOfChildrenResolver(answers.preferredAgeRangeOfChildren),
		...groupArrivalResolver(
			answers.actualGroupArrival,
			answers.latestGroupArrival,
		),
	};
}

const filterHiddenTiles = map<SummaryCards, SummaryCards>(summaryCards => {
	return Object.keys(summaryCards).reduce((result, key) => {
		if (summaryCards[key].answer) {
			result[key] = summaryCards[key];
		}

		return result;
	}, {} as SummaryCards);
});
