import { IChildCareReferenceBody } from '@ccap/interfaces';
import { SummaryCard } from '../../../../interfaces/summary-cards';

function approvedChildcareExperience(
	experience: IChildCareReferenceBody[],
): IChildCareReferenceBody[] {
	return experience.filter(
		(answer: IChildCareReferenceBody) => answer.status === 'Approved',
	);
}

function childcareReferencesAnswer(
	childcareReferences: IChildCareReferenceBody[],
): string {
	if (!Array.isArray(childcareReferences)) {
		return null;
	}
	const set: Set<string> = approvedChildcareExperience(
		childcareReferences,
	).reduce((acc: Set<string>, answer: IChildCareReferenceBody) => {
		acc.add(answer.experienceType);
		return acc;
	}, new Set());
	return set.size > 0 ? Array.from(set).join(', ') : null;
}

export function childcareReferencesResolver(
	childcareReferences: IChildCareReferenceBody[],
): Record<'childcareReferences', SummaryCard> {
	return {
		childcareReferences: {
			title: 'Childcare Experience',
			icon: 'Childcare Experience',
			answer: childcareReferencesAnswer(childcareReferences),
		},
	};
}
