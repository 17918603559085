import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnswerAbstractListItemModule } from '../answer-abstract-list-item/answer-abstract-list-item.module';
import { AnswerQuestionListComponent } from './answer-question-list.component';

@NgModule({
	imports: [CommonModule, AnswerAbstractListItemModule],
	declarations: [AnswerQuestionListComponent],
	exports: [AnswerQuestionListComponent],
})
export class AnswerQuestionListModule {}
