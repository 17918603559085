import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IQuestionExtended, IQuestionWithValidation } from '@ccap/interfaces';
import { AnswerChangePayload } from '@ccap/question-engine/ui-types';
import {
	IDeleteResourcePayload,
	IDeleteResourceRemotePayload,
	ISaveAnswerNewPayload,
	ISaveAnswersRemotePayload,
	ISaveComplianceDocumentPayload,
	ISaveComplianceDocumentsSuccessPayload,
	ISaveCourseFilePayload,
	ISaveCourseFileSuccessPayload,
	ISaveCoursePayload,
	ISaveMediaFileMetadataPayload,
	ISaveQuestionItemPayload,
	ISaveQuestionsFilePayload,
	ISaveQuestionsMediaFileSuccessPayload,
} from '../interfaces';

export const getAllQuestions = createAction(
	'[Questions] Get All Questions',
	props<{ loadAnswers: boolean }>(),
);

export const getQuestionsSuccess = createAction(
	'[Questions] Get Questions Success',
	props<{ questions: IQuestionExtended[] }>(),
);

export const getQuestionsError = createAction(
	'[Questions] Get Questions Error',
	props<{ error: HttpErrorResponse }>(),
);

export const getAnswers = createAction('[Questions] Get Answers');

// [TODO] - strictly type
export const getAnswersSuccess = createAction(
	'[Questions] Get Answers Success',
	props<{ answers: any[] }>(),
);

// [TODO] - strictly type
export const getTravelAnswersSuccess = createAction(
	'[Questions] Get Travel Answers Success',
	props<{ answers: any }>(),
);

export const getTravelQuestions = createAction(
	'[Questions] Get Travel Questions',
);

export const getTravelQuestionsSuccess = createAction(
	'[Questions] Get Travel Questions Success',
	props<{ questions: IQuestionExtended[] }>(),
);

// [TODO] - strictly type
export const getExtensionAnswersSuccess = createAction(
	'[Questions] Get Extension Answers Success',
	props<{ answers: any }>(),
);

// [TODO] - strictly type
export const getCoursesAnswersSuccess = createAction(
	'[Questions] Get Courses Answers Success',
	props<{ answers: any }>(),
);

export const getExtensionQuestions = createAction(
	'[Questions] Get Extension Questions',
);

export const getExtensionQuestionsSuccess = createAction(
	'[Questions] Get Extension Questions Success',
	props<{ questions: IQuestionExtended[] }>(),
);

export const saveAnswers = createAction(
	'[Questions] Save Answers',
	props<ISaveAnswerNewPayload>(),
);

export const answerChange = createAction(
	'[Quesitons] Answer change',
	(payload: AnswerChangePayload) => ({ payload }),
);

export const saveAnswersLocal = createAction(
	'[Questions] Save Answers Local',
	props<{ questions: IQuestionWithValidation[] }>(),
);

export const saveAnswersRemote = createAction(
	'[Questions] Save Answers Remote',
	props<ISaveAnswersRemotePayload>(),
);

export const saveAnswersRemoteSuccess = createAction(
	'[Questions] Save Answers Remote Success',
);

// [TODO] - not used
export const saveAnswersRemoteError = createAction(
	'[Questions] Save Answers RemoteError',
	props<{ questions: IQuestionWithValidation[] }>(),
);

export const saveWorkExperienceAnswersRemote = createAction(
	'[Questions] Save Work Experience Answers Remote',
	props<ISaveQuestionItemPayload>(),
);

export const saveReferencesRemote = createAction(
	'[Questions] Save References Remote',
	props<ISaveQuestionItemPayload>(),
);

export const saveParentsRemote = createAction(
	'[Questions] Save Parents Remote',
	props<ISaveQuestionItemPayload>(),
);

export const saveCourseRemote = createAction(
	'[Questions] Save Source Remote',
	props<ISaveCoursePayload>(),
);

export const deleteResourceAction = createAction(
	'[Questions] Delete Resource',
	props<IDeleteResourcePayload>(),
);

export const deleteResourceRemote = createAction(
	'[Questions] Delete Resource Remote',
	props<IDeleteResourceRemotePayload>(),
);

export const deleteResourceRemoteSuccess = createAction(
	'[Questions] Delete Resource Remote Success',
);

export const saveMediaFileMetadata = createAction(
	'[Questions] Save Media File Metadata',
	props<ISaveMediaFileMetadataPayload>(),
);

export const saveCourseFile = createAction(
	'[Questions] Save Course File',
	props<ISaveCourseFilePayload>(),
);

export const saveCourseFileSuccess = createAction(
	'[Questions] Save Course File Success',
	props<ISaveCourseFileSuccessPayload>(),
);

export const saveMediaFile = createAction(
	'[Questions] Save Media File',
	props<ISaveQuestionsFilePayload>(),
);

export const saveMediaFileSuccess = createAction(
	'[Questions] Save Media File Success',
	props<ISaveQuestionsMediaFileSuccessPayload>(),
);

export const saveMediaFileError = createAction(
	'[Questions] Save Media File Error',
	props<{ questionID: string }>(),
);

export const saveComplianceDocument = createAction(
	'[Questions] Save Compliance Document',
	props<ISaveComplianceDocumentPayload>(),
);

export const saveComplianceDocumentSuccess = createAction(
	'[Questions] Save Compliance Document Success',
	props<ISaveComplianceDocumentsSuccessPayload>(),
);

export const saveComplianceDocumentError = createAction(
	'[Questions] Save Compliance Document Error',
	props<{ questionID: string }>(),
);

export const deleteComplianceDocumentRemoteSuccess = createAction(
	'[Questions] Delete Compliance Document Remote Success',
	props<{ complianceDocumentQuestion: IQuestionWithValidation }>(),
);
