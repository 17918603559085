import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuPairElement, AuPairElementId } from '@ccap/interfaces';

export const getElements = createFeatureSelector<AuPairElement[]>('elements');

const getElementIsVisible = (
	elements: AuPairElement[],
	elementId: AuPairElementId,
) => elements.find(({ id }) => id === elementId)?.visible || false;

export const getElementReportAConcernIsVisible = createSelector(
	getElements,
	elements => getElementIsVisible(elements, AuPairElementId.ReportAConcern),
);

export const getElementBusyStatusIsVisible = createSelector(
	getElements,
	elements => getElementIsVisible(elements, AuPairElementId.BusyStatus),
);

export const getElementEmbassyStatusIsVisible = createSelector(
	getElements,
	elements => getElementIsVisible(elements, AuPairElementId.EmbassyStatus),
);

export const getElementEmbassyPromptIsVisible = createSelector(
	getElements,
	elements => getElementIsVisible(elements, AuPairElementId.EmbassyPrompt),
);

export const getElementAgreementsMissingPromptIsVisible = createSelector(
	getElements,
	elements =>
		getElementIsVisible(elements, AuPairElementId.AgreementsMissingPrompt),
);
