import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { IChildCareReferenceBody, IReferenceBody } from '@ccap/interfaces';
import {
	addChildcareReference,
	addPersonalReference,
	patchChildcareReferences,
	patchPersonalReferences,
	removeChildcareReference,
	removePersonalReference,
	rollbackChildcareReferences,
	rollbackPersonalReferences,
	updateChildcareReferences,
	updatePersonalReferences,
} from './references.actions';

export interface ReferencesState {
	personal: IReferenceBody[];
	childcare: IChildCareReferenceBody[];
}

const personalReferencesReducer = createReducer<IReferenceBody[]>(
	null,
	on(
		updatePersonalReferences,
		rollbackPersonalReferences,
		(_, { references }) => references,
	),
	on(addPersonalReference, (state, { reference }) => [
		...state,
		reference as IReferenceBody,
	]),
	on(patchPersonalReferences, (state, { reference }) =>
		state
			? state.map(ref =>
					ref.referenceId === reference.referenceId ? reference : ref,
			  )
			: state,
	),
	on(removePersonalReference, (state, { referenceId }) =>
		state ? state.filter(ref => ref.referenceId !== referenceId) : state,
	),
);

const childcareReferencesReducer = createReducer<IChildCareReferenceBody[]>(
	null,
	on(
		updateChildcareReferences,
		rollbackChildcareReferences,
		(_, { references }) => references,
	),
	on(addChildcareReference, (state, { reference }) => [
		...state,
		reference as IChildCareReferenceBody,
	]),
	on(patchChildcareReferences, (state, { reference }) =>
		state
			? state.map(ref =>
					ref.referenceId === reference.referenceId ? reference : ref,
			  )
			: state,
	),
	on(removeChildcareReference, (state, { referenceId }) =>
		state ? state.filter(ref => ref.referenceId !== referenceId) : state,
	),
);

const reducer = combineReducers<ReferencesState>({
	personal: personalReferencesReducer,
	childcare: childcareReferencesReducer,
});

export function referencesReducer(
	state: ReferencesState,
	action: Action,
): ReferencesState {
	return reducer(state, action);
}
