import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthConfig } from './auth-config-interface';
import { AUTH_CONFIG } from './auth-injection-token';
import { AuthService } from './auth.service';

/**
 * Intercept HTTP requests and add the authorization header that identifies the user.
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	private readonly urlRegExp?: RegExp;

	constructor(
		private authService: AuthService,
		@Inject(AUTH_CONFIG) config: AuthConfig,
	) {
		this.urlRegExp =
			(config.unauthenticatedUrls &&
				config.unauthenticatedUrls.length &&
				new RegExp(
					config.unauthenticatedUrls.map(this.escapeStringForRegex).join('|'),
					'i',
				)) ||
			/.^/;
	}

	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (this.urlRegExp.test(req.url)) {
			return next.handle(req);
		}

		const headers = new HttpHeaders().set(
			'Authorization',
			this.authService.getAuthorizationHeader(),
		);
		const authorizedReq = req.clone({ headers });

		return next.handle(authorizedReq);
	}

	/**
	 * Escapes strings that contain characters which, if passed to a regex, would not be treated as string characters
	 * For example, in the string "culturalcare.com", the dot means "any" in regex, unless it's escaped to `\.`
	 */
	private escapeStringForRegex(url: string): string {
		return url.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
	}
}
