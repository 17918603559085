import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

import { HttpService } from '@ccap/shared/data-access';

interface CountryInfo {
	demonym: string;
	flag: string;
}

@Injectable({
	providedIn: 'root',
})
export class CountryInfoResource {
	private endpoint = 'https://restcountries.com/v2';

	constructor(private httpService: HttpService) {}

	public getCountryInfo(nationality: string): Observable<CountryInfo> {
		if (!nationality) {
			return of({ demonym: null, flag: null });
		}

		const isTwoLetterCountryCode = nationality.length === 2;

		let countryEndpoint: string;

		if (isTwoLetterCountryCode) {
			countryEndpoint = `${this.endpoint}/alpha/${nationality.toLowerCase()}`;
		} else {
			countryEndpoint = `${this.endpoint}/name/${nationality.toLowerCase()}`;
		}

		return this.httpService.authGet(countryEndpoint).pipe(
			timeout(1500),
			map((data: any[] | Record<string, unknown>) => {
				if (Array.isArray(data)) {
					return data[0];
				}
				return data;
			}),
			map(({ demonym, flags }) => {
				return {
					demonym,
					flag: flags[0],
				};
			}),
			catchError(() => of({ demonym: null, flag: null })),
		);
	}

	public getCountryName(countryCode: string): Observable<string> {
		const countryCodeEndpoint = `${
			this.endpoint
		}/alpha/${countryCode.toLowerCase()}`;

		return this.httpService.authGet(countryCodeEndpoint).pipe(
			timeout(1500),
			map(({ name }) => name),
			catchError(() => of('')),
		);
	}
}
