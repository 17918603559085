import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SubSectionDescriptionModule } from '../sub-section-description/sub-section-description.module';
import { AnswersModule } from '../answers/answers.module';
import { SubSectionComponent } from './index';

@NgModule({
	imports: [CommonModule, SubSectionDescriptionModule, AnswersModule],
	declarations: [SubSectionComponent],
	exports: [SubSectionComponent],
})
export class SubSectionModule {}
