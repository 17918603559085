import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';

import { InterviewBody } from '@ccap/interfaces';
import { AnswerAbstractListComponent } from '../../answer-abstract-list/answer-abstract-list.component';

@Component({
	selector: 'app-answer-interview',
	templateUrl: 'answer-interview.component.html',
})
export class AnswerInterviewComponent extends AnswerAbstractListComponent {
	constructor(private datePipe: DatePipe) {
		super();
	}

	public get answers(): InterviewBody {
		const answer = this.question.answer as InterviewBody;
		return answer && !!answer.date
			? ({
					...answer,
					date: this.datePipe.transform(answer['date'], 'dd MMMM yyyy'),
			  } as InterviewBody)
			: answer;
	}
}
