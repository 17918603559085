import { combineReducers, createReducer, on } from '@ngrx/store';

import { deleteFromArray, replaceInArray } from '@ccap/shared/utils';
import { AsyncState, createAsyncReducer } from '../async';
import { queueReducers } from '../queue-reducers';
import { AsyncItem, AsyncListActionCreators } from './async-list.models';

export const createAsyncListReducer = <T extends AsyncItem>(
	config: AsyncListActionCreators<T>,
	initialState: T[] = [],
) => {
	type Data = T[];

	const {
		fetchItemsSuccess,
		addItemSuccess,
		updateItem,
		deleteItem,
		defaultItems,
	} = config;

	const asyncReducer = createAsyncReducer<Data>(config);

	const asyncListReducer = combineReducers<AsyncState<Data>>({
		status: state => state,

		error: state => state,

		data: createReducer<Data>(
			initialState,

			on(fetchItemsSuccess, (_state, { payload }) =>
				payload.length ? payload : defaultItems,
			),

			on(addItemSuccess, (state, { payload }) => {
				return [...state, payload as T];
			}),

			on(updateItem, (state, { answerId, payload }) => {
				const index = state.findIndex(({ id }) => id === answerId);

				return replaceInArray(state, index, {
					...state[index],
					...payload,
				});
			}),

			on(deleteItem, (state, { answerId }) => {
				const index = state.findIndex(({ id }) => id === answerId);

				return deleteFromArray(state, index);
			}),
		),
	});

	return queueReducers(asyncReducer, asyncListReducer);
};
