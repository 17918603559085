import { IAbstractListValue } from '@ccap/interfaces';
import { SummaryCard } from '../../../../interfaces/summary-cards';

function siblingsTitle(siblings: IAbstractListValue[]): string {
	if (!Array.isArray(siblings) || !siblings.length) {
		return null;
	}

	return siblings.length >= 3 ? 'Big family' : 'Medium family';
}

function siblingsIcon(siblings: IAbstractListValue[]): string {
	if (!Array.isArray(siblings) || !siblings.length) {
		return null;
	}

	switch (siblings.length) {
		case 1:
			return 'Small family';
		case 2:
			return 'Medium family';
		default:
			return 'Big family';
	}
}

function siblingsAnswer(siblings: IAbstractListValue[]): string {
	if (!Array.isArray(siblings) || !siblings.length) {
		return null;
	}

	return `${siblings.length} sibling${siblings.length > 1 ? 's' : ''}`;
}

export function siblingsResolver(
	siblings: IAbstractListValue[],
): Record<'siblings', SummaryCard> {
	return {
		siblings: {
			title: siblingsTitle(siblings),
			icon: siblingsIcon(siblings),
			answer: siblingsAnswer(siblings),
		},
	};
}
