import { SubSections } from '../../interfaces/schema';
export const subSections: SubSections = {
	personalDetailsForm: {
		title: 'Personal details',
		questions: [
			'firstName',
			'lastName',
			'nickname',
			'gender',
			'nationality',
			'dob',
			'countryOfBirth',
			'cityOfBirth',
		],
	},
	contactDetails: {
		title: 'Contact Details',
		questions: [
			'email',
			'address',
			'internationalPhone',
			'hasWhatsApp',
			'whatsApp',
			'skype',
		],
	},
	familyLifeForm: {
		title: 'Family life',
		questions: ['familyLife'],
	},
	familyForm: {
		title: 'Parents or guardians',
		questions: ['parents'],
	},
	siblingsForm: {
		title: 'Brothers and sisters',
		questions: ['hasSiblings', 'siblings'],
	},
	lifestyleForm: {
		title: 'Lifestyle',
		questions: [
			'isInRelationship',
			'affectiveRelationship',
			'hasTattoos',
			'tattoos',
			'hasPiercings',
			'piercings',
			'religionFollowed',
			'plansAfterAuPairYear',
		],
	},
	interviewSubSection: {
		title: 'Interview',
		questions: ['interview'],
	},
	householdCompositionForm: {
		title: 'Host family preferences',
		questions: [
			'preferredAgeRangeOfChildren',
			'numberOfChildrenCaredFor',
			'willingToBeSpecialNeedsCarer',
			'kindsOfHouseholdsComfortableLivingIn',
		],
	},
	familyPetsForm: {
		title: 'Host family pets',
		questions: ['willLiveWithFamilyWithPets', 'petsComfortableWith'],
	},
	dietaryNeedsForm: {
		title: 'Dietary needs',
		questions: [
			'hasSpecialDiet',
			'specialDiet',
			'willingToLiveWithFamilyWithDifferentDiet',
			'willingToPrepareDifferentDiet',
		],
	},
	childcareReferencesForm: {
		title: 'Childcare references',
		questions: ['childcareReferences'],
	},
	personalReferencesForm: {
		title: 'Personal recommendations',
		questions: ['hasPersonalReferences', 'personalReferences'],
	},
	workExperiencesForm: {
		title: 'Additional work references',
		questions: ['hasWorkExperience', 'workExperiences'],
	},
	travelExperienceForm: {
		title: 'Travel',
		questions: ['hasTravelledOnYourOwn', 'travelExperiences'],
	},
	livingOnYourOwn: {
		title: 'Independent living',
		questions: ['hasLivedOnYourOwn', 'livingByYourSelfExperiences'],
	},
	householdActivitiesForm: {
		title: 'Chores and such',
		questions: ['houseHoldActivities'],
	},
	languagesForm: {
		title: 'Languages',
		questions: ['interview', 'speaksNonEnglishLanguages', 'languages'],
	},
	secondarySchoolForm: {
		title: 'High school or vocational school',
		questions: ['secondarySchool'],
	},
	universityCollegeForm: {
		title: 'University or college',
		questions: ['hasAttendedUniversityOrCollege', 'universityOrCollege'],
	},
	certificationsForm: {
		title: 'Certifications',
		questions: ['hasTrainingCertifications', 'trainingCertifications'],
	},
	drivingForm: {
		title: 'Driving',
		questions: [
			'hasDrivingLicense',
			'drivingSinceDate',
			'drivingFrequency',
			'drivingTransmission',
			'drivingConditions',
			'anticipatedDrivingLicenseDate',
			'drivingTrainingSinceDate',
		],
	},
	swimmingForm: {
		title: 'Swimming',
		questions: ['isComfortableSwimming', 'swimmingLevel'],
	},
	selectInterestsForm: {
		title: 'Interests',
		questions: ['interests'],
	},
	personalityTestForm: {
		title: 'Personality assessment',
		questions: [
			'140',
			'134',
			'136',
			'132',
			'135',
			'133',
			'139',
			'138',
			'137',
			'126',
			'141',
			'152',
			'147',
			'148',
			'149',
			'150',
			'142',
			'151',
			'153',
			'143',
			'144',
			'145',
			'146',
			'125',
			'127',
			'128',
			'119',
			'129',
			'130',
			'131',
			'154',
			'156',
			'157',
			'158',
			'159',
			'160',
			'161',
			'162',
			'155',
			'163',
			'164',
			'165',
			'166',
			'167',
			'120',
			'121',
			'122',
			'123',
			'124',
			'168',
		], // TODO fetch from API
	},
	eyesAndEarsForm: {
		title: 'Eyes and ears',
		questions: [
			'hasAudioVisualAids',
			'audioVisualAids',
			'audioVisualAidsDescription',
		],
	},
	medicalTreatmentsForm: {
		title: 'Medical treatments',
		questions: [
			'hasLastTwoYearsMedicalTreatments',
			'lastTwoYearsMedicalTreatments',
		],
	},
	medicationForm: {
		title: 'Medications',
		questions: ['isTakingMedication', 'medication'],
	},
	counsellingForm: {
		title: 'Psychiatric or psychological counseling',
		questions: [
			'hasPsychiatricPsychologicalCounselling',
			'psychiatricPsychologicalCounsellingList',
		],
	},
	smoking: {
		title: 'Smoking',
		questions: ['smoking'],
	},
	hospitalizationsForm: {
		title: 'Hospitalizations',
		questions: ['hasHospitalizations', 'hospitalizations'],
	},
	impairmentsForm: {
		title: 'Any other current conditions we should be aware of?',
		questions: ['hasOtherHealthConditions', 'otherHealthConditions'],
	},
	immunisationForm: {
		title: 'Immunization',
		questions: ['willingToBeImmunized', 'notWillingImmunizedExplanation'],
	},
	allergiesForm: {
		title: 'Allergies',
		questions: ['hasAllergies', 'allergies'],
	},
	translatedMedicalNote: {
		title: 'Medical Note',
		questions: [],
	},
};
