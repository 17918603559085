import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MultiChoiceDictModule } from '@ccap/shared/pipes';
import { PreserveNewlineModule, QuestionAnswerModule } from '@ccap/shared/ui';
import { MoreLessModule } from '@ccap/shared/ui/layout';
import { TabsModule } from '@ccap/shared/ui/navigation';
import { FileDownloadModule } from '../../shared/components/file-download/file-download.module';
import { ExtensionDetailsComponent } from './extension-details/extension-details.component';
import { InCountryComponent } from './in-country.component';
import { PerspectivesComponent } from './perspectives/perspectives.component';
import { TransitionDetailsComponent } from './transition-details/transition-details.component';

@NgModule({
	imports: [
		CommonModule,
		FileDownloadModule,
		TabsModule,
		MoreLessModule,
		MultiChoiceDictModule,
		QuestionAnswerModule,
		PreserveNewlineModule,
	],
	declarations: [
		InCountryComponent,
		PerspectivesComponent,
		ExtensionDetailsComponent,
		TransitionDetailsComponent,
	],
	exports: [InCountryComponent],
})
export class InCountryModule {}
