import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-host-family-letter',
	templateUrl: 'host-family-letter.component.html',
	styleUrls: ['host-family-letter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostFamilyLetterComponent {
	@Input() public hostFamilyLetter?: string;
}
