import { Action, createReducer, on } from '@ngrx/store';

import { EmergencyContactDto } from '@ccap/au-pair/data-access/models';
import { replaceInArray } from '@ccap/shared/utils';
import {
	addEmergencyContact,
	addEmergencyContactLocally,
	addEmergencyContactSuccess,
	deleteEmergencyContact,
	deleteEmergencyContactLocally,
	fetchEmergencyContactsSuccess,
	updateEmergencyContact,
} from './emergency-contact.actions';

export type EmergencyContactState = EmergencyContactDto[];

const reducer = createReducer<EmergencyContactState>(
	[],
	on(addEmergencyContactLocally, state => [
		...state,
		{} as EmergencyContactDto,
	]),
	on(fetchEmergencyContactsSuccess, (_, { contacts }) => contacts),
	on(addEmergencyContact, (state, { contact }) =>
		replaceInArray(state, state.length - 1, contact as EmergencyContactDto),
	),
	on(addEmergencyContactSuccess, (state, { contact }) =>
		replaceInArray(state, state.length - 1, contact),
	),
	on(updateEmergencyContact, (state, { contactId, contact }) => {
		const index = state.findIndex(({ id }) => id === contactId);

		return replaceInArray(state, index, {
			...state[index],
			...contact,
		});
	}),
	on(deleteEmergencyContact, (state, { contactId }) =>
		state.filter(ref => ref.id !== contactId),
	),
	on(deleteEmergencyContactLocally, state => state.filter(ref => ref.id)),
);

export function emergencyContactsReducer(
	state: EmergencyContactState,
	action: Action,
): EmergencyContactState {
	return reducer(state, action);
}
