import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';

import { ModalService } from '@ccap/au-pair/ui';
import { IQuestionExtended } from '@ccap/interfaces';
import {
	DeclineReasons,
	LinkButtonText,
	LinkStatus,
	ILinkData,
} from '../../../../interfaces/links';
import { ProfileService } from '../../../core/services/profile.service';
import { LinkSandboxService } from '../link-sandbox.service';
import { ConnectInAModalComponent } from '../../../core/modal/connect-in-a-modal/connect-in-a-modal.component';
import { DisconnectInAModalComponent } from '../../../core/modal/disconnect-in-a-modal/disconnect-in-a-modal.component';

@Component({
	selector: 'app-connect',
	templateUrl: './connect.component.html',
	styleUrls: ['./connect.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectComponent implements OnInit {
	@Input()
	public name = '';

	public auPairId = this.profileService.getCurrentProfileId();

	public ready$?: Observable<boolean>;
	public buttonEnabled$?: Observable<boolean>;
	public buttonText$?: Observable<LinkButtonText>;

	private readonly questionConnectId = 'questionConnect';
	private readonly questionDisconnectId = 'questionDisconnect';

	constructor(
		private linkSandbox: LinkSandboxService,
		private profileService: ProfileService,
		private modalService: ModalService,
	) {}

	public ngOnInit(): void {
		this.linkSandbox.fetchAuPairLink(this.auPairId);
		this.linkSandbox.fetchDeclineReason(this.auPairId);

		this.ready$ = this.linkSandbox
			.getAuPairConnection(this.auPairId)
			.pipe(map(Boolean), distinctUntilChanged());

		this.buttonEnabled$ = this.linkSandbox
			.getAuPairConnection(this.auPairId)
			.pipe(map(this.buttonEnabled), distinctUntilChanged());

		this.buttonText$ = this.linkSandbox
			.getAuPairConnection(this.auPairId)
			.pipe(map(this.buttonText), distinctUntilChanged());
	}

	public connectToggle(): void {
		this.linkSandbox
			.getAuPairConnection(this.auPairId)
			.pipe(first())
			.subscribe(connect => {
				const isPendingPermitted = this.linkSandbox.isStatusPermitted(
					connect.permittedStatuses,
					LinkStatus.pending,
				);

				const isDecliningPermitted = this.linkSandbox.isStatusPermitted(
					connect.permittedStatuses,
					LinkStatus.declined,
				);

				if (isPendingPermitted) {
					const options = {
						questions: [this.questionConnect()],
						formSteps: [this.formGroupConnect()],
						payload: {
							name: this.name,
						},
					};

					this.modalService.openModal(ConnectInAModalComponent, options);
				}

				if (isDecliningPermitted) {
					const declineReasonType =
						connect.status === LinkStatus.pending
							? 'cancellation'
							: 'connection';

					this.questionDisconnect(declineReasonType)
						.pipe(first())
						.subscribe(disconnectedQuestion => {
							const disconnectedFormStep = this.formGroupDisconnect();

							const options = {
								questions: [disconnectedQuestion],
								formSteps: [disconnectedFormStep],
							};

							this.modalService.openModal(DisconnectInAModalComponent, options);
						});
				}
			});
	}

	private questionConnect(): IQuestionExtended {
		return {
			id: this.questionConnectId,
			type: 'textarea',
			typeVariations: {
				maxLength: 1000,
			},
		} as IQuestionExtended;
	}

	private questionDisconnect(
		declineReasonType: keyof DeclineReasons,
	): Observable<IQuestionExtended> {
		return this.linkSandbox
			.getDeclineReasonOfType(this.auPairId, declineReasonType)
			.pipe(
				filter(declineReasonOfType => Boolean(declineReasonOfType.length)),
				map(
					declineReasonOfType =>
						({
							id: this.questionDisconnectId,
							options: declineReasonOfType.map(reason => ({
								label: reason,
								value: reason,
							})),
						} as IQuestionExtended),
				),
			);
	}

	private formGroupConnect(): FormGroup {
		return new FormGroup({
			[this.questionConnectId]: new FormControl('', [Validators.required]),
		});
	}

	private formGroupDisconnect(): FormGroup {
		return new FormGroup({
			[this.questionDisconnectId]: new FormControl('', [Validators.required]),
		});
	}

	private buttonEnabled(connection: ILinkData): boolean {
		return !!connection.permittedStatuses.length;
	}

	private buttonText(connection: ILinkData): LinkButtonText {
		return LinkButtonText[connection.status];
	}
}
