import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
	IBusyStatus,
	IBusyStatusOptions,
} from '@ccap/au-pair/data-access/models';
import { BusyStatusActions, BusyStatusActionTypes } from './actions';

export interface State extends IBusyStatus, IBusyStatusOptions {}

export const initialBusyStatusState: State = {
	status: null,
	statuses: [],
};

export function busyStatusReducer(
	state: State = initialBusyStatusState,
	action: BusyStatusActions,
): State {
	switch (action.type) {
		case BusyStatusActionTypes.GetSuccess:
		case BusyStatusActionTypes.Set:
		case BusyStatusActionTypes.GetOptionsSuccess: {
			return { ...state, ...action.payload };
		}

		default: {
			return state;
		}
	}
}

export const selectBusyStatus = createFeatureSelector<State>('busyStatus');

export const selectStatusFromBusyStatus = createSelector(
	selectBusyStatus,
	(selectedState: State) => selectedState.status,
);

export const selectStatusOptionsFromBusyStatus = createSelector(
	selectBusyStatus,
	(selectedState: State) =>
		selectedState.statuses.map(status => ({
			label: status,
			value: status,
		})),
);
