import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
	selector: 'select[efInput], input[efInput], textarea[efInput]',
})
export class InputDirective {
	@HostBinding('class.ef-input')
	public get isText() {
		return !this.isBoolean;
	}

	@HostBinding('class.ef-boolean__input')
	public get isBoolean() {
		return ['radio', 'checkbox'].includes(
			this.elemRef.nativeElement.type?.toLowerCase(),
		);
	}

	public get isSelect() {
		return this.elemRef.nativeElement.tagName.toLowerCase() === 'select';
	}

	public get isTextArea() {
		return this.elemRef.nativeElement.tagName.toLowerCase() === 'textarea';
	}

	@HostBinding('class.-is-loading')
	@Input()
	public isLoading = false;

	constructor(private elemRef: ElementRef) {}
}
