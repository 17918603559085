import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PreserveNewlineComponent } from './preserve-newline.component';

@NgModule({
	declarations: [PreserveNewlineComponent],
	exports: [PreserveNewlineComponent],
	imports: [CommonModule],
})
export class PreserveNewlineModule {}
