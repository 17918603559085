import { Inject, Injectable } from '@angular/core';
import merge from 'lodash/merge';

import { AUTH_CONFIG } from '../auth-injection-token';
import { defaultLockOptions } from '../default-config';
import { AuthConfig } from '../auth-config-interface';

/**
 * Auth0 Lock Factory.
 */
/* istanbul ignore next */
@Injectable()
export class Auth0LockFactory {
	private readonly auth0Lib: Promise<{
		Auth0Lock: Auth0LockStatic;
	}>;

	constructor(@Inject(AUTH_CONFIG) private config: AuthConfig) {
		// Lazy-loading - improve performance
		this.auth0Lib = import('auth0-lock') as any;
	}

	/**
	 * Creates an Auth0Lock instance as per configuration settings.
	 */
	public async create(
		dynamicOptions: Auth0LockConstructorOptions,
	): Promise<Auth0LockStatic> {
		const { Auth0Lock } = await this.auth0Lib;

		const { clientID, domain } = this.config.clientOptions;
		const lockOptions = merge(
			defaultLockOptions,
			dynamicOptions,
			this.config.lockOptions,
		);

		return new Auth0Lock(clientID, domain, lockOptions);
	}
}
