import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BooleanInputWrapperDirective } from './boolean-input-wrapper.directive';
import { InputWrapperDirective } from './input-wrapper.directive';
import { InputDirective } from './input.directive';
import { InputMarkAsDirective } from './input-mark-as.directive';

@NgModule({
	declarations: [
		InputDirective,
		InputWrapperDirective,
		InputMarkAsDirective,
		BooleanInputWrapperDirective,
	],
	exports: [
		InputDirective,
		InputWrapperDirective,
		InputMarkAsDirective,
		BooleanInputWrapperDirective,
	],
	imports: [CommonModule],
})
export class InputModule {}
