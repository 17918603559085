<section class="metrics">
	<ng-container *ngFor="let color of sortedColors$ | async">
		<p>
			<span>{{ colorMap[color] }}</span>
			<ccap-progress-bar
				class="metrics__progress-bar"
				[color]="color"
				size="large"
				[percentage]="getPercentageResult(color) | number: '1.0-0'"
			>
			</ccap-progress-bar>
		</p>
	</ng-container>
</section>
