<ccap-header-wrapper>
	<ccap-logo></ccap-logo>

	<nav class="header__nav" *ngIf="routes && routes.length">
		<a
			href="javascript:void(0)"
			id="e2e-header-menu"
			class="header__nav__settings-link"
			(click)="toggleDropdown()"
		>
			<div class="header__nav-route">
				<svg class="header__nav-route-icon" xmlns="http://www.w3.org/2000/svg">
					<ng-container *ngIf="!toggled">
						<path
							fill="#00314d"
							d="M0.686 3.543h14.629c0.377 0 0.686-0.309 0.686-0.686s-0.309-0.686-0.686-0.686h-14.629c-0.377 0-0.686 0.309-0.686 0.686s0.309 0.686 0.686 0.686z"
						/>
						<path
							fill="#00314d"
							d="M0.686 8.686h14.629c0.377 0 0.686-0.309 0.686-0.686s-0.309-0.686-0.686-0.686h-14.629c-0.377 0-0.686 0.309-0.686 0.686s0.309 0.686 0.686 0.686z"
						/>
						<path
							fill="#00314d"
							d="M0.686 13.829h14.629c0.377 0 0.686-0.309 0.686-0.686s-0.309-0.686-0.686-0.686h-14.629c-0.377 0-0.686 0.309-0.686 0.686s0.309 0.686 0.686 0.686z"
						/>
					</ng-container>
					<ng-container *ngIf="toggled">
						<path
							fill="#00314d"
							d="M16 0.727l-0.727-0.727-7.273 7.273-7.273-7.273-0.727 0.727 7.273 7.273-7.273 7.273 0.727 0.727 7.273-7.273 7.273 7.273 0.727-0.727-7.273-7.273z"
						/>
					</ng-container>
				</svg>
				<div class="header__nav-route-text">Menu</div>
			</div>
		</a>

		<div class="header__nav-dropdown-menu-container">
			<ul
				class="header__nav-dropdown-menu"
				[ngClass]="{ 'header__nav-dropdown-menu--open': toggled }"
			>
				<ng-container
					*ngFor="let section of routes; trackBy: trackByFn; last as isLast"
				>
					<ng-container *ngFor="let route of section; trackBy: trackByFn">
						<li class="header__nav-dropdown-link">
							<a
								id="e2e-{{
									route.title
										.toLowerCase()
										.split(' ')
										.join('-')
								}}"
								class="header__nav-dropdown-link-text"
								[class.header__nav-dropdown-link-text--disabled]="
									route.isEnabled === false
								"
								(click)="onMenuLinkClicked(route)"
							>
								{{ route.title }}
							</a>
						</li>
					</ng-container>

					<ng-container *ngIf="!isLast">
						<div class="header__nav-dropdown-spacer"></div>
					</ng-container>
				</ng-container>
			</ul>
		</div>
	</nav>
</ccap-header-wrapper>
