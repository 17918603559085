import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	constructor(private route: ActivatedRoute) {}

	public getCurrentProfileId(): string | null | undefined {
		/*
		 * Non components get the root route injected, which does not have any information about the current routes params
		 */
		if (!this.route.firstChild || !this.route.firstChild.firstChild) {
			return null;
		}

		return this.route.firstChild.firstChild.snapshot.paramMap.get('userId');
	}
}
