import { Inject, Injectable } from '@angular/core';

import {
	ApiEndpoints,
	ApiTokens,
	API_ENDPOINTS,
	API_TOKENS,
} from './endpoints.config';

const removeTrailingSlash = (str: string): string => str.replace(/\/$/, '');

@Injectable({
	providedIn: 'root',
})
export class Endpoints {
	private endpoints?: ApiEndpoints;

	constructor(
		@Inject(API_ENDPOINTS) endpoints: ApiEndpoints,
		@Inject(API_TOKENS) private tokens: ApiTokens,
	) {
		this.endpoints = Object.keys(endpoints).reduce((ep, key) => {
			return { ...ep, [key]: removeTrailingSlash(endpoints[key]) };
		}, {} as ApiEndpoints);
	}
	public get elements(): string {
		return `${this.endpoints.apollo}/v1.0/au-pair/elements`;
	}

	public get frontPageWidgets(): string {
		return `${this.endpoints.apollo}/v1.0/au-pair/front-page-widgets`;
	}

	public get pages(): string {
		return `${this.endpoints.apollo}/v1.0/au-pair/pages`;
	}

	public get matchesDocuments(): string {
		return `${this.matches}/documents`;
	}

	public get matches(): string {
		return `${this.endpoints.pink}/v1.0/matches`;
	}

	public get agreements(): string {
		return `${this.matches}/agreements`;
	}

	public get accountsAupairs(): string {
		return `${this.endpoints.pink}/v2.0/accounts/aupairs`;
	}

	public get createAccount(): string {
		return `${this.endpoints.pink}/v2.0/accounts/aupairs`;
	}

	public get passwordReset(): string {
		return `${this.endpoints.pink}/v2.0/accounts/aupairs/passwordreset`;
	}

	public get salesCountry(): string {
		return `${this.endpoints.pink}/v2.0/accounts/aupairs/salescountry`;
	}

	public get aupairs(): string {
		return `${this.endpoints.pink}/v1.2/aupairs`;
	}

	public get progress(): string {
		return `${this.endpoints.pink}/v1.0/progress`;
	}

	public get familyLinkDeclineReasons(): string {
		return `${this.endpoints.family}/v1.0/links/declineReasons`;
	}

	public get familyLinks(): string {
		return `${this.endpoints.family}/v1.0/links/aupair`;
	}

	public get busyStatus(): string {
		return `${this.endpoints.pink}/v1.0/busystatus`;
	}

	public get childcareReferences(): string {
		return `${this.endpoints.pink}/v2.0/references/childcare`;
	}

	public get contactDetails(): string {
		return `${this.endpoints.pink}/v1.0/ContactDetails`;
	}

	public get courses(): string {
		return `${this.endpoints.pink}/v2.0/courses`;
	}

	public get deleteAccount(): string {
		return `${this.endpoints.pink}/v1.0/DataProtection/deleterequest`;
	}

	public get complianceDocument(): string {
		return `${this.endpoints.pink}/v3.0/documents`;
	}

	public get documentsTypes(): string {
		return `${this.endpoints.pink}/v3.0/documents/types`;
	}

	public get document(): string {
		return `${this.endpoints.pink}/v2.1/documents`;
	}

	public get documentsByKey(): string {
		return `${this.endpoints.pink}/v2.1/documents/key`;
	}

	public get downloadInformation(): string {
		return `${this.endpoints.pink}/v1.0/DataProtection/datarequest`;
	}

	public get emergencyContacts(): string {
		return `${this.endpoints.pink}/v1.0/contactdetails/emergency`;
	}

	public get extension(): string {
		return `${this.endpoints.pink}/v1.0/extension`;
	}

	public get extensionPayment(): string {
		return `${this.extension}/payment`;
	}

	public get extensionStatus(): string {
		return `${this.endpoints.pink}/v1.0/extension/section`;
	}

	public get flights(): string {
		return `${this.endpoints.pink}/v2.0/flights`;
	}

	public get returnFlight(): string {
		return `${this.endpoints.pink}/v2.0/flights/returnflighturl`;
	}

	public get changeReturnFlight(): string {
		return `${this.endpoints.pink}/v2.0/flights/returnflightchangeurl`;
	}

	public get contentLocales(): string {
		return `/assets/content-locales.json`;
	}

	public get hasWorkExperience(): string {
		return `${this.endpoints.pink}/v1.0/WorkExperience`;
	}

	public get health(): string {
		return `${this.endpoints.pink}/v1.0/health`;
	}

	public get image(): string {
		return `${this.endpoints.pink}/v1.1/images`;
	}

	public get inCountry(): string {
		return `${this.endpoints.pink}/v2.0/incountry`;
	}

	public get interview(): string {
		return `${this.endpoints.pink}/v1.0/interview`;
	}

	public get linkDeclineReasons(): string {
		return `${this.endpoints.pink}/v2.0/Links/declinereasons`;
	}

	public get links(): string {
		return `${this.endpoints.pink}/v2.0/Links`;
	}

	public get personalityTestAnswers(): string {
		return `${this.endpoints.pink}/v1.0/personalitytest/answers`;
	}

	public get personalityTestReport(): string {
		return `${this.endpoints.pink}/v1.0/personalitytest/report`;
	}

	public get personalityTestResults(): string {
		return `${this.endpoints.pink}/v1.0/personalitytest/results`;
	}

	public get personalityTestStatus(): string {
		return `${this.endpoints.pink}/v1.0/personalitytest/status`;
	}

	public get personalReferences(): string {
		return `${this.endpoints.pink}/v2.0/references/personal`;
	}

	public get hostFamilyReferences(): string {
		return `${this.endpoints.pink}/v1.0/references/hostfamily`;
	}

	public get lccReferences(): string {
		return `${this.endpoints.pink}/v1.0/references/lcc`;
	}

	public get profile(): string {
		return `${this.endpoints.pink}/v1.0/Profile`;
	}

	public get restrictedaccess(): string {
		return `${this.endpoints.pink}/v1.0/restrictedaccess`;
	}

	public get schema(): string {
		return `${this.endpoints.schema}/v2`;
	}

	public get marketQualificationQuestions(): string {
		return `${this.endpoints.pink}/v1.1/Questions/preQualification`;
	}

	public get preQualifications(): string {
		return `${this.endpoints.pink}/v1.0/PreQualifications`;
	}

	public get status(): string {
		return `${this.endpoints.pink}/v1.0/status`;
	}

	public get travelInsurance(): string {
		return `${this.endpoints.pink}/v1.0/travelinsurance`;
	}

	public get unitedStatesContactDetails(): string {
		return `${this.endpoints.pink}/v1.0/ContactDetails/unitedstates`;
	}

	public get video(): string {
		return `${this.endpoints.pink}/v1.0/videos`;
	}

	public get visa(): string {
		return `${this.endpoints.pink}/v1.0/visa`;
	}

	public get workExperiences(): string {
		return `${this.endpoints.pink}/v1.0/WorkExperience/WorkExperiences`;
	}

	public get culturalCareContacts(): string {
		return `${this.endpoints.pink}/v1.0/contactdetails/culturalcare`;
	}

	public get currentHostFamilies(): string {
		return `${this.endpoints.pink}/v2.0/HostFamilies/current`;
	}

	public get futureHostFamilies(): string {
		return `${this.endpoints.pink}/v2.0/HostFamilies/future`;
	}

	public get concerns(): string {
		return `${this.endpoints.pink}/v1.0/concerns`;
	}

	public get parents(): string {
		return `${this.endpoints.pink}/v1.0/ParentsGuardians`;
	}

	public getPrivacyPolicyContent(locale: string): string {
		return `${this.endpoints.contentful}/4LKz9bcgv6Gm00eWsuWe0?access_token=${this.tokens.contentful}&locale=${locale}`;
	}

	public getLegalTextContent(locale: string): string {
		return `${this.endpoints.contentful}/J8zIt3JXig0UyMUcgGoss?access_token=${this.tokens.contentful}&locale=${locale}`;
	}
}
