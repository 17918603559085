import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlToucherService } from './control-toucher.service';
import { ModalComponentFactoryService } from './modal-component-factory.service';
import { ModalMainComponent } from './modal-main.component';
import { ModalRootComponent } from './modal-root.component';
import { ModalScrollService } from './modal-scroll.service';
import { ModalService } from './modal.service';

@NgModule({
	imports: [CommonModule, OverlayModule],
	declarations: [ModalRootComponent, ModalMainComponent],
	providers: [
		ModalComponentFactoryService,
		ModalService,
		ModalScrollService,
		ControlToucherService,
	],
})
export class ModalModule {}
