import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	CreateParentPayload,
	ParentDto,
	UpdateParentPayload,
} from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class ParentsResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getParents(): Observable<ParentDto[]> {
		return this.httpService.authGet<ParentDto[]>(this.endpoints.parents);
	}

	public createParent(reference: CreateParentPayload): Observable<ParentDto> {
		return this.httpService.authPost<ParentDto>(
			this.endpoints.parents,
			reference,
		);
	}

	public updateParent(
		parentId: string,
		reference: UpdateParentPayload,
	): Observable<ParentDto> {
		return this.httpService.authPatch<ParentDto>(
			`${this.endpoints.parents}/${parentId}`,

			reference,
		);
	}

	public deleteParent(parentId: string): Observable<ParentDto> {
		return this.httpService.authDelete<ParentDto>(
			`${this.endpoints.parents}/${parentId}`,
		);
	}
}
