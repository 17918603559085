/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ActionCreator, combineReducers, createReducer, on } from '@ngrx/store';

import { AsyncState, AsyncStateStatus } from './async-state.model';

export interface AsyncReducerConfig {
	setLoadingActions: ActionCreator[];
	setLoadedActions: ActionCreator[];
	setErrorActions: ActionCreator[];
}

export const createAsyncReducer = <T>({
	setLoadingActions,
	setLoadedActions,
	setErrorActions,
}: AsyncReducerConfig) =>
	combineReducers<AsyncState<T>>({
		status: createReducer<AsyncState<T>['status']>(
			AsyncStateStatus.Init,

			// @ts-ignore - https://github.com/microsoft/TypeScript/issues/28010
			on(...setLoadingActions, (status: AsyncStateStatus) =>
				status === AsyncStateStatus.Init
					? AsyncStateStatus.InitialLoading
					: AsyncStateStatus.Loading,
			),

			// @ts-ignore - https://github.com/microsoft/TypeScript/issues/28010
			on(...setLoadedActions, () => AsyncStateStatus.Loaded),

			// @ts-ignore - https://github.com/microsoft/TypeScript/issues/28010
			on(...setErrorActions, () => AsyncStateStatus.LoadedWithError),
		),

		error: createReducer<AsyncState<T>['error']>(
			null,
			// @ts-ignore - https://github.com/microsoft/TypeScript/issues/28010
			on(...setErrorActions, (_, { error }) => error),

			// @ts-ignore - https://github.com/microsoft/TypeScript/issues/28010
			on(...setLoadedActions, () => null),
		),

		data: state => state,
	});
