import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IAnswers } from '@ccap/interfaces';

export const fetchAupairLife = createAction('[Aupair Life] Get');
export const fetchAupairLifeFail = createAction(
	'[Aupair Life] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setAupairLife = createAction(
	'[Aupair Life] Set',
	props<{ aupairLife: Partial<IAnswers> }>(),
);

export const setAupairLifeFail = createAction(
	'[Aupair Life] Set Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateAupairLife = createAction(
	'[Aupair Life] Update',
	props<{ aupairLife: IAnswers }>(),
);

/**
 * Updates the whole state
 */
export const rollbackAupairLife = createAction(
	'[Aupair Life] Rollback',
	props<{ aupairLife: IAnswers }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchAupairLife = createAction(
	'[Aupair Life] Patch',
	props<{ aupairLife: Partial<IAnswers> }>(),
);
