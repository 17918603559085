import { Action, createReducer, on } from '@ngrx/store';

import { HealthDto } from '@ccap/au-pair/data-access/models';
import { patchHealth, rollbackHealth, updateHealth } from './health.actions';

export type HealthState = HealthDto | null;

const reducer = createReducer<HealthState>(
	null,
	on(updateHealth, rollbackHealth, (_, { health }) => health),
	on(patchHealth, (state, { health }) => ({ ...state, ...health })),
);

export function healthReducer(state: HealthState, action: Action): HealthState {
	return reducer(state, action);
}
