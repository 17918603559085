import { createSelector } from '@ngrx/store';

import { getExtension } from '../extension.selectors';

export const getExtensionCourses = createSelector(
	getExtension,
	({ courses }) => courses,
);

export const getCourseById = (courseId: string) =>
	createSelector(getExtensionCourses, courses =>
		courses ? courses.find(({ id }) => id === courseId) : null,
	);
