<div efInputWrapper efInputMarkAs>
	<select
		ccapCustomControl
		efInput
		[exactName]="exactName"
		[attr.id]="id"
		[attr.aria-label]="label"
		[value]="value || ''"
		(change)="onInputChange($event)"
		(blur)="onInputBlur()"
	>
		<ng-container *ngIf="noValueText">
			<option style="display: none" selected disabled value="">
				{{ noValueText }}
			</option>
		</ng-container>

		<ng-container *ngFor="let option of options">
			<option [value]="option?.value" [selected]="value === option?.value">
				{{ option?.label }}
			</option>
		</ng-container>
	</select>
</div>

<ccap-custom-control-validation></ccap-custom-control-validation>
