import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MenuItem } from './menu-item.interface';

@Component({
	selector: 'ccap-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	@Input() public routes?: MenuItem[][];

	public toggled = false;

	public toggleDropdown(): void {
		this.toggled = !this.toggled;
	}

	public trackByFn(index) {
		return index;
	}

	public onMenuLinkClicked(route: MenuItem): void {
		if (route.isEnabled === false) {
			return;
		}

		this.toggled = false;
		route.action();
	}
}
