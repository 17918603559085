import { Action, createReducer, on } from '@ngrx/store';

import {
	fetchVisaSuccess,
	setEmbassyAppointment,
	setEmbassyAppointmentSuccess,
} from './visa.actions';
import { VisaState } from './visa.model';

const reducer = createReducer<VisaState>(
	null,

	on(fetchVisaSuccess, (_, { visa }) => visa),

	on(
		setEmbassyAppointment,
		setEmbassyAppointmentSuccess,
		(state, { embassyAppointment }) => ({
			...state,
			embassyAppointment,
		}),
	),
);

export function visaReducer(state: VisaState, action: Action): VisaState {
	return reducer(state, action);
}
