<dl class="tiles">
	<dt>
		<p>{{ question.content.question }}</p>
	</dt>

	<dd>
		<ng-container *ngIf="!answers.length; else tiles">
			<p class="no-answer no-answer--center">Not specified</p>
		</ng-container>

		<ng-template #tiles>
			<div class="tileset">
				<ng-container *ngFor="let selection of answers">
					<div class="tile">
						<div class="tile__label">
							<ccap-svg [title]="selection.id"></ccap-svg>

							<div class="tile__title">{{ selection.label }}</div>

							<div class="tile__sub-title ef-text-caption">
								{{ selection.householdActivitiesFrequency }}
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-template>
	</dd>
</dl>
