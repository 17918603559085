import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MoreLessModule } from '@ccap/shared/ui/layout';
import { LazyTabContentDirective } from './lazy-tab-content/lazy-tab-content.directive';
import { TabButtonComponent } from './tab-button/tab-button.component';
import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tabs.component';

@NgModule({
	declarations: [
		TabsComponent,
		TabComponent,
		TabButtonComponent,
		LazyTabContentDirective,
	],
	exports: [TabsComponent, TabComponent, LazyTabContentDirective],
	imports: [CommonModule, MoreLessModule],
})
export class TabsModule {}
