import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CookieService {
	constructor(@Inject(DOCUMENT) private document: any) {}

	public create(key: string, value: string, days?: number) {
		let expires = '';
		const host: string = this.document.location.host;

		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = `; expires=${date.toUTCString()}`;
		}

		const baseCookie = `${key}=${value}${expires}; path=/;`;
		let extendedCookie = '';

		if (host.split('.').length > 1) {
			const domainParts = host.split('.');
			domainParts.shift();
			const domain = '.' + domainParts.join('.');
			extendedCookie = ` domain=${domain}`;
		}

		this.document.cookie = `${baseCookie}${extendedCookie}`;
	}

	public erase(key: string) {
		this.create(key, '', -1);
	}
}
