import { createAction, props } from '@ngrx/store';

import { AddAnswerPayload, BaseQuestion } from '@ccap/question-engine/models';

export const deleteQuestionListAnswer = createAction(
	'[Questions] Delete Question List Answer',
	props<{
		questionId: string;
		answerId: string;
		endpoint: string;
	}>(),
);

export const addQuestionListAnswer = createAction(
	'[Questions] Add Question List Answer',
	<Q extends BaseQuestion>(payload: AddAnswerPayload<Q['answer']>) => payload,
);
