import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Option } from '@ccap/interfaces';

@Component({
	selector: 'app-answer-radio-tile',
	templateUrl: 'answer-radio-tile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerRadioTileComponent {
	@Input() public label?: string;
	@Input() public value?: string | boolean;
	@Input() public options?: Option<string | boolean>[];

	public get answer(): string | boolean {
		const selectedOption = this.options?.find(
			({ value }) => value === this.value,
		);

		return selectedOption ? selectedOption.label : this.value;
	}
}
