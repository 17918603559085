import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileExtensionModule } from '@ccap/shared/pipes';
import { ButtonModule } from '@ccap/ef-ui/components';
import { FileDownloadModule } from '../../shared/components/file-download/file-download.module';
import { PersonalityTestGraphsModule } from './personality-test-graphs/personality-test-graphs.module';
import { PersonalityTestResultsComponent } from './personality-test-results.component';

@NgModule({
	imports: [
		CommonModule,
		PersonalityTestGraphsModule,
		FileDownloadModule,
		ButtonModule,
		FileExtensionModule,
	],
	declarations: [PersonalityTestResultsComponent],
	exports: [PersonalityTestResultsComponent],
})
export class PersonalityTestResultsModule {}
