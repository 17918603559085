import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerModule } from '../answer/answer.module';
import { AnswersComponent } from './answers.component';

@NgModule({
	imports: [CommonModule, SvgModule, AnswerModule],
	declarations: [AnswersComponent],
	exports: [AnswersComponent],
})
export class AnswersModule {}
