<dl class="tiles">
	<dt>Languages spoken, as self-assessed by au pair</dt>

	<dd>
		<ng-container *ngIf="!answers.length; else tiles">
			<p class="no-answer no-answer--center">Not specified</p>
		</ng-container>

		<ng-template #tiles>
			<div class="tileset">
				<ng-container *ngFor="let selection of answers">
					<div class="tile tile--answered">
						<div class="tile__label">
							<div class="tile__title">{{ selection.label }}</div>

							<div class="tile__rating">
								<ccap-svg
									*ngFor="let r of 5 | fill; let i = index"
									[title]="'star'"
									[classList]="
										'star ' +
										(selection.rating > i ? 'star--full' : 'star--empty')
									"
								>
								</ccap-svg>
							</div>

							<div class="tile__sub-title ef-text-caption">
								{{ ratingDescription(selection.rating) }}
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-template>
	</dd>
</dl>
