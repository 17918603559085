import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnerModule } from '@ccap/shared/ui/indicators';
import { FileDownloadComponent } from './file-download.component';

@NgModule({
	imports: [CommonModule, SpinnerModule],
	declarations: [FileDownloadComponent],
	exports: [FileDownloadComponent],
})
export class FileDownloadModule {}
