import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	AuPairElementDto,
	AuPairElementId,
	AuPairElementsDto,
} from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class ElementsResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getElements(): Observable<AuPairElementsDto> {
		return this.httpService.authGet<AuPairElementsDto>(this.endpoints.elements);
	}

	public getElement(element: AuPairElementId): Observable<AuPairElementDto> {
		return this.httpService.authGet<AuPairElementDto>(
			`${this.endpoints.elements}/${element}`,
		);
	}
}
