import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FooterModule } from '@ccap/shared/ui/navigation';
import { FooterContainerComponent } from './footer-container.component';

@NgModule({
	imports: [CommonModule, FooterModule],
	declarations: [FooterContainerComponent],
	exports: [FooterContainerComponent],
})
export class FooterContainerModule {}
