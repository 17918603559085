import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AgreementTypes } from '@ccap/au-pair/data-access/models';
import { DocumentsState } from './documents.reducer';

export const getDocuments = createFeatureSelector<DocumentsState>('documents');

export const getAgreements = createSelector(getDocuments, ({ agreements }) =>
	Object.keys(agreements).map(key => agreements[key]),
);

export const getAgreementById = (agreementId: string) =>
	createSelector(getDocuments, ({ agreements }) => agreements[agreementId]);

export const getAgreementsByType = (agreementType: AgreementTypes) =>
	createSelector(getAgreements, agreements =>
		agreements.filter(({ type }) => type === agreementType),
	);

export const getMatchDocuments = createSelector(
	getDocuments,
	({ matchDocuments }) => matchDocuments,
);

export const getDocumentsByKey = (key: string) =>
	createSelector(getDocuments, ({ documents }) =>
		Object.keys(documents).reduce(
			(acc, id) => (documents[id].key === key ? [...acc, documents[id]] : acc),
			[],
		),
	);

export const getDisplayedDocumentIds = createSelector(
	getDocuments,
	({ displayedDocumentIds }) => displayedDocumentIds,
);
