import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@ccap/ef-ui/components';

import { E2eModule } from '@ccap/e2e';
import { ModalActionsComponent } from './modal-actions.component';

@NgModule({
	imports: [CommonModule, ButtonModule, E2eModule],
	declarations: [ModalActionsComponent],
	exports: [ModalActionsComponent],
})
export class ModalActionsModule {}
