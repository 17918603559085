import { Component, Input } from '@angular/core';

import { transformImage } from '@ccap/shared/utils';

@Component({
	selector: 'app-profile-picture',
	templateUrl: 'profile-picture.component.html',
	styleUrls: ['profile-picture.component.scss'],
})
export class ProfilePictureComponent {
	@Input() public size = 208;
	@Input() public thickFrame = true;
	@Input() public profilePhotoUrl?: string;

	public get pictureSize() {
		return {
			height: `${this.size}px`,
			width: `${this.size}px`,
		};
	}

	public get backgroundImage() {
		const cloudinarySize: number = this.thickFrame ? this.size - 8 : this.size;
		return {
			'background-image': `url("${transformImage(
				this.profilePhotoUrl,
				cloudinarySize,
				cloudinarySize,
				['c_thumb'],
			)}")`,
		};
	}
}
