export interface ChangeEmailPayload {
	currentEmail: string;
	password: string;
	newEmail: string;
}

export enum LoginType {
	'Auth0' = 'Username_Password',
	'Facebook' = 'Facebook',
	'Google' = 'Google',
}

export interface IAccount {
	accountExists: boolean;
	isApproved: boolean;
	publicId: string;
	legacyId: string;
	loginType: LoginType;
}
