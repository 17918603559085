import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[efButton]',
})
export class ButtonDirective {
	@HostBinding('class.-is-loading')
	@Input()
	public get isLoading(): boolean {
		return this.loading;
	}
	public set isLoading(loading: boolean) {
		this.loading = loading;
		this.ariaLabel = loading ? 'loading' : null;
	}

	@HostBinding('class.ef-button')
	public readonly baseClass = true;

	@HostBinding('class.-primary')
	@Input()
	public primary = false;

	@HostBinding('class.-outlined')
	@Input()
	public outlined = false;

	@HostBinding('class.-filled')
	@Input()
	public filled = false;

	@HostBinding('class.-square')
	@Input()
	public square = false;

	@HostBinding('class.-small')
	@Input()
	public small = false;

	@HostBinding('class.-m')
	@Input()
	public medium = false;

	@HostBinding('class.-large')
	@HostBinding('class.-l')
	@Input()
	public large = false;

	@HostBinding('class.-xl')
	@Input()
	public xlarge = false;

	@HostBinding('class.-icon')
	@Input()
	public icon = false;

	@HostBinding('class.-s-extended')
	@Input()
	public extended = false;

	@HostBinding('class.-s-half')
	@Input()
	public half = false;

	@HostBinding('attr.aria-label')
	@Input()
	public ariaLabel = null;

	private loading = false;
}
