<h4 class="ef-h4">Key facts</h4>

<div class="summary-card__container">
	<ng-container
		*ngFor="let summaryCardTile of summaryCardKeys(); trackBy: trackByFn"
	>
		<div
			class="summary-card__tile"
			[class.summary-card__tile--highlight]="
				summaryCards[summaryCardTile].highlight
			"
		>
			<ccap-svg [title]="summaryCards[summaryCardTile].icon"> </ccap-svg>
			<dl>
				<dt>{{ summaryCards[summaryCardTile].title }}</dt>
				<dd
					[innerHtml]="
						summaryCards[summaryCardTile].answer | selected: 'Not specified'
					"
				></dd>
			</dl>
		</div>
	</ng-container>
</div>
