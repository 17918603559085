import { Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'ccap-svg',
	templateUrl: 'svg.component.html',
	styleUrls: ['svg.component.scss'],
})
export class SvgComponent {
	@Input() public title?: string;

	@HostBinding('class') @Input() public classList = '';
}
