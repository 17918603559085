import { createSelector } from '@ngrx/store';

import { createAsyncSelectors } from '@ccap/state';
import { AccountDataState } from '../models/account-state.model';

export const {
	getData: getAccountData,
	getIsLoading: getIsAccountLoading,
	getError: getAccountError,
} = createAsyncSelectors<AccountDataState>('account');

export const getAuPairPublicId = createSelector(
	getAccountData,
	({ publicId }) => publicId,
);

export const accountExists = createSelector(
	getAccountData,
	(selectedState: AccountDataState) => selectedState.accountExists,
);

export const isApproved = createSelector(
	getAccountData,
	(selectedState: AccountDataState) => selectedState.isApproved,
);
