import { Action, createReducer, on } from '@ngrx/store';

import { FlightDto } from '@ccap/au-pair/data-access/models';
import { updateFlights } from './flights.actions';

export type FlightsState = FlightDto[] | null;

const reducer = createReducer<FlightsState>(
	null,
	on(updateFlights, (_, { flights }) => flights),
);

export function flightsReducer(
	state: FlightsState,
	action: Action,
): FlightsState {
	return reducer(state, action);
}
