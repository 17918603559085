import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
	ExtensionPaymentDetailsDto,
	ExtensionPaymentTransactionDto,
} from '../../interfaces';
import { updatePayment, updatePaymentTransaction } from './payment.actions';

export interface ExtensionPaymentState {
	details: ExtensionPaymentDetailsDto;
	transaction: ExtensionPaymentTransactionDto;
}

const paymentDetailsReducer = createReducer<ExtensionPaymentDetailsDto>(
	null,
	on(updatePayment, (_, { payment }) => payment),
);

const paymentTransactionReducer = createReducer<ExtensionPaymentTransactionDto>(
	null,
	on(updatePaymentTransaction, (_, { transaction }) => transaction),
);

const reducer = combineReducers<ExtensionPaymentState>({
	details: paymentDetailsReducer,
	transaction: paymentTransactionReducer,
});

export function paymentReducer(
	state: ExtensionPaymentState,
	action: Action,
): ExtensionPaymentState {
	return reducer(state, action);
}
