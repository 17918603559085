<ng-container *ngIf="mediaFiles?.length">
	<h4 class="ef-h4" id="e2e-first-impressions-title">First impressions</h4>
	<p>{{ currentIndex$ | async }}/{{ mediaFiles.length }}</p>

	<div class="carousel__container">
		<div class="glide" #carousel>
			<div class="glide__track" data-glide-el="track">
				<ul class="glide__slides">
					<li class="glide__slide" *ngFor="let file of mediaFiles">
						<div class="carousel__view">
							<ng-container *ngIf="file.type === 'video'; else isImage">
								<video #videoPlayer controls controlsList="nodownload">
									<source [src]="transformVideo(file.url)" />
								</video>
							</ng-container>
							<ng-template #isImage>
								<img
									[src]="transformImage(file.url)"
									[alt]="file.fileName"
									draggable="false"
								/>
							</ng-template>
						</div>
						<div class="carousel__description">
							<p class="bold" *ngIf="file.type === 'video' && file.question">
								{{ file.question }}
							</p>
							{{ file.meta.description }}
						</div>
					</li>
				</ul>
			</div>

			<div class="carousel__controls" data-glide-el="controls">
				<div
					class="carousel__control carousel__control--left"
					data-glide-dir="<"
				>
					<span class="arrow-container"> <span class="arrow"></span> </span>
				</div>

				<div
					class="carousel__control carousel__control--right"
					data-glide-dir=">"
				>
					<span class="arrow-container"> <span class="arrow"></span> </span>
				</div>
			</div>
		</div>
	</div>
</ng-container>
