import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IAbstractListValue } from '@ccap/interfaces';
import { mapTileListAnswersToQuestionOptionsLabel } from '@ccap/question-engine/utils';
import { AnswerAbstractListComponent } from '../answer-abstract-list/answer-abstract-list.component';

export interface ITileListInterests extends IAbstractListValue {
	id: string;
	rating?: string;
	ratingExplanation?: string;
	label: string;
}

@Component({
	selector: 'app-answer-tile-list-interests',
	styleUrls: ['answer-tile-list-interests.component.scss'],
	templateUrl: 'answer-tile-list-interests.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerTileListInterestsComponent extends AnswerAbstractListComponent {
	public get answers(): ITileListInterests[] {
		const listValues = this.answer() as ITileListInterests[];
		return mapTileListAnswersToQuestionOptionsLabel<ITileListInterests>(
			listValues,
			this.question.options,
		);
	}

	public ratingDescription(rating: number): string {
		const index = rating - 1;
		return this.question.typeVariations.questionListItems.find(
			item => item.id === 'rating',
		).content.externalDescription.descriptions[index].title;
	}
}
