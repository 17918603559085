<footer class="footer">
	<div class="footer__container">
		<span class="footer__copyright">
			&copy; International Care Ltd. All rights reserved.
		</span>
		<ul class="footer__list">
			<li class="footer__item" *ngFor="let item of footerItems">
				<a
					class="link--no-arrow ef-link"
					[routerLink]="item.href"
					target="_blank"
					rel="noreferrer noopener"
				>
					{{ item.title }}
				</a>
			</li>
		</ul>
	</div>
</footer>
