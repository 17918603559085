import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

@Component({
	selector: 'ccap-modal-actions',
	templateUrl: 'modal-actions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalActionsComponent {
	@Input() public disabledSaveAndContinue?: boolean;
	@Input() public saveButtonText = 'Save';
	@Input() public cancelButtonText = 'Close';
	@Output() public cancelAction?: EventEmitter<any> = new EventEmitter<any>();
	@Output() public saveAction?: EventEmitter<any> = new EventEmitter<any>();

	public onCancel() {
		this.cancelAction.emit();
	}

	public onSave() {
		this.saveAction.emit();
	}
}
