import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	AgreementDto,
	AgreementTypes,
	ComplianceDocumentTypes,
	MatchDocumentDto,
} from '@ccap/au-pair/data-access/models';
import { IMediaFile } from '@ccap/interfaces';

export const fetchAgreements = createAction('[Documents] Get Agreements');

export const fetchAgreementsFail = createAction(
	'[Documents] Get Agreements Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateAgreements = createAction(
	'[Documents] Update Agreements',
	props<{ agreements: AgreementDto[] }>(),
);

export const updateAgreement = createAction(
	'[Documents] Update An Agreement',
	props<{ agreement: AgreementDto }>(),
);

export const acceptAgreement = createAction(
	'[Documents] Accept Agreement',
	props<{ matchId: string; agreementType: AgreementTypes }>(),
);

export const fetchMatchDocuments = createAction(
	'[Documents] Get Match Documents',
);

export const fetchMatchDocumentsFail = createAction(
	'[Documents] Get Match Documents Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateMatchDocuments = createAction(
	'[Documents] Update Match Documents',
	props<{ documents: MatchDocumentDto[] }>(),
);

export const fetchDocumentsByKey = createAction(
	'[Documents] Get By Key',
	props<{ key: string }>(),
);

export const fetchDocumentsByKeyFail = createAction(
	'[Documents] Get By Key Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const addDocuments = createAction(
	'[Documents] Add Documents',
	props<{ documents: IMediaFile[] }>(),
);

export const downloadDocument = createAction(
	'[Documents] Download',
	props<{ url: string; name: string }>(),
);

export const fetchDisplayedDocumentIds = createAction(
	'[Documents] Get Displayed Document Ids',
);

export const fetchDisplayedDocumentIdsFail = createAction(
	'[Documents] Get Displayed Document Ids Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateDisplayedDocumentIds = createAction(
	'[Documents] Update Displayed Document Ids',
	props<{ documentIds: ComplianceDocumentTypes[] }>(),
);
