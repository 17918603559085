import { Injectable, Optional } from '@angular/core';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import {
	ApplicationInsights,
	SeverityLevel,
} from '@microsoft/applicationinsights-web';

import { getUserPropertyFromJwt } from '../jwt-helper';
import { errorMessagesFilter } from './error-messages-filter';
import {
	removeSensitiveData,
	sensitiveDataFilter,
} from './sensitive-data-filter';

@Injectable({
	providedIn: 'root',
})
export class MonitoringService {
	constructor(@Optional() private appInsights?: ApplicationInsights) {
		if (this.appInsights) {
			this.appInsights.loadAppInsights();
			this.appInsights.addTelemetryInitializer(errorMessagesFilter);
			this.appInsights.addTelemetryInitializer(sensitiveDataFilter);
		}
	}

	public setAuthenticatedUserId(): void {
		let userEmail: string;
		try {
			userEmail = getUserPropertyFromJwt('email');
		} catch (e) {
			/* eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function */
		} finally {
			if (this.appInsights) {
				this.appInsights.setAuthenticatedUserContext(userEmail);
			}
		}
	}

	public logError(
		error: Error | string | unknown,
		properties?: Record<string, string>,
	): void {
		if (error instanceof Error && error.message) {
			error.message = removeSensitiveData(error.message);
		}

		if (this.appInsights) {
			const isErrorString = typeof error === 'string';
			const errorToLog =
				error instanceof Error
					? error
					: new Error(isErrorString ? error : JSON.stringify(error));

			this.appInsights.trackException({
				exception: errorToLog,
				properties,
			});
		}
	}

	public logInformation(
		category: string,
		message: string,
		properties?: ICustomProperties,
	): void {
		if (this.appInsights) {
			this.appInsights.trackTrace(
				{
					message: `${category} - ${message}`,
					severityLevel: SeverityLevel.Information,
				},
				properties,
			);
		}
	}

	public logEvent(name: string): void {
		if (this.appInsights) {
			this.appInsights.trackEvent({
				name,
			});
		}
	}
}
