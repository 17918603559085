export type StylePropertyUnit =
	| 'px'
	| '%'
	| 'in'
	| 'cm'
	| 'mm'
	| 'pt'
	| 'pc'
	| 'em'
	| 'ex';

export interface StyleProperty {
	value: number;
	units: StylePropertyUnit;
}

export const getElementStyleDeclaration = <T extends keyof CSSStyleDeclaration>(
	element: HTMLElement,
	property: T,
): CSSStyleDeclaration[T] => {
	if (window.getComputedStyle) {
		return window.getComputedStyle(element)[property];
	}

	return element.style[property];
};

export const parseElementStyleDeclaration = <
	T extends keyof CSSStyleDeclaration,
	K extends CSSStyleDeclaration[T]
>(
	elementStyleProperty: K,
): StyleProperty => {
	const valueMatch = elementStyleProperty
		.toString()
		.match(/\d+/) as StylePropertyUnit[];
	const value = valueMatch ? Number(valueMatch[0]) : 0;

	const unitMatch = elementStyleProperty
		.toString()
		.match(/\D+$/) as StylePropertyUnit[];
	const units: StylePropertyUnit = unitMatch ? unitMatch[0] : 'px';

	return {
		value,
		units,
	};
};

export const getElementStyleProperty = <T extends keyof CSSStyleDeclaration>(
	element: HTMLElement,
	property: T,
): StyleProperty => {
	const elementStyleDeclaration = getElementStyleDeclaration(element, property);
	const elementStyleProperty = parseElementStyleDeclaration(
		elementStyleDeclaration,
	);

	return elementStyleProperty;
};

export const getElementLineHeight = (element: HTMLElement): number => {
	const lineHeightStyleProperty = getElementStyleProperty(
		element,
		'lineHeight',
	);

	const elementLineHeight = lineHeightStyleProperty.value;

	return elementLineHeight;
};

export const getNumberOfLinesInElementContent = (
	element: HTMLElement,
	elementLineHeight: number,
): number => {
	const elementHeight = element.offsetHeight;
	const numberOfLines = Math.round(elementHeight / elementLineHeight) || 0;

	return numberOfLines;
};
