import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	ChangeReturnFlightDto,
	ReturnFlightDto,
} from '@ccap/au-pair/data-access/models';

export const fetchReturnFlight = createAction('[ReturnFlight] Get');
export const fetchReturnFlightFail = createAction(
	'[ReturnFlight] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateReturnFlight = createAction(
	'[ReturnFlight] Update',
	props<{ returnFlight: ReturnFlightDto }>(),
);

export const fetchChangeReturnFlight = createAction(
	'[ReturnFlight] Get Change',
);
export const fetchChangeReturnFlightFail = createAction(
	'[ReturnFlight] Get Change Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateChangeReturnFlight = createAction(
	'[ReturnFlight] Update Change',
	props<{ changeReturnFlight: ChangeReturnFlightDto }>(),
);
