import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FEATURE_TOGGLE_CONFIG } from './feature-toggle-injection-token';
import { FeatureToggleResource } from './feature-toggle/feature-toggle-resource.service';
import { FeatureToggleSandboxService } from './feature-toggle/feature-toggle-sandbox.service';
import { FeatureToggleConfig } from './feature-toggle/interfaces';
import { FeatureToggleEffects } from './feature-toggle/store/feature-toggle.effects';
import { featureToggleReducer } from './feature-toggle/store/feature-toggle.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('features', featureToggleReducer),
		EffectsModule.forFeature([FeatureToggleEffects]),
	],
	providers: [FeatureToggleSandboxService, FeatureToggleResource],
})
export class FeatureToggleModule {
	public static forRoot(
		features: () => FeatureToggleConfig,
	): ModuleWithProviders<FeatureToggleModule> {
		return {
			ngModule: FeatureToggleModule,
			providers: [
				{
					provide: FEATURE_TOGGLE_CONFIG,
					useFactory: features,
				},
				FeatureToggleSandboxService,
				FeatureToggleResource,
			],
		};
	}
}
