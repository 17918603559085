import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	CreateWorkExperienceReferencePayload,
	UpdateWorkExperiencePayload,
	UpdateWorkExperienceReferencePayload,
	WorkExperienceDto,
	WorkExperienceReference,
} from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class WorkExperienceResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getWorkExperience(): Observable<WorkExperienceDto> {
		return this.httpService.authGet<WorkExperienceDto>(
			this.endpoints.hasWorkExperience,
		);
	}

	public updateWorkExperience(
		workExperience: UpdateWorkExperiencePayload,
	): Observable<WorkExperienceDto> {
		return this.httpService.authPatch<WorkExperienceDto>(
			this.endpoints.hasWorkExperience,
			workExperience,
		);
	}

	public createWorkExperienceReference(
		reference: CreateWorkExperienceReferencePayload,
	): Observable<WorkExperienceReference> {
		return this.httpService.authPost<WorkExperienceReference>(
			this.endpoints.workExperiences,
			reference,
		);
	}

	public updateWorkExperienceReference(
		referenceId: string,
		reference: UpdateWorkExperienceReferencePayload,
	): Observable<WorkExperienceReference> {
		return this.httpService.authPatch<WorkExperienceReference>(
			`${this.endpoints.workExperiences}/${referenceId}`,
			reference,
		);
	}

	public deleteWorkExperienceReference(
		referenceId: string,
	): Observable<WorkExperienceReference> {
		return this.httpService.authDelete<WorkExperienceReference>(
			`${this.endpoints.workExperiences}/${referenceId}`,
		);
	}
}
