import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollService } from '@ccap/shared/ui';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { SubSectionModule } from '../sub-section/sub-section.module';
import { ToggleService } from '../../core/services/toggle.service';
import { SectionComponent } from './section.component';

@NgModule({
	imports: [CommonModule, SubSectionModule, SvgModule],
	declarations: [SectionComponent],
	exports: [SectionComponent],
	providers: [ScrollService, ToggleService],
})
export class SectionModule {}
