import { animate, style, transition, trigger } from '@angular/animations';

const animationTimings = '220ms cubic-bezier(0.42, 0, 1, 1)';
const closedStyles = style({
	transform: 'translate3d(0, -100%, 0)',
	opacity: 0,
});
const openStyles = style({ transform: 'translate3d(0, 0, 0)', opacity: 1 });

export const dialogAnimations = [
	trigger('enterLeaveTrigger', [
		transition(':enter', [closedStyles, animate(animationTimings, openStyles)]),

		// close animations currently not working,
		// we need to wait for this animation to finish
		// before closing the CDK Portal
		transition(':leave', [animate(animationTimings, closedStyles)]),
	]),
];
