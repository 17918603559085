import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
} from '@angular/core';

@Component({
	selector: 'app-host-family-letters',
	templateUrl: './host-family-letters.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostFamilyLettersComponent implements OnChanges {
	@Input()
	public firstYearLetter?: string;

	@Input()
	public secondYearLetter?: string;

	public activeTabIndex?: number;

	public ngOnChanges(): void {
		this.activeTabIndex = this.secondYearLetter ? 1 : 0;
	}
}
