import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LinksState } from './links.reducer';

export const getLinksState = createFeatureSelector<LinksState>('links');

export const getLinks = createSelector(
	getLinksState,
	({ links }) => links,
);

export const getDeclineReasons = createSelector(
	getLinksState,
	({ declineReasons }) => declineReasons,
);
