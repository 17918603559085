import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

/**
 * Intercept HTTP requests and redirects to the auth page if a 401 or 403 happens
 */
@Injectable()
export class SessionInvalidInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.error instanceof ErrorEvent) {
					return throwError(() => error);
				}

				if (error.status === 401) {
					this.authService.logout();

					return [];
				}

				return throwError(() => error);
			}),
		);
	}
}
