import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'escapeString',
})
export class EscapeStringPipe implements PipeTransform {
	public transform(value: any): string {
		if (typeof value !== 'string') {
			return value;
		}

		return value.toLowerCase().replace(/\W+/g, '-');
	}
}
