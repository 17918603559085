import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PersonalityTestState } from './personality-test.reducer';

export const getPersonalityTest =
	createFeatureSelector<PersonalityTestState>('personalityTest');

export const getPersonalityTestAnswers = createSelector(
	getPersonalityTest,
	({ answers }) => answers,
);

export const getPersonalityTestStatus = createSelector(
	getPersonalityTest,
	({ status }) => status,
);

export const getPersonalityTestResults = createSelector(
	getPersonalityTest,
	({ results }) => results,
);
