<ccap-header></ccap-header>

<ng-container
	*ngIf="ready | async; else loading"
	[ngTemplateOutlet]="loaded"
	[ngTemplateOutletContext]="{
		profilePhotoUrl: profilePhoto$ | async,
		busyStatus: busyStatus$ | async
	}"
>
</ng-container>

<ng-template #loading>
	<ccap-spinner></ccap-spinner>
</ng-template>

<ng-template
	#loaded
	let-profilePhotoUrl="profilePhotoUrl"
	let-busyStatus="busyStatus"
>
	<app-navigation-header
		*ngIf="canUseLinks"
		[firstName]="firstName"
		[fullName]="fullName"
		[profilePhotoUrl]="profilePhotoUrl"
		[busyStatus]="busyStatus"
		[isBusyStatusVisible]="isBusyStatusVisible$ | async"
	>
	</app-navigation-header>

	<div class="profile-header">
		<div
			class="profile-header__section profile-dashboard"
			*ngIf="identityAnswers$ | async as identityAnswers"
		>
			<div class="profile-dashboard__column profile-dashboard__column--left">
				<app-profile-picture [profilePhotoUrl]="profilePhotoUrl">
				</app-profile-picture>

				<ccap-flag [flagUrl]="identityAnswers?.flag"> </ccap-flag>

				<ccap-busy-status-indicator
					*ngIf="isBusyStatusVisible$ | async"
					[status]="busyStatus"
				></ccap-busy-status-indicator>
			</div>

			<div class="profile-dashboard__column profile-dashboard__column--right">
				<p *ngIf="canViewLegacyId && (legacyId$ | async) as legacyId">
					{{ legacyId }}
				</p>

				<ccap-identity [identityAnswers]="identityAnswers"></ccap-identity>

				<div class="profile-dashboard__actions">
					<app-like *ngIf="canUseLinks"></app-like>

					<app-connect *ngIf="canUseLinks" [name]="fullName"></app-connect>
				</div>
			</div>
		</div>

		<app-in-country
			class="profile-header__section"
			*ngIf="canViewInCountry && (inCountry$ | async) as inCountry"
			[inCountry]="inCountry"
			[hostFamilyReferences]="hostFamilyReferences$ | async"
			[lccReferences]="lccReferences$ | async"
			[fileIdentifier]="'transition-' + (fullNameJoined | escapeString)"
			[contactDetails]="contactDetails"
			[canViewContactDetails]="hasAccess('contactdetails')"
		></app-in-country>

		<app-summary-cards
			class="profile-header__section"
			[summaryCards]="summaryCards$ | async"
		></app-summary-cards>

		<div
			class="profile-header__section"
			*ngIf="hostFamilyLetter && inCountry$ | async as inCountry"
		>
			<h4 class="ef-h4">Letter to host family</h4>

			<app-host-family-letters
				[firstYearLetter]="hostFamilyLetter"
				[secondYearLetter]="inCountry?.extension?.extensionHostFamilyLetter"
			>
			</app-host-family-letters>
		</div>

		<app-carousel
			class="profile-header__section"
			*ngIf="media$ | async as media"
			class="hide-for-print"
			[media]="media"
		></app-carousel>
	</div>

	<app-section [section]="sections.aboutMe">
		<app-sub-section [subSection]="subSections.personalDetailsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.personalDetailsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section
			*ngIf="hasAccess('contactdetails')"
			[subSection]="subSections.contactDetails"
		>
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.contactDetails.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.familyLifeForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.familyLifeForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.familyForm">
			<app-answers
				[questions]="questionObjectsFromIds(subSections.familyForm.questions)"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.siblingsForm">
			<app-answers
				[questions]="questionObjectsFromIds(subSections.siblingsForm.questions)"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.lifestyleForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.lifestyleForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.interviewSubSection">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.interviewSubSection.questions)
				"
			>
			</app-answers>
		</app-sub-section>
	</app-section>

	<app-section [section]="sections.myAuPairPreferences">
		<app-sub-section [subSection]="subSections.householdCompositionForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.householdCompositionForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.familyPetsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.familyPetsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.dietaryNeedsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.dietaryNeedsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>
	</app-section>

	<app-section [section]="sections.references">
		<app-sub-section [subSection]="subSections.childcareReferencesForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.childcareReferencesForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.personalReferencesForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.personalReferencesForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.workExperiencesForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.workExperiencesForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>
	</app-section>

	<app-section [section]="sections.lifeExperiences">
		<app-sub-section [subSection]="subSections.travelExperienceForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.travelExperienceForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.livingOnYourOwn">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.livingOnYourOwn.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.householdActivitiesForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.householdActivitiesForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>
	</app-section>

	<app-section [section]="sections.skillsAndEducation">
		<app-sub-section [subSection]="subSections.languagesForm">
			<app-answers [questions]="languagesQuestions()"> </app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.secondarySchoolForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.secondarySchoolForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.universityCollegeForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.universityCollegeForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.certificationsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.certificationsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.drivingForm">
			<app-answers
				[questions]="questionObjectsFromIds(subSections.drivingForm.questions)"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.swimmingForm">
			<app-answers
				[questions]="questionObjectsFromIds(subSections.swimmingForm.questions)"
			>
			</app-answers>
		</app-sub-section>
	</app-section>

	<app-section [section]="sections.personalityProfile">
		<app-sub-section [subSection]="subSections.selectInterestsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.selectInterestsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.personalityTestForm">
			<app-personality-test-results
				[personalityTestResults$]="personalityTestResults$"
				[personalityTestStatus$]="personalityTestStatus$"
				[fileIdentifier]="'personality-test-' + (fullNameJoined | escapeString)"
			>
			</app-personality-test-results>
		</app-sub-section>
	</app-section>

	<app-section *ngIf="hasAccess('health')" [section]="sections.health">
		<app-sub-section [subSection]="subSections.eyesAndEarsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.eyesAndEarsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.allergiesForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.allergiesForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.hospitalizationsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.hospitalizationsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.medicationForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.medicationForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.medicalTreatmentsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.medicalTreatmentsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.counsellingForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.counsellingForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.immunisationForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.immunisationForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<app-sub-section [subSection]="subSections.impairmentsForm">
			<app-answers
				[questions]="
					questionObjectsFromIds(subSections.impairmentsForm.questions)
				"
			>
			</app-answers>
		</app-sub-section>

		<ng-container
			*ngIf="
				questionObjectsFromIds(subSections.smoking.questions) as smokingQuestion
			"
		>
			<app-sub-section
				[subSection]="subSections.smoking"
				*ngIf="smokingQuestion[0]?.answer?.smokingAgreementAccepted"
			>
				<app-answers [questions]="smokingQuestion"></app-answers>
			</app-sub-section>
		</ng-container>

		<app-sub-section
			[subSection]="subSections.translatedMedicalNote"
			*ngIf="medicalNoteUrl$ | async as medicalNote"
		>
			<div class="medical-note">
				<p>Medical note(s), translated by Cultural Care Staff.</p>
				<app-file-download
					[documentUrl]="medicalNote"
					[fileIdentifier]="
						'medical-note-translated-' + (fullNameJoined | escapeString)
					"
					class="u-mt-l u-mb-l u-text-center"
				>
					<button efButton [primary]="true" [square]="true" type="button">
						Download Medical Note
					</button>
				</app-file-download>
			</div>
		</app-sub-section>
	</app-section>

	<div class="section__link u-mt-l u-mb-l u-text-center" *ngIf="isStaff()">
		<app-print-button></app-print-button>
	</div>
</ng-template>

<ccap-footer-container></ccap-footer-container>
