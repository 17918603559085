import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IAbstractListValue } from '@ccap/interfaces';
import { mapTileListAnswersToQuestionOptionsLabel } from '@ccap/question-engine/utils';
import { AnswerAbstractListComponent } from '../answer-abstract-list/answer-abstract-list.component';

export interface ITileListHouseholdActivities extends IAbstractListValue {
	id: string;
	householdActivitiesFrequency?: string;
	label: string;
}

@Component({
	selector: 'app-answer-tile-list-household',
	styleUrls: ['answer-tile-list-household.component.scss'],
	templateUrl: 'answer-tile-list-household.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerTileListHouseholdComponent extends AnswerAbstractListComponent {
	public get answers(): ITileListHouseholdActivities[] {
		const listValues = this.answer() as ITileListHouseholdActivities[];
		return mapTileListAnswersToQuestionOptionsLabel<ITileListHouseholdActivities>(
			listValues,
			this.question.options,
		);
	}
}
