export interface IImmutableAction {
	item?: any;
	index: number;
}

// TODO: move to shared/utils
export class ImmutableCollectionsService {
	public static insertItem(array, action: IImmutableAction): any[] {
		const newArray = array.slice();
		newArray.splice(action.index, 0, action.item);
		return newArray;
	}

	public static removeItem(array, action: IImmutableAction): any[] {
		return array.filter((item, index) => index !== action.index);
	}

	public static updateObjectInArray(array, action: IImmutableAction): any[] {
		return array.reduce(
			(acc, item, i) =>
				i === action.index
					? [...acc, { ...item, ...action.item }] // TODO: this is not updating but merging
					: [...acc, item],
			[],
		);
	}
}
