import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@ccap/ef-ui/components';
import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
	imports: [CommonModule, ButtonModule],
	declarations: [LanguageSelectorComponent],
	exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
