import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2eModule } from '@ccap/e2e';
import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
	imports: [CommonModule, E2eModule],
	declarations: [ProgressBarComponent],
	exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
