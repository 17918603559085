import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToggleService {
	private _activeSection: BehaviorSubject<string> = new BehaviorSubject(null);

	public getActiveSection$(): BehaviorSubject<string> {
		return this._activeSection;
	}

	public setActiveSection(value: string) {
		this._activeSection.next(value);
	}
}
