import { ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import {
	defaultMonitoringConfig,
	MonitoringConfig,
	MONITORING_CONFIG,
} from './monitoring.config';

export const appInsightsFactory = (
	config: MonitoringConfig,
): ApplicationInsights | null =>
	config
		? new ApplicationInsights({
				config: {
					...defaultMonitoringConfig,
					...config,
				},
		  })
		: null;

@NgModule()
export class MonitoringModule {
	public static forRoot(): ModuleWithProviders<MonitoringModule> {
		return {
			ngModule: MonitoringModule,
			providers: [
				{
					provide: ApplicationInsights,
					useFactory: appInsightsFactory,
					deps: [[new Optional(), MONITORING_CONFIG]],
				},
			],
		};
	}
}
