import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-print-button',
	templateUrl: 'print-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintButtonComponent {
	public print() {
		window.print();
	}
}
