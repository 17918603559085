import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ISubSectionExtended } from '@ccap/interfaces';

@Component({
	selector: 'app-sub-section',
	templateUrl: 'sub-section.component.html',
	styleUrls: ['sub-section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubSectionComponent {
	@Input() public subSection?: ISubSectionExtended;
}
