import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SubSectionDescriptionComponent } from './sub-section-description.component';

@NgModule({
	imports: [CommonModule],
	declarations: [SubSectionDescriptionComponent],
	exports: [SubSectionDescriptionComponent],
})
export class SubSectionDescriptionModule {}
