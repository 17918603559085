import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { AccountResource } from '@ccap/au-pair/data-access/account';
import { BusyStatusResource } from '@ccap/au-pair/data-access/busy-status';
import { CountryInfoResource } from '@ccap/au-pair/data-access/country-info';
import { getElementBusyStatusIsVisible } from '@ccap/au-pair/data-access/elements';
import {
	PersonalityTestResultsDto,
	PersonalityTestStatusDto,
} from '@ccap/au-pair/data-access/models';
import { PersonalityTestResource } from '@ccap/au-pair/data-access/personality-test';
import { ReferenceResource } from '@ccap/au-pair/data-access/reference';
import { InCountryDto } from '@ccap/au-pair/in-country';
import { AuthService } from '@ccap/auth';
import {
	Address,
	DateValue,
	DisplayedUnitedStatesContactDetails,
	IMediaFile,
	IQuestionExtended,
} from '@ccap/interfaces';
import {
	HostFamilyReference,
	LCCReference,
	PhoneNumber,
} from '@ccap/interfaces';
import {
	DateUtils,
	inclusiveGenderByAge,
	MonitoringService,
	BiologicalSex,
} from '@ccap/shared/utils';
import { sections } from '../../../assets/content/sections';
import { subSections } from '../../../assets/content/sub-sections';
import {
	IServerResponseMedia,
	IAggregatedContentExtended,
} from '../../../interfaces/aggregator';
import { SubSections, Sections } from '../../../interfaces/schema';
import { SummaryCards } from '../../../interfaces/summary-cards';
import { HttpSandboxService } from '../../core/services/http-sandbox.service';
import { SummaryCardsService } from '../summary-cards/summary-cards.service';
import { UserIdentification } from '../../user-identification';

export type IIdentityAnswers = Record<string, any>;

@Component({
	templateUrl: 'profile.component.html',
	styleUrls: ['profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
	public sections?: Sections;
	public subSections?: SubSections;
	public media$?: Observable<IServerResponseMedia>;
	public firstName?: string;
	public fullName?: string;
	public hostFamilyLetter?: string;
	public contactDetails?: DisplayedUnitedStatesContactDetails;
	public busyStatus$?: Observable<string>;
	public summaryCards$?: Observable<SummaryCards>;
	public inCountry$?: Observable<InCountryDto>;
	public identityAnswers$?: Observable<IIdentityAnswers>;
	public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public profilePhoto$: BehaviorSubject<string> = new BehaviorSubject(null);
	public medicalNoteUrl$: BehaviorSubject<any> = new BehaviorSubject(null);

	public personalityTestStatus$?: Observable<PersonalityTestStatusDto>;
	public personalityTestResults$?: Observable<PersonalityTestResultsDto>;

	public lccReferences$?: Observable<LCCReference[]>;
	public hostFamilyReferences$?: Observable<HostFamilyReference[]>;
	public legacyId$?: Observable<string>;

	public isBusyStatusVisible$?: Observable<boolean>;

	private questions?: Record<string, IQuestionExtended>;
	private restrictedAccess?: string[];

	constructor(
		private httpSandboxService: HttpSandboxService,
		private personalityTest: PersonalityTestResource,
		private authService: AuthService,
		private summaryCardsService: SummaryCardsService,
		private titleService: Title,
		private endpoints: Endpoints,
		private countryInfoResource: CountryInfoResource,
		private referenceResource: ReferenceResource,
		private busyStatusResource: BusyStatusResource,
		private monitoringService: MonitoringService,
		private accountResource: AccountResource,
		private store: Store,
	) {}

	public get routes() {
		return [
			{
				title: 'Log out',
				action: (): void => {
					this.authService.logout();
					localStorage.removeItem('user_type');
					localStorage.removeItem('user_id');
				},
			},
		];
	}

	public get fullNameJoined() {
		return this.fullName.replace(/ /g, '-');
	}

	public get canUseLinks(): boolean {
		return new UserIdentification().userType() === 'hf';
	}

	public get canViewInCountry(): boolean {
		return !!new UserIdentification().userType().length;
	}

	public get canViewLegacyId(): boolean {
		return this.isStaff();
	}

	public ngOnInit(): void {
		this.setRestrictedAccess();
		this.getMedia();
		this.getBusyStatus();
		this.getInCountry();
		this.getSummaryCards();
		this.getMedicalNote();
		this.personalityTestStatus$ =
			this.personalityTest.getPersonalityTestStatus();
		this.personalityTestResults$ =
			this.personalityTest.getPersonalityTestResults();
		this.getContent();

		this.sections = sections;
		this.subSections = subSections;

		this.monitoringService.setAuthenticatedUserId();

		this.isBusyStatusVisible$ = this.store.select(
			getElementBusyStatusIsVisible,
		);

		this.hostFamilyReferences$ = this.httpSandboxService
			.getRestrictedAccess$()
			.pipe(
				switchMap(filters =>
					!filters.includes('hostfamilyreferences')
						? this.referenceResource.getHostFamilyReferences()
						: [],
				),
				shareReplay(),
			);

		this.lccReferences$ = this.httpSandboxService.getRestrictedAccess$().pipe(
			switchMap(filters =>
				!filters.includes('lccreferences')
					? this.referenceResource.getLCCReferences()
					: [],
			),
			shareReplay(),
		);

		this.legacyId$ = this.accountResource.getAuPairAccount().pipe(
			map(({ legacyId }) => legacyId),
			shareReplay(),
		);
	}

	public isStaff(): boolean {
		const userType = new UserIdentification().userType();
		return userType === 'staff' || userType === 'lcc';
	}

	public hasAccess(domain: string): boolean {
		return !this.restrictedAccess.includes(domain);
	}

	public questionObjectsFromIds(questions: string[]): IQuestionExtended[] {
		return questions
			.filter((questionId: string) =>
				Object.keys(this.questions).includes(questionId),
			)
			.map((questionId: string) => this.questions[questionId]);
	}

	public languagesQuestions(): IQuestionExtended[] {
		let languageQuestions = this.questionObjectsFromIds(
			subSections.languagesForm.questions,
		);

		languageQuestions = languageQuestions.map(languageQuestion => {
			if (languageQuestion.id === 'interview') {
				return {
					...languageQuestion,
					typeVariations: {
						...languageQuestion.typeVariations,
						additionalFields: languageQuestion.typeVariations?.additionalFields
							.filter(field => field.key === 'englishLevel')
							.map(field => {
								return {
									...field,
									label: 'English level, as assessed by Cultural Care',
								};
							}),
					},
				};
			}
			return languageQuestion;
		});

		return languageQuestions;
	}

	private getContent(): void {
		this.httpSandboxService
			.getRestrictedAccess$()
			.pipe(
				switchMap((restrictedAccess: string[]) => {
					return this.httpSandboxService
						.getContent(restrictedAccess)
						.pipe(take(1));
				}),
			)
			.subscribe((res: IAggregatedContentExtended) => {
				this.setName(res);
				this.setContent(res);
				this.setProfilePhoto(res);
				this.setIdentityCardContent(res);
				this.setHostFamilyLetter(res);

				if (this.hasAccess('contactdetails')) {
					this.setContactDetails(res);
				}

				this.titleService.setTitle(this.firstName);
				this.ready.next(true);
			});
	}

	private setRestrictedAccess(): void {
		this.httpSandboxService
			.getRestrictedAccess$()
			.pipe(take(1))
			.subscribe((res: string[]) => {
				this.restrictedAccess = res;
			});
	}

	private setName(res: IAggregatedContentExtended): void {
		const { firstName, lastName } = res.answers;
		this.firstName = res.answers.firstName as string;
		this.fullName = `${firstName} ${lastName}`;
	}

	private setContent(res: IAggregatedContentExtended): void {
		const { questions, answers } = res;

		this.questions = questions.reduce((acc, question: IQuestionExtended) => {
			acc[question.id] = {
				...question,
				answer:
					answers[question.id] || answers[question.id] === false
						? answers[question.id]
						: null,
			};
			return acc;
		}, {});
	}

	private setProfilePhoto(res: IAggregatedContentExtended): void {
		const [profilephoto] = res.profilephoto; // TODO optimize endpoint to return non-array

		this.emitProfilePhoto(profilephoto);
	}

	private setIdentityCardContent(res: IAggregatedContentExtended): void {
		const {
			firstName,
			nickname,
			lastName,
			dob,
			address,
			nationality,
			gender,
			arrivalDate,
		} = res.answers;

		const dateOfBirth = DateUtils.getDateFromDateValue(dob as DateValue);
		const ageLabel = arrivalDate ? 'Age on arrival' : null;
		const futureDate = arrivalDate
			? new Date(arrivalDate as string)
			: new Date();
		const age = DateUtils.calculateAge(dateOfBirth, futureDate);

		const ageInfo = {
			age,
			ageLabel,
		};

		this.identityAnswers$ = this.countryInfoResource
			.getCountryInfo(nationality as string)
			.pipe(
				map(countryInfo => {
					return {
						firstName,
						nickname,
						lastName,
						addressCountry: (address as Address)?.countryName,
						gender: inclusiveGenderByAge(gender as BiologicalSex, age),
						...countryInfo,
						...ageInfo,
					};
				}),
			);
	}

	private setHostFamilyLetter(res: IAggregatedContentExtended): void {
		const { letter } = res.answers;

		this.hostFamilyLetter = letter as string;
	}

	private setContactDetails(res: IAggregatedContentExtended): void {
		if (
			!res.answers ||
			!res.answers.unitedStatesContactPhone ||
			!res.answers.unitedStatesBestContactTime
		) {
			return;
		}

		const { unitedStatesContactPhone, unitedStatesBestContactTime } =
			res.answers;

		this.contactDetails = {
			unitedStatesContactPhone: unitedStatesContactPhone as PhoneNumber,
			unitedStatesBestContactTime: Object.keys(unitedStatesBestContactTime)
				.filter(key => unitedStatesBestContactTime[key])
				.join(', '),
		};
	}

	private getMedia(): void {
		this.media$ = this.httpSandboxService.getMedia().pipe(take(1));
	}

	private getBusyStatus(): void {
		this.busyStatus$ = this.busyStatusResource
			.getBusyStatus()
			.pipe(map(({ status }) => status));
	}

	private getInCountry(): void {
		this.inCountry$ = this.httpSandboxService.getInCountry$();
	}

	private getSummaryCards(): void {
		this.summaryCards$ = this.summaryCardsService.summaryCards$();
	}

	private getMedicalNote(): void {
		this.httpSandboxService
			.getMedicalNote$()
			.pipe(
				filter(id => !!id),
				map((file: IMediaFile) => file.id),
			)
			.subscribe((id: string) => {
				this.medicalNoteUrl$.next(`${this.endpoints.document}/${id}/download`);
			});
	}

	private emitProfilePhoto(profilephoto: IMediaFile) {
		const photo = profilephoto ? profilephoto.url : null;
		this.profilePhoto$.next(photo);
	}
}
