import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InCountryDto } from '@ccap/au-pair/in-country';
import {
	DisplayedUnitedStatesContactDetails,
	HostFamilyReference,
	LCCReference,
} from '@ccap/interfaces';

@Component({
	selector: 'app-in-country',
	templateUrl: 'in-country.component.html',
	styleUrls: ['in-country.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InCountryComponent {
	@Input() public fileIdentifier?: string;
	@Input() public inCountry?: InCountryDto;
	@Input() public contactDetails?: DisplayedUnitedStatesContactDetails;
	@Input() public canViewContactDetails?: boolean;
	@Input() public hostFamilyReferences?: HostFamilyReference[];
	@Input() public lccReferences?: LCCReference[];

	public isTransitionDetailsPanelVisible = false;

	public toggleShowTransitionDetails() {
		this.isTransitionDetailsPanelVisible = !this
			.isTransitionDetailsPanelVisible;
	}
}
