import { ICheckBoxValue } from '@ccap/interfaces';
import { TypeChecker } from '@ccap/shared/utils';
import { SummaryCard } from '../../../../interfaces/summary-cards';

function preferredAgeRangeOfChildrenAnswer(
	preferredAgeRangeOfChildren: ICheckBoxValue,
): string {
	if (!TypeChecker.isObject(preferredAgeRangeOfChildren)) {
		return null;
	}

	// TODO: get these age ranges from the question service.
	// Before the backend returned all possible results, now we only
	// send the selected age buckets to the backend, so the old approach
	// to figure out if all ages were selected no longer worked.
	const possibleAgeRanges = [
		'0-5 months',
		'6-12 months',
		'13-18 months',
		'19-23 months',
		'2-5 years',
		'6-9 years',
		'10+ years',
	];

	const keys = Object.keys(preferredAgeRangeOfChildren);
	return possibleAgeRanges.every(
		(option: string): boolean => preferredAgeRangeOfChildren[option],
	)
		? 'All ages'
		: keys
				.filter(
					(option: string): boolean => preferredAgeRangeOfChildren[option],
				)
				.join(', ');
}

export function preferredAgeRangeOfChildrenResolver(
	preferredAgeRangeOfChildren: ICheckBoxValue,
): Record<'preferredAgeRangeOfChildren', SummaryCard> {
	return {
		preferredAgeRangeOfChildren: {
			title: 'Preferred ages',
			icon: 'Preferred ages',
			answer: preferredAgeRangeOfChildrenAnswer(preferredAgeRangeOfChildren),
		},
	};
}
