<ng-container *ngIf="!(loading$ | async); else loading">
	<h2 class="ef-h3" [ccapE2EId]="'profile-header--name-nickname'">
		<ng-container
			*ngIf="identityAnswers?.nickname as nickName; else noNickName"
		>
			“{{ nickName }}”
		</ng-container>

		<strong>
			<ng-template #noNickName> {{ identityAnswers?.firstName }} </ng-template>

			{{ identityAnswers?.lastName }}
		</strong>
	</h2>

	<p [ccapE2EId]="'profile-header--gender-demonym-countryOfResidence'">
		{{ genderDemonymAddressCountryLine }}
	</p>

	<p [ccapE2EId]="'profile-header--age'" id="age">
		{{ ageLine }}
	</p>
</ng-container>

<ng-template #loading> <ccap-spinner></ccap-spinner> </ng-template>
