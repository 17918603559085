import {
	Component,
	EventEmitter,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { INotification, NotificationEvent } from './notification.interface';
import { NotificationService } from './notification.service';

@Component({
	selector: 'ccap-notification',
	templateUrl: 'notification.component.html',
	styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
	@Output() public create: EventEmitter<any> = new EventEmitter();
	@Output() public destroy: EventEmitter<any> = new EventEmitter();

	public notifications: INotification[] = [];
	public item?: INotification;

	private listener?: Subscription;

	private timeOut?: number;
	private stopTime = false;
	private steps?: number;
	private speed?: number;
	private count?: number;
	private start?: number;
	private timer?: any;
	private diff?: number;

	constructor(
		private notificationService: NotificationService,
		private zone: NgZone,
	) {
		// Listen for changes in the service
		this.listener = this.notificationService
			.getChangeEmitter()
			.subscribe((notificationEvent: NotificationEvent) => {
				this.init(notificationEvent);
			});
	}

	public removeOnClick(item: INotification) {
		this.notificationService.set(item, false);
		clearTimeout(this.timer);
	}

	public onEnter(): void {
		if (this.item.pauseOnHover) {
			this.stopTime = true;
		}
	}

	public onLeave(): void {
		if (this.item.pauseOnHover) {
			this.stopTime = false;
			setTimeout(this.instance, this.speed - this.diff);
		}
	}

	public get stoptime() {
		return this.stopTime;
	}

	public ngOnInit() {
		this.notificationService.lifeCycle.next(true);
	}

	public ngOnDestroy() {
		if (this.listener) {
			this.listener.unsubscribe();
		}
	}

	private init(notificationEvent: NotificationEvent) {
		this.item = notificationEvent.notification;

		if (!notificationEvent.notification.persistent) {
			this.timeOut = notificationEvent.notification.duration || 3000;
			this.startTimeOut();
		}

		if (notificationEvent.add) {
			this.add(notificationEvent.notification);
		} else {
			this.defaultBehavior(notificationEvent);
		}
	}

	// Add the new notification to the notification array
	private add(item: INotification) {
		item.buildAt = new Date();
		const toBlock = this.block(item);

		if (!toBlock) {
			if (this.notifications.length) {
				this.notifications.splice(this.notifications.length - 1, 1); // add at the end
			}
			this.notifications.splice(0, 0, item); // add at the start?

			this.create.emit(this.buildEmit(item, true));
		}
	}

	// Default behavior on event
	private defaultBehavior(value: NotificationEvent) {
		this.notifications.splice(
			this.notifications.indexOf(value.notification),
			1,
		);
		this.destroy.emit(this.buildEmit(value.notification, false));
	}

	// Check if notifications should be prevented
	private block(item: INotification): boolean {
		const checkExists = (checker: INotification) =>
			checker.type === item.type &&
			checker.title === item.title &&
			checker.content === item.content;

		for (const notification of this.notifications) {
			if (notification.sticky || checkExists(notification)) {
				return true;
			}
		}

		return false;
	}

	private startTimeOut(): void {
		this.count = 0;
		this.steps = this.timeOut / 10;
		this.speed = this.timeOut / this.steps;
		this.start = new Date().getTime();

		this.zone.runOutsideAngular(
			() => (this.timer = setTimeout(this.instance, this.speed)),
		);
	}

	private instance = () => {
		this.zone.runOutsideAngular(() => {
			this.zone.run(
				() =>
					(this.diff =
						new Date().getTime() - this.start - this.count * this.speed),
			);

			if (this.count++ === this.steps) {
				this.zone.run(() => this.removeOnClick(this.item));
			} else if (!this.stopTime) {
				this.timer = setTimeout(this.instance, this.speed - this.diff);
			}
		});
	};

	private buildEmit(notification: INotification, to: boolean): INotification {
		const toEmit: INotification = {
			buildAt: notification.buildAt,
			type: notification.type,
			id: notification.id,
			title: notification.title,
			content: notification.content,
		};

		if (!to) {
			toEmit['destroyedOn'] = new Date();
		}

		return toEmit;
	}
}
