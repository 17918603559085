import { Action, createReducer, on } from '@ngrx/store';

import { IMediaFile } from '@ccap/interfaces';
import { addImage } from './image.actions';
import {
	patchImage,
	removeImage,
	rollbackImages,
	updateImages,
} from './image.actions';

export type ImageState = IMediaFile[];

const reducer = createReducer<ImageState>(
	null,
	on(updateImages, rollbackImages, (_, { images }) => images),
	on(addImage, (state, { image }) => [...state, image]),
	on(patchImage, (state, { image }) =>
		state ? state.map(img => (img.id === image.id ? image : img)) : state,
	),
	on(removeImage, (state, { imageId }) =>
		state ? state.filter(img => img.id !== imageId) : state,
	),
);

export function imageReducer(state: ImageState, action: Action): ImageState {
	return reducer(state, action);
}
