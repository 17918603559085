import { Action, createReducer, on } from '@ngrx/store';

import { AuPairPagesDto } from '@ccap/interfaces';
import { replaceInArray } from '@ccap/shared/utils';
import { fetchPagesSuccess, fetchPageSuccess } from './pages.actions';

export const auPairPagesStateKey = 'pages';

export type AuPairPagesState = AuPairPagesDto;

const reducer = createReducer<AuPairPagesState>(
	[],
	on(fetchPagesSuccess, (_, { pages }) => pages),
	on(fetchPageSuccess, (state, { page }) => {
		const index = state.findIndex(pageToCheck => page.id === pageToCheck.id);

		return replaceInArray(state, index, {
			...state[index],
			...page,
		});
	}),
);

export function auPairPagesReducer(
	state: AuPairPagesState,
	action: Action,
): AuPairPagesState {
	return reducer(state, action);
}
