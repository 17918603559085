import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
	DisplayedUnitedStatesContactDetails,
	IExtension,
} from '@ccap/interfaces';

@Component({
	selector: 'app-extension-details',
	templateUrl: './extension-details.component.html',
	styleUrls: ['./extension-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtensionDetailsComponent {
	@Input()
	public canViewContactDetails?: boolean;

	@Input()
	public contactDetails?: DisplayedUnitedStatesContactDetails;

	@Input()
	public extension?: IExtension;
}
