import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MoreLessTextModule } from '@ccap/shared/ui/layout';
import { TabsModule } from '@ccap/shared/ui/navigation';
import { HostFamilyLetterComponent } from './host-family-letter.component';
import { HostFamilyLettersComponent } from './host-family-letters/host-family-letters.component';

@NgModule({
	imports: [CommonModule, TabsModule, MoreLessTextModule],
	declarations: [HostFamilyLetterComponent, HostFamilyLettersComponent],
	exports: [HostFamilyLettersComponent],
})
export class HostFamilyLetterModule {}
