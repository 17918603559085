import { ITelemetryItem } from '@microsoft/applicationinsights-web';

export const errorMessagesFilter = (item: ITelemetryItem): boolean => {
	if (
		item.baseType === 'ExceptionData' &&
		item.baseData.message?.indexOf(
			`The browser's same-origin policy prevents us from getting the details of this exception`,
		) >= 0
	) {
		return false;
	}

	return true;
};
