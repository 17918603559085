import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';

import { ParentsResource } from '@ccap/au-pair/data-access/parents';
import { actionMergeMap } from '@ccap/shared/rx';
import { Method } from '../../questions/interfaces';
import {
	deleteResourceRemote,
	getAnswers,
	saveParentsRemote,
} from '../../questions/store/questions.actions';
import {
	createParent,
	deleteParent,
	deleteParentFail,
	fetchParents,
	fetchParentsFail,
	patchParents,
	removeParent,
	setParent,
	setParentFail,
	updateParents,
} from './parents.actions';

@Injectable({ providedIn: 'root' })
export class ParentsEffect {
	public getAnswers$ = createEffect(() =>
		this.actions$.pipe(ofType(getAnswers), map(fetchParents)),
	);

	public saveParentsRemote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveParentsRemote),
			filter(({ question }) => question.endpoint === 'parents'),
			map(({ options, question }) => {
				const parent = question.answer[options.index];

				if (options.method === Method.AuthPatch) {
					return setParent({
						parentId: parent.id,
						parent,
					});
				}

				return createParent({
					parent,
					questionId: question.id,
					answerIndex: options.index,
				});
			}),
		),
	);

	public deleteResourceRemote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteResourceRemote),
			filter(({ newAnswers: [newAnswer] }) => newAnswer.endpoint === 'parents'),
			map(({ options: { resourceId } }) =>
				deleteParent({
					parentId: resourceId,
				}),
			),
		),
	);

	public fetchParents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchParents),
			switchMap(() =>
				this.parentsResource.getParents().pipe(
					map(parents => updateParents({ parents })),
					catchError(error => of(fetchParentsFail({ error }))),
				),
			),
		),
	);

	public setParent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setParent),
			actionMergeMap(action => {
				return this.parentsResource
					.updateParent(action.parentId, action.parent)
					.pipe(
						map(parent => patchParents({ parent })),
						catchError(error => [setParentFail({ error }), fetchParents()]),
						startWith(
							patchParents({
								parent: action.parent,
							}),
						),
					);
			}),
		),
	);

	public deleteParent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteParent),
			// concatMap fixes race condition on BE side
			concatMap(action => {
				return this.parentsResource.deleteParent(action.parentId).pipe(
					map(() =>
						removeParent({
							parentId: action.parentId,
						}),
					),
					catchError(error => [deleteParentFail({ error }), fetchParents()]),
				);
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private parentsResource: ParentsResource,
	) {}
}
