import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { HealthDto } from '@ccap/au-pair/data-access/models';

export const fetchHealth = createAction('[Health] Get');
export const fetchHealthFail = createAction(
	'[Health] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setHealth = createAction(
	'[Health] Set',
	props<{ health: Partial<HealthDto> }>(),
);

export const setHealthFail = createAction(
	'[Health] Set Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateHealth = createAction(
	'[Health] Update',
	props<{ health: HealthDto }>(),
);

/**
 * Updates the whole state
 */
export const rollbackHealth = createAction(
	'[Health] Rollback',
	props<{ health: HealthDto }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchHealth = createAction(
	'[Health] Patch',
	props<{ health: Partial<HealthDto> }>(),
);
