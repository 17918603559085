import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { HostFamilyReference, LCCReference } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class ReferenceResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getHostFamilyReferences(): Observable<HostFamilyReference[]> {
		return this.httpService.authGet<HostFamilyReference[]>(
			this.endpoints.hostFamilyReferences,
		);
	}

	public getLCCReferences(): Observable<LCCReference[]> {
		return this.httpService.authGet<LCCReference[]>(
			this.endpoints.lccReferences,
		);
	}
}
