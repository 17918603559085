import { InjectionToken } from '@angular/core';

export const VIDEO_ENDPOINT = new InjectionToken(
	'ccap.shared.media-upload.video-endpoint',
);

export const PHOTO_ENDPOINT = new InjectionToken(
	'ccap.shared.media-upload.photo-endpoint',
);

export const MEDIA_FORM_META_DATA = new InjectionToken(
	'ccap.shared.media-upload.media-form-meta-data',
);

export const MEDIA_AUTH_HEADER = new InjectionToken(
	'ccap.shared.media-upload.media-auth-token',
);

export const PHOTO_MAXIMUM_FILE_SIZE_BYTES = new InjectionToken(
	'ccap.shared.media-upload.photo-maximum-file-size-bytes',
);

export const VIDEO_MAXIMUM_FILE_SIZE_BYTES = new InjectionToken(
	'ccap.shared.media-upload.video-maximum-file-size-bytes',
);
