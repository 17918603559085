import { Component } from '@angular/core';

import { IAbstractListValue } from '@ccap/interfaces';
import { AnswerAbstractListComponent } from '../answer-abstract-list/answer-abstract-list.component';

@Component({
	selector: 'app-answer-question-list',
	templateUrl: 'answer-question-list.component.html',
	styleUrls: ['answer-question-list.component.scss'],
})
export class AnswerQuestionListComponent extends AnswerAbstractListComponent {
	public get answers(): IAbstractListValue[] {
		return super.answer() as IAbstractListValue[];
	}
}
