import {
	Action,
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';

import {
	AuPairLocationStatus,
	AuPairStatus,
	AuPairStatusDto,
} from '@ccap/au-pair/data-access/models';
import { updateStatus } from './actions';

export interface State extends AuPairStatusDto {}

export const initialStatusState: State = {
	statusMessage: '',
	statusName: null,
	locationStatus: null,
	isFlagged: undefined,
	hasTravelled: undefined,
};

const reducer = createReducer<State>(
	initialStatusState,
	on(updateStatus, (state, { status }) => status),
);

export function statusReducer(
	state: State = initialStatusState,
	action: Action,
): State {
	return reducer(state, action);
}

export const selectStatus = createFeatureSelector<AuPairStatusDto>('status');

export const getStatusName = createSelector(
	selectStatus,
	({ statusName }) => statusName,
);

export const getLocationStatus = createSelector(
	selectStatus,
	({ locationStatus }) => locationStatus,
);

export const getHasFinishedProgram = createSelector(
	getStatusName,
	statusName => statusName === AuPairStatus.FinishedProgram,
);

export const getIsApplicationStarted = createSelector(
	getStatusName,
	statusName => statusName === AuPairStatus.ApplicationStarted,
);

export const getIsOnProgram = createSelector(
	getLocationStatus,
	locationStatus => locationStatus === AuPairLocationStatus.OnProgram,
);
