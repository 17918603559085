<ng-template #availableWhenConnected>
	Available when connected
</ng-template>

<ccap-question-answer-group title="Au Pair Contact Details">
	<ccap-question-answer question="Phone Number">
		<ng-container *ngIf="canViewContactDetails; else availableWhenConnected">
			{{ contactDetails?.unitedStatesContactPhone?.countryCode }}
			{{ contactDetails?.unitedStatesContactPhone?.number }}
		</ng-container>
	</ccap-question-answer>

	<ccap-question-answer question="Best Time to Reach">
		<ng-container *ngIf="canViewContactDetails; else availableWhenConnected">
			{{ contactDetails?.unitedStatesBestContactTime }}
		</ng-container>
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Location Preferences">
	<ccap-question-answer question="State preference(s)">
		{{ extension.extensionLocationChoices | multiChoiceDict }}
	</ccap-question-answer>

	<ccap-question-answer question="Additional requests">
		{{ extension.extensionOtherLocationWishes }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Personal Growth">
	<ccap-question-answer question="Childcare experience as an au pair">
		{{ extension.extensionChildcareExperience }}
	</ccap-question-answer>

	<ccap-question-answer question="Activities with host family">
		{{ extension.extensionActivities }}
	</ccap-question-answer>

	<ccap-question-answer question="Additional skills gained as an au pair">
		{{ extension.extensionSkills }}
	</ccap-question-answer>

	<ccap-question-answer question="Activities enjoyed in free time">
		{{ extension.extensionFreeTime }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Driving Info">
	<ccap-question-answer question="United States driver's license">
		{{ extension.extensionHasUnitedStatesDrivingLicense }}
	</ccap-question-answer>

	<ccap-question-answer question="Experience driving in the United States">
		{{ extension.extensionDrivingInUnitedStates }}
	</ccap-question-answer>

	<ccap-question-answer question="Frequency of driving">
		{{ extension.extensionDrivingFrequency }}
	</ccap-question-answer>

	<ccap-question-answer question="Common driving circumstances">
		{{ extension.extensionDrivingConditions | multiChoiceDict }}
	</ccap-question-answer>

	<ccap-question-answer question="Accidents or traffic violations">
		{{ extension.extensionCarAccidentInUnitedStates }}
	</ccap-question-answer>

	<ccap-question-answer
		*ngIf="extension.extensionCarAccidentInUnitedStates === 'Yes'"
		question="Description of accident or violation"
	>
		{{ extension.extensionCarAccidentInUnitedStatesDescription }}
	</ccap-question-answer>

	<ccap-question-answer
		*ngIf="extension.extensionHasUnitedStatesDrivingLicense === 'No'"
		question="Planning to get a U.S. driver's license"
	>
		{{ extension.extensionWillGetUnitedStatesDrivingLicense }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Extension program overview">
	<ccap-question-answer
		question="Education and vacation requirements for extension au pairs"
	>
		Extension au pairs receive an educational stipend and paid vacation as
		follows:
		<ul class="standard-list">
			<li>
				Six-month extension: $250 educational allowance and one week paid
				vacation
			</li>
			<li>
				Nine- or twelve-month extension: $500 educational allowance and two
				weeks paid vacation
			</li>
		</ul>
	</ccap-question-answer>

	<ccap-question-answer question="International travel during extension term">
		Extension au pairs will likely not be able to travel internationally during
		the extension term as they will not have a valid J-1 visa travel document to
		be able to re-enter the U.S.
	</ccap-question-answer>

	<ccap-question-answer question="Extension program fee">
		Domestic transportation fees and payment deadlines still apply for all
		extension Au Pairs. For Au Pairs extending for a full 12 months, normal
		match and program fees apply. For au pairs extending for six or nine months,
		families pay the match fee and a pro-rated program fee based on the number
		of expected program weeks with this au pair.
	</ccap-question-answer>

	<ccap-question-answer question="More information">
		If you would like to learn more about your Au Pair during his/her extension
		term, please call us at
		<a href="tel:800-333-6056">800-333-6056</a>
	</ccap-question-answer>
</ccap-question-answer-group>
