import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Option } from '@ccap/interfaces';
import { CustomControl } from '../custom-control';

@Component({
	selector: 'ccap-neo-dropdown',
	templateUrl: './dropdown.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @angular-eslint/no-forward-ref
			useExisting: forwardRef(() => NeoDropdownComponent),
			multi: true,
		},
	],
})
export class NeoDropdownComponent extends CustomControl {
	@Input()
	public id?: string;

	@Input()
	public options?: Option[];

	@Input()
	public label?: string;

	@Input()
	public exactName?: boolean;

	@Input()
	public noValueText = 'Please choose';

	@Output()
	// eslint-disable-next-line @angular-eslint/no-output-native
	public blur = new EventEmitter<void>();

	public onInputChange(event: Event): void {
		const target = event.target as HTMLSelectElement;

		this.writeValue(target.value);
		this.onChange(target.value);
		this.onTouched();
	}

	public onInputBlur(): void {
		this.blur.emit();
	}
}
