import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

import { ContentLocaleDto } from '@ccap/au-pair/data-access/models';

@Component({
	selector: 'ccap-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
	@Input()
	public locales?: ContentLocaleDto[];

	@Output()
	public localeSelect = new EventEmitter<ContentLocaleDto>();

	public isLanguageSelectorActive = false;

	public onLanguageSelectorToggleClicked(): void {
		this.isLanguageSelectorActive = !this.isLanguageSelectorActive;
	}

	public onLanguageButtonClicked(locale: ContentLocaleDto): void {
		this.localeSelect.emit(locale);
		this.isLanguageSelectorActive = false;
	}
}
