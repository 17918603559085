import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContactDetailsState } from './contact-details.reducer';

export const getContactDetails =
	createFeatureSelector<ContactDetailsState>('contactDetails');

export const getUSAContactDetails = createSelector(
	getContactDetails,
	({ usa }) => usa,
);

export const getHomeContactDetails = createSelector(
	getContactDetails,
	({ home }) => home,
);

export const getHomeCountry = createSelector(
	getHomeContactDetails,
	home => home.address.countryName,
);
