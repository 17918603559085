import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { TravelInsuranceDto } from '@ccap/au-pair/data-access/models';

export const fetchInsurance = createAction('[Insurance] Get');
export const fetchInsuranceFail = createAction(
	'[Insurance] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateInsurance = createAction(
	'[Insurance] Update',
	props<{ insurance: TravelInsuranceDto }>(),
);
