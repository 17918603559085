import { Injectable } from '@angular/core';

const scrollOptions = {
	duration: 500,
	yDiff: (el: HTMLElement) => el.offsetTop - window.scrollY,
};

@Injectable({ providedIn: 'root' })
export class ScrollService {
	public scrollTo(element: HTMLElement) {
		if (element instanceof HTMLElement) {
			this.scrollToElement(element);
		} else {
			throw new Error('The element provided is not of type HTMLElement.');
		}
	}

	private scrollToElement(el: HTMLElement) {
		let start: number;

		const step: FrameRequestCallback = timestamp => {
			start = !start ? timestamp : start;
			const time = timestamp - start;
			const percent = Math.min(time / scrollOptions.duration, 1);
			const y = window.scrollY + scrollOptions.yDiff(el) * percent;

			window.scrollTo(0, y);

			if (time < scrollOptions.duration) {
				window.requestAnimationFrame(step);
			}
		};

		window.requestAnimationFrame(step);
	}
}
