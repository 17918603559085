import { Component, Input } from '@angular/core';

import { ISubSectionExtended } from '@ccap/interfaces';

@Component({
	selector: 'app-sub-section-description',
	templateUrl: 'sub-section-description.component.html',
	styleUrls: ['sub-section-description.component.scss'],
})
export class SubSectionDescriptionComponent {
	@Input() public subSection?: ISubSectionExtended;
}
