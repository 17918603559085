<div class="ef-row u-mt-l u-mb-l" efButtonGroup>
	<button
		efButton
		[outlined]="true"
		[square]="true"
		[half]="true"
		[ccapE2EId]="'e2e-cancel-button'"
		type="button"
		(click)="onCancel()"
		class="ef-col"
	>
		{{ cancelButtonText }}
	</button>

	<button
		efButton
		[primary]="true"
		[square]="true"
		[half]="true"
		[ccapE2EId]="'e2e-save-button'"
		type="button"
		[disabled]="disabledSaveAndContinue"
		(click)="onSave()"
		class="ef-col"
	>
		{{ saveButtonText }}
	</button>
</div>
