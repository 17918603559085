import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2eModule } from '@ccap/e2e';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { NotificationComponent } from './notification.component';

@NgModule({
	imports: [CommonModule, SvgModule, E2eModule],
	declarations: [NotificationComponent],
	exports: [NotificationComponent],
})
export class NotificationModule {}
