import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { E2eModule } from '@ccap/e2e';
import { LogoComponent } from './logo.component';

@NgModule({
	imports: [CommonModule, RouterModule, E2eModule],
	declarations: [LogoComponent],
	exports: [LogoComponent],
})
export class LogoModule {}
