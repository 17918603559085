import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InCountryEffect } from './store/in-country.effects';
import { inCountryReducer } from './store/in-country.reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('inCountry', inCountryReducer),
		EffectsModule.forFeature([InCountryEffect]),
	],
})
export class InCountryModule {}
