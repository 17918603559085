import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { CountryInfoResource } from '@ccap/au-pair/data-access/country-info';
import {
	ContactDetailsHomeApiDto,
	ContactDetailsHomeDto,
	ContactDetailsUSADto,
} from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

const mapApiResponseToAppResponse = (
	contactDetails: ContactDetailsHomeApiDto,
): ContactDetailsHomeDto => {
	const {
		addressStreet,
		addressStreet2,
		addressCity,
		addressState,
		addressCountry,
		addressZip,
		...remainingContactDetails
	} = contactDetails;

	return {
		...remainingContactDetails,

		...(addressStreet && {
			address: {
				street: addressStreet,
				street2: addressStreet2,
				city: addressCity,
				zip: addressZip,
				state: addressState,
				countryCode: addressCountry,
				countryName: '',
			},
		}),
	};
};

const mapAppRequestToApiRequest = (
	contactDetails: Partial<ContactDetailsHomeDto>,
): Partial<ContactDetailsHomeApiDto> => {
	const { address, ...remainingContactDetails } = contactDetails;

	return {
		...remainingContactDetails,

		...(address?.street && {
			addressStreet: address.street,
			addressStreet2: address.street2,
			addressCity: address.city,
			addressZip: address.zip,
			addressState: address.state,
			addressCountry: address.countryCode,
		}),
	};
};

@Injectable({
	providedIn: 'root',
})
export class ContactDetailsResource {
	constructor(
		private httpService: HttpService,
		private endpoints: Endpoints,
		private countryInfoResource: CountryInfoResource,
	) {}

	public getHomeContactDetails(): Observable<ContactDetailsHomeDto> {
		return this.httpService
			.authGet<ContactDetailsHomeApiDto>(this.endpoints.contactDetails)
			.pipe(
				map(payload => mapApiResponseToAppResponse(payload)),
				this.getCountryName(),
			);
	}

	public updateHomeContactDetails(
		payload: Partial<ContactDetailsHomeDto>,
	): Observable<ContactDetailsHomeDto> {
		const apiPayload = mapAppRequestToApiRequest(payload);

		return this.httpService
			.authPatch<ContactDetailsHomeApiDto>(
				this.endpoints.contactDetails,
				apiPayload,
			)
			.pipe(
				map(res => mapApiResponseToAppResponse(res)),
				this.getCountryName(),
			);
	}

	public getUSAContactDetails(): Observable<ContactDetailsUSADto> {
		return this.httpService.authGet<ContactDetailsUSADto>(
			this.endpoints.unitedStatesContactDetails,
		);
	}

	public updateUSAContactDetails(
		payload: Partial<ContactDetailsUSADto>,
	): Observable<ContactDetailsUSADto> {
		return this.httpService.authPatch<ContactDetailsUSADto>(
			this.endpoints.unitedStatesContactDetails,
			payload,
		);
	}

	private getCountryName() {
		return (source: Observable<ContactDetailsHomeDto>) =>
			source.pipe(
				switchMap(contactDetails =>
					contactDetails.address?.countryCode
						? this.countryInfoResource
								.getCountryName(contactDetails.address.countryCode)
								.pipe(
									map(countryName => ({
										...contactDetails,
										address: {
											...contactDetails.address,
											countryName,
										},
									})),
								)
						: of(contactDetails),
				),
			);
	}
}
