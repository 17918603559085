import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FeatureToggleSandboxService } from '@ccap/feature-toggle';
import { HttpService } from '@ccap/shared/data-access';
import { NotificationService } from '@ccap/shared/ui/popups';

@Component({
	selector: 'app-root',
	encapsulation: ViewEncapsulation.None,
	template: `
		<ccap-notification></ccap-notification>
		<router-outlet></router-outlet>
	`,
})
export class AppComponent implements OnInit {
	constructor(
		private httpService: HttpService,
		private notificationService: NotificationService,
		private featureToggleSandboxService: FeatureToggleSandboxService,
	) {}

	public ngOnInit(): void {
		this.httpService.error$.subscribe(errorData => {
			this.notificationService.error(errorData);
		});
		this.featureToggleSandboxService.fetchFeatureFlags();
	}
}
