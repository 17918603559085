import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnswerRadioTileComponent } from './answer-radio-tile.component';

@NgModule({
	imports: [CommonModule],
	declarations: [AnswerRadioTileComponent],
	exports: [AnswerRadioTileComponent],
})
export class AnswerRadioTileModule {}
