import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { AnswerAbstractListItemModule } from '../answer-abstract-list-item/answer-abstract-list-item.module';
import { AnswerWorkExperienceListComponent } from './answer-work-experience-list.component';

@NgModule({
	imports: [CommonModule, PipesModule, AnswerAbstractListItemModule],
	declarations: [AnswerWorkExperienceListComponent],
	exports: [AnswerWorkExperienceListComponent],
})
export class AnswerWorkExperienceListModule {}
