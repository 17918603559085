import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
	ChangeReturnFlightDto,
	ReturnFlightDto,
} from '@ccap/au-pair/data-access/models';
import {
	updateChangeReturnFlight,
	updateReturnFlight,
} from './return-flight.actions';

export interface ReturnFlightState {
	returnFlight: ReturnFlightDto | null;
	changeReturnFlight: ChangeReturnFlightDto | null;
}

const reducer = combineReducers<ReturnFlightState>({
	returnFlight: createReducer<ReturnFlightDto | null>(
		null,
		on(updateReturnFlight, (_, { returnFlight }) => returnFlight),
	),
	changeReturnFlight: createReducer<ChangeReturnFlightDto | null>(
		null,
		on(
			updateChangeReturnFlight,
			(_, { changeReturnFlight }) => changeReturnFlight,
		),
	),
});

export function returnFlightReducer(
	state: ReturnFlightState,
	action: Action,
): ReturnFlightState {
	return reducer(state, action);
}
