import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CharacterLimitPipe } from './character-limit.pipe';
import { CommaSeparatedPipe } from './comma-separated.pipe';
import { CustomDatePipe } from './custom-date/custom-date.pipe';
import { EscapeStringPipe } from './escapeString.pipe';
import { FillPipe } from './fill.pipe';
import { GroupByPipe } from './group-by.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { SelectedPipe } from './selected.pipe';
import { YesNoPipe } from './yes-no.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		EscapeStringPipe,
		FillPipe,
		GroupByPipe,
		SelectedPipe,
		YesNoPipe,
		OrdinalPipe,
		CommaSeparatedPipe,
		CharacterLimitPipe,
		CustomDatePipe,
	],
	exports: [
		EscapeStringPipe,
		FillPipe,
		GroupByPipe,
		SelectedPipe,
		YesNoPipe,
		OrdinalPipe,
		CommaSeparatedPipe,
		CharacterLimitPipe,
		CustomDatePipe,
	],
})
export class PipesModule {}
