import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuPairFrontPageWidget } from '@ccap/interfaces';

export const getFrontPageWidgets = createFeatureSelector<
	AuPairFrontPageWidget[]
>('frontPageWidgets');

export const getIsUsPhoneNumberWidgetEnabled = createSelector(
	getFrontPageWidgets,
	frontPageWidgets =>
		frontPageWidgets.includes(AuPairFrontPageWidget.UsPhoneNumber),
);

export const getIsReferFriendWidgetEnabled = createSelector(
	getFrontPageWidgets,
	frontPageWidgets =>
		frontPageWidgets.includes(AuPairFrontPageWidget.ReferFriend),
);

export const getIsReferFamilyWidgetEnabled = createSelector(
	getFrontPageWidgets,
	frontPageWidgets =>
		frontPageWidgets.includes(AuPairFrontPageWidget.ReferFamily),
);

export const getIsAwaitingFlightTicketsWidgetEnabled = createSelector(
	getFrontPageWidgets,
	frontPageWidgets =>
		frontPageWidgets.includes(AuPairFrontPageWidget.AwaitingFlightTickets),
);

export const getIsFlightsSummaryWidgetEnabled = createSelector(
	getFrontPageWidgets,
	frontPageWidgets =>
		frontPageWidgets.includes(AuPairFrontPageWidget.FlightsSummary),
);
