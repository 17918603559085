import { Injectable } from '@angular/core';
import { compose, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { HttpService } from '@ccap/shared/data-access';
import { NotificationService } from '@ccap/shared/ui/popups';
import {
	DeclineReasons,
	EndpointType,
	ILink,
	ILinkData,
	ILinkUpdatePayload,
	LinkStatus,
	NotificationMessage,
} from '../../../interfaces/links';

interface LinkState {
	[auPairId: string]: {
		link: ILink | null;
		declineReasons: DeclineReasons | null;
	};
}

const getAuPair = (auPairId: string) => (state: LinkState) => state[auPairId];

const getLink = (auPairId: string) =>
	compose(({ link }) => link, getAuPair(auPairId));

const getConnection = (auPairId: string) =>
	compose(link => (link ? link.connection : null), getLink(auPairId));

const getLike = (auPairId: string) =>
	compose(link => (link ? link.like : null), getLink(auPairId));

const getDeclineReasons = (auPairId: string) =>
	compose(({ declineReasons }) => declineReasons, getAuPair(auPairId));

@Injectable({
	providedIn: 'root',
})
export class LinkSandboxService {
	private state$ = new BehaviorSubject<LinkState>({});

	constructor(
		private endpoints: Endpoints,
		private httpService: HttpService,
		private notificationService: NotificationService,
	) {}

	public isStatusPermitted(
		permittedStatuses: LinkStatus[],
		status: LinkStatus,
	) {
		return permittedStatuses.includes(status);
	}

	public isAuPairLinked(auPairId: string): Observable<boolean> {
		this.setInitialState(auPairId);

		return this.state$.pipe(map(getLink(auPairId)), select(Boolean));
	}

	public getAuPairConnection(auPairId: string): Observable<ILinkData> {
		this.setInitialState(auPairId);

		return this.state$.pipe(select(getConnection(auPairId)));
	}

	public getAuPairLike(auPairId: string): Observable<ILinkData> {
		this.setInitialState(auPairId);

		return this.state$.pipe(select(getLike(auPairId)));
	}

	public getAuPairLink(auPairId: string): Observable<ILink> {
		this.setInitialState(auPairId);

		return this.state$.pipe(select(getLink(auPairId)));
	}

	public setAuPairLink(auPairId: string, link: ILink): Observable<void> {
		const state = this.state$.getValue();

		this.state$.next({
			...state,
			[auPairId]: {
				...state[auPairId],
				link,
			},
		});

		return this.state$.pipe(first(), mapTo(undefined));
	}

	public setDeclineReason(
		auPairId: string,
		declineReasons: DeclineReasons,
	): Observable<void> {
		const state = this.state$.getValue();

		this.state$.next({
			...state,
			[auPairId]: {
				...state[auPairId],
				declineReasons,
			},
		});

		return this.state$.pipe(first(), mapTo(undefined));
	}

	public getDeclineReason(auPairId: string): Observable<DeclineReasons> {
		this.setInitialState(auPairId);

		return this.state$.pipe(select(getDeclineReasons(auPairId)));
	}

	public getDeclineReasonOfType(
		auPairId: string,
		type: keyof DeclineReasons,
	): Observable<string[]> {
		this.setInitialState(auPairId);

		return this.state$
			.pipe(select(getDeclineReasons(auPairId)))
			.pipe(select(reasons => (reasons ? reasons[type] : [])));
	}

	public likeAuPair(auPairId: string): Observable<void> {
		this.setInitialState(auPairId);

		const body: ILinkUpdatePayload = {
			status: LinkStatus.liked,
		};

		return this.httpService
			.authPatch<ILink>(this.endpointCreate(auPairId, EndpointType.Like), body)
			.pipe(
				first(),
				tap(() =>
					this.notificationService.success({
						content: NotificationMessage.Like,
					}),
				),
				switchMap(link => this.setAuPairLink(auPairId, link)),
			);
	}

	public removeLikeOfAuPair(auPairId: string): Observable<void> {
		this.setInitialState(auPairId);

		const body: ILinkUpdatePayload = {
			status: LinkStatus.none,
		};

		return this.httpService
			.authPatch<ILink>(this.endpointCreate(auPairId, EndpointType.Like), body)
			.pipe(
				first(),
				tap(() =>
					this.notificationService.success({
						content: NotificationMessage.Unlike,
					}),
				),
				switchMap(link => this.setAuPairLink(auPairId, link)),
			);
	}

	public connectWithAuPair(
		auPairId: string,
		message: string,
	): Observable<void> {
		this.setInitialState(auPairId);

		const body: ILinkUpdatePayload = {
			status: LinkStatus.pending,
			message,
		};

		return this.httpService
			.authPatch<ILink>(
				this.endpointCreate(auPairId, EndpointType.Connect),
				body,
			)
			.pipe(
				first(),
				tap(() =>
					this.notificationService.success({
						content: NotificationMessage.Connect,
					}),
				),
				switchMap(link => this.setAuPairLink(auPairId, link)),
			);
	}

	public disconnectFromAuPair(
		auPairId: string,
		declineReason = '',
	): Observable<void> {
		this.setInitialState(auPairId);

		const body: ILinkUpdatePayload = {
			status: LinkStatus.declined,
			declineReason,
		};

		return this.httpService
			.authPatch<ILink>(
				this.endpointCreate(auPairId, EndpointType.Connect),
				body,
			)
			.pipe(
				first(),
				tap(() =>
					this.notificationService.success({
						content: NotificationMessage.Disconnect,
					}),
				),
				switchMap(link => this.setAuPairLink(auPairId, link)),
			);
	}

	public fetchAuPairLink(auPairId: string): void {
		this.httpService
			.authGet(this.aupairPublicIdEndpoint(auPairId))
			.pipe(
				first(),
				switchMap(link => this.setAuPairLink(auPairId, link)),
			)
			.subscribe();
	}

	public fetchDeclineReason(auPairId: string): void {
		this.httpService
			.authGet(this.endpoints.familyLinkDeclineReasons)
			.pipe(
				first(),
				switchMap(reasons => this.setDeclineReason(auPairId, reasons)),
			)
			.subscribe();
	}

	private setInitialState(auPairId: string): Observable<void> {
		const state = this.state$.getValue();

		if (state[auPairId]) {
			return;
		}

		this.state$.next({
			...state,
			[auPairId]: {
				link: null,
				declineReasons: null,
			},
		});

		return this.state$.pipe(first(), mapTo(undefined));
	}

	private aupairPublicIdEndpoint(auPairId: string): string {
		return `${this.endpoints.familyLinks}/${auPairId}`;
	}

	private endpointCreate(auPairId: string, endpointType: EndpointType): string {
		return `${this.aupairPublicIdEndpoint(auPairId)}/${endpointType}`;
	}
}
