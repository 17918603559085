import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NetworkAwarePreloadStrategy } from '@ccap/shared/utils';
import { ROUTES } from './app.routes';

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
			anchorScrolling: 'enabled',
			preloadingStrategy: NetworkAwarePreloadStrategy,
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
