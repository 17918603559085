<ng-container *ngIf="answers.length; else noExperiences">
	<ng-container *ngFor="let group of answers | groupBy: 'status'">
		<div class="experience-list__item" *ngFor="let reference of group.values">
			<ng-container *ngIf="group.key.toLowerCase() as key">
				<div
					class="experience-list__item-bar experience-list__item-bar--{{ key }}"
				>
					<h5 class="ef-h6 u-m-0" *ngIf="key === 'pending'">
						Reference verification pending
					</h5>

					<ng-container *ngIf="key === 'rejected'">
						<h5 class="ef-h6 u-m-0">Reference rejected</h5>
						<ccap-svg [title]="'error'" [classList]="'error'"> </ccap-svg>
					</ng-container>

					<ng-container *ngIf="key === 'approved'">
						<h5 class="ef-h6 u-m-0" *ngIf="reference.verifiedBy">
							Reference verified by {{ reference.verifiedBy }}
							<ng-container *ngIf="reference.verifiedDateTimeUtc">
								on
								{{
									reference.verifiedDateTimeUtc | date: 'dd MMMM yyyy'
								}}</ng-container
							>
						</h5>
						<ccap-svg [title]="'check'" [classList]="'checkmark'"> </ccap-svg>
					</ng-container>
				</div>
				<app-abstract-list-item [labeledFields]="getLabelsForFields(reference)">
				</app-abstract-list-item>
			</ng-container>
		</div>
	</ng-container>
</ng-container>

<ng-template #noExperiences>
	<p class="no-answer no-answer--center">No Experiences Added</p>
</ng-template>
