import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IExtensionStatus } from '../../interfaces';

export const fetchStatus = createAction('[Extension] Status Get');
export const fetchStatusFail = createAction(
	'[Extension] Status Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateStatus = createAction(
	'[Extension] Status Update',
	props<{ status: IExtensionStatus }>(),
);
