import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[ccapE2EId]',
})
export class E2eIdDirective {
	private selector?: string;

	constructor(private elementRef: ElementRef) {}

	@Input()
	public get ccapE2EId(): string {
		return this.selector;
	}

	public set ccapE2EId(selector: string) {
		this.selector = selector;
		this.addId(selector);
	}

	private get element(): HTMLElement {
		return this.elementRef.nativeElement;
	}

	private addId(id: string): void {
		this.element.setAttribute('data-e2e-id', id);
	}
}
