import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	HostListener,
	Injector,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';

import { AbstractModalBase } from './abstract-modal-base';
import { ModalScrollService } from './modal-scroll.service';

/**
 * @whatItDoes
 * The ModalMainComponent class is an invisible container for a single modal.
 * When rendered, it’s related to the DOM element between the modalRoot and the visible modal elements (.modal-main).
 */
@Component({
	template: `
		<div class="modal-wrap" [ngClass]="{ in: shown }" role="dialog">
			<div class="main-wrap"><ng-template #element></ng-template></div>
		</div>
	`,
})
export class ModalMainComponent {
	public shown = false;

	@ViewChild('element', { read: ViewContainerRef, static: true })
	private _element?: ViewContainerRef;
	private content?: AbstractModalBase;

	constructor(
		private modalScrollService: ModalScrollService,
		private resolver: ComponentFactoryResolver,
	) {}

	/**
	 * Creates and adds to DOM the main modal (overlay) parent component
	 */
	public addComponent(component: Type<AbstractModalBase>) {
		const factory: ComponentFactory<AbstractModalBase> = this.resolver.resolveComponentFactory(
			component,
		);
		const injector: Injector = Injector.create({
			providers: [],
			parent: this._element.injector,
		});
		const componentRef: ComponentRef<AbstractModalBase> = factory.create(
			injector,
		);

		this._element.insert(componentRef.hostView);
		this.content = componentRef.instance;
		this.content.modalMainComponent = this;
		return this.content;
	}

	public show(): void {
		this.shown = true;
		this.modalScrollService.disableScroll();
	}

	public hide(): void {
		this.shown = false;
		this.modalScrollService.enableScroll();
	}

	public set element(value: ViewContainerRef) {
		this._element = value;
	}

	// Esc will close the modal.
	@HostListener('window:keydown', ['$event'])
	public keyboardInput(event: KeyboardEvent) {
		event.stopPropagation();
		if (event.code === 'Escape') {
			this.content.removeModal();
		}
	}
}
