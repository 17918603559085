import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { AuPairFrontPageWidgetsDto } from '@ccap/interfaces';

export const fetchFrontPageWidgets = createAction('[Front Page Widgets] Get');

export const fetchFrontPageWidgetsError = createAction(
	'[Front Page Widgets] Get Error',
	props<{ error: HttpErrorResponse }>(),
);

export const fetchFrontPageWidgetsSuccess = createAction(
	'[Front Page Widgets] Get Front Page Widgets Success',
	props<{ frontPageWidgets: AuPairFrontPageWidgetsDto }>(),
);
