<div class="question-answer">
	<div class="question-answer__question">
		<div *ngFor="let question of questionLines">
			{{ question }}
		</div>
	</div>

	<div class="question-answer__answer">
		<ng-content></ng-content>
	</div>
</div>
