import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	ExtensionPaymentDetailsDto,
	ExtensionPaymentTransactionDto,
} from '../../interfaces';

export const fetchPayment = createAction('[Extension] Payment Get');
export const fetchPaymentFail = createAction(
	'[Extension] Payment Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePayment = createAction(
	'[Extension] Payment Update',
	props<{ payment: ExtensionPaymentDetailsDto }>(),
);

// Transaction
export const createPaymentTransaction = createAction(
	'[Extension] Payment Transaction Create',
	props<{ transaction: ExtensionPaymentTransactionDto }>(),
);

export const createPaymentTransactionFail = createAction(
	'[Extension] Payment Transaction Create Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePaymentTransaction = createAction(
	'[Extension] Payment Transaction Update',
	props<{ transaction: ExtensionPaymentTransactionDto }>(),
);
