import { Component } from '@angular/core';
import { AnswerWorkExperienceListComponent } from '../answer-work-experience-list/answer-work-experience-list.component';

@Component({
	selector: 'app-answer-experience-list',
	templateUrl: 'answer-experience-list.component.html',
	styleUrls: [
		'../answer-work-experience-list/answer-work-experience-list.component.scss',
	],
})
export class AnswerExperienceListComponent extends AnswerWorkExperienceListComponent {}
