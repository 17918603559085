<div>
	<div efInputWrapper efInputMarkAs>
		<textarea
			cdkTextareaAutosize
			ccapCustomControl
			efInput
			[exactName]="exactName"
			[value]="value"
			[attr.id]="id"
			[ccapE2EClass]="'text-input'"
			[attr.placeholder]="placeholder"
			[attr.maxlength]="maxlength"
			[attr.autofocus]="autofocus"
			(blur)="onTouched()"
			(input)="onInputChange($event)"
		></textarea>
	</div>

	<div
		class="ef-text-caption textarea__max-length"
		*ngIf="!ngControl?.errors && maxlength"
	>
		({{ value ? value.length : 0 }}/{{ maxlength }}
		characters)
	</div>

	<ccap-validation
		[errors]="ngControl?.errors"
		[visible]="ngControl?.touched"
	></ccap-validation>
</div>
