import { BehaviorSubject } from 'rxjs';

import { ModalComponentFactoryService } from './modal-component-factory.service';
import { ModalMainComponent } from './modal-main.component';

/**
 * The AbstractModalBase abstract class contains implementation details for its derived members.
 * It may not be instantiated directly.
 *
 * It only defines methods for closing modals.
 * https://www.typescriptlang.org/docs/handbook/classes.html
 *
 */

export abstract class AbstractModalBase {
	public modalMainComponent?: ModalMainComponent;
	protected behavior: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		protected modalComponentFactoryService: ModalComponentFactoryService,
	) {}

	// Set input parameters to component properties.
	public setInputsToProperties(data: any = {}): BehaviorSubject<boolean> {
		Object.keys(data).forEach((key: string) => {
			this[key] = data[key];
		});

		this.behavior.next(true);
		return this.behavior;
	}

	public removeModal() {
		this.behavior.next(false);
		return this.modalComponentFactoryService.removeModal(this);
	}
}
