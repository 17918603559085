import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { PhoneNumber } from '@ccap/interfaces';

const COUNTRY_CODE_MIN_LENGTH = 2;
const COUNTRY_CODE_MAX_LENGTH = 5;
const NUMBER_MIN_LENGTH = 2;
const NUMBER_MAX_LENGTH = 25;

export const phoneNumberValidator: ValidatorFn = (
	control: AbstractControl,
): ValidationErrors => {
	const phoneNumber: PhoneNumber | null = control.value;

	if (!phoneNumber) {
		return null;
	}

	if (
		phoneNumber.number &&
		(!phoneNumber.countryCode ||
			phoneNumber.countryCode.length < COUNTRY_CODE_MIN_LENGTH)
	) {
		return {
			phoneNumber: {
				errorMessage: `The country code must be at least ${COUNTRY_CODE_MIN_LENGTH} characters long`,
			},
		};
	}

	if (
		phoneNumber.number &&
		phoneNumber.countryCode.length > COUNTRY_CODE_MAX_LENGTH
	) {
		return {
			phoneNumber: {
				errorMessage: `The country code must be less than ${COUNTRY_CODE_MAX_LENGTH} characters long`,
			},
		};
	}

	if (
		phoneNumber.countryCode &&
		(!phoneNumber.number || phoneNumber.number.length < NUMBER_MIN_LENGTH)
	) {
		return {
			phoneNumber: {
				errorMessage: `The phone number must be at least ${COUNTRY_CODE_MIN_LENGTH} characters long`,
			},
		};
	}

	if (
		phoneNumber.countryCode &&
		phoneNumber.number.length > NUMBER_MAX_LENGTH
	) {
		return {
			phoneNumber: {
				errorMessage: `The phone number must be less than ${NUMBER_MAX_LENGTH} characters long`,
			},
		};
	}

	return null;
};
