import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICheckBoxValue, IQuestionExtended } from '@ccap/interfaces';
import { mapAnswersToQuestionOptionsLabel } from '@ccap/question-engine/utils';

@Component({
	selector: 'app-answer-checkbox',
	templateUrl: 'answer-checkbox.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerCheckboxComponent {
	@Input() public question?: IQuestionExtended;

	public get selectedChoices(): string {
		const answer = this.question.answer as ICheckBoxValue;

		if (!answer || !this.question.options) {
			return '';
		}

		return mapAnswersToQuestionOptionsLabel(answer, this.question.options)
			.map(({ label }) => label)
			.join(', ');
	}
}
