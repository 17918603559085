import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'selected',
})
export class SelectedPipe implements PipeTransform {
	public transform(value: any, message?: string): string {
		const msg = message || 'Required';

		let result: string;

		if (Array.isArray(value)) {
			result = this.flatten(value).join(' ');
		} else if (this.isObject(value)) {
			result = this.objectToArray(value).join(' ');
		} else if (typeof value === 'boolean') {
			result = value ? 'Yes' : 'No';
		} else {
			result = value;
		}

		return this.getFlatAnswer(result, msg);
	}

	private isObject = (v: any) =>
		Object.prototype.toString.call(v) === '[object Object]';

	private flatten: any = (deep: any[], flat: any[] = []) => {
		if (deep.length === 0) {
			return flat;
		}

		const head = deep[0];
		const tail = deep.slice(1);

		if (this.isObject(head)) {
			return this.flatten(tail, [].concat(flat, this.objectToArray(head)));
		} else if (Array.isArray(head)) {
			return this.flatten(tail, [].concat(flat, this.flatten(head)));
		} else {
			return this.flatten(tail, [].concat(flat, head));
		}
	};

	private getFlatAnswer(value: any, message: string) {
		const defaultMessage = `<p class="no-answer">${message}</p>`;
		const urlEncodedString =
			value === null || value === undefined
				? value
				: this.getUrlEncodedString(value);
		return urlEncodedString ? urlEncodedString : defaultMessage;
	}

	private getUrlEncodedString(value: any) {
		return value
			.toString()
			.trim()
			.replace(/ +/g, ' ')
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;');
	}

	private objectToArray(obj: any): string[] {
		let set: any[] = [];
		for (const key in obj) {
			if (obj[key]) {
				set = [].concat(set, obj[key]);
			}
		}
		return set;
	}
}
