import { Sections } from '../../interfaces/schema';

export const sections: Sections = {
	aboutMe: {
		title: 'Overview',
		description: {
			image: 'about_me.svg',
			text: `Essential details and additional information from our in-person interview.`,
		},
	},
	myAuPairPreferences: {
		title: 'Home life preferences',
		description: {
			image: 'my-au-pair-year.svg',
			text: `Expectations and preferences in house family composition and lifestyle.`,
		},
	},
	references: {
		title: 'Experience and references',
		description: {
			image: 'childcare_skills.svg',
			text: `Childcare and personal recommendations marked as “verified” have been confirmed by Cultural Care Au Pair staff.`,
		},
	},
	lifeExperiences: {
		title: 'Life experiences',
		description: {
			image: 'life_experiences.svg',
			text: `Travel experience and more.`,
		},
	},
	skillsAndEducation: {
		title: 'Skills and education',
		description: {
			image: 'skills_education.svg',
			text: `Accredited skills and education.`,
		},
	},
	personalityProfile: {
		title: 'Personality profile',
		description: {
			image: 'personality.svg',
			text: `Interests, hobbies and answers to our personality quiz.`,
		},
	},
	health: {
		title: 'Health information',
		description: {
			image: 'health.svg',
			text: ``,
		},
	},
};
