import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { E2eModule } from '@ccap/e2e';
import { FormModule } from '@ccap/ef-ui/components';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CustomControlModule } from '../custom-control';
import { ValidationModule } from '../validation';
import { TextareaComponent } from './textarea.component';

@NgModule({
	declarations: [TextareaComponent],
	exports: [TextareaComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TextFieldModule,
		ValidationModule,
		CustomControlModule,
		FormModule,
		E2eModule,
	],
})
export class NeoTextareaModule {}
