import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { IMediaFile } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class MediaResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getImages(): Observable<IMediaFile[]> {
		return this.httpService.authGet<IMediaFile[]>(this.endpoints.image);
	}

	public updateImageMetadata(
		key: string,
		id: string,
		metadata: Record<string, any>,
	): Observable<IMediaFile> {
		return this.httpService.authPut<IMediaFile>(
			`${this.endpoints.image}/${key}?id=${id}`,
			metadata,
		);
	}

	public uploadImage(key: string, image: File): Observable<IMediaFile> {
		const formData: FormData = new FormData();
		formData.append('file', image, image.name);

		return this.httpService.authPost<IMediaFile>(
			`${this.endpoints.image}/${key}`,
			formData,
		);
	}

	public deleteImage(key: string, id: string): Observable<void> {
		return this.httpService.authDelete<void>(
			`${this.endpoints.image}/${key}?id=${id}`,
		);
	}

	public getProfileImages(): Observable<IMediaFile[]> {
		return this.httpService.authGet(`${this.endpoints.image}/profilephoto`);
	}

	public getVideos(): Observable<IMediaFile[]> {
		return this.httpService.authGet<IMediaFile[]>(this.endpoints.video);
	}

	public updateVideoMetadata(
		key: string,
		id: string,
		metadata: Record<string, any>,
	): Observable<IMediaFile> {
		return this.httpService.authPut<IMediaFile>(
			`${this.endpoints.video}/${key}?id=${id}`,
			metadata,
		);
	}

	public uploadVideo(key: string, video: File): Observable<IMediaFile> {
		const formData: FormData = new FormData();
		formData.append('file', video, video.name);

		return this.httpService.authPost<IMediaFile>(
			`${this.endpoints.video}/${key}`,
			formData,
		);
	}

	public deleteVideo(key: string, id: string): Observable<void> {
		return this.httpService.authDelete<void>(
			`${this.endpoints.video}/${key}?id=${id}`,
		);
	}
}
