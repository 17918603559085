<div
	class="more-less-text"
	[ngClass]="{
		'more-less-text--show-full': shouldShowFullText
	}"
>
	<div
		class="more-less-text__fade-out"
		*ngIf="!shouldShowFullText && shouldCollapse"
		[style.height.px]="elementLineHeight * fadeOutLinesOffset"
	></div>

	<div #moreLessTextContentContainer>
		<ng-content></ng-content>
	</div>
</div>

<ccap-more-less
	*ngIf="shouldCollapse"
	[showMore]="!shouldShowFullText"
	(click)="toggleShouldShowFullText()"
>
</ccap-more-less>
