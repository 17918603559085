import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuPairPage, AuPairPageId } from '@ccap/interfaces';

export const getPages = createFeatureSelector<AuPairPage[]>('pages');

const getPageIsEnabled = (pages: AuPairPage[], pageId: AuPairPageId) =>
	pages.find(({ id }) => id === pageId)?.enabled || false;

export const getPageFrontPageIsEnabled = createSelector(getPages, pages =>
	getPageIsEnabled(pages, AuPairPageId.FrontPage),
);
