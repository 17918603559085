import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerTileListInterestsComponent } from './answer-tile-list-interests.component';

@NgModule({
	imports: [CommonModule, SvgModule, PipesModule],
	declarations: [AnswerTileListInterestsComponent],
	exports: [AnswerTileListInterestsComponent],
})
export class AnswerTileListInterestsModule {}
