import { Action, createReducer, on } from '@ngrx/store';

import { IExtensionStatus } from '../../interfaces';
import { updateStatus } from './status.actions';

export type ExtensionStatusState = IExtensionStatus;

const reducer = createReducer<ExtensionStatusState>(
	null,
	on(updateStatus, (_, { status }) => status),
);

export function statusReducer(
	state: ExtensionStatusState,
	action: Action,
): ExtensionStatusState {
	return reducer(state, action);
}
