import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlagComponent } from './flag.component';

@NgModule({
	imports: [CommonModule],
	declarations: [FlagComponent],
	exports: [FlagComponent],
})
export class FlagModule {}
