import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	CreateExtensionCourseDto,
	ExtensionCourseDocument,
	ExtensionCourseDto,
} from '../../interfaces';

export const fetchExtensionCourses = createAction('[Extension] Get Courses');
export const fetchExtensionCoursesFail = createAction(
	'[Extension] Get Courses Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const createExtensionCourse = createAction(
	'[Extension] Create Course',
	props<{
		course: CreateExtensionCourseDto;
		/*
			We need to dispatch a saveAnswersLocal action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the course without any ids.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
	}>(),
);

export const createExtensionCourseFail = createAction(
	'[Extension] Create Course Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setExtensionCourse = createAction(
	'[Extension] Set Course',
	props<{ courseId: string; course: ExtensionCourseDto }>(),
);

export const setExtensionCourseFail = createAction(
	'[Extension] Set Course Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteExtensionCourse = createAction(
	'[Extension] Delete Course',
	props<{ courseId: string }>(),
);

export const deleteExtensionCourseFail = createAction(
	'[Extension] Delete Course Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateExtensionCourses = createAction(
	'[Extension] Update Courses',
	props<{ courses: ExtensionCourseDto[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchExtensionCourse = createAction(
	'[Extension] Patch Course',
	props<{ course: ExtensionCourseDto }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addExtensionCourse = createAction(
	'[Extension] Add Course',
	props<{ course: ExtensionCourseDto }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeExtensionCourse = createAction(
	'[Extension] Remove Course',
	props<{ courseId: string }>(),
);

// Course Document

/**
 * Patches values in state **and updates on server**.
 */
export const uploadExtensionCourseDocument = createAction(
	'[Extension] Upload Course Document',
	props<{
		/*
			We need to dispatch a saveCourseFileSuccess action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the document without any id.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
		courseId: string;
		document: File;
	}>(),
);

export const uploadExtensionCourseDocumentFail = createAction(
	'[Extension] Upload Course Document Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteExtensionCourseDocument = createAction(
	'[Extension] Delete Course Document',
	props<{ courseId: string; documentId: string }>(),
);

export const deleteExtensionCourseDocumentFail = createAction(
	'[Extension] Delete Course Document Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addExtensionCourseDocument = createAction(
	'[Extension] Add Course Document',
	props<{ courseId: string; document: ExtensionCourseDocument }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeExtensionCourseDocument = createAction(
	'[Extension] Remove Course Document',
	props<{ courseId: string; documentId: string }>(),
);
