import {
	createFeatureSelector,
	createSelector,
	MemoizedSelector,
} from '@ngrx/store';

import { IAppState } from '../../reducers';
import { SubSectionActions, SubSectionActionTypes } from './actions';
import { SubSectionModel } from './model';

interface State extends SubSectionModel {}

export const initialSubSectionState: State = {
	active: '',
	activeQuestions: [],
};

export function subSectionReducer(
	state: State = initialSubSectionState,
	action: SubSectionActions,
): State {
	switch (action.type) {
		case SubSectionActionTypes.SetSubSection: {
			const newState: State = {
				...state,
				active: action.payload.active,
				activeQuestions: state.activeQuestions,
			};

			return {
				...state,
				...newState,
			};
		}

		case SubSectionActionTypes.SetActiveQuestions: {
			return {
				...state,
				activeQuestions: action.payload,
			};
		}

		default: {
			return state;
		}
	}
}

export const selectSubSection = createFeatureSelector<SubSectionModel>(
	'subSection',
);

export const selectActiveSubSectionID: MemoizedSelector<
	IAppState,
	string
> = createSelector(
	selectSubSection,
	(selectedState: SubSectionModel) => selectedState.active,
);
