import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProgressBarColor, ProgressBarSize } from './progress-bar.models';

const baseClass = 'progress-bar';

@Component({
	selector: 'ccap-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
	@Input()
	public set color(color: ProgressBarColor | null) {
		this.bufferColorClass = color ? `${baseClass}__buffer--${color}` : null;
		this.textColorClass =
			color === 'yellow' || color === 'green'
				? `${baseClass}__text--dark`
				: null;
	}

	@Input()
	public set size(size: ProgressBarSize | null) {
		this.containerSizeClass = size ? `${baseClass}--${size}` : null;
		this.textSizeClass = size ? `${baseClass}__text--${size}` : null;
	}

	@Input()
	public percentage?: number;

	public bufferColorClass?: string;
	public containerSizeClass?: string;
	public textSizeClass?: string;
	public textColorClass?: string;

	public getTextClass() {
		if (this.textColorClass) {
			return `${this.textColorClass} ${this.textSizeClass}`;
		}
		return this.textSizeClass;
	}
}
