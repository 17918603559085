export enum StorageKey {
	userType = 'user_type',
	userId = 'user_id',
}

export enum UrlKey {
	userType = 'userType',
	userId = 'id',
}

export class UserIdentification {
	private urlParams = {
		userType: '',
		id: '',
	};

	public getWindow(): Window {
		return window;
	}

	public userType(): string {
		return localStorage.getItem(StorageKey.userType) || '';
	}

	public userId(): string {
		return localStorage.getItem(StorageKey.userId) || '';
	}

	public clearUserId(): void {
		localStorage.removeItem(StorageKey.userId);
	}

	public getClientID(): string {
		const userType = this.userType().toLowerCase();
		const roles = ['aupair', 'hf', 'staff', 'lcc'];
		const key = roles.includes(userType) ? userType : 'aupair';
		return this.getWindow().config[`AUTH0_CLIENT_ID_${key.toUpperCase()}`];
	}

	public initLocalStorage(): void {
		this.urlParams.userType = this.getUrlParameter(UrlKey.userType, true);
		this.urlParams.id = this.getUrlParameter(UrlKey.userId);
		this.setSession();
	}

	private setSession(): void {
		if (this.urlParams.userType) {
			localStorage.setItem(StorageKey.userType, this.urlParams.userType);
		}

		if (this.urlParams.id) {
			localStorage.setItem(StorageKey.userId, this.urlParams.id);
		}
	}

	private getUrlParameter(name: string, parameterValueToLower = false) {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]').toLowerCase();
		let search = this.getWindow().location.search;

		if (parameterValueToLower) {
			search = search.toLowerCase();
		}

		return this.getCleanParam(name, search);
	}

	private getCleanParam(name: string, search: string): string | null {
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		const results: RegExpExecArray | null = regex.exec(search);
		return results === null
			? ''
			: decodeURIComponent(results[1].replace(/\+/g, ' '));
	}
}
