<ng-template>
	<div class="tab">
		<div>
			<ng-content></ng-content>
		</div>

		<ccap-show-less
			*ngIf="canClose"
			class="tab__show-less"
			(click)="showLess.emit()"
		></ccap-show-less>
	</div>
</ng-template>
