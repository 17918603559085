/* istanbul ignore next */
export function initTagManager(tagManagerId: string) {
	const script = document.createElement('script');
	script.innerHTML = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer', '${tagManagerId}');
	`;

	document.head.appendChild(script);

	const iframe = document.createElement('iframe');
	iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerId}`;
	iframe.height = '0';
	iframe.width = '0';
	iframe.setAttribute('style', 'display:none;visibility:hidden');

	const noscript = document.createElement('noscript');
	noscript.appendChild(iframe);

	document.body.appendChild(noscript);
}
