import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IMediaFile } from '@ccap/interfaces';

export const fetchVideos = createAction('[Video] Get All');
export const fetchVideosFail = createAction(
	'[Video] Get All Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const uploadVideo = createAction(
	'[Video] Upload',
	props<{ key: string; video: File }>(),
);

export const uploadVideoFail = createAction(
	'[Video] Upload Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteVideo = createAction(
	'[Video] Delete',
	props<{ key: string; videoId: string }>(),
);

export const deleteVideoFail = createAction(
	'[Video] Delete Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches value in state **and on server**.
 */
export const setVideoMetadata = createAction(
	'[Video] Set Metadata',
	props<{ key: string; videoId: string; metadata: Record<string, any> }>(),
);

export const setVideoMetadataFail = createAction(
	'[Video] Set Metadata Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateVideos = createAction(
	'[Video] Update',
	props<{ videos: IMediaFile[] }>(),
);

/**
 * Updates the whole state
 */
export const rollbackVideos = createAction(
	'[Video] Rollback',
	props<{ videos: IMediaFile[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchVideo = createAction(
	'[Video] Patch',
	props<{ video: IMediaFile }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchVideoMetadata = createAction(
	'[Video] Patch Metadata',
	props<{ videoId: string; metadata: Record<string, any> }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addVideo = createAction(
	'[Video] Add',
	props<{ video: IMediaFile }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeVideo = createAction(
	'[Video] Remove',
	props<{ videoId: string }>(),
);
