import { NgModule } from '@angular/core';

import { ValidationModule } from '../validation/validation.module';
import { CustomControlValidationComponent } from './custom-control-validation.component';

@NgModule({
	imports: [ValidationModule],
	declarations: [CustomControlValidationComponent],
	exports: [CustomControlValidationComponent],
})
export class CustomControlValidationModule {}
