import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ComponentChanges } from '@ccap/shared/utils';

export interface IIdentityAnswerKeys {
	firstName: string;
	nickname: string;
	lastName: string;
	age: number;
	ageLabel?: string;
	addressCountry: string;
	nationality: string;
	gender: string;
	demonym: string;
	flag: string;
}

@Component({
	selector: 'ccap-identity',
	templateUrl: 'identity.component.html',
	styleUrls: ['identity.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityComponent implements OnChanges {
	@Input()
	public identityAnswers?: IIdentityAnswerKeys;

	public genderDemonymAddressCountryLine?: string;
	public ageLine?: string;

	public loading$ = new BehaviorSubject(true);

	public ngOnChanges(changes: ComponentChanges<IdentityComponent>) {
		if (changes.identityAnswers.currentValue) {
			this.loading$.next(false);

			this.putTogetherGenderDemonymAddressCountryLine();
			this.putTogetherAgeLine();
		}
	}

	private putTogetherGenderDemonymAddressCountryLine(): void {
		const { addressCountry, gender, demonym } = this.identityAnswers;

		const addressCountrySentence = addressCountry
			? `lives in ${addressCountry}`
			: null;

		this.genderDemonymAddressCountryLine = [
			gender,
			demonym,
			addressCountrySentence,
		]
			.filter(item => Boolean(item))
			.join(', ');
	}

	private putTogetherAgeLine(): void {
		const { age, ageLabel } = this.identityAnswers;

		const label = ageLabel ? `${ageLabel}:` : null;

		const ageText = age ? `${age} years old` : null;

		this.ageLine = [label, ageText].filter(item => Boolean(item)).join(' ');
	}
}
