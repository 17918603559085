import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NeoHeaderWrapperComponent } from './header-wrapper.component';

@NgModule({
	declarations: [NeoHeaderWrapperComponent],
	exports: [NeoHeaderWrapperComponent],
	imports: [CommonModule],
})
export class NeoHeaderWrapperModule {}
