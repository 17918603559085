import { getUserPropertyFromJwt } from '@ccap/shared/utils';

export enum AuthRoles {
	HostFamily = 'host-family',
	AuPair = 'au-pair',
	Staff = 'ccap-staff',
	Lcc = 'lcc',
}

export const userHasRoles = (rolesToCheck: string[]): boolean => {
	const roles: AuthRoles[] = getUserPropertyFromJwt(
		'http://culturalcare.com/claims/roles',
	);

	return roles?.some(role => rolesToCheck.includes(role)) ?? false;
};
