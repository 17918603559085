import { createSelector } from '@ngrx/store';

import { getTravel } from '../../store/travel.selectors';

export const getVisa = createSelector(getTravel, ({ visa }) => visa);

export const getSevis = createSelector(getVisa, visa =>
	visa && visa.sevisNumber ? visa.sevisNumber : '',
);

export const getEmbassyAppointment = createSelector(
	getVisa,
	visa => visa?.embassyAppointment ?? null,
);
