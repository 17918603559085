import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Address, IQuestionWithValidation } from '@ccap/interfaces';

@Component({
	selector: 'app-answer-address',
	templateUrl: './answer-address.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerAddressComponent {
	public label = '';
	public answer = '';

	@Input()
	public set question(question: IQuestionWithValidation) {
		if (!question?.answer) {
			return;
		}

		this.label = question.group.label;

		const {
			street,
			street2,
			city,
			zip,
			state,
			countryName,
		} = question.answer as Address;

		let answer = `${street}`;
		if (street2) {
			answer += `, ${street2}`;
		}
		answer += `, ${zip}, ${city}, ${state}, ${countryName}`;

		this.answer = answer;
	}
}
