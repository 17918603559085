import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { AuPairStatusDto } from '@ccap/au-pair/data-access/models';

export const fetchStatus = createAction('[Status] Get');
export const fetchStatusFail = createAction(
	'[Status] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);
export const updateStatus = createAction(
	'[Status] Update',
	props<{ status: AuPairStatusDto }>(),
);
