<div class="language-selector__toggle ef-row -x-center u-mb-l">
	<button
		efButton
		[primary]="true"
		[square]="true"
		type="button"
		(click)="onLanguageSelectorToggleClicked()"
		class="ef-col -s-shrink"
	>
		Select Language
	</button>
</div>

<div class="language-selector__languages" *ngIf="isLanguageSelectorActive">
	<ng-container *ngFor="let locale of locales">
		<div
			class="language-selector__language-item u-m-pl-m u-m-pr-m u-mb-l u-text-center"
		>
			<button
				efButton
				[primary]="true"
				[square]="true"
				type="button"
				(click)="onLanguageButtonClicked(locale)"
			>
				{{ locale.localName }}
			</button>
		</div>
	</ng-container>
</div>
