import {
	ChangeDetectorRef,
	Directive,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	SkipSelf,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let uniqueId = 0;

@Directive({
	selector: '[ccapCustomControl]',
})
export class CustomControlDirective implements OnInit, OnDestroy {
	@Input()
	public exactName?: boolean;

	@Input()
	public inputName?: string;

	@HostBinding('attr.name')
	public name?: string;

	@HostBinding('attr.id')
	public id?: string;

	@HostBinding('attr.disabled')
	public disabled?: boolean;

	@HostBinding('attr.aria-invalid')
	@HostBinding('class.ccap-input--invalid')
	public hasErrorState?: boolean;

	private uniqueId = uniqueId++;

	private destroy$ = new Subject<void>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		@SkipSelf() private ngControl: NgControl,
	) {}

	public ngOnInit(): void {
		this.setState();

		this.ngControl.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.setState();
			});
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	@HostListener('blur')
	public onBlur(): void {
		this.ngControl.control.markAllAsTouched();
		this.setHasErrorState();

		this.changeDetectorRef.detectChanges();
	}

	private setState(): void {
		const getUnqiueName = (name?: string | number): string => {
			if (!name) {
				return null;
			}
			return this.exactName ? `${name}` : `${name}-${this.uniqueId}`;
		};

		this.name =
			getUnqiueName(this.inputName) ?? getUnqiueName(this.ngControl.name);

		this.id = getUnqiueName(this.ngControl.name);

		this.disabled = (this.ngControl && this.ngControl.disabled) || null;
		this.setHasErrorState();

		this.changeDetectorRef.detectChanges();
	}

	private setHasErrorState(): void {
		this.hasErrorState = this.ngControl.invalid && this.ngControl.touched;
	}
}
