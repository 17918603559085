import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoursesState } from './courses.reducer';

export const getCourses = createFeatureSelector<CoursesState>('courses');

export const getCoursesAvailability = createSelector(
	getCourses,
	({ availability }) => availability,
);
