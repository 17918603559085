export const defaultLockOptions: Auth0LockConstructorOptions = {
	autofocus: true,
	avatar: null,
	socialButtonStyle: 'big',
	container: 'root',
	auth: {
		redirectUrl: window.location.origin + '/auth-callback',
		responseType: 'token id_token',
		params: {
			scope: 'openid email',
		},
	},
	theme: {
		primaryColor: '#007aff',
	},
	languageDictionary: {
		title: '',
		success: {
			forgotPassword: `
				We have just sent you an email to reset your password
				</br></br>
				Didn't receive an email? The email may be delayed several minutes, and remember to check your spam or junk folder or filter.`,
		},
	},
	mustAcceptTerms: true,
	rememberLastLogin: false,
};
