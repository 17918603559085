import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ReferencesState } from './references.reducer';

export const getReferences =
	createFeatureSelector<ReferencesState>('references');

export const getPersonalReferences = createSelector(
	getReferences,
	({ personal }) => personal,
);

export const getPersonalReferenceById = (id: string) =>
	createSelector(getPersonalReferences, references =>
		references
			? references.find(({ referenceId }) => referenceId === id)
			: null,
	);

export const getChildcareReferences = createSelector(
	getReferences,
	({ childcare }) => childcare,
);

export const getChildcareReferenceById = (id: string) =>
	createSelector(getChildcareReferences, references =>
		references
			? references.find(({ referenceId }) => referenceId === id)
			: null,
	);
