import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fill', // TODO remove/replace
})
export class FillPipe implements PipeTransform {
	public transform(value: number) {
		return new Array(value).fill(1);
	}
}
