<button
	(click)="clicked($event)"
	class="more-less"
	[ngClass]="{
		'show-more': showMore,
		'show-less': !showMore,
		'more-less--inherit-color': inheritColor
	}"
>
	<ng-container *ngIf="showMore">
		Show more
	</ng-container>

	<ng-container *ngIf="!showMore">
		Show less
	</ng-container>
</button>
