import { Action, createReducer, on } from '@ngrx/store';

import { IMediaFile } from '@ccap/interfaces';
import { addVideo } from './video.actions';
import {
	patchVideo,
	removeVideo,
	rollbackVideos,
	updateVideos,
} from './video.actions';

export type VideoState = IMediaFile[];

const reducer = createReducer<VideoState>(
	null,
	on(updateVideos, rollbackVideos, (_, { videos }) => videos),
	on(addVideo, (state, { video }) => [...state, video]),
	on(patchVideo, (state, { video }) =>
		state ? state.map(vid => (vid.id === video.id ? video : vid)) : state,
	),
	on(removeVideo, (state, { videoId }) =>
		state ? state.filter(vid => vid.id !== videoId) : state,
	),
);

export function videoReducer(state: VideoState, action: Action): VideoState {
	return reducer(state, action);
}
