import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'ccap-busy-status-indicator',
	templateUrl: './busy-status-indicator.component.html',
	styleUrls: ['./busy-status-indicator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyStatusIndicatorComponent {
	@Input() public status?: string;
	@Input() public minimal = false;

	public get isInitialised(): boolean {
		return !!this.status;
	}

	public get statusName(): string {
		return this.status.toLowerCase() === 'available'
			? 'available'
			: 'unavailable';
	}

	public get type(): string {
		return this.minimal ? 'minimal' : 'full';
	}
}
