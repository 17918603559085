import { NgModule } from '@angular/core';

import {
	ModalMainComponent,
	ModalModule as ModalModuleBase,
	ModalRootComponent,
} from '@ccap/au-pair/ui';
import { ConnectInAModalComponent } from './connect-in-a-modal/connect-in-a-modal.component';
import { DisconnectInAModalComponent } from './disconnect-in-a-modal/disconnect-in-a-modal.component';

@NgModule({
	imports: [ModalModuleBase],
	entryComponents: [
		ModalRootComponent,
		ModalMainComponent,
		ConnectInAModalComponent,
		DisconnectInAModalComponent,
	],
})
export class ModalModule {}
