import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	CreateChildcareReferencePayload,
	CreatePersonalReferencePayload,
} from '@ccap/au-pair/data-access/models';
import { IChildCareReferenceBody, IReferenceBody } from '@ccap/interfaces';

export const fetchPersonalReferences = createAction(
	'[References] Get Personal',
);
export const fetchPersonalReferencesFail = createAction(
	'[References] Get Personal Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const createPersonalReference = createAction(
	'[References] Create Personal',
	props<{
		reference: CreatePersonalReferencePayload;
		/*
			We need to dispatch a saveAnswersLocal action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the reference without any ids.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
	}>(),
);

export const createPersonalReferenceFail = createAction(
	'[References] Create Personal Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setPersonalReference = createAction(
	'[References] Set Personal',
	props<{ referenceId: string; reference: IReferenceBody }>(),
);

export const setPersonalReferenceFail = createAction(
	'[References] Set Personal Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deletePersonalReference = createAction(
	'[References] Delete Personal',
	props<{ referenceId: string }>(),
);

export const deletePersonalReferenceFail = createAction(
	'[References] Delete Personal Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePersonalReferences = createAction(
	'[References] Update Personal',
	props<{ references: IReferenceBody[] }>(),
);

/**
 * Updates the whole state
 */
export const rollbackPersonalReferences = createAction(
	'[References] Rollback Personal',
	props<{ references: IReferenceBody[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchPersonalReferences = createAction(
	'[References] Patch Personal',
	props<{ reference: IReferenceBody }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addPersonalReference = createAction(
	'[References] Add Personal',
	props<{ reference: CreatePersonalReferencePayload }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removePersonalReference = createAction(
	'[References] Remove Personal',
	props<{ referenceId: string }>(),
);

// Childcare

export const fetchChildcareReferences = createAction(
	'[References] Get Childcare',
);
export const fetchChildcareReferencesFail = createAction(
	'[References] Get Childcare Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const createChildcareReference = createAction(
	'[References] Create Childcare',
	props<{
		reference: CreateChildcareReferencePayload;
		/*
			We need to dispatch a saveAnswersLocal action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the reference without any ids.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
	}>(),
);

export const createChildcareReferenceFail = createAction(
	'[References] Create Childcare Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setChildcareReference = createAction(
	'[References] Set Childcare',
	props<{ referenceId: string; reference: IChildCareReferenceBody }>(),
);

export const setChildcareReferenceFail = createAction(
	'[References] Set Childcare Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteChildcareReference = createAction(
	'[References] Delete Childcare',
	props<{ referenceId: string }>(),
);

export const deleteChildcareReferenceFail = createAction(
	'[References] Delete Childcare Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateChildcareReferences = createAction(
	'[References] Update Childcare',
	props<{ references: IChildCareReferenceBody[] }>(),
);

/**
 * Updates the whole state
 */
export const rollbackChildcareReferences = createAction(
	'[References] Rollback Childcare',
	props<{ references: IChildCareReferenceBody[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchChildcareReferences = createAction(
	'[References] Patch Childcare',
	props<{ reference: IChildCareReferenceBody }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addChildcareReference = createAction(
	'[References] Add Childcare',
	props<{ reference: CreateChildcareReferencePayload }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeChildcareReference = createAction(
	'[References] Remove Childcare',
	props<{ referenceId: string }>(),
);
