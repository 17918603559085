import { Component } from '@angular/core';

import { IReferenceBody } from '@ccap/interfaces';
import { AnswerAbstractListComponent } from '../answer-abstract-list/answer-abstract-list.component';

@Component({
	selector: 'app-answer-work-experience-list',
	templateUrl: 'answer-work-experience-list.component.html',
	styleUrls: ['answer-work-experience-list.component.scss'],
})
export class AnswerWorkExperienceListComponent extends AnswerAbstractListComponent {
	public get answers(): IReferenceBody[] {
		return super.answer() as IReferenceBody[];
	}
}
