export interface Section {
	title: string;
	description: {
		image: string;
		text?: string;
		footerText?: string;
	};
	color: string;
}

export type Sections = Record<string, Section>;

export const sections: Sections = {
	aboutMe: {
		title: 'About me',
		description: {
			image: 'about_me.svg',
			text: `A warm-hearted welcome from all of us here at Cultural Care Au Pair! We and our host families are excited to learn all about you. Let’s get started.`,
		},
		color: '#fe9fa6',
	},
	training: {
		title: 'Training',
		description: {
			text: `The U.S. Department of State requires that all au pairs receive training.  We help you meet that requirement with
				a unique blend of workshops, webinar, online modules, our own au pair training school, and MyAuPairCourses.`,
			image: '',
		},
		color: '#00314d',
	},
	preQualifications: {
		title: 'preQualifications',
		description: {
			text: '',
			image: '',
		},
		color: '',
	},
	myAuPairPreferences: {
		title: 'Life with your host family',
		description: {
			image: 'my-au-pair-year.svg',
			text: `Tell us more about life as you like it to be with your host family`,
		},
		color: '#ffd66d',
	},
	references: {
		title: 'Experience and references',
		description: {
			image: 'childcare_skills.svg',
			text: `Host families appreciate learning more about you, especially your childcare experience.`,
		},
		color: '#5fe8ed',
	},
	lifeExperiences: {
		title: 'Life experiences',
		description: {
			image: 'life_experiences.svg',
			text: `Your life experiences are what makes you… well, unique! We want to hear all about them and so do host families. It’s best to be open and honest – let your real self shine through!`,
		},
		color: '#ea78f0',
	},
	skillsAndEducation: {
		title: 'Skills and education',
		description: {
			image: 'skills_education.svg',
			text: 'Please share your skills and educational background with your future host family.',
		},
		color: '#699bf0',
	},
	personalityProfile: {
		title: 'Interests and personality',
		description: {
			image: 'personality.svg',
			text: `Host families want to know all about you and what you like to do. Take the personality quiz and tell us about your hobbies so families can see what you have in common.`,
		},
		color: '#bc78f0',
	},
	healthInformation: {
		title: 'Health',
		description: {
			image: 'health.svg',
			text: `You are going to be living away from home for a while! Getting an assessment of your health is important so we can be prepared to help you address any medical needs in the United States.`,
		},
		color: '#69f0c7',
	},
	requiredDocuments: {
		title: 'Pre-departure documents and agreements',
		description: {
			image: '',
			text: 'You must supply legible copies of the documents shown below.',
		},
		color: '#00314d',
	},
	additionalDocuments: {
		title: 'Additional documents',
		description: {
			image: '',
			text: 'In some instances, we may require you to provide some of the documents below.',
		},
		color: '#00314d',
	},
	auPairDocuments: {
		title: 'Program support documents',
		description: {
			image: '',
			text: 'These documents will help you with important tasks like establishing a social security number or bank account once in the United States.',
		},
		color: '#00314d',
	},
	extension: {
		title: 'Extend your year',
		description: {
			image: '',
			text: `Stay in the U.S. as an au pair for up to one more year! Extend with your current family
			or experience life with a new host family for an additional six, nine, or 12 months.`,
		},
		color: '#00314d',
	},
	courses: {
		title: 'U.S. education courses',
		description: {
			image: '',
			text: `
				During your year, you must complete 6 academic credits (or 72 hours if it is a non-credit course) at an accredited post-secondary institution. You are free to select which courses you would like to take. This is an opportunity to study something you can use in your future career or that you have always been interested in!

				Your host family and your LCC can help you select and sign up for courses. Your host family will contribute up to 500 USD towards your classes but any additional costs are your responsibility. Most au pairs do pay an amount, but the total cost varies based on the type of school or course.
			`,
		},
		color: '#00314d',
	},
	matches: {
		title: 'Host family agreements',
		description: {
			image: '',
			text: `Congratulations on your match! Before departing for your host family home, please read and accept the following agreements.`,
		},
		color: '#00314d',
	},
};
