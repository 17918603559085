import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
	PersonalityTestAnswersDto,
	PersonalityTestResultsDto,
	PersonalityTestStatusDto,
} from '@ccap/au-pair/data-access/models';
import {
	patchPersonalityTestAnswers,
	rollbackPersonalityTestAnswers,
	updatePersonalityTestAnswers,
	updatePersonalityTestResults,
	updatePersonalityTestStatus,
} from './personality-test.actions';

export interface PersonalityTestState {
	answers: PersonalityTestAnswersDto | undefined;
	results: PersonalityTestResultsDto | undefined;
	status: PersonalityTestStatusDto | undefined;
}

const reducer = combineReducers<PersonalityTestState>({
	answers: createReducer<PersonalityTestAnswersDto>(
		undefined,
		on(
			updatePersonalityTestAnswers,
			rollbackPersonalityTestAnswers,
			(state, { answers }) => answers,
		),
		on(patchPersonalityTestAnswers, (state, { answers }) => ({
			...state,
			...answers,
		})),
	),
	results: createReducer<PersonalityTestResultsDto>(
		undefined,
		on(updatePersonalityTestResults, (state, { results }) => results),
	),
	status: createReducer<PersonalityTestStatusDto>(
		undefined,
		on(updatePersonalityTestStatus, (state, { status }) => status),
	),
});

export function personalityTestReducer(
	state: PersonalityTestState,
	action: Action,
): PersonalityTestState {
	return reducer(state, action);
}
