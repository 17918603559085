import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	ModuleWithProviders,
	NgModule,
	Optional,
	SkipSelf,
} from '@angular/core';

import { AuthConfig } from './auth-config-interface';
import { AuthHttpInterceptor } from './auth-http-interceptor';
import { AUTH_CONFIG } from './auth-injection-token';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { Auth0LockFactory } from './auth0/auth0-lock-factory.service';
import { Auth0Service } from './auth0/auth0.service';
import { AuthCallbackComponent } from './components/auth-callback.component';
import { AuthComponent } from './components/auth.component';
import { CookieService } from './cookie.service';
import { IsLoggedOutGuard } from './is-logged-out.guard';

@NgModule({
	imports: [CommonModule, AuthRoutingModule],
	declarations: [AuthComponent, AuthCallbackComponent],
	providers: [
		Auth0LockFactory,
		Auth0Service,
		AuthService,
		AuthGuard,
		IsLoggedOutGuard,
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true,
		},
	],
})
export class AuthModule {
	constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
		if (parentModule) {
			throw new Error(
				`AuthModule is already loaded. It should only be imported in your application's main module.`,
			);
		}
	}

	// If your package exposes a service, include this
	// and consume it like: SampleModule.forRoot()
	public static forRoot(
		optionsFactory: (widnow?: Window) => AuthConfig,
	): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				{
					provide: AUTH_CONFIG,
					useFactory: optionsFactory,
					deps: [Window],
				},
				Auth0LockFactory,
				Auth0Service,
				AuthService,
				AuthGuard,
				CookieService,
				IsLoggedOutGuard,
			],
		};
	}
}
