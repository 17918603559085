import { AbstractControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';

import { IAnswer, IQuestionWithValidation } from '@ccap/interfaces';
import { AbstractModalBase } from './abstract-modal-base';
import { ControlToucherService } from './control-toucher.service';
import { ModalComponentFactoryService } from './modal-component-factory.service';

/**
 * The AbstractModal abstract class contains implementation details for its derived members.
 * It may not be instantiated directly.
 * It also defines methods for closing modals.
 * https://www.typescriptlang.org/docs/handbook/classes.html
 *
 * Any derived component is passed from the modal.service wrapper
 * to the modal-main.component (where the actual content of the modal resides).
 *
 */

export abstract class AbstractModal extends AbstractModalBase {
	public questions?: IQuestionWithValidation[];
	public formSteps?: AbstractControl[];
	public editing?: boolean;
	protected abstract controlToucherService?: ControlToucherService;

	constructor(
		protected modalComponentFactoryService: ModalComponentFactoryService,
	) {
		super(modalComponentFactoryService);
	}

	public completeForm(): void {
		if (this.isAllQuestionsValid()) {
			if (this.hasChanges()) {
				this.save();
			}

			this.removeModal();
		}
	}

	public isAllQuestionsValid() {
		return this.formSteps.every((formStep: AbstractControl) => formStep.valid);
	}

	protected abstract save(): void;

	protected reduceAnswers(): IQuestionWithValidation[] {
		return this.formSteps.map((formStep: AbstractControl) => {
			const id: string = Object.keys(formStep.value)[0];
			const question: IQuestionWithValidation = this.questions.find(
				(que: IQuestionWithValidation) => que.id === id,
			);

			return { ...question, answer: formStep.value[id] };
		});
	}

	protected hasChanges(): boolean {
		// To reduce unecessary requests we check if there are any changes in a simple modal
		return this.formSteps.some(({ value }, index: number) => {
			const { id, answer } = this.questions[index];

			return !(isEqual(value, answer) || isEqual(value[id], answer));
		});
	}

	protected listHasChanges(answerIndex: number): boolean {
		// To reduce unecessary requests we check if there are any changes in a list modal
		return this.formSteps.some(({ value }, index: number) => {
			const question = this.questions[index];

			if (!question.answer || !question.answer[answerIndex]) {
				return true;
			}

			const answer = question.answer[answerIndex] as IAnswer;

			const editablePropsOfAnswer = this.getCorrespondingProps(answer, value);

			return !isEqual(value, editablePropsOfAnswer);
		});
	}

	private getCorrespondingProps(
		previous: IAnswer,
		target: IAnswer,
	): Record<string, any> {
		const keys = Object.keys(target);

		const props = keys.reduce((acc, key) => {
			const value =
				previous[key] && typeof previous[key] === 'object'
					? this.getCorrespondingProps(previous[key], target[key])
					: previous[key];

			return { ...acc, [key]: value };
		}, {} as Record<string, any>);

		return props;
	}
}
