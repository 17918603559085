import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { marked, Renderer } from 'marked';

import { ContentLocaleDto } from '@ccap/au-pair/data-access/models';

const createRenderer = () => {
	const renderer = new Renderer();

	renderer.link = function (href, title, text) {
		const isHash = href.startsWith('#');
		let updatedHref = href;

		if (updatedHref === '/legal') {
			updatedHref = `${window.location.origin}${updatedHref}`;
		}

		if (isHash) {
			updatedHref = `${window.location.origin}${window.location.pathname}${updatedHref}`;
		}

		const link = Renderer.prototype.link.call(this, updatedHref, title, text);

		return isHash ? link : link.replace('<a', `<a target="_blank" `);
	};

	return renderer;
};

@Component({
	selector: 'ccap-translated-content',
	templateUrl: './translated-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatedContentComponent implements OnChanges {
	public markedOptions?: marked.MarkedOptions;

	@Input()
	public title?: string;

	@Input()
	public content?: string;

	@Input()
	public contentLocales?: ContentLocaleDto[];

	@Input()
	public currentLocale?: ContentLocaleDto;

	@Output()
	public localeSelect = new EventEmitter<ContentLocaleDto>();

	public onLocaleSelected(locale: ContentLocaleDto): void {
		this.localeSelect.emit(locale);
	}

	public ngOnChanges() {
		this.markedOptions = {
			baseUrl: this.currentLocale ? this.currentLocale.localUrl : null,
			renderer: createRenderer(),
		};

		if (window && window.scrollTo) {
			window.scrollTo(0, 0);
		}
	}
}
