<div
	class="notification__item"
	[ccapE2EClass]="'notification-' + item.type"
	*ngFor="let item of notifications"
	[ngClass]="{
		'notification__item--alert': item.type === 'alert',
		'notification__item--error': item.type === 'error',
		'notification__item--success': item.type === 'success'
	}"
	(mouseenter)="onEnter()"
	(mouseleave)="onLeave()"
>
	<div class="notification__item-wrap">
		<div class="notification__item-message">
			<p class="notification__item-title" *ngIf="item.title">
				{{ item.title }}
			</p>
			<p class="notification__item-content" [innerHTML]="item.content"></p>
		</div>

		<aside class="notification__item-dismiss">
			<ccap-svg
				[ccapE2EClass]="'notification-dismiss'"
				[title]="'close'"
				[classList]="'close close--rounded close--small close--outline'"
				(click)="removeOnClick(item)"
			>
			</ccap-svg>
		</aside>
	</div>
</div>
