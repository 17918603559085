import { Action, combineReducers } from '@ngrx/store';

import {
	answersReducer,
	ExtensionAnswersState,
} from './answers/answers.reducer';
import { coursesReducer, CoursesState } from './course/course.reducer';
import {
	ExtensionPaymentState,
	paymentReducer,
} from './payment/payment.reducer';
import { ExtensionStatusState, statusReducer } from './status/status.reducer';

export const extensionStateKey = 'extension';

export interface ExtensionState {
	payment: ExtensionPaymentState;
	status: ExtensionStatusState;
	answers: ExtensionAnswersState;
	courses: CoursesState;
}

const reducer = combineReducers<ExtensionState>({
	payment: paymentReducer,
	status: statusReducer,
	answers: answersReducer,
	courses: coursesReducer,
});

export function extensionReducer(
	state: ExtensionState,
	action: Action,
): ExtensionState {
	return reducer(state, action);
}
