import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import Glide, {
	Controls,
	Keyboard,
	Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import { BehaviorSubject } from 'rxjs';

import { IMediaFileExtended, IServerResponseMedia } from '@ccap/interfaces';
import { MediaSize, transformImage, transformVideo } from '@ccap/shared/utils';

@Component({
	selector: 'app-carousel',
	templateUrl: 'carousel.component.html',
	styleUrls: ['carousel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewInit {
	@Input() public media?: IServerResponseMedia;
	public mediaFiles?: IMediaFileExtended[];
	public currentIndex$ = new BehaviorSubject(1);

	@ViewChildren('carousel') private carousel?: QueryList<ElementRef>;
	@ViewChildren('videoPlayer') private videoPlayer?: QueryList<ElementRef>;
	private glide?: Glide;

	public ngAfterViewInit() {
		if (this.mediaFiles.length) {
			this.glide = new Glide(this.carousel.first.nativeElement, {
				type: 'carousel',
				perView: 1,
			});

			this.glide.on(['run.after'], () => {
				this.pauseVideoPlayer();
				this.setCurrentIndex(this.glide.index + 1);
			});

			this.glide.mount({ Controls, Keyboard, Swipe });
		}
	}

	public ngOnInit() {
		const mediaServerResponse: IServerResponseMedia = this.media;

		if (mediaServerResponse) {
			this.mediaFiles = [
				...mediaServerResponse.response.video,
				...mediaServerResponse.response.images,
			];
		}
	}

	public transformImage(url: string): string {
		return transformImage(url, MediaSize.Default, MediaSize.Default);
	}

	public transformVideo(url: string): string {
		return transformVideo(url, MediaSize.Default);
	}

	private pauseVideoPlayer(): void {
		this.videoPlayer
			.map((player: ElementRef) => player.nativeElement)
			.filter((element: any) => {
				return !element.paused;
			})
			.forEach((element: any) => {
				element.pause();
			});
	}

	private setCurrentIndex(index: number): void {
		this.currentIndex$.next(index);
	}
}
