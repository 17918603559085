<div class="modal-card">
	<h4 class="ef-h5 u-text-center">Disconnect</h4>

	<p>Reason for your disconnection</p>

	<ng-container [formGroup]="formSteps[0]">
		<ccap-neo-dropdown
			[formControlName]="questions[0]?.id"
			[id]="questions[0]?.id"
			[options]="questions[0]?.options"
		>
		</ccap-neo-dropdown>
	</ng-container>

	<ccap-modal-actions
		[disabledSaveAndContinue]="!isAllQuestionsValid()"
		[saveButtonText]="'Proceed'"
		(cancelAction)="removeModal()"
		(saveAction)="completeForm()"
	></ccap-modal-actions>
</div>
