import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BusyStatusIndicatorModule } from '@ccap/shared/ui/indicators';
import { LinkModule } from '../link/link.module';
import { ProfilePictureModule } from '../profile-picture/profile-picture.module';
import { NavigationHeaderComponent } from './navigation-header.component';

@NgModule({
	imports: [
		CommonModule,
		LinkModule,
		ProfilePictureModule,
		BusyStatusIndicatorModule,
	],
	declarations: [NavigationHeaderComponent],
	exports: [NavigationHeaderComponent],
})
export class NavigationHeaderModule {}
