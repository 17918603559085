import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getFlags } from './store/feature-toggle.actions';
import { selectFeatureFlag } from './store/feature-toggle.reducer';
import { FeatureFlagState, FeatureToggles } from './interfaces';

@Injectable()
export class FeatureToggleSandboxService {
	constructor(private store: Store<FeatureFlagState>) {}

	public fetchFeatureFlags(): void {
		this.store.dispatch(getFlags());
	}

	public isFeatureEnabled(feature: FeatureToggles): Observable<boolean> {
		return this.store.pipe(select(selectFeatureFlag(feature)));
	}
}
