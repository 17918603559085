import {
	AfterContentInit,
	ContentChild,
	Directive,
	HostBinding,
	Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { InputDirective } from '../input/input.directive';

@Directive({
	selector: '[efInputWrapper]',
})
export class InputWrapperDirective implements AfterContentInit {
	@ContentChild(NgControl)
	public ngControl?: NgControl;

	@ContentChild(InputDirective)
	public inputChild?: InputDirective;

	@HostBinding('class.ef-input-w')
	@HostBinding('class.u-mb-m')
	public readonly baseClass = true;

	@HostBinding('class.-textarea')
	public get isTextArea() {
		return this.inputChild?.isTextArea;
	}

	@HostBinding('class.-select')
	public get isSelect() {
		return this.inputChild?.isSelect;
	}

	@HostBinding('class.-is-disabled')
	public get isDisabledSelect() {
		return this.inputChild?.isSelect && this.ngControl?.disabled;
	}

	@HostBinding('class.-is-valid')
	public get isValid() {
		return (
			this.ngControl?.valid && this.ngControl.dirty && !this.ngControl.disabled
		);
	}

	@HostBinding('class.-is-required')
	public get failedRequiredValidation() {
		return (
			this.ngControl?.control?.validator?.(this.ngControl.control)?.required &&
			!this.ngControl.disabled &&
			this.ngControl.touched
		);
	}

	@HostBinding('class.-is-invalid')
	public get isInvalid() {
		return (
			this.ngControl?.invalid &&
			!this.failedRequiredValidation &&
			!this.ngControl.disabled &&
			this.ngControl.touched
		);
	}

	@HostBinding('class.-is-loading')
	public get isLoading() {
		return this.inputChild?.isLoading && this.ngControl?.disabled;
	}

	constructor(@Optional() private parentControl: NgControl) {}

	public ngAfterContentInit() {
		if (this.parentControl) {
			this.ngControl = this.parentControl;
		}
	}
}
