import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { DeclineReasonsDto } from '@ccap/au-pair/data-access/models';
import { ILinkData } from '@ccap/interfaces';
import { ILinkActionPayload } from '../interfaces';

export const fetchLinks = createAction('[Links] Get');
export const fetchLinksFail = createAction(
	'[Links] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateLinks = createAction(
	'[Links] Update',
	props<{ links: ILinkData[] }>(),
);

export const rollbackLinks = createAction(
	'[Links] Rollback',
	props<{ links: ILinkData[] }>(),
);

export const setLink = createAction(
	'[Links] Set Link',
	props<{ link: ILinkActionPayload }>(),
);
export const setLinkFail = createAction(
	'[Links] Set Link Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const deleteLink = createAction(
	'[Links] Delete Link',
	props<{ linkId: string }>(),
);

export const deleteLinkFail = createAction(
	'[Links] Delete Link Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const removeLink = createAction(
	'[Links] Remove Link',
	props<{ linkId: string }>(),
);

export const updateLink = createAction(
	'[Links] Update Link',
	props<{ link: ILinkActionPayload }>(),
);

export const fetchDeclineReasons = createAction('[Links] Get Decline Reasons');

export const fetchDeclineReasonsFail = createAction(
	'[Links] Get Decline Reasons Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateDeclineReasons = createAction(
	'[Links] Update Decline Reasons',
	props<{ reasons: DeclineReasonsDto }>(),
);
