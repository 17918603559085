import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const fetchCompletionPercentage = createAction(
	'[Profile Completion] Get Percentage',
);

export const fetchCompletionPercentageFail = createAction(
	'[Profile Completion] Get Percentage Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const setCompletionPercentage = createAction(
	'[Profile Completion] Set Percentage',
	props<{ percentage: number }>(),
);

export const setCompletionPercentageFail = createAction(
	'[Profile Completion] Set Percentage Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateCompletionPercentage = createAction(
	'[Profile Completion] Update Percentage',
	props<{ percentage: number }>(),
);
