import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	IBusyStatus,
	IBusyStatusOptions,
} from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class BusyStatusResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getBusyStatus(): Observable<IBusyStatus> {
		return this.httpService.authGet<IBusyStatus>(this.endpoints.busyStatus);
	}

	public setBusyStatus(status: IBusyStatus): Observable<IBusyStatus> {
		return this.httpService.authPatch<IBusyStatus>(
			this.endpoints.busyStatus,
			status,
		);
	}

	public getBusyStatusMetaData(): Observable<IBusyStatusOptions> {
		return this.httpService.authGet<IBusyStatusOptions>(
			`${this.endpoints.busyStatus}/metadata`,
		);
	}
}
