import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { IQuestionExtended } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class QuestionSchemaResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getSchema(): Observable<Record<string, IQuestionExtended>> {
		return this.httpService.authGet(`${this.endpoints.schema}/questions`);
	}
}
