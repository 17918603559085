const adultInclusiveGenderMale = 'Man';
const adultInclusiveGenderFemale = 'Woman';
const childInclusiveGenderMale = 'Boy';
const childInclusiveGenderFemale = 'Girl';

const biologicalSexMale = 'Male';
const biologicalSexFemale = 'Female';

export type BiologicalSex =
	| typeof biologicalSexMale
	| typeof biologicalSexFemale;

export const inclusiveGenderByAge = (
	biologicalSex: BiologicalSex,
	age: number,
): string => {
	if (!biologicalSex || !age) {
		return '';
	}

	const isAdult = age >= 18;

	if (isAdult) {
		return biologicalSex.toLowerCase() === biologicalSexMale.toLowerCase()
			? adultInclusiveGenderMale
			: adultInclusiveGenderFemale;
	}

	return biologicalSex.toLowerCase() === biologicalSexMale.toLowerCase()
		? childInclusiveGenderMale
		: childInclusiveGenderFemale;
};
