export enum AuPairElementId {
	ReportAConcern = 'report-a-concern',
	BusyStatus = 'busy-status',
	EmbassyStatus = 'embassy-status',
	EmbassyPrompt = 'embassy-prompt',
	AgreementsMissingPrompt = 'agreements-missing-prompt',
}

export interface AuPairElement {
	id: AuPairElementId;
	visible: boolean;
	enabled: boolean;
}
