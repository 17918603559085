declare global {
	interface Window {
		config: any;
	}
}

window.config = {};

export interface AppEnvironment {
	production?: boolean;
	isLocal?: boolean;
	SCHEMA_ENDPOINT: string;
	API_ENDPOINT: string;
	API_ENDPOINT_FAMILY: string;
	APP_INSIGHTS_AU_PAIR_PUBLIC_PROFILE: {
		instrumentationKey: string;
	};
	AUTH0_CLIENT_ID_STAFF: string;
	AUTH0_CLIENT_ID_AUPAIR: string;
	AUTH0_CLIENT_ID_HF: string;
	AUTH0_CLIENT_ID_LCC: string;
	AUTH0_CLIENT_DOMAIN: string;
	TAG_MANAGER_ID: string;
	SOCIAL_MEDIA_LOGIN_URL: string;
	MATCH_LOGIN_URL: string;
	APOLLO_ENDPOINT: string;
}

export class AppEnvironmentBase implements AppEnvironment {
	public isLocal?: boolean;

	public get production() {
		return window.config.PRODUCTION;
	}

	public get SCHEMA_ENDPOINT() {
		return window.config.SCHEMA_ENDPOINT;
	}

	public get API_ENDPOINT() {
		return window.config.API_ENDPOINT;
	}

	public get API_ENDPOINT_FAMILY() {
		return window.config.API_ENDPOINT_FAMILY;
	}

	public get APP_INSIGHTS_AU_PAIR_PUBLIC_PROFILE() {
		return window.config.APP_INSIGHTS_AU_PAIR_PUBLIC_PROFILE;
	}

	public get AUTH0_CLIENT_ID_STAFF() {
		return window.config.AUTH0_CLIENT_ID_STAFF;
	}

	public get AUTH0_CLIENT_ID_AUPAIR() {
		return window.config.AUTH0_CLIENT_ID_AUPAIR;
	}

	public get AUTH0_CLIENT_ID_HF() {
		return window.config.AUTH0_CLIENT_ID_HF;
	}

	public get AUTH0_CLIENT_ID_LCC() {
		return window.config.AUTH0_CLIENT_ID_LCC;
	}

	public get AUTH0_CLIENT_DOMAIN() {
		return window.config.AUTH0_CLIENT_DOMAIN;
	}

	public get TAG_MANAGER_ID() {
		return window.config.TAG_MANAGER_ID;
	}

	public get SOCIAL_MEDIA_LOGIN_URL() {
		return window.config.SOCIAL_MEDIA_LOGIN_URL;
	}

	public get MATCH_LOGIN_URL() {
		return window.config.MATCH_LOGIN_URL;
	}

	public get APOLLO_ENDPOINT() {
		return window.config.APOLLO_ENDPOINT;
	}
}
