import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { AnswerAbstractListItemModule } from './answer-abstract-list-item/answer-abstract-list-item.module';
import { AnswerAbstractListComponent } from './answer-abstract-list/answer-abstract-list.component';
import { AnswerAddressModule } from './answer-address';
import { AnswerCheckboxListWithOtherModule } from './answer-checkbox-list-with-other/answer-checkbox-list-with-other.module';
import { AnswerCheckboxModule } from './answer-checkbox/answer-checkbox.module';
import { AnswerDropdownModule } from './answer-dropdown/answer-dropdown.module';
import { AnswerExperienceListModule } from './answer-experience-list/answer-experience-list.module';
import { AnswerInterviewModule } from './answer-question-list/answer-interview/answer-interview.module';
import { AnswerQuestionListModule } from './answer-question-list/answer-question-list.module';
import { AnswerRadioTileModule } from './answer-radio-tile/answer-radio-tile.module';
import { AnswerSmokingModule } from './answer-smoking/answer-smoking.module';
import { AnswerTileListHouseholdModule } from './answer-tile-list-household/answer-tile-list-household.module';
import { AnswerTileListInterestsModule } from './answer-tile-list-interests/answer-tile-list-interests.module';
import { AnswerTileListLanguagesModule } from './answer-tile-list-languages/answer-tile-list-languages.module';
import { AnswerWorkExperienceListModule } from './answer-work-experience-list/answer-work-experience-list.module';
import { AnswerComponent } from './answer.component';

@NgModule({
	imports: [
		CommonModule,
		AnswerQuestionListModule,
		AnswerExperienceListModule,
		AnswerWorkExperienceListModule,
		AnswerTileListHouseholdModule,
		AnswerTileListLanguagesModule,
		AnswerTileListInterestsModule,
		AnswerCheckboxModule,
		AnswerCheckboxListWithOtherModule,
		AnswerInterviewModule,
		AnswerSmokingModule,
		AnswerAddressModule,
		AnswerDropdownModule,
		AnswerAbstractListItemModule,
		AnswerRadioTileModule,
		PipesModule,
	],
	declarations: [AnswerComponent, AnswerAbstractListComponent],
	exports: [AnswerComponent],
	providers: [],
})
export class AnswerModule {}
