import { Action, createReducer, on } from '@ngrx/store';

import {
	AgreementDto,
	ComplianceDocumentTypes,
	MatchDocumentDto,
} from '@ccap/au-pair/data-access/models';
import { IMediaFile } from '@ccap/interfaces';
import {
	addDocuments,
	updateAgreement,
	updateAgreements,
	updateDisplayedDocumentIds,
	updateMatchDocuments,
} from './documents.action';

export const buildCompoundAgreementId = (
	agreement: Pick<AgreementDto, 'matchId' | 'type'>,
) => `${agreement.matchId}:${agreement.type}`;

export interface DocumentsState {
	agreements: Record<string, AgreementDto>;
	documents: Record<string, IMediaFile>;
	matchDocuments: MatchDocumentDto[];
	displayedDocumentIds: ComplianceDocumentTypes[];
}

export const documentsInitialState = {
	agreements: {},
	documents: {},
	matchDocuments: [],
	displayedDocumentIds: [],
};

const reducer = createReducer<DocumentsState>(
	documentsInitialState,
	on(updateAgreements, (state: DocumentsState | null, { agreements }) => {
		const agreementsDictionary = agreements.reduce(
			(acc, curr) => ({ ...acc, [buildCompoundAgreementId(curr)]: curr }),
			{},
		);

		return {
			...state,
			agreements: {
				...((state && state.agreements) || {}),
				...agreementsDictionary,
			},
		};
	}),
	on(addDocuments, (state: DocumentsState | null, { documents }) => {
		const documentsDictonary = documents.reduce(
			(acc, curr) => ({ ...acc, [curr.id]: curr }),
			{},
		);

		return {
			...state,
			documents: {
				...((state && state.documents) || {}),
				...documentsDictonary,
			},
		};
	}),
	on(updateMatchDocuments, (state: DocumentsState | null, { documents }) => ({
		...state,
		matchDocuments: documents,
	})),
	on(updateAgreement, (state: DocumentsState | null, { agreement }) => {
		return {
			...state,
			agreements: {
				...((state && state.agreements) || {}),
				[buildCompoundAgreementId(agreement)]: agreement,
			},
		};
	}),
	on(
		updateDisplayedDocumentIds,
		(state: DocumentsState | null, { documentIds }) => ({
			...state,
			displayedDocumentIds: documentIds,
		}),
	),
);

export function documentsReducer(
	state: DocumentsState,
	action: Action,
): DocumentsState {
	return reducer(state, action);
}
