import { ICheckBoxValue, ISavedDateValue } from '@ccap/interfaces';
import { SummaryCard } from '../../../../interfaces/summary-cards';

function drivingSkillAnswer(
	drivingFrequency: string,
	drivingSinceDate: ISavedDateValue | null,
	drivingTrainingSinceDate: ISavedDateValue | null,
	drivingTransmission: ICheckBoxValue | null,
	drivingConditions: ICheckBoxValue | null,
): string {
	const drivingSkillNotes: string[] = [];
	if (drivingSinceDate) {
		drivingSkillNotes.push(
			`Licensed since: ${drivingSinceDate.MM} ${drivingSinceDate.YYYY}`,
		);
	}
	if (drivingTrainingSinceDate) {
		drivingSkillNotes.push(
			`Practicing since: ${drivingTrainingSinceDate.MM} ${drivingTrainingSinceDate.YYYY}`,
		);
	}

	if (drivingFrequency) {
		const frequency =
			drivingFrequency.toLowerCase() === 'irregularly'
				? 'an irregular'
				: `a ${drivingFrequency.toLowerCase()}`;

		drivingSkillNotes.push(`Licensed and drives on ${frequency} basis`);
	}

	if (drivingTransmission) {
		const selectedTransmissions = Object.keys(drivingTransmission)
			.filter(transmission => drivingTransmission[transmission])
			.join(' and ');

		drivingSkillNotes.push(`Transmission: ${selectedTransmissions}`);
	}

	if (drivingConditions) {
		const selectedConditions = Object.keys(drivingConditions)
			.filter(condition => drivingConditions[condition])
			.join(', ');

		drivingSkillNotes.push(`Condition: ${selectedConditions}`);
	}

	return drivingSkillNotes.join('\n');
}

function drivingLicenseAnswer(
	anticipatedDrivingLicenseDate: ISavedDateValue,
	drivingTrainingSinceDate: ISavedDateValue,
): string {
	const drivingSkillNotes: string[] = ['Training to get a license'];

	if (anticipatedDrivingLicenseDate) {
		drivingSkillNotes.push(
			`Expected date of licensure: ${anticipatedDrivingLicenseDate.MM} ${anticipatedDrivingLicenseDate.YYYY}`,
		);
	}

	if (drivingTrainingSinceDate) {
		drivingSkillNotes.push(
			`Practicing since: ${drivingTrainingSinceDate.MM} ${drivingTrainingSinceDate.YYYY}`,
		);
	}

	return drivingSkillNotes.join('\n');
}

export function drivingResolver(
	canDrive: string,
	drivingFrequency: string,
	anticipatedDrivingLicenseDate: ISavedDateValue,
	drivingTrainingSinceDate: ISavedDateValue,
	drivingSinceDate: ISavedDateValue,
	drivingTransmission: ICheckBoxValue,
	drivingConditions: ICheckBoxValue,
): Record<'driving', SummaryCard> {
	const drivingCard: SummaryCard = {
		title: 'Driving Skills',
		icon: 'Car',
		answer: undefined,
	};

	switch (canDrive) {
		case 'No':
			drivingCard.answer = 'Non-driver';
			drivingCard.highlight = true;
			break;

		case 'Yes':
			drivingCard.answer = drivingSkillAnswer(
				drivingFrequency,
				drivingSinceDate,
				drivingTrainingSinceDate,
				drivingTransmission,
				drivingConditions,
			);
			break;

		case 'Training to get license':
			drivingCard.answer = drivingLicenseAnswer(
				anticipatedDrivingLicenseDate,
				drivingTrainingSinceDate,
			);
			break;
	}

	return {
		driving: drivingCard as SummaryCard,
	};
}
