import { ChangeDetectionStrategy, Component } from '@angular/core';
import { first } from 'rxjs/operators';

import {
	AbstractModal,
	ControlToucherService,
	ModalComponentFactoryService,
} from '@ccap/au-pair/ui';
import { LinkSandboxService } from '../../../components/link/link-sandbox.service';
import { ProfileService } from '../../services/profile.service';

@Component({
	templateUrl: './connect-in-a-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectInAModalComponent extends AbstractModal {
	public payload?: any;

	constructor(
		private linkSandbox: LinkSandboxService,
		private profileService: ProfileService,
		protected modalComponentFactoryService: ModalComponentFactoryService,
		protected controlToucherService: ControlToucherService,
	) {
		super(modalComponentFactoryService);
	}

	protected save(): void {
		const message = this.formSteps[0].get(this.questions[0].id).value;

		this.linkSandbox
			.connectWithAuPair(this.profileService.getCurrentProfileId(), message)
			.pipe(first())
			.subscribe();
	}
}
