import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { ParentDto } from '@ccap/au-pair/data-access/models';

export const fetchParents = createAction('[Parents/Guardians] Get');
export const fetchParentsFail = createAction(
	'[Parents/Guardians] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const createParent = createAction(
	'[Parents/Guardians] Create',
	props<{
		parent: ParentDto;
		/*
			We need to dispatch a saveAnswersLocal action due to the optimistic update made by the questions effect.
			If we do not do that, then the question will only have the parent without any ids.
			questionId & answerIndex are needed to update the answers array of the question.
		*/
		questionId: string;
		answerIndex: number;
	}>(),
);

export const createParentFail = createAction(
	'[Parents/Guardians] Create Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setParent = createAction(
	'[Parents/Guardians] Set',
	props<{ parentId: string; parent: ParentDto }>(),
);

export const setParentFail = createAction(
	'[Parents/Guardians] Set Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Deletes value in state **and on server**.
 */
export const deleteParent = createAction(
	'[Parents/Guardians] Delete',
	props<{ parentId: string }>(),
);

export const deleteParentFail = createAction(
	'[Parents/Guardians] Delete Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateParents = createAction(
	'[Parents/Guardians] Update',
	props<{ parents: ParentDto[] }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchParents = createAction(
	'[Parents/Guardians] Patch',
	props<{ parent: ParentDto }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const addParent = createAction(
	'[Parents/Guardians] Add',
	props<{ parent: ParentDto }>(),
);

/**
 * Remove value in state.
 * Does **not** result in an HTTP request
 */
export const removeParent = createAction(
	'[Parents/Guardians] Remove',
	props<{ parentId: string }>(),
);
