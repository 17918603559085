import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { HttpSandboxService } from '../../../core/services/http-sandbox.service';

@Component({
	selector: 'app-file-download',
	templateUrl: './file-download.component.html',
	styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent {
	@Input() public fileIdentifier?: string;
	@Input() public documentUrl?: string;
	public isLoading$ = new BehaviorSubject(false);

	constructor(private httpSandboxService: HttpSandboxService) {}

	public downloadDocument(): void {
		this.isLoading$.next(true);

		this.httpSandboxService
			.getDownloadableFile$(this.documentUrl)
			.pipe(
				take(1),
				finalize(() => {
					this.isLoading$.next(false);
				}),
			)
			.subscribe((blob: Blob) => {
				FileSaver.saveAs(blob, this.fileIdentifier);
			});
	}
}
