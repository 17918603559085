import {
	AfterViewInit,
	ChangeDetectorRef,
	ContentChild,
	Directive,
	Injector,
	OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarkAsNotificationService } from './mark-as-notification.service';

@Directive({
	selector: '[efInputMarkAs]',
})
export class InputMarkAsDirective implements AfterViewInit, OnDestroy {
	private onDestroy$ = new Subject<void>();

	@ContentChild(NgControl)
	private _ngControl?: NgControl;

	private get ngControl(): NgControl {
		const parentControl = this.injector.get(NgControl);

		return this._ngControl ?? parentControl;
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private notificationService: MarkAsNotificationService,
		private injector: Injector,
	) {}

	public ngAfterViewInit() {
		this.notificationService.markAsTouched$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.ngControl.control.markAsTouched();
				this.changeDetectorRef.markForCheck();
			});

		this.notificationService.markAsDirty$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(() => {
				this.ngControl.control.markAsDirty();
				this.changeDetectorRef.markForCheck();
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
