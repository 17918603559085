<ccap-tabs [activeTabIndex]="activeTabIndex">
	<ccap-tab title="First year" *ngIf="firstYearLetter">
		<ng-template ccapLazyTabContent>
			<app-host-family-letter
				[hostFamilyLetter]="firstYearLetter"
			></app-host-family-letter>
		</ng-template>
	</ccap-tab>

	<ccap-tab title="Second year" *ngIf="secondYearLetter">
		<ng-template ccapLazyTabContent>
			<app-host-family-letter [hostFamilyLetter]="secondYearLetter">
			</app-host-family-letter>
		</ng-template>
	</ccap-tab>
</ccap-tabs>
