import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { CoursesAvailabilityDto } from '@ccap/au-pair/data-access/models';

export const fetchCoursesAvailability = createAction(
	'[Courses] Get Availability',
);

export const fetchCoursesAvailabilityFail = createAction(
	'[Courses] Get Availability Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateCoursesAvailability = createAction(
	'[Courses] Update Availability',
	props<{ availability: CoursesAvailabilityDto }>(),
);
