import { Input } from '@angular/core';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';

@Component({
	selector: 'ccap-show-less',
	templateUrl: './show-less.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowLessComponent {
	@Output()
	// eslint-disable-next-line @angular-eslint/no-output-native
	public click = new EventEmitter<MouseEvent>();

	@Input()
	public inheritColor?: boolean;

	public clicked(event: MouseEvent): void {
		event.stopPropagation();
		this.click.emit(event);
	}
}
