import format from 'date-fns/format';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { DateValue } from '@ccap/interfaces';

export const dateValueToIsoString = (date: DateValue): string => {
	if (every(date, isEmpty)) {
		return '';
	}

	const day = date.DD ?? '01';
	const month = date.MM ?? 'January';
	const year = date.YYYY;

	return format(
		new Date(`${month} ${day}, ${year}`),
		`yyyy-MM-dd'T'HH:mm:ss'Z'`,
	);
};
