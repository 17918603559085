import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'comma',
})
export class CommaSeparatedPipe implements PipeTransform {
	public transform(value: any): any {
		return Array.isArray(value) ? this.flatten(value).join(', ') : value;
	}

	private flatten: any = (deep: any[], flat: any[] = []) => {
		if (deep.length === 0) {
			return flat;
		}

		const head = deep[0];
		const tail = deep.slice(1);

		return Array.isArray(head)
			? this.flatten(tail, [].concat(flat, this.flatten(head)))
			: this.flatten(tail, [].concat(flat, head));
	};
}
