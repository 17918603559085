import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WorkExperienceState } from './work-experience.reducer';

export const getWorkExperience =
	createFeatureSelector<WorkExperienceState>('workExperience');

export const getWorkExperienceReferenceById = (id: string) =>
	createSelector(getWorkExperience, experience =>
		experience && experience.workExperiences
			? experience.workExperiences.find(({ referenceId }) => referenceId === id)
			: null,
	);
