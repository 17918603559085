import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkAwarePreloadStrategy implements PreloadingStrategy {
	constructor(private window: Window) {}

	public preload(route: Route, load: () => Observable<any>): Observable<any> {
		return this.hasGoodConnection ? load() : EMPTY;
	}

	private get hasGoodConnection(): boolean {
		const { connection } = this.window.navigator as any;

		if (!connection) {
			return false;
		}

		if (connection.saveData) {
			// save data mode is enabled, so dont preload
			return false;
		}

		const avoidTheseConnections = ['slow-2g', '2g', '3g'];
		const effectiveType = connection.effectiveType || '';

		return !avoidTheseConnections.includes(effectiveType);
	}
}
