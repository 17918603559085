<dl>
	<ng-container *ngFor="let field of labeledFields">
		<ng-container [ngSwitch]="field.type">
			<ng-container *ngSwitchCase="'dropdown'">
				<app-answer-dropdown
					[label]="field.label"
					[value]="field.value"
					[options]="field.options"
				></app-answer-dropdown>
			</ng-container>

			<ng-container *ngSwitchCase="'radioTile'">
				<app-answer-radio-tile
					[label]="field.label"
					[value]="field.value"
					[options]="field.options"
				></app-answer-radio-tile>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<div class="answer-row">
					<dt>{{ field.label }}</dt>
					<dd
						class="space-below"
						[innerHTML]="
							field.value
								| customDate
								| comma
								| selected: 'Not specified'
								| yesNo
						"
					></dd>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</dl>
