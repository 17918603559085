export enum AuPairStatus {
	ApplicationStarted = 'ApplicationStarted',
	InMatchingPool = 'InMatchingPool',
	Matched = 'Matched',
	AtTrainingSchool = 'AtTrainingSchool',
	WithHostFamily = 'WithHostFamily',
	FinishedProgram = 'FinishedProgram',
}
export enum AuPairLocationStatus {
	Overseas = 'Overseas',
	OnProgram = 'OnProgram',
	ProgramFinished = 'ProgramFinished',
}

export interface AuPairStatusDto {
	isFlagged: boolean;
	statusMessage: string;
	locationStatus: AuPairLocationStatus;
	statusName: AuPairStatus;
	hasTravelled: boolean;
}
