import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerCheckboxListWithOtherComponent } from './answer-checkbox-list-with-other.component';

@NgModule({
	imports: [CommonModule, SvgModule, PipesModule],
	declarations: [AnswerCheckboxListWithOtherComponent],
	exports: [AnswerCheckboxListWithOtherComponent],
})
export class AnswerCheckboxListWithOtherModule {}
