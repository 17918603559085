export enum LinkStatus {
	declined = 'declined',
	pending = 'pending',
	accepted = 'accepted',
	none = 'none',
	liked = 'liked',
}

export enum LinkButtonText {
	pending = 'Connection pending',
	accepted = 'Disconnect',
	none = 'Connect',
}

export interface ILinkData {
	status: LinkStatus;
	permittedStatuses: LinkStatus[];
}

export interface ILinkLike {
	like: ILinkData;
}

export interface ILinkConnection {
	connection: ILinkData;
}

export interface ILinkUpdatePayload {
	status: string;
	declineReason?: string;
	message?: string;
}

export interface ILink extends ILinkLike, ILinkConnection {
	id: string;
	auPairPublicId: string;
}

export enum EndpointType {
	Like = 'like',
	Connect = 'connection',
}

export enum NotificationMessage {
	Like = 'The profile has been added to your Like-List',
	Unlike = 'The profile has been removed from your Like-List',
	Connect = 'The request for connection has been sent',
	Disconnect = 'Disconnect successful',
}

export interface DeclineReasons {
	connection: string[];
	cancellation: string[];
}
