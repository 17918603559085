import { Action, createReducer, on } from '@ngrx/store';

import { InCountryDto } from '../in-country-resource.interface';
import { updateInCountry } from './in-country.actions';

export type InCountryState = InCountryDto | null;

const reducer = createReducer<InCountryState>(
	null,
	on(updateInCountry, (_, { data }) => data),
);

export function inCountryReducer(
	state: InCountryState,
	action: Action,
): InCountryState {
	return reducer(state, action);
}
