import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HostFamilyReference, LCCReference } from '@ccap/interfaces';

@Component({
	selector: 'app-perspectives',
	templateUrl: './perspectives.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerspectivesComponent {
	@Input()
	public lccReferences?: LCCReference[];

	@Input()
	public hostFamilyReferences?: HostFamilyReference[];

	public trackReferences(index: number): number {
		return index;
	}
}
