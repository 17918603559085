import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalActionsModule } from '@ccap/au-pair/ui';
import {
	NeoDropdownModule,
	NeoTextareaModule,
	ValidationModule,
} from '@ccap/shared/ui/form';
import { ConnectInAModalComponent } from './connect-in-a-modal.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ValidationModule,
		NeoDropdownModule,
		ModalActionsModule,
		NeoTextareaModule,
	],
	declarations: [ConnectInAModalComponent],
	exports: [ConnectInAModalComponent],
})
export class ConnectInAModalModule {}
