import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { FlightDto } from '@ccap/au-pair/data-access/models';

export const fetchFlights = createAction('[Flights] Get');
export const fetchFlightsFail = createAction(
	'[Flights] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateFlights = createAction(
	'[Flights] Update',
	props<{ flights: FlightDto[] }>(),
);
