<ng-template #notConnected>
	Available when connected
</ng-template>

<ng-template #notProvided>
	Not available to be contacted this way
</ng-template>

<ng-container
	*ngFor="let reference of hostFamilyReferences; trackBy: trackReferences"
>
	<ccap-question-answer-group title="Host Family – Perspective">
		<ccap-question-answer question="Au Pair's schedule">
			<ng-container *ngIf="reference.morningEveningSchedule.oftenScheduled">
				<p>
					Morning through evening:
					{{ reference.morningEveningSchedule.comments }}
				</p>
			</ng-container>

			<ng-container *ngIf="reference.afternoonSchedule.oftenScheduled">
				<p>Afternoon: {{ reference.afternoonSchedule.comments }}</p>
			</ng-container>

			<ng-container *ngIf="reference.eveningSchedule.oftenScheduled">
				<p>Evening: {{ reference.eveningSchedule.comments }}</p>
			</ng-container>

			<ng-container *ngIf="reference.overnightSchedule.oftenScheduled">
				<p>Overnight: {{ reference.overnightSchedule.comments }}</p>
			</ng-container>

			<ng-container *ngIf="reference.weekendsSchedule.oftenScheduled">
				<p>Weekends: {{ reference.weekendsSchedule.comments }}</p>
			</ng-container>
		</ccap-question-answer>

		<ccap-question-answer
			question="Driving Experience"
			*ngIf="reference.auPairDrives?.toLowerCase() === 'yes'"
		>
			{{ reference.drivingReasons.join(', ') }}

			<br />Frequency: {{ reference.drivingFrequency }} <br />Conditions:
			{{ reference.drivingConditions.join(', ') }} <br />Vehicle:
			{{ reference.drivingVehicleType }}
		</ccap-question-answer>

		<ccap-question-answer question="Au Pair's Personality">
			{{ reference.personality.join(', ') }}

			<ng-container *ngIf="reference.personalityComments">
				<br />
				{{ reference.personalityComments }}
			</ng-container>
		</ccap-question-answer>

		<ccap-question-answer question="Childcare Responsibilities">
			{{ reference.responsibilities.join(', ') }}
		</ccap-question-answer>

		<ccap-question-answer
			question="What Host Children mostly liked about the Au Pair"
		>
			{{ reference.childrenLikeMostAboutAuPair }}
		</ccap-question-answer>

		<ccap-question-answer
			question="Relationship between Host Family and the Au Pair"
		>
			{{ reference.hostFamilyAndAuPairRelationship }}
		</ccap-question-answer>

		<ccap-question-answer
			question="Personal advice for a prospective family in order to have a successful year with this au pair?"
		>
			{{ reference.hostFamilyAdvice }}
		</ccap-question-answer>
	</ccap-question-answer-group>

	<ccap-question-answer-group title="Host Family – Contact Details">
		<ccap-question-answer question="Host Parents' Name">
			<ng-container *ngIf="reference.name.status === 'Accessible'">
				{{ reference.name.firstName }} {{ reference.name.lastName }}
			</ng-container>
			<ng-container
				*ngIf="reference.name.status === 'NotConnected'; then notConnected"
			></ng-container>
			<ng-container
				*ngIf="reference.name.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>

		<ccap-question-answer question="Host Parents' Phone">
			<ng-container *ngIf="reference.phoneNumber.status === 'Accessible'">
				<a
					[href]="'tel:' + reference.phoneNumber.number"
					[title]="'Call ' + reference.phoneNumber.number"
				>
					{{ reference.phoneNumber.number }}
				</a>
			</ng-container>
			<ng-container
				*ngIf="
					reference.phoneNumber.status === 'NotConnected';
					then notConnected
				"
			></ng-container>
			<ng-container
				*ngIf="reference.phoneNumber.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>

		<ccap-question-answer question="Host Parents' Email">
			<ng-container *ngIf="reference.email.status === 'Accessible'">
				<a
					[href]="'mailto:' + reference.email.emailAddress"
					[title]="'Mail ' + reference.email.emailAddress"
				>
					{{ reference.email.emailAddress }}
				</a>
			</ng-container>
			<ng-container
				*ngIf="reference.email.status === 'NotConnected'; then notConnected"
			></ng-container>
			<ng-container
				*ngIf="reference.email.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>

		<ccap-question-answer
			question="Best Time to Reach"
			*ngIf="reference.phoneNumber.bestTimeToReach"
		>
			{{ reference.phoneNumber.bestTimeToReach }}
		</ccap-question-answer>
	</ccap-question-answer-group>
</ng-container>

<ng-container *ngFor="let reference of lccReferences; trackBy: trackReferences">
	<ccap-question-answer-group title="LCC – Perspective">
		<ccap-question-answer question="Au Pair's responsiveness to communication">
			{{ reference.communicationResponsivenessRating.score }}/5 &mdash;
			{{ reference.communicationResponsivenessRating.label }}

			<ng-container
				*ngIf="reference.willingnessToAddressConcernsRating.comments"
			>
				<br />
				{{ reference.communicationResponsivenessRating.comments }}
			</ng-container>
		</ccap-question-answer>

		<ccap-question-answer
			question="Au Pair's willingness to address concerns when raised by LCC, Host Family or others"
		>
			{{ reference.willingnessToAddressConcernsRating.score }}/5 &mdash;
			{{ reference.willingnessToAddressConcernsRating.label }}

			<ng-container
				*ngIf="reference.willingnessToAddressConcernsRating.comments"
			>
				<br />
				{{ reference.willingnessToAddressConcernsRating.comments }}
			</ng-container>
		</ccap-question-answer>

		<ccap-question-answer
			question="Additional insights for prospective Host Family"
		>
			{{ reference.additionalInsightsRelevantToHostFamily }}
		</ccap-question-answer>
	</ccap-question-answer-group>

	<ccap-question-answer-group title="LCC – Contact Details">
		<ccap-question-answer question="LCC's Name">
			<ng-container *ngIf="reference.name.status === 'Accessible'">
				{{ reference.name.firstName }} {{ reference.name.lastName }}
			</ng-container>
			<ng-container
				*ngIf="reference.name.status === 'NotConnected'; then notConnected"
			></ng-container>
			<ng-container
				*ngIf="reference.name.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>

		<ccap-question-answer question="LCC's Phone">
			<ng-container *ngIf="reference.phoneNumber.status === 'Accessible'">
				<a
					[href]="'tel:' + reference.phoneNumber.number"
					[title]="'Call ' + reference.phoneNumber.number"
				>
					{{ reference.phoneNumber.number }}
				</a>
			</ng-container>
			<ng-container
				*ngIf="
					reference.phoneNumber.status === 'NotConnected';
					then notConnected
				"
			></ng-container>
			<ng-container
				*ngIf="reference.phoneNumber.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>

		<ccap-question-answer question="LCC's Email">
			<ng-container *ngIf="reference.email.status === 'Accessible'">
				<a
					[href]="'mailto:' + reference.email.emailAddress"
					[title]="'Mail ' + reference.email.emailAddress"
				>
					{{ reference.email.emailAddress }}
				</a>
			</ng-container>
			<ng-container
				*ngIf="reference.email.status === 'NotConnected'; then notConnected"
			></ng-container>
			<ng-container
				*ngIf="reference.email.status === 'NotProvided'; then notProvided"
			></ng-container>
		</ccap-question-answer>
	</ccap-question-answer-group>
</ng-container>
