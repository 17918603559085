import {
	createFeatureSelector,
	createSelector,
	DefaultProjectorFn,
	MemoizedSelector,
} from '@ngrx/store';

import { AsyncState, AsyncStateStatus } from './async-state.model';

type FeatureSelector<T> = MemoizedSelector<
	unknown,
	AsyncState<T>,
	DefaultProjectorFn<AsyncState<T>>
>;

export const createAsyncSelectors = <T>(
	featureSelector: string | FeatureSelector<T>,
) => {
	const selectFeature =
		typeof featureSelector === 'string'
			? createFeatureSelector<AsyncState<T>>(featureSelector)
			: featureSelector;

	const getData = createSelector(selectFeature, slice => slice?.data);

	const getStatus = createSelector(selectFeature, slice => slice?.status);

	const getDidInitialLoad = createSelector(
		getStatus,
		status =>
			status !== AsyncStateStatus.Init &&
			status !== AsyncStateStatus.InitialLoading,
	);

	const getIsLoading = createSelector(
		getStatus,
		status => status === AsyncStateStatus.Loading,
	);

	const getError = createSelector(selectFeature, slice => slice?.error);

	return {
		selectFeature,
		getData,
		getStatus,
		getDidInitialLoad,
		getError,
		getIsLoading,
	};
};
