import { Component, Input } from '@angular/core';

import { IsoDateTypeFormat } from '@ccap/interfaces';

const optionsForDateFormat = (
	format: IsoDateTypeFormat,
): Intl.DateTimeFormatOptions => {
	const year: Intl.DateTimeFormatOptions = { year: 'numeric' };
	const month: Intl.DateTimeFormatOptions = { month: 'long' };
	const day: Intl.DateTimeFormatOptions = { day: 'numeric' };

	if (format === 'YYYY') {
		return year;
	}

	if (format === 'YYYY-MM') {
		return { ...year, ...month };
	}

	return { ...year, ...month, ...day };
};

const pluraliseWord = (word: string, length: number): string =>
	length > 1 ? `${word}s` : word;

@Component({
	selector: 'ccap-validation',
	templateUrl: './validation.component.html',
	styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent {
	@Input() public errors?: Record<string, any>;
	@Input() public visible?: boolean;

	public get errorMessage() {
		for (const propertyName in this.errors) {
			if (this.errors[propertyName]) {
				return this.getValidatorErrorMessage(
					propertyName,
					this.errors[propertyName],
				);
			}
		}

		return null;
	}

	private getValidatorErrorMessage(
		validatorName: string,
		validatorValue?: any,
	) {
		const config: Record<string, string> = {
			required: 'This answer is required!',
			minlength: `Minimum length is ${validatorValue.requiredLength}.`,
			maxlength: `The maximum length of ${validatorValue.requiredLength} has been exceeded. Current length is ${validatorValue.actualLength}.`,
			minNumberOfFiles: `Please upload at least ${
				validatorValue.minNumberOfFiles
			} ${pluraliseWord('file', validatorValue.minNumberOfFiles)}`,
			email: 'Invalid email address',
			preQualifications:
				'Sorry! Based on your answer, you do not qualify to sign up as an au pair.',
			preQualificationAge:
				'Sorry! Based on your answer, you do not qualify to sign up as an au pair.',
			listMinlength: `Please enter at least ${
				validatorValue.minLength
			} ${pluraliseWord('item', validatorValue.minLength)}`,
			minApprovedOrPendingReference: `Please enter at least ${
				validatorValue.minLength
			} ${pluraliseWord('reference', validatorValue.minLength)}`,
			minChecked: `Please select at least ${
				validatorValue.minimumAmountNeededToBeChecked
			} ${pluraliseWord(
				'item',
				validatorValue.minimumAmountNeededToBeChecked,
			)}`,
			maxChecked: this.getMaxCheckedMessage(validatorValue),
			listMaxLength: `This list should not contain more than ${
				validatorValue.maxLength
			} ${pluraliseWord('item', validatorValue.maxLength)}`,
			date: 'This answer is required!',
			dateOutOfRange: this.getDateOutOfRangeMessage(validatorValue),
			dayDisabled: 'Please select a different day of the week',
			completionDateValidator: `Your course completion date cannot be after ${validatorValue.deadline?.toLocaleDateString(
				'en-US',
				optionsForDateFormat(validatorValue.format),
			)}.<br>Please call <a href="tel:+18003336056">800-333-6056</a>`,
			min:
				validatorValue.min === 0
					? `Please enter a non negative number`
					: validatorValue.min === 1
					? `Please enter a positive number`
					: `Please enter a number greater than ${validatorValue.min - 1}`,
		};

		return config[validatorName] ?? validatorValue?.errorMessage ?? null;
	}

	private getMaxCheckedMessage(validatorValue: {
		maximumAmountThatCanBeChecked: number;
		actualChecked: number;
	}): string {
		const firstSentence = `Please only select a maximum of ${
			validatorValue.maximumAmountThatCanBeChecked
		} ${pluraliseWord('item', validatorValue.maximumAmountThatCanBeChecked)}.`;

		const secondSentence = `Currently ${
			validatorValue.actualChecked
		} ${pluraliseWord('item', validatorValue.actualChecked)} are selected.`;

		return `${firstSentence} ${secondSentence}`;
	}

	private getDateOutOfRangeMessage(validatorValue: {
		min: Date | null;
		max: Date | null;
		format: IsoDateTypeFormat;
	}): string {
		const { min, max, format } = validatorValue;

		if (min && !(min instanceof Date)) {
			return '';
		}

		const options = optionsForDateFormat(format);
		const formattedMinDate = min
			? min.toLocaleDateString('en-US', options)
			: null;
		const formattedMaxDate = max
			? max.toLocaleDateString('en-US', options)
			: null;

		return min && max
			? `Please select a date between ${formattedMinDate} and ${formattedMaxDate}`
			: min
			? `Please select a date from ${formattedMinDate} or after`
			: `Please select a date from ${formattedMaxDate} or before`;
	}
}
