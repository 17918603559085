import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgModule } from '@ccap/shared/ui/core-assets';
import { SpinnerModule } from '@ccap/shared/ui/indicators';
import { LikeComponent } from './like.component';

@NgModule({
	imports: [CommonModule, SpinnerModule, SvgModule],
	declarations: [LikeComponent],
	exports: [LikeComponent],
})
export class LikeModule {}
