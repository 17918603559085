/* eslint-disable max-classes-per-file */

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FeatureFlagState } from '../interfaces';

export const getFlags = createAction('[Feature Toggle] Get Flags');

export const getFlagsSuccess = createAction(
	'[Feature Toggle] Get Flag Success',
	props<{ payload: FeatureFlagState }>(),
);

export const getFlagsFail = createAction(
	'[Feature Toggle] Get Flag Fail',
	props<{ httpErrorResponse: HttpErrorResponse }>(),
);
