import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProfileWrapperComponent } from './profile-wrapper.component';

@NgModule({
	declarations: [ProfileWrapperComponent],
	exports: [ProfileWrapperComponent],
	imports: [CommonModule, RouterModule],
})
export class ProfileWrapperModule {}
