import {
	AbstractControl,
	FormArray,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';

import { ValidationService } from '@ccap/au-pair/utils';
import {
	IAnswer,
	IQuestionExtended,
	IReferenceBody,
	Option,
	QuestionType,
} from '@ccap/interfaces';

export type IValidationError = Record<string, any>;

export const getValidationErrors = (
	question: IQuestionExtended,
): IValidationError => {
	const validators: ValidatorFn = ValidationService.getQuestionValidators(
		question,
	);
	const { answer, options, type } = question;

	switch (type) {
		case 'checkbox':
			return validateFormGroup(answer, type, options, validators);

		case 'experienceList':
		case 'workExperienceList':
		case 'parentList':
		case 'modalQuestionList':
		case 'questionList': {
			const questionListValidators = question.typeVariations.questionListItems.reduce(
				(acc, q) => ({
					...acc,
					[q.id]: ValidationService.getQuestionValidators(q as any),
				}),
				{},
			);

			return arrayLike(answer as [], validators, questionListValidators);
		}

		default:
			return new FormControl(answer, validators).errors;
	}
};

const arrayLike = (
	answer: unknown[],
	validators: ValidatorFn,
	questionListValidators: Record<string, ValidatorFn>,
) => {
	const answersList = answer || [];
	const isPending = (a: IReferenceBody | null) => a && a.status === 'Pending';

	const controls = answersList.map(
		itemAnswer =>
			new FormGroup(
				Object.keys(itemAnswer).reduce(
					(fg, key) => ({
						...fg,
						[key]: new FormControl(
							itemAnswer[key],
							isPending(itemAnswer as IReferenceBody)
								? questionListValidators[key]
								: undefined,
						),
					}),
					{},
				),
			),
	);

	const errors = {
		...new FormArray(controls, validators).errors,
		...getFormGroupControlsErrors(controls),
	};

	return Object.keys(errors).length ? errors : null;
};

const getFormGroupControlsErrors = (
	formGroups: FormGroup[],
): ValidationErrors =>
	formGroups.reduce((err, fg) => {
		return {
			...err,
			...flattenControlsErrors(fg.controls),
		};
	}, {});

const flattenControlsErrors = (
	controls: Record<string, AbstractControl>,
): ValidationErrors => {
	return Object.keys(controls).reduce((e, controlKey) => {
		if (controls[controlKey].valid) {
			return e;
		}

		return { ...e, ...controls[controlKey].errors };
	}, {});
};

const validateFormGroup = (
	answer: IAnswer,
	type: QuestionType,
	options: Option[],
	validators: ValidatorFn,
): IValidationError => {
	const formGroup = new FormGroup({}, validators);
	const optionKeys: string[] = options.map(({ value }) => value);

	optionKeys.forEach((optionKey: string) => {
		const val = answer && answer[optionKey] ? answer[optionKey] : answer;

		formGroup.addControl(optionKey, new FormControl(val, null));
	});

	return formGroup.errors;
};
