import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs/operators';

import { MediaResource } from '@ccap/au-pair/data-access/media';
import {
	deleteResourceRemote,
	getAnswers,
	saveAnswersLocal,
	saveMediaFile,
	saveMediaFileMetadata,
	saveMediaFileSuccess,
} from '../../questions/store/questions.actions';
import { IAppState } from '../../reducers';
import {
	addVideo,
	deleteVideo,
	deleteVideoFail,
	fetchVideos,
	fetchVideosFail,
	patchVideo,
	patchVideoMetadata,
	removeVideo,
	rollbackVideos,
	setVideoMetadata,
	setVideoMetadataFail,
	updateVideos,
	uploadVideo,
	uploadVideoFail,
} from './video.actions';
import { getVideos } from './video.selectors';

@Injectable({ providedIn: 'root' })
export class VideoEffect {
	public getAnswers$ = createEffect(() =>
		this.actions$.pipe(ofType(getAnswers), map(fetchVideos)),
	);

	public saveMediaFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveMediaFile),
			filter(({ fileType }) => fileType === 'video'),
			map(({ file, endpointKey }) =>
				uploadVideo({ video: file, key: endpointKey }),
			),
		),
	);

	public saveMediaFileMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveMediaFileMetadata),
			filter(({ question }) => question.typeVariations.fileType === 'video'),
			mergeMap(({ answer, question }: any) => [
				setVideoMetadata({
					key: answer.key,
					videoId: answer.id,
					metadata: answer.meta,
				}),
				saveAnswersLocal({ questions: [question] }),
			]),
		),
	);

	public deleteResourceRemote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteResourceRemote),
			filter(({ newAnswers: [newAnswer] }) => newAnswer.endpoint === 'video'),
			map(({ newAnswers: [newAnswer], options }: any) =>
				deleteVideo({
					key: newAnswer.id,
					videoId: options.resourceId,
				}),
			),
		),
	);

	public fetchVideos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchVideos),
			switchMap(() =>
				this.mediaResource.getVideos().pipe(
					map(videos => updateVideos({ videos })),
					catchError(error => of(fetchVideosFail({ error }))),
				),
			),
		),
	);

	public uploadVideo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadVideo),
			// concatMap fixes race condition on BE side
			concatMap(action => {
				const sameAction$ = this.actions$.pipe(
					ofType(uploadVideo),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.mediaResource.uploadVideo(action.key, action.video).pipe(
					takeUntil(sameAction$),
					mergeMap(video => [
						addVideo({ video }),
						saveMediaFileSuccess({
							questionID: action.key,
							file: video,
						}),
					]),
					catchError(error => of(uploadVideoFail({ error }))),
				);
			}),
		),
	);

	public setVideoMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setVideoMetadata),
			withLatestFrom(this.store.select(getVideos)),
			mergeMap(([action, oldVideos]) => {
				const sameAction$ = this.actions$.pipe(
					ofType(setVideoMetadata),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.mediaResource
					.updateVideoMetadata(action.key, action.videoId, action.metadata)
					.pipe(
						takeUntil(sameAction$),
						map(video => patchVideo({ video })),
						catchError(error => [
							setVideoMetadataFail({ error }),
							rollbackVideos({ videos: oldVideos }),
						]),
						startWith(
							patchVideoMetadata({
								videoId: action.videoId,
								metadata: action.metadata,
							}),
						),
					);
			}),
		),
	);

	public deleteVideo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteVideo),
			withLatestFrom(this.store.select(getVideos)),
			// concatMap fixes race condition on BE side
			concatMap(([action, oldVideos]) => {
				return this.mediaResource.deleteVideo(action.key, action.videoId).pipe(
					map(() =>
						removeVideo({
							videoId: action.videoId,
						}),
					),
					catchError(error => [
						deleteVideoFail({ error }),
						rollbackVideos({ videos: oldVideos }),
					]),
				);
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private mediaResource: MediaResource,
	) {}
}
