import { Action, createReducer, on } from '@ngrx/store';

import { updateCompletionPercentage } from './profile-completion.action';

export interface ProfileCompletionState {
	percentage?: number;
}

const reducer = createReducer<ProfileCompletionState>(
	{},
	on(updateCompletionPercentage, (state, { percentage }) => ({
		...state,
		percentage,
	})),
);

export function profileCompletionReducer(
	state: ProfileCompletionState | undefined,
	action: Action,
) {
	return reducer(state, action);
}
