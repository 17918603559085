import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

@Component({
	selector: 'ccap-more-less',
	templateUrl: './more-less.component.html',
	styleUrls: ['./more-less.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreLessComponent {
	@Input()
	public showMore?: boolean;

	@Input()
	public inheritColor?: boolean;

	@Output()
	// eslint-disable-next-line @angular-eslint/no-output-native
	public click = new EventEmitter<MouseEvent>();

	public clicked(event: MouseEvent): void {
		event.stopPropagation();
		this.click.emit(event);
	}
}
