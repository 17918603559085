import { Component, Input } from '@angular/core';

import { LabeledField } from '../answer-abstract-list/answer-abstract-list.component';

@Component({
	selector: 'app-abstract-list-item',
	templateUrl: 'answer-abstract-list-item.component.html',
	styleUrls: ['answer-abstract-list-item.component.scss'],
})
export class AnswerAbstractListItemComponent {
	@Input() public labeledFields?: LabeledField[];
}
