import { Action, createReducer, on } from '@ngrx/store';

import { WorkExperienceDto } from '@ccap/au-pair/data-access/models';
import { queueReducers } from '@ccap/state';
import {
	addWorkExperienceReference,
	patchWorkExperience,
	patchWorkExperienceReference,
	removeWorkExperienceReference,
	rollbackWorkExperience,
	updateWorkExperience,
} from './work-experience.actions';

export type WorkExperienceState = WorkExperienceDto;

const experienceReducer = createReducer<WorkExperienceState>(
	null,
	on(
		updateWorkExperience,
		rollbackWorkExperience,
		(_, { experience }) => experience,
	),
	on(patchWorkExperience, (state, { experience }) => ({
		...state,
		experience,
	})),
);

const experienceReferenceReducer = createReducer<WorkExperienceState>(
	null,
	on(addWorkExperienceReference, (state, { reference }) => ({
		...state,
		workExperiences: state.workExperiences
			? [...state.workExperiences, reference]
			: [reference],
	})),
	on(patchWorkExperienceReference, (state, { reference, referenceId }) => ({
		...state,
		workExperiences: state.workExperiences
			? state.workExperiences.map(ref =>
					ref.referenceId === referenceId ? reference : ref,
			  )
			: state.workExperiences,
	})),
	on(removeWorkExperienceReference, (state, { referenceId }) => ({
		...state,
		workExperiences: state.workExperiences
			? state.workExperiences.filter(ref => ref.referenceId !== referenceId)
			: state.workExperiences,
	})),
);

const reducer = queueReducers(experienceReducer, experienceReferenceReducer);

export function workExperienceReducer(
	state: WorkExperienceState,
	action: Action,
): WorkExperienceState {
	return reducer(state, action);
}
