export enum MediaSize {
	Default = 480,
	Small = 200,
	Large = 720,
	ExtraLarge = 1280,
}

export function transformImage(
	url: string,
	width: MediaSize,
	height?: MediaSize,
	additionalTransformations: string[] = ['c_limit'],
): string {
	const CLOUDINARY_REGEX =
		/^(.+\.cloudinary\.com\/(?:[^/]+\/)(?:image)\/(?:upload|fetch))(.*)$/;
	const transformations = [
		...additionalTransformations,
		`w_${width}`,
		...(height ? [`h_${height}`] : []),
		'f_auto',
		'q_auto:eco',
	];

	if (CLOUDINARY_REGEX.test(url)) {
		const [, firstPart, secondPart] = CLOUDINARY_REGEX.exec(url);
		return `${firstPart}/${transformations.join(',')}${secondPart}`;
	} else {
		return url;
	}
}

export function transformImageForFaces(
	url: string,
	width: MediaSize,
	height: MediaSize,
	additionalTransformations: string[] = ['c_limit'],
): string {
	return transformImage(url, width, height, [
		...additionalTransformations,
		'c_thumb',
		'g_faces',
		'z_0.75',
	]);
}

export function transformVideoToThumbnail(
	url: string,
	width: MediaSize,
	height?: MediaSize,
	additionalTransformations: string[] = ['c_limit'],
): string {
	const CLOUDINARY_REGEX =
		/^(.+\.cloudinary\.com\/(?:[^/]+\/)(?:video)\/(?:upload|fetch))(.*)$/;
	const transformations = [
		...additionalTransformations,
		...(height ? [`h_${height}`] : []),
		`w_${width}`,
		'f_auto',
		'q_auto:eco',
	];
	// the API already returns an optimization string
	// if we double the optimization string we can not generate a thumbnail out of it
	const urlWithoutOptimization = url?.replace('/q_60,vc_auto/', '/');

	if (CLOUDINARY_REGEX.test(urlWithoutOptimization)) {
		const [, firstPart, secondPart] = CLOUDINARY_REGEX.exec(
			urlWithoutOptimization,
		);
		const transformedVideoUrl = `${firstPart}/${transformations.join(
			',',
		)}${secondPart}`;
		const transformedThumbnail =
			transformedVideoUrl.substr(0, transformedVideoUrl.lastIndexOf('.')) +
			'.jpg';

		return transformedThumbnail;
	} else {
		return url;
	}
}

export function transformVideo(
	url: string,
	width: MediaSize,
	height?: MediaSize,
	additionalTransformations: string[] = [],
): string {
	const CLOUDINARY_REGEX =
		/^(.+\.cloudinary\.com\/(?:[^/]+\/)(?:video)\/(?:upload|fetch))(.*)$/;
	const transformations = [
		...additionalTransformations,
		...(height ? [`h_${height}`] : []),
		`w_${width}`,
		// TODO: waiting for answer from Cloudinary, on how to handle this. Currently, it shows videos as "washed out" on Windows + Chrome
		// 'f_auto',
		'q_auto:eco',
	];

	if (CLOUDINARY_REGEX.test(url)) {
		const [, firstPart, secondPart] = CLOUDINARY_REGEX.exec(url);
		return `${firstPart}/${transformations.join(',')}${secondPart}`;
	} else {
		return url;
	}
}
