import { API_ENDPOINTS, API_TOKENS } from './endpoints.config';
import { Endpoints } from './endpoints.service';

export const apiEndpointsMock = {
	pink: 'https://pink.api',
	family: 'https://family.api',
	schema: 'https://schema.api',
	contentful: 'https://contentful.api',
	apollo: 'https://apollo.api',
};

export const apiTokensMock = {
	contentful: 'contentful_api_token',
};

export const endpointsMock = new Endpoints(apiEndpointsMock, apiTokensMock);

export const getEndpointMock = () => [
	Endpoints,
	{
		provide: API_ENDPOINTS,
		useValue: apiEndpointsMock,
	},
	{
		provide: API_TOKENS,
		useValue: apiTokensMock,
	},
];
