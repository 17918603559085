import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TabsTheme } from '../tabs.theme';

@Component({
	selector: 'ccap-tab-button',
	templateUrl: './tab-button.component.html',
	styleUrls: ['./tab-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabButtonComponent {
	@Input()
	public isActive?: boolean;

	@Input()
	public theme?: TabsTheme;
}
