import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormGroupDirective } from './group.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [FormGroupDirective],
	exports: [FormGroupDirective],
})
export class FormGroupModule {}
