import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalActionsModule } from '@ccap/au-pair/ui';
import { NeoDropdownModule, ValidationModule } from '@ccap/shared/ui/form';
import { DisconnectInAModalComponent } from './disconnect-in-a-modal.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ValidationModule,
		ModalActionsModule,
		NeoDropdownModule,
	],
	declarations: [DisconnectInAModalComponent],
	exports: [DisconnectInAModalComponent],
})
export class DisconnectInAModalModule {}
