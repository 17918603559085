import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { MonitoringService } from '@ccap/shared/utils';
import { environment } from '../environments/environment';

// TODO: move to shared lib
@Injectable()
export class CustomErrorHandler extends ErrorHandler {
	constructor(private injector: Injector) {
		super();
	}

	public handleError(err: any): void {
		if (!environment.isLocal) {
			const monitoringService = this.injector.get(MonitoringService);
			monitoringService.logError(err);
		}

		super.handleError(err);
	}
}
