import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProgressBarModule } from '@ccap/shared/ui/indicators';
import { PersonalityTestGraphsComponent } from './personality-test-graphs.component';

@NgModule({
	imports: [CommonModule, ProgressBarModule],
	declarations: [PersonalityTestGraphsComponent],
	exports: [PersonalityTestGraphsComponent],
})
export class PersonalityTestGraphsModule {}
