import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IAnswer, IQuestionExtended, QuestionType } from '@ccap/interfaces';

@Component({
	selector: 'app-answer',
	templateUrl: 'answer.component.html',
	styleUrls: ['answer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerComponent {
	@Input() public question?: IQuestionExtended;
	@Input() public answer?: IAnswer;

	public simpleType(questionType: QuestionType): boolean {
		const simpleQuestion: Set<QuestionType> = new Set([
			'textInput',
			'datepicker',
			'internationalPhone',
			'textarea',
			'personalityProfileRadio',
			'rating',
		] as QuestionType[]);

		return simpleQuestion.has(questionType);
	}
}
