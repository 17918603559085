import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { PersonalityTestResultsDto } from '@ccap/au-pair/data-access/models';

type colorType = 'yellow' | 'green' | 'blue' | 'red';

@Component({
	selector: 'app-personality-test-graphs',
	templateUrl: './personality-test-graphs.component.html',
	styleUrls: ['./personality-test-graphs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalityTestGraphsComponent implements OnInit, OnDestroy {
	@Input()
	public personalityTestResults$: Observable<PersonalityTestResultsDto>;

	public sortedColors$: BehaviorSubject<string[]> = new BehaviorSubject([]);
	public personalityTestResults?: PersonalityTestResultsDto;
	private subscription?: Subscription;

	public get colorMap(): { [key in colorType]: string } {
		// todo: remove this when the backend is ready
		return {
			blue: 'Cautious / Measured, Concrete / Structured',
			red: 'Systems Thinking, Tough Minded / Direct',
			yellow: 'Inventive / Future-Oriented, Curious / Energetic',
			green: 'Empathetic / Inclusive, Contemplative / Contextual',
		};
	}

	public ngOnInit() {
		this.subscription = this.personalityTestResults$.subscribe(
			(streamValue: PersonalityTestResultsDto): void => {
				this.personalityTestResults = streamValue;
				this.sortedColors$.next(
					['blue', 'red', 'yellow', 'green'].sort(
						(a: colorType, b: colorType): number =>
							streamValue[b] - streamValue[a],
					),
				);
			},
		);
	}

	public ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	public getPercentageResult(color: colorType): number {
		return this.personalityTestResults[color];
	}
}
