import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';
import { FeatureFlagState, FeatureToggles } from '../interfaces';

import {
	getFlags,
	getFlagsFail,
	getFlagsSuccess,
} from './feature-toggle.actions';

export const initialFeatureToggleState: FeatureFlagState = {};

export const reducer = createReducer(
	initialFeatureToggleState,
	on(getFlagsSuccess, (state, { payload }) => {
		return { ...state, ...payload };
	}),
	on(getFlags, getFlagsFail, state => state),
);

export function featureToggleReducer(state, action) {
	return reducer(state, action);
}

const selectFeatures = createFeatureSelector<FeatureFlagState>('features');

export const selectFeatureFlag = (flag: FeatureToggles) =>
	createSelector(
		selectFeatures,
		(selectedState: FeatureFlagState) => selectedState[flag],
	);
