import { Action, createReducer, on } from '@ngrx/store';

import { ImmutableCollectionsService } from '@ccap/au-pair/utils';
import {
	IAnswers,
	IMediaFile,
	IQuestionExtended,
	IQuestionListItem,
	IQuestionWithValidation,
} from '@ccap/interfaces';
import {
	ISaveComplianceDocumentsSuccessPayload,
	ISaveQuestionsMediaFileSuccessPayload,
} from '../interfaces';
import {
	getValidationErrors,
	IValidationError,
} from '../utils/questions-validation.utils';
import {
	derivedQuestionState,
	getFollowUpChain,
	getParentQuestion,
	getQuestionAndNestedQuestion,
	getUpdatedParentQuestionWithChild,
	isQuestionEnabled,
} from '../utils/questions.utils';
import {
	getAnswersSuccess,
	getCoursesAnswersSuccess,
	getExtensionAnswersSuccess,
	getExtensionQuestionsSuccess,
	getQuestionsSuccess,
	getTravelAnswersSuccess,
	getTravelQuestionsSuccess,
	saveAnswersLocal,
	saveComplianceDocumentError,
	saveComplianceDocumentSuccess,
	saveCourseFile,
	saveCourseFileSuccess,
	saveMediaFile,
	saveMediaFileError,
	saveMediaFileSuccess,
} from './questions.actions';
import { ISchema } from './questions.effects';
import { initialQuestionsState, QuestionsState } from './questions.state';

const reducer = createReducer(
	initialQuestionsState,

	on(
		getQuestionsSuccess,
		getTravelQuestionsSuccess,
		getExtensionQuestionsSuccess,
		(state, { questions }) => {
			const updatedQuestionsState = updatedQuestions(state, questions, false);
			return { ...state, ...updatedQuestionsState };
		},
	),

	on(
		getTravelAnswersSuccess,
		getExtensionAnswersSuccess,
		(state, { answers }) => {
			const questions = Object.keys(state)
				.filter(key => typeof answers[key] !== 'undefined')
				.map(key => ({ ...state[key], answer: answers[key] }));
			return reduceQuestions(state, questions, false);
		},
	),

	on(getCoursesAnswersSuccess, (state, { answers }) => {
		const updatedExtensionCourses = {
			...state.extensionCourses,
			answer: answers,
		};

		const questions = [updatedExtensionCourses];
		return reduceQuestions(state, questions, false);
	}),

	on(getAnswersSuccess, (state, action) => {
		const [
			personalReferences,
			childcareReferences,
			imagesResponse,
			videosResponse,
			interview,
			aupairs,
			profile,
			workExperience,
			health,
			personalityTestAnswers,
			complianceDocs,
			contactDetails,
			unitedStatesContactDetails,
			parents,
		] = action.answers;

		const groupedFiles = groupFilesByKey(complianceDocs);
		const groupedImgs = groupFilesByKey(imagesResponse);
		const groupedVids = groupFilesByKey(videosResponse);
		const mediaObjects = { ...groupedImgs, ...groupedVids, ...groupedFiles };

		const answers: IAnswers = {
			personalReferences,
			childcareReferences,
			interview,
			...personalityTestAnswers,
			...aupairs,
			...profile,
			...health,
			...contactDetails,
			...workExperience,
			...mediaObjects,
			parents,
			unitedStatesPhone: unitedStatesContactDetails['unitedStatesContactPhone'],
		};

		const questionsWithAnswers = Object.keys(state)
			.filter(key => {
				const questionFromState = state[key];

				const isQuestionListItem = () =>
					questionFromState.typeVariations &&
					questionFromState.typeVariations.questionListItems &&
					questionFromState.typeVariations.questionListItems.find(
						questionItem => {
							return !!answers[questionItem.id];
						},
					);

				return (
					typeof answers[key] !== 'undefined' ||
					(state[key].type === 'questionList' && isQuestionListItem())
				);
			})
			.map(questionId => {
				const questionFromState = state[questionId];
				const answer = answers[questionId];

				if (questionFromState.type !== 'questionList') {
					return { ...questionFromState, answer };
				}

				const questionsItemsWithUploadAnswers = questionFromState.typeVariations.questionListItems.filter(
					questionItem => {
						return (
							questionItem.type === 'fileUpload' && !!answers[questionItem.id]
						);
					},
				);

				if (questionsItemsWithUploadAnswers.length === 0) {
					return { ...questionFromState, answer };
				}

				return getParentQuestionsWithNestedUploadAnswers(
					state,
					questionId,
					questionsItemsWithUploadAnswers,
					answers,
				);
			});

		return reduceQuestions(state, questionsWithAnswers, false);
	}),

	on(saveAnswersLocal, (state, { questions }) => {
		const updatedState = updateSmokingAgreementAcceptedDateTimeUtc(
			state,
			questions,
		);

		const stateWithUpdatedUsMobileNumber = updateUsMobileNumberQuestion(
			updatedState,
			questions,
		);

		return reduceQuestions(stateWithUpdatedUsMobileNumber, questions, true);
	}),

	on(saveMediaFile, (state, { question: { id: questionID } }) => {
		const { question, nestedQuestion } = getQuestionAndNestedQuestion(
			questionID,
			state,
		);

		if (nestedQuestion) {
			const newQuestion = getUpdatedParentQuestionWithChild(
				{
					...(nestedQuestion as any),
					uploadingFilesAmount: (nestedQuestion.uploadingFilesAmount || 0) + 1,
				},
				state,
			);

			return { ...state, [question.id]: newQuestion };
		}

		return {
			...state,
			[question.id]: {
				...state[questionID],
				uploadingFilesAmount: (question.uploadingFilesAmount || 0) + 1,
			},
		};
	}),

	on(saveMediaFileSuccess, (state, action) => {
		const questionID = action.questionID;

		const { question, nestedQuestion } = getQuestionAndNestedQuestion(
			questionID,
			state,
		);

		return {
			...state,
			[question.id]: addedMediaFileToQuestion(
				action,
				question,
				state,
				nestedQuestion,
				action.answerIdx,
			),
		};
	}),

	on(saveComplianceDocumentSuccess, (state, action) => {
		return updateComplianceDocumentState(state, action);
	}),

	on(saveComplianceDocumentError, (state, { questionID }) => {
		return updateMediaFileStateWithError(state, questionID);
	}),

	on(saveCourseFile, (state, action) => {
		const questionID = action.question.id;

		const { question, nestedQuestion } = getQuestionAndNestedQuestion(
			questionID,
			state,
		);

		const newQuestion = getUpdatedParentQuestionWithChild(
			{
				...(nestedQuestion as any),
				uploadingFilesAmount: (nestedQuestion.uploadingFilesAmount || 0) + 1,
			},
			state,
		);

		return { ...state, [question.id]: newQuestion };
	}),

	on(saveCourseFileSuccess, (state, action) => {
		const questionID = action.questionID;

		const { question, nestedQuestion } = getQuestionAndNestedQuestion(
			questionID,
			state,
		);

		return {
			...state,
			[question.id]: addedCourseMediaFileToQuestion(
				action,
				question,
				state,
				action.answerIdx,
				nestedQuestion,
			),
		};
	}),

	on(saveMediaFileError, (state, { questionID }) => {
		const { nestedQuestion } = getQuestionAndNestedQuestion(questionID, state);

		if (nestedQuestion) {
			const nestedQuestionUpdated = {
				...nestedQuestion,
				uploadingFilesAmount: (nestedQuestion as any).uploadingFilesAmount - 1,
				...derivedQuestionState(true, nestedQuestion as any),
			};

			const newParentQuestion = getUpdatedParentQuestionWithChild(
				nestedQuestionUpdated as any,
				state,
			);

			return {
				...state,
				[questionID]: { ...newParentQuestion, touched: true },
			};
		}

		return updateMediaFileStateWithError(state, questionID);
	}),
);

export function questionsReducer(
	state: QuestionsState | undefined,
	action: Action,
) {
	return reducer(state, action);
}

function getParentQuestionsWithNestedUploadAnswers(
	state: QuestionsState,
	questionId: string,
	questionsItemsWithAnswers: IQuestionListItem[],
	answers: IAnswers,
) {
	const parentQuestion = state[questionId];
	const question = questionsItemsWithAnswers.reduce(
		(prevQuestion, questionListItem) => {
			if (questionListItem) {
				return getQuestionWithCombinedAnswers(
					answers,
					questionListItem,
					parentQuestion,
					prevQuestion,
				);
			}
		},
		parentQuestion,
	);

	return { ...question };
}

function getQuestionWithCombinedAnswers(
	answers: IAnswers,
	questionListItem: IQuestionListItem,
	parentQuestion: IQuestionWithValidation,
	prevQuestion: IQuestionWithValidation,
) {
	const nestedQuestionAnswer = answers[questionListItem.id];
	const questionUpdatedWithAnswer = (nestedQuestionAnswer as IMediaFile[]).reduce(
		(prev: IQuestionWithValidation, file) => {
			// TODO: instead of subkey it should this from the courses document endpoint
			if (file.subkey === undefined || file.subkey === null) {
				/* eslint-disable-next-line no-console */
				console.warn(`File ${file.fileName} has no subkey`);
				return { ...prev };
			}
			const populatedParentQuestion = getParentQuestionWithPopulatedAnswers(
				parentQuestion,
				file,
				questionListItem,
			);
			return { ...prev, answer: populatedParentQuestion.answer };
		},
		prevQuestion,
	);
	return { ...prevQuestion, ...questionUpdatedWithAnswer };
}

function getParentQuestionWithPopulatedAnswers(
	parentQuestion: IQuestionWithValidation,
	file: IMediaFile,
	questionListItem: IQuestionListItem,
) {
	let populatedQuestion = { ...parentQuestion };
	const idxExistsForFileSubkey =
		populatedQuestion.answer && parentQuestion.answer[file.subkey];

	if (!idxExistsForFileSubkey) {
		populatedQuestion = fillEmptyMissingAnswers(populatedQuestion, file);
	}

	const questionFileAnswer =
		populatedQuestion.answer[file.subkey][questionListItem.id] || [];

	populatedQuestion.answer[file.subkey][questionListItem.id] = [
		...questionFileAnswer,
		file,
	];

	return populatedQuestion;
}

function fillEmptyMissingAnswers(
	parentQuestion: IQuestionWithValidation,
	file: IMediaFile,
) {
	const prefilledQuestion = {
		...parentQuestion,
		answer: parentQuestion.answer || [],
	};

	const answerLength = Array.isArray(prefilledQuestion.answer)
		? prefilledQuestion.answer.length
		: 0;

	const nrOfanswersToCreate = +file.subkey + 1 - answerLength;
	for (let index = 0; index < nrOfanswersToCreate; index++) {
		(prefilledQuestion.answer as any[]) = [
			...(prefilledQuestion.answer as any[]),
			{},
		];
	}

	return prefilledQuestion;
}

function reduceQuestions(
	state: QuestionsState,
	questions: IQuestionWithValidation[],
	touched = false,
) {
	const updatedState = {
		...state,
		...updatedQuestions(state, questions, touched),
	};

	const followups = getFollowUpChain(updatedState, questions);

	return {
		...updatedState,
		...updatedQuestions(updatedState, followups, touched),
	};
}

function updatedQuestions(
	state: QuestionsState,
	questions: IQuestionExtended[],
	touched: boolean,
): QuestionsState {
	const questionsObject = questions.reduce(
		(acc, q) => ({ ...acc, [q.id]: q as IQuestionWithValidation }),
		{} as ISchema,
	);

	return questions.reduce(
		(acc: QuestionsState, question: IQuestionExtended) => {
			const updatedQuestion = { ...question };
			const isEnabled = isQuestionEnabled(
				state,
				updatedQuestion,
				questionsObject,
			);

			let errors = isEnabled ? getValidationErrors(updatedQuestion) : null;

			if (updatedQuestion.type === 'fileUpload') {
				((updatedQuestion.answer || []) as IMediaFile[]).forEach(
					(file: IMediaFile) => {
						errors = {
							...errors,
							...evaluateFileMetaData(
								updatedQuestion.typeVariations.questionListItems,
								file,
							),
						};
					},
				);
				errors = Object.keys(errors).length === 0 ? null : errors;
			}

			const prevQuestion = state[updatedQuestion.id];
			if (
				updatedQuestion.type === 'questionList' &&
				prevQuestion &&
				!!prevQuestion.answer
			) {
				let idx = 0;
				// TODO: ensure that updatedQuestions is not getting called multiple times in the first place
				updatedQuestion.answer = (
					(updatedQuestion.answer as any[]) || []
				).reduce((prev, answer) => {
					return [...prev, { ...prevQuestion.answer[idx++], ...answer }];
				}, []);
			}

			const parent = getParentQuestion(state, updatedQuestion, acc);
			const persistNodeQuestions =
				parent &&
				parent.typeVariations &&
				parent.typeVariations.persistNodeQuestions;

			acc[updatedQuestion.id] = {
				...updatedQuestion,
				answer:
					isEnabled || persistNodeQuestions ? updatedQuestion.answer : null,
				valid: !errors,
				enabled: isEnabled,
				errors,
				touched,
			};

			return acc;
		},
		{},
	);
}

function evaluateFileMetaData(
	questionListItems: IQuestionListItem[],
	file: IMediaFile,
): IValidationError {
	return questionListItems.reduce(
		(errors: IValidationError, item: IQuestionListItem) => {
			const meta = { ...item, answer: file.meta[item.id] } as any;
			const err = getValidationErrors(meta);
			errors = { ...errors, ...err };
			return errors;
		},
		{},
	);
}

function addMediaFilesToQuestion(
	files: IMediaFile[],
	question: IQuestionWithValidation,
): IQuestionWithValidation {
	const updatedQuestion = { ...question, answer: files };

	let errors = {
		...getValidationErrors(updatedQuestion),
		...evaluateFileMetaData(
			question.typeVariations.questionListItems,
			files[0],
		),
	};
	errors = Object.keys(errors).length === 0 ? null : errors;

	const updatedQuestionWithErrors = {
		...updatedQuestion,
		valid: !errors,
		enabled: true,
		touched: true,
		errors,
	};

	return {
		...updatedQuestionWithErrors,
		uploadingFilesAmount: updatedQuestion.uploadingFilesAmount - 1,
	};
}

function addedMediaFileToQuestion(
	payload: ISaveQuestionsMediaFileSuccessPayload,
	quest: IQuestionWithValidation,
	state: QuestionsState,
	nestedQuest?: IQuestionListItem,
	answerIdx?: number,
): IQuestionWithValidation {
	let updatedQuestion;
	if (nestedQuest) {
		quest.answer[answerIdx][nestedQuest.id] =
			quest.answer[answerIdx][nestedQuest.id] || [];
		quest.answer[answerIdx][nestedQuest.id].push(payload.file);
		updatedQuestion = { ...quest, answer: quest.answer };
	} else {
		const newAnswer = quest.answer
			? [...(quest.answer as any[]), payload.file]
			: [payload.file];
		updatedQuestion = { ...quest, answer: newAnswer };
	}

	let errors = {
		...getValidationErrors(updatedQuestion),
		...evaluateFileMetaData(
			quest.typeVariations.questionListItems,
			payload.file,
		),
	};
	errors = Object.keys(errors).length === 0 ? null : errors;

	const updatedQuestionWithErrors = {
		...updatedQuestion,
		valid: !errors,
		enabled: true,
		touched: true,
		errors,
	};

	if (nestedQuest) {
		const newQuestion = getUpdatedParentQuestionWithChild(
			{
				...(nestedQuest as any),
				uploadingFilesAmount: nestedQuest.uploadingFilesAmount - 1,
			},
			state,
		);

		return {
			...updatedQuestionWithErrors,
			...newQuestion,
		};
	}

	return {
		...updatedQuestionWithErrors,
		uploadingFilesAmount: updatedQuestion.uploadingFilesAmount - 1,
	};
}

function addedCourseMediaFileToQuestion(
	payload: ISaveQuestionsMediaFileSuccessPayload,
	quest: IQuestionWithValidation,
	state: QuestionsState,
	answerIdx: number,
	nestedQuest?: IQuestionListItem,
): IQuestionWithValidation {
	const newCourseAnswer = {
		...quest.answer[answerIdx],
		[nestedQuest.id]: quest.answer[answerIdx][nestedQuest.id] || [],
	};

	const newCourseWithFile = {
		...newCourseAnswer,
		[nestedQuest.id]: [...newCourseAnswer[nestedQuest.id], payload.file],
		courseDocuments: [...newCourseAnswer.courseDocuments, payload.file],
	};

	const newAnswer = ImmutableCollectionsService.updateObjectInArray(
		quest.answer,
		{ index: answerIdx, item: newCourseWithFile },
	);

	const updatedQuestion = { ...quest, answer: newAnswer };

	let errors = {
		...getValidationErrors(updatedQuestion),
	};
	errors = Object.keys(errors).length === 0 ? null : errors;

	const updatedQuestionWithErrors = {
		...updatedQuestion,
		valid: !errors,
		enabled: true,
		touched: true,
		errors,
	};

	const newQuestion = getUpdatedParentQuestionWithChild(
		{
			...(nestedQuest as any),
			uploadingFilesAmount: nestedQuest.uploadingFilesAmount - 1,
		},
		state,
	);

	return {
		...updatedQuestionWithErrors,
		typeVariations: newQuestion.typeVariations,
	};
}

function groupFilesByKey(files: IMediaFile[]) {
	return (files ? files : []).reduce((acc, file: IMediaFile) => {
		if (!Array.isArray(acc[file.key])) {
			acc[file.key] = [];
		}
		acc[file.key].push(file);

		return acc;
	}, {});
}

/**
 * Manually keep optional and required US mobile number questions in sync with each other.
 */
function updateUsMobileNumberQuestion(
	state: QuestionsState,
	questions: IQuestionWithValidation[],
): QuestionsState {
	const optionalQuestion = questions.find(
		({ id }) => id === 'unitedStatesPhone',
	);

	if (optionalQuestion && optionalQuestion.answer) {
		return {
			...state,
			unitedStatesContactPhone: {
				...state.unitedStatesContactPhone,
				answer: optionalQuestion.answer,
			},
		};
	}

	const mandatoryQuestion = questions.find(
		({ id }) => id === 'unitedStatesContactPhone',
	);

	if (mandatoryQuestion && mandatoryQuestion.answer) {
		return {
			...state,
			unitedStatesPhone: {
				...state.unitedStatesPhone,
				answer: mandatoryQuestion.answer,
			},
		};
	}

	return state;
}

/**
 * saveAnswersRemoteSuccess does not update the store,
 * thus we need to manually add the time when agreement has been accepted.
 *
 * smokingAgreementAcceptedDateTimeUtc is only a question because currently we only
 * have access to data that belong to a question if it's part of this reducer.
 * But since it is not actually a question being answered, the data is updated by BE only.
 *
 * @see: https://culturalcare.atlassian.net/browse/PS-38 problem #5 after QA
 * @TODO: remove when we update store after saveAnswersRemoteSuccess
 */
function updateSmokingAgreementAcceptedDateTimeUtc(
	state: QuestionsState,
	questions: IQuestionWithValidation[],
): QuestionsState {
	const isSmokingQuestionBeingAccepted = Boolean(
		questions.find(
			({ id, answer }) =>
				id === 'smoking' && answer && (answer as any).smokingAgreementAccepted,
		),
	);

	const { smokingAgreementAcceptedDateTimeUtc } = state;

	if (isSmokingQuestionBeingAccepted && smokingAgreementAcceptedDateTimeUtc) {
		const updatedQuestion = {
			...smokingAgreementAcceptedDateTimeUtc,
			answer: new Date().toISOString(),
		};

		return {
			...state,
			smokingAgreementAcceptedDateTimeUtc: updatedQuestion,
		};
	}

	return state;
}

function updateComplianceDocumentState(
	state: QuestionsState,
	action: ISaveComplianceDocumentsSuccessPayload,
): QuestionsState {
	const questionID = action.questionID;

	const { question } = getQuestionAndNestedQuestion(questionID, state);

	return {
		...state,
		[question.id]: addMediaFilesToQuestion(action.files, question),
	};
}

function updateMediaFileStateWithError(
	state: QuestionsState,
	questionID: string,
): QuestionsState {
	return {
		...state,
		[questionID]: {
			...state[questionID],
			uploadingFilesAmount: state[questionID].uploadingFilesAmount - 1,
			...derivedQuestionState(true, state[questionID]),
			touched: true,
		},
	};
}
