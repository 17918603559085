import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { CheckboxListWithOtherAnswer } from '@ccap/question-engine/models';

export const checkboxListWithOtherValidator: ValidatorFn = (
	control: FormControl,
): ValidationErrors => {
	const errors: Record<string, boolean> = {};
	const value: CheckboxListWithOtherAnswer = control.value;
	const isAnswered = Object.values(value?.choices ?? {}).some(val => val);

	if (!isAnswered) {
		errors.required = true;
	} else if (
		value.choices.other &&
		(!value.other || !String(value.other).trim().length)
	) {
		errors.otherDescriptionRequired = true;
	}

	return Object.keys(errors).length ? errors : null;
};
