import { Action, createReducer, on } from '@ngrx/store';

import { ExtensionCourseDto } from '../../interfaces';
import {
	addExtensionCourse,
	addExtensionCourseDocument,
	patchExtensionCourse,
	removeExtensionCourse,
	removeExtensionCourseDocument,
	updateExtensionCourses,
} from './course.actions';

export type CoursesState = ExtensionCourseDto[];

const reducer = createReducer<CoursesState>(
	null,
	on(updateExtensionCourses, (_, { courses }) => courses),
	on(addExtensionCourse, (state, { course }) => [...state, course]),
	on(patchExtensionCourse, (state, { course }) =>
		state ? state.map(c => (c.id === course.id ? course : c)) : state,
	),
	on(removeExtensionCourse, (state, { courseId }) =>
		state ? state.filter(course => course.id !== courseId) : state,
	),
	// course documents
	on(addExtensionCourseDocument, (state, { courseId, document }) =>
		state
			? state.map(course =>
					course.id === courseId
						? {
								...course,
								courseDocuments: [...course.courseDocuments, document],
						  }
						: course,
			  )
			: state,
	),
	on(removeExtensionCourseDocument, (state, { courseId, documentId }) =>
		state
			? state.map(course =>
					course.id === courseId
						? {
								...course,
								courseDocuments: course.courseDocuments.filter(
									document => document.id !== documentId,
								),
						  }
						: course,
			  )
			: state,
	),
);

export function coursesReducer(
	state: CoursesState,
	action: Action,
): CoursesState {
	return reducer(state, action);
}
