import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MoreLessComponent } from './more-less.component';
import { ShowLessComponent } from './show-less/show-less.component';
import { ShowMoreComponent } from './show-more/show-more.component';

@NgModule({
	declarations: [ShowMoreComponent, ShowLessComponent, MoreLessComponent],
	exports: [ShowMoreComponent, ShowLessComponent, MoreLessComponent],
	imports: [CommonModule],
})
export class MoreLessModule {}
