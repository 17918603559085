import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileService } from './profile.service';

@Injectable({
	providedIn: 'root',
})
export class PublicIdInterceptor implements HttpInterceptor {
	constructor(private profileService: ProfileService) {}

	public intercept(
		oldReq: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (oldReq.method !== 'GET') {
			return next.handle(oldReq);
		}

		const userId = this.profileService.getCurrentProfileId();
		const req = oldReq.clone({
			setParams: userId ? { publicId: userId } : {},
		});

		return next.handle(req);
	}
}
