import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LoginType } from '@ccap/au-pair/data-access/models';
import { IQuestionWithValidation } from '@ccap/interfaces';
import { getAccountData } from '../../account/store/account.selectors';
import {
	getQuestionAndNestedQuestion,
	isQuestionItemVisible,
} from '../utils/questions.utils';
import { QuestionsState } from './questions.state';

export const selectQuestions = createSelector(
	createFeatureSelector<QuestionsState>('questions'),
	getAccountData,
	(schema, account): QuestionsState => {
		const { email, ...restSchema } = schema;

		if (account && account.loginType === LoginType.Auth0) {
			return restSchema;
		}

		return { ...restSchema, email };
	},
);

export const selectQuestionsFromIDs = (iDList: string[]) =>
	createSelector(selectQuestions, (selectedState: QuestionsState) =>
		iDList.map((id: string) => selectedState[id]).filter(Boolean),
	);

export const selectQuestionsAreLoaded = (iDList: string[]) =>
	createSelector(selectQuestions, (selectedState: QuestionsState) =>
		iDList.every((id: string) => !!selectedState[id]),
	);

export const getQuestionsAreValid = (iDList: string[]) =>
	createSelector(selectQuestions, (selectedState: QuestionsState) =>
		iDList.every((id: string) => {
			if (!Object.keys(selectedState).length) {
				return false;
			}

			if (!selectedState[id]) {
				return true;
			}

			return selectedState[id].enabled ? selectedState[id].valid : true;
		}),
	);

export const selectQuestionsAreTouched = (iDList: string[]) =>
	createSelector(selectQuestions, (selectedState: QuestionsState) =>
		iDList.some((id: string) =>
			selectedState[id] ? selectedState[id].touched : false,
		),
	);

export const selectEnabledQuestionsFromIDs = (iDList: string[]) =>
	createSelector(
		selectQuestionsFromIDs(iDList),
		(questions: IQuestionWithValidation[]) =>
			questions.filter((question: IQuestionWithValidation) =>
				question ? question.enabled : false,
			),
	);

export const getIsQuestionItemVisible = (
	questionId: string,
	currentAnswerIndex: number,
) =>
	createSelector(selectQuestions, questions =>
		isQuestionItemVisible(questions, questionId, currentAnswerIndex),
	);

export const getParentAndNestedQuestions = (questionId: string) =>
	createSelector(selectQuestions, questions =>
		getQuestionAndNestedQuestion(questionId, questions),
	);

export const getFirstName = createSelector(
	selectQuestionsFromIDs(['firstName']),
	questions => (questions[0] ? (questions[0].answer as string) : ''),
);
