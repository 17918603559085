<ng-container *ngIf="ready$ | async; else loading">
	<button
		efButton
		[primary]="true"
		[square]="true"
		type="button"
		[disabled]="!(buttonEnabled$ | async)"
		(click)="connectToggle()"
	>
		{{ buttonText$ | async }}
	</button>
</ng-container>

<ng-template #loading>
	<ccap-spinner></ccap-spinner>
</ng-template>
