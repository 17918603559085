<ccap-question-answer-group title="Transition">
	<ccap-question-answer question="Date of transition">
		{{ transitionDetails.transitionDate | date: 'dd MMMM yyyy' }}
	</ccap-question-answer>

	<ccap-question-answer question="English level">
		{{ transitionDetails.englishLevel }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Driving info">
	<ccap-question-answer
		question="1) Driving with host children\n2) Car accident while in the U.S.?\n3) State license"
	>
		{{ transitionDetails.drivingInformation }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Reasons for transition">
	<ccap-question-answer
		question="1) Host Family perspective\n2) Au Pair perspective\n3) LCC perspective\n4) PD perspective (reason for standing behind Au Pair)"
	>
		{{ transitionDetails.transitionReason }}
	</ccap-question-answer>

	<ccap-question-answer question="Who initiated the transition">
		{{ transitionDetails.transitionInitiatedBy }}
	</ccap-question-answer>

	<ccap-question-answer
		question="Who is recommending the Au Pair for the placement?"
	>
		{{ transitionDetails.placementRecommendationBy }}
	</ccap-question-answer>

	<ccap-question-answer
		question="When is the Au Pair available to go to a new Host Family?"
	>
		{{ transitionDetails.availableToMoveInDate | date: 'dd MMMM yyyy' }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Host Family Description">
	<ccap-question-answer
		question="1) Location\n2) Ages of kids\n3) Single parent\n4) Working from home parent"
	>
		{{ transitionDetails.hostFamilyDescription }}
	</ccap-question-answer>

	<ccap-question-answer question="How many days of vacation has Au Pair taken?">
		{{ transitionDetails.takenVacationDays }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Educational component">
	<ccap-question-answer question="Credits/hours completed">
		{{ transitionDetails.educationCreditsOrHours }}
	</ccap-question-answer>

	<ccap-question-answer question="Amount paid by Host Family">
		{{ transitionDetails.educationDollarsPaid }}
	</ccap-question-answer>

	<ccap-question-answer question="Note">
		{{ transitionDetails.educationNote }}
	</ccap-question-answer>

	<ccap-question-answer question="Must be placed by">
		{{ transitionDetails.mustBePlacedBy | date: 'dd MMMM yyyy' }}
	</ccap-question-answer>
</ccap-question-answer-group>

<ccap-question-answer-group title="Additional transition details">
	<ccap-question-answer
		question="1) Au Pair gateway\n2) Au Pair regional requests\n3) Cell phone\n4) Can the Au Pair leave early?"
	>
		{{ transitionDetails.additionalDetails }}
	</ccap-question-answer>
</ccap-question-answer-group>
