import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Auth0DecodedHash } from 'auth0-js';

import { MonitoringService } from '@ccap/shared/utils';
import { AuthConfig } from './auth-config-interface';
import { AUTH_CONFIG } from './auth-injection-token';
import { Auth0Service } from './auth0/auth0.service';
import { CookieService } from './cookie.service';
import { AuthRoles, userHasRoles } from './auth-role';

export enum StorageKey {
	accessToken = 'access-token',
	token = 'token',
	expiresAt = 'expires-at',
}

export enum CookieKey {
	JwtId = 'JwtId',
	PersonId = 'PersonId',
}

@Injectable()
export class AuthService {
	constructor(
		@Inject(AUTH_CONFIG) private config: AuthConfig,
		@Inject(DOCUMENT) private document: Document,
		private auth0Service: Auth0Service,
		private cookieService: CookieService,
		private monitoringService: MonitoringService,
	) {}

	public async showSignInForm(): Promise<void> {
		await this.auth0Service.show();
	}

	/**
	 * Handles the second phase of authentication where the provider redirects to the configured
	 * URL and sets the authentication info on the URL fragment. The validated info is persisted
	 * to local storage.
	 */
	public async handleAuthentication(): Promise<void> {
		try {
			const auth0Response = await this.auth0Service.resumeAuthentication();

			this.setSession(auth0Response);
		} catch (err) {
			this.monitoringService.logError(err);
		}
	}

	/**
	 * Return true of the auth token has not yet expired.
	 */
	public isAuthenticated(): boolean {
		const expiresAtStr = localStorage.getItem(StorageKey.expiresAt);
		const expiresAt = expiresAtStr && JSON.parse(expiresAtStr);
		const now = Date.now() / 1000;

		return expiresAt >= now;
	}

	/**
	 * Return the value that should be used for the HTTP Authorization header to make
	 * authorized HTTP requests.
	 */
	public getAuthorizationHeader(): string {
		return 'Bearer ' + localStorage.getItem(StorageKey.token);
	}

	public logout(): void {
		if (userHasRoles([AuthRoles.AuPair])) {
			this.cookieService.erase(CookieKey.JwtId);
			this.cookieService.erase(CookieKey.PersonId);
		}

		localStorage.removeItem(StorageKey.accessToken);
		localStorage.removeItem(StorageKey.token);
		localStorage.removeItem(StorageKey.expiresAt);

		this.singleSignOut();
	}

	public scheduleTokenRefresh(): void {
		const expiresAt = JSON.parse(localStorage.getItem(StorageKey.expiresAt));

		const refreshAt = expiresAt * 1000 - 30000; // Refresh 30 seconds before expiry
		setTimeout(() => {
			this.renewToken();
		}, refreshAt - Date.now());
	}

	public async renewToken(): Promise<void> {
		try {
			const session = await this.auth0Service.renewAuthToken();
			this.setSession(session);
			this.scheduleTokenRefresh();
		} catch (err) {
			this.monitoringService.logError(err);
		}
	}

	private setSession(res: Auth0DecodedHash): void {
		localStorage.setItem(StorageKey.accessToken, res.accessToken);
		localStorage.setItem(StorageKey.token, res.idToken);

		const expiresAt = JSON.stringify(res.idTokenPayload.exp);
		localStorage.setItem(StorageKey.expiresAt, expiresAt);

		if (userHasRoles([AuthRoles.AuPair])) {
			this.cookieService.create(CookieKey.JwtId, res.idToken);
			this.cookieService.create(CookieKey.PersonId, 'PersonType=AP');
		}
	}

	private singleSignOut(): void {
		this.document.location.href =
			'https://' +
			this.config.clientOptions.domain +
			'/v2/logout?returnTo=' +
			encodeURIComponent(this.config.matchLoginUrl);
	}
}
