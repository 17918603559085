import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@ccap/ef-ui/components';
import { SpinnerModule } from '@ccap/shared/ui/indicators';
import { ConnectComponent } from './connect.component';

@NgModule({
	imports: [CommonModule, SpinnerModule, ButtonModule],
	declarations: [ConnectComponent],
	exports: [ConnectComponent],
})
export class ConnectModule {}
