<div class="progress-bar" [ngClass]="containerSizeClass">
	<div
		[ccapE2EId]="'progress-bar-buffer'"
		class="progress-bar__buffer"
		[style.width.%]="percentage"
		[ngClass]="bufferColorClass"
	></div>
	<span
		[ccapE2EId]="'progress-bar-percentage'"
		class="progress-bar__text"
		[ngClass]="getTextClass()"
	>
		{{ percentage }}%
	</span>
</div>
