import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AbstractModalBase } from './abstract-modal-base';
import { ModalMainComponent } from './modal-main.component';

/**
 * @whatItDoes
 * Creates and removes the ModalMainComponent
 * and passes a  derived instance (or multiple instances) of the AbstractModalBase.
 *
 * When rendered, the component is related to the top-most DOM element of the modal (modal-root)
 */

@Component({
	template: '<ng-template #element></ng-template>',
	styleUrls: ['modal.scss'],
})
export class ModalRootComponent {
	// Array to hold multiple modals.
	public modals: AbstractModalBase[] = [];

	// Target element to insert modal.
	@ViewChild('element', { read: ViewContainerRef, static: true })
	private _element?: ViewContainerRef;

	constructor(private resolver: ComponentFactoryResolver) {}

	/**
	 * Adds a modal
	 */
	public addModal(
		component: Type<AbstractModalBase>,
		data?: any,
	): BehaviorSubject<boolean> {
		const factory: ComponentFactory<ModalMainComponent> = this.resolver.resolveComponentFactory(
			ModalMainComponent,
		);
		const componentRef: ComponentRef<ModalMainComponent> = this._element.createComponent(
			factory,
		);
		const modalMainComponent: ModalMainComponent = componentRef.instance as ModalMainComponent;
		const _component: AbstractModalBase = modalMainComponent.addComponent(
			component,
		);

		this.modals.push(_component);

		setTimeout(() => {
			modalMainComponent.show();
		});

		return _component.setInputsToProperties(data);
	}

	// Removes open modal.
	public removeModal(component: AbstractModalBase) {
		component.modalMainComponent.hide();
		const index = this.modals.indexOf(component);
		if (index > -1) {
			this._element.remove(index);
			this.modals.splice(index, 1);
		}
	}

	// Removes all multiple opened modals.
	public removeAllModals() {
		this.modals.forEach((modal: AbstractModalBase) => {
			this.removeModal(modal);
		});
	}

	public set element(value: ViewContainerRef) {
		this._element = value;
	}
}
