import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'ccap-question-answer-group',
	templateUrl: './question-answer-group.component.html',
	styleUrls: ['./question-answer-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionAnswerGroupComponent {
	@Input()
	public title?: string;
}
