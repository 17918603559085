<div class="modal-card">
	<h4 class="ef-h5 u-text-center">Connect</h4>

	<p>
		Please write a few words to {{ payload.name }} to accompany this connect
		request
	</p>

	<ng-container [formGroup]="formSteps[0]">
		<ccap-neo-textarea
			placeholder="Type your answer here"
			[id]="questions[0]?.id"
			[formControlName]="questions[0]?.id"
			[maxlength]="questions[0]?.typeVariations?.maxLength"
		></ccap-neo-textarea>
	</ng-container>

	<ccap-modal-actions
		[disabledSaveAndContinue]="!isAllQuestionsValid()"
		[saveButtonText]="'Proceed'"
		(cancelAction)="removeModal()"
		(saveAction)="completeForm()"
	></ccap-modal-actions>
</div>
