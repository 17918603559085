import {
	FormControl,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

import { isAfter } from 'date-fns';

import { DateUtils } from '@ccap/shared/utils';

import { DateValue } from '@ccap/interfaces';

import {
	EmbassyAppointment,
	VisaAppointmentStatus,
} from '@ccap/au-pair/data-access/models';
import {
	IQuestionConditionalAgreement,
	IQuestionExtended,
	IReferenceBody,
} from '@ccap/interfaces';
import {
	addressAutocompleteValidator,
	checkboxListWithOtherValidator,
	datepickerValidator,
	phoneNumberRequiredValidator,
	phoneNumberValidator,
} from '@ccap/question-engine/forms';
import { TypeChecker } from '@ccap/shared/utils';

// TODO: make most public methods private
export class ValidationService {
	public static educationDeadline: Date | undefined;

	public static getQuestionValidators(
		question: IQuestionExtended,
	): ValidatorFn {
		const result: ValidatorFn[] = [];

		switch (question.type) {
			case 'addressAutocomplete': {
				result.push(addressAutocompleteValidator);
				break;
			}
		}

		for (const validation in question.validations) {
			if (question.validations[validation] !== false) {
				switch (validation) {
					case 'required':
						if (question.type === 'conditionalAgreement') {
							result.push(this.requiredConditionalAgreement(question as any));
						}

						result.push(Validators.required, this.noWhitespaceValidator);
						break;
					case 'minLength':
						result.push(
							Validators.minLength(question.validations[validation] as number),
						);
						break;
					case 'maxLength':
						result.push(
							Validators.maxLength(question.validations[validation] as number),
						);
						break;
					case 'min':
						result.push(
							Validators.min(question.validations[validation] as number),
						);
						break;
					case 'minChecked':
						result.push(
							this.minChecked(question.validations[validation] as number),
						);
						break;
					case 'email':
						result.push(this.emailValidator);
						break;
					case 'listMinLength':
						result.push(
							this.listMinLength(question.validations[validation] as number),
						);
						break;
					case 'listMaxLength':
						result.push(
							this.listMaxLength(question.validations[validation] as number),
						);
						break;
					case 'minApprovedOrPendingReference':
						result.push(
							this.minApprovedOrPendingReference(
								question.answer as IReferenceBody[],
								question.validations[validation] as number,
							),
						);
						break;
					case 'datepickerValidator':
						result.push(datepickerValidator(question.options));
						break;
					case 'checkboxListWithOtherValidator':
						result.push(checkboxListWithOtherValidator);
						break;
					case 'minNumberOfFiles':
						result.push(
							this.minNumberOfFiles(
								question.answer as any,
								question.validations[validation] as number,
							),
						);
						break;
					case 'phoneNumber':
						result.push(phoneNumberValidator);
						break;
					case 'phoneNumberRequired':
						result.push(phoneNumberRequiredValidator);
						break;
					case 'embassyAppointmentValidator':
						result.push(this.embassyAppointmentValidator);
						break;
					case 'completionDateValidator':
						result.push(this.completionDateValidator);
						break;
					default:
						throw new Error('No map for this validation');
				}
			}
		}

		return Validators.compose(result);
	}

	public static embassyAppointmentValidator(
		control: FormControl,
	): ValidationErrors | null {
		const value: EmbassyAppointment = control.value;

		const statusesWithDate = [
			VisaAppointmentStatus.AppointmentBooked,
			VisaAppointmentStatus.VisaReceived,
			VisaAppointmentStatus.VisaPending,
		];

		const error = statusesWithDate.includes(value?.status)
			? !value?.status || !value?.statusDate
			: !value?.status;

		return error ? { required: true } : null;
	}

	public static completionDateValidator(
		control: FormControl,
	): ValidationErrors | null {
		const value: DateValue = control.value;

		if (
			value !== null &&
			ValidationService.educationDeadline &&
			isAfter(
				DateUtils.getDateFromDateValue(value),
				ValidationService.educationDeadline,
			)
		) {
			return {
				completionDateValidator: {
					deadline: ValidationService.educationDeadline,
					format: 'YYYY-MM-DD',
				},
			};
		}

		return null;
	}

	public static noWhitespaceValidator(
		control: FormControl,
	): ValidationErrors | null {
		const isString = typeof control.value === 'string';
		const hasValue = control.value !== '';

		return isString && hasValue
			? control.value?.trim().length !== 0
				? null
				: { required: true }
			: null;
	}

	public static emailValidator(control: FormControl) {
		if (control.value) {
			return Validators.email(control);
		} else {
			return null;
		}
	}

	public static minNumberOfFiles(
		answers: any[],
		minNumberOfFiles: number,
	): () => ValidationErrors {
		return () => {
			if (Array.isArray(answers)) {
				const error = {
					minNumberOfFiles: {
						minNumberOfFiles,
						actualLength: answers.length,
					},
				};
				return answers.length < minNumberOfFiles ? error : null;
			}
			return { minNumberOfFiles: { minNumberOfFiles, actualLength: 0 } };
		};
	}

	public static listMinLength(minLength: number) {
		return (control: FormControl): Record<string, any> => {
			const value: any[] = control.value;
			const length: number = value ? value.length : 0;

			return length < minLength
				? { listMinlength: { minLength, actualLength: length } }
				: null;
		};
	}

	public static minApprovedOrPendingReference(
		answers: IReferenceBody[],
		minLength: number,
	) {
		return () => {
			const length: number =
				answers && answers.length
					? answers.filter(
							(answer: IReferenceBody) =>
								answer.status === 'Pending' || answer.status === 'Approved',
					  ).length
					: 0;
			return length < minLength
				? {
						minApprovedOrPendingReference: {
							minLength,
							actualLength: length,
						},
				  }
				: null;
		};
	}

	public static minChecked(minimumChecked: number) {
		return (control: FormControl): Record<string, any> => {
			const value: Record<string, boolean> = control.value;
			const numberOfChecked = TypeChecker.isObject(value)
				? Object.keys(value).filter(k => value[k] === true).length
				: 0;

			return numberOfChecked < minimumChecked
				? {
						minChecked: {
							minimumAmountNeededToBeChecked: minimumChecked,
							actualChecked: numberOfChecked,
						},
				  }
				: null;
		};
	}

	public static listMaxLength(maxLength: number) {
		return (control: FormControl): Record<string, any> => {
			const value: any[] = control.value;
			const length: number = value ? value.length : 0;

			return length <= maxLength
				? null
				: { listMaxLength: { maxLength, actualLength: length } };
		};
	}

	private static requiredConditionalAgreement(
		question: IQuestionConditionalAgreement,
	) {
		return (control: FormControl): ValidationErrors | null => {
			const conditionValue =
				control.value && control.value[question.conditionProperty];
			const conditionSet =
				conditionValue !== null && conditionValue !== undefined;

			const valid = conditionSet && control.value[question.agreementProperty];

			return valid ? null : { required: true };
		};
	}
}
