import { Component, HostBinding, Input } from '@angular/core';

export type Color = 'blue' | 'white';

@Component({
	selector: 'ccap-spinner',
	template: `
		<div class="bar"></div>
		<div class="bar bar--2"></div>
		<div class="bar bar--3"></div>
		<div class="bar bar--4"></div>
		<div class="bar bar--5"></div>
	`,
	styleUrls: ['spinner.component.scss'],
})
export class SpinnerComponent {
	@Input() public color: Color = 'blue';

	@HostBinding('class.white')
	public get isWhite(): boolean {
		return this.color === 'white';
	}
}
