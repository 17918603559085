import { HttpErrorResponse } from '@angular/common/http';

export enum AsyncStateStatus {
	Init = 'init',
	InitialLoading = 'initial-loading',
	Loading = 'loading',
	Loaded = 'loaded',
	LoadedWithError = 'loaded-with-error',
}

export interface AsyncState<T> {
	data: T;
	status: AsyncStateStatus;
	error: HttpErrorResponse | null;
}
