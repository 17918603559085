<div class="header" [ngClass]="{ 'header--hidden': hideHeader }">
	<div class="header__content ef-container">
		<img
			class="header__content__logo"
			src="//shared-assets.culturalcare.com/logo/logo-horizontal.svg"
			alt="Cultural Care Au Pair"
		/>

		<div class="header__content__left">
			<app-profile-picture
				[size]="48"
				[thickFrame]="false"
				[profilePhotoUrl]="profilePhotoUrl"
			>
			</app-profile-picture>

			<ccap-busy-status-indicator
				*ngIf="isBusyStatusVisible"
				[minimal]="true"
				[status]="busyStatus"
			>
			</ccap-busy-status-indicator>

			<div class="header__content__details">
				<h4 class="ef-h4 u-m-0">{{ firstName }}</h4>
			</div>
		</div>

		<div class="header__content__right">
			<app-like></app-like>

			<app-connect [name]="fullName"></app-connect>
		</div>
	</div>
</div>
