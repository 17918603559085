import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthConfig } from '../auth-config-interface';
import { AUTH_CONFIG } from '../auth-injection-token';
import { AuthService } from '../auth.service';

/**
 * AuthComponent: host the Sign In form
 * (Note the div element with id="root" is required by Auth0)
 */
@Component({
	templateUrl: 'auth.component.html',
	styleUrls: ['auth.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
	public showSocialMediaLogin$?: Observable<boolean>;
	public socialMediaLoginUrl: string;

	public title: string;
	public subTitleDescription: string;

	private isLoginFormDisplayed$?: Observable<boolean>;

	constructor(
		@Inject(AUTH_CONFIG) private config: AuthConfig,
		private authService: AuthService,
		private route: ActivatedRoute,
	) {}

	public ngOnInit() {
		this.title = this.config.title;
		this.subTitleDescription = this.config.subTitleDescription;
		this.socialMediaLoginUrl = this.config.socialMediaLoginUrl;

		this.authService.showSignInForm();

		this.isLoginFormDisplayed$ = this.route.queryParams.pipe(
			map(({ action }) => action === 'login'),
		);

		this.showSocialMediaLogin$ = combineLatest([
			this.isLoginFormDisplayed$,
			of(this.config.showSocialMediaLogin),
		]).pipe(map(([isLoginForm, isEnabled]) => isLoginForm && isEnabled));
	}
}
