import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'ccap-flag',
	templateUrl: 'flag.component.html',
	styleUrls: ['flag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagComponent {
	@Input() public flagUrl?: string;

	public get backgroundImage() {
		return {
			'background-image': `url("${this.flagUrl}")`,
		};
	}
}
