import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConnectModule } from './connect/connect.module';
import { LikeModule } from './like/like.module';

@NgModule({
	imports: [CommonModule, ConnectModule, LikeModule],
	exports: [ConnectModule, LikeModule],
})
export class LinkModule {}
