import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FooterItem } from '@ccap/shared/ui/navigation';

@Component({
	selector: 'ccap-footer-container',
	templateUrl: './footer-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterContainerComponent {
	public footerItems: FooterItem[] = [
		{
			href: '/legal',
			title: 'Legal Text',
		},
		{
			href: '/privacy-policy',
			title: 'Privacy Policy',
		},
	];
}
