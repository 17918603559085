import { ChangeDetectionStrategy, Component, SkipSelf } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
	selector: 'ccap-custom-control-validation',
	templateUrl: 'custom-control-validation.component.html',
	changeDetection: ChangeDetectionStrategy.Default, // https://github.com/angular/angular/issues/10887
})
export class CustomControlValidationComponent {
	constructor(@SkipSelf() public ngControl: NgControl) {}
}
