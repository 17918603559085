import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { actionMergeMap } from '@ccap/shared/rx';
import { ElementsResource } from '../elements-resource.service';
import {
	fetchElement,
	fetchElementError,
	fetchElements,
	fetchElementsError,
	fetchElementsSuccess,
	fetchElementSuccess,
} from './elements.actions';

@Injectable({ providedIn: 'root' })
export class ElementsEffects {
	public fetchElements$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchElements),
			switchMap(() =>
				this.elementsResource.getElements().pipe(
					map(elements => fetchElementsSuccess({ elements })),
					catchError(error => of(fetchElementsError({ error }))),
				),
			),
		),
	);

	public fetchElement$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchElement),
			actionMergeMap(res =>
				this.elementsResource.getElement(res.id).pipe(
					map(({ enabled, visible }) =>
						fetchElementSuccess({
							element: { id: res.id, enabled, visible },
						}),
					),
					catchError(error => of(fetchElementError({ error }))),
				),
			),
		),
	);

	constructor(
		private actions$: Actions,
		private elementsResource: ElementsResource,
	) {}
}
