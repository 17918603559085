import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { fetchElement } from '@ccap/au-pair/data-access/elements';
import { AuPairElementId } from '@ccap/interfaces';

@Component({
	selector: 'app-profile-wrapper',
	templateUrl: './profile-wrapper.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileWrapperComponent implements OnInit {
	constructor(private store: Store) {}

	public ngOnInit(): void {
		this.store.dispatch(
			fetchElement({
				id: AuPairElementId.BusyStatus,
			}),
		);
	}
}
