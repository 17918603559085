import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	PersonalityTestAnswersDto,
	PersonalityTestResultsDto,
	PersonalityTestStatusDto,
} from '@ccap/au-pair/data-access/models';
import { IQuestionExtended } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class PersonalityTestResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getSchema(): Observable<Record<string, IQuestionExtended>> {
		return this.httpService.authGet(`${this.endpoints.schema}/personalitytest`);
	}

	public getPersonalityTestStatus(): Observable<PersonalityTestStatusDto> {
		return this.httpService.authGet<PersonalityTestStatusDto>(
			this.endpoints.personalityTestStatus,
		);
	}

	public getPersonalityTestAnswers(): Observable<PersonalityTestAnswersDto> {
		return this.httpService.authGet<PersonalityTestAnswersDto>(
			this.endpoints.personalityTestAnswers,
		);
	}

	public updatePersonalityTestAnswers(
		answers: PersonalityTestAnswersDto,
	): Observable<PersonalityTestAnswersDto> {
		return this.httpService.authPut<PersonalityTestAnswersDto>( // although it is a put, it behaves like a patch
			this.endpoints.personalityTestAnswers,
			answers,
		);
	}

	public getPersonalityTestResults(): Observable<PersonalityTestResultsDto> {
		return this.httpService.authGet<PersonalityTestResultsDto>(
			this.endpoints.personalityTestResults,
		);
	}
}
