import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgModule } from '@ccap/shared/ui/core-assets';
import { ProfilePictureComponent } from './profile-picture.component';

@NgModule({
	imports: [CommonModule, SvgModule],
	declarations: [ProfilePictureComponent],
	exports: [ProfilePictureComponent],
})
export class ProfilePictureModule {}
