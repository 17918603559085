import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ProfileWrapperModule } from '../components/profile-wrapper/profile-wrapper.module';
import { ProfileModule } from '../components/profile/profile.module';
import { NoContentComponent } from '../components/no-content/no-content';
import { HttpSandboxService } from './services/http-sandbox.service';
import { PublicIdInterceptor } from './services/public-id.interceptor';
import { ModalModule } from './modal/modal.module';
import { ConnectInAModalModule } from './modal/connect-in-a-modal/connect-in-a-modal.module';
import { DisconnectInAModalModule } from './modal/disconnect-in-a-modal/disconnect-in-a-modal.module';

/**
 * https://angular.io/guide/styleguide#core-feature-module
 */

@NgModule({
	exports: [
		ModalModule,
		ConnectInAModalModule,
		DisconnectInAModalModule,
		ProfileModule,
		ProfileWrapperModule,
	],
	declarations: [NoContentComponent],
})
export class CoreModule {
	public static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				HttpSandboxService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: PublicIdInterceptor,
					multi: true,
				},
			],
		};
	}
}
