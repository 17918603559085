import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';

/**
 * Require users be un-authenticated users to allow to route.
 *
 * Currently we do not handle the case when the token is invalid.
 * If we do not use this guard the user can fix their token by going to the authentication page again.
 * If we use it the user is trapped on a white page
 *
 * Until we fix the problem, do not use this guard.
 */
@Injectable()
export class IsLoggedOutGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	public async canActivate(): Promise<boolean> {
		const isAuthenticated = this.authService.isAuthenticated();

		if (isAuthenticated) {
			this.router.navigate(['/']);
		}

		return !isAuthenticated;
	}
}
