import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FooterContainerModule } from '@ccap/au-pair/containers';
import { IdentityModule } from '@ccap/au-pair/ui';
import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import {
	BusyStatusIndicatorModule,
	FlagModule,
	SpinnerModule,
} from '@ccap/shared/ui/indicators';
import { HeaderModule } from '@ccap/shared/ui/navigation';
import { ButtonModule } from '@ccap/ef-ui/components';
import { SectionModule } from '../section/section.module';
import { SubSectionModule } from '../sub-section/sub-section.module';
import { AnswersModule } from '../answers/answers.module';
import { ProfilePictureModule } from '../profile-picture/profile-picture.module';
import { SummaryCardsModule } from '../summary-cards/summary-cards.module';
import { CarouselModule } from '../carousel/carousel.module';
import { HostFamilyLetterModule } from '../host-family-letter/host-family-letter.module';
import { LinkModule } from '../link/link.module';
import { InCountryModule } from '../in-country/in-country.module';
import { NavigationHeaderModule } from '../navigation-header/navigation-header.module';
import { PersonalityTestResultsModule } from '../personality-test-results/personality-test-results.module';
import { FileDownloadModule } from '../../shared/components/file-download/file-download.module';
import { PrintButtonModule } from '../../shared/components/print-button/print-button.module';
import { ProfileComponent } from './profile.component';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		BusyStatusIndicatorModule,
		SectionModule,
		SubSectionModule,
		AnswersModule,
		ProfilePictureModule,
		IdentityModule,
		SummaryCardsModule,
		SvgModule,
		FlagModule,
		HeaderModule,
		FooterContainerModule,
		SpinnerModule,
		CarouselModule,
		HostFamilyLetterModule,
		LinkModule,
		InCountryModule,
		NavigationHeaderModule,
		PersonalityTestResultsModule,
		FileDownloadModule,
		PrintButtonModule,
		ButtonModule,
	],
	declarations: [ProfileComponent],
	exports: [ProfileComponent],
})
export class ProfileModule {}
