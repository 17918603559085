import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerTileListLanguagesComponent } from './answer-tile-list-languages.component';

@NgModule({
	imports: [CommonModule, PipesModule, SvgModule],
	declarations: [AnswerTileListLanguagesComponent],
	exports: [AnswerTileListLanguagesComponent],
})
export class AnswerTileListLanguagesModule {}
