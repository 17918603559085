import { Action, combineReducers } from '@ngrx/store';

import {
	emergencyContactsReducer,
	EmergencyContactState,
} from '../emergency-contact/store/emergency-contact.reducer';
import { flightsReducer, FlightsState } from '../flights/store/flights.reducer';
import {
	insuranceReducer,
	InsuranceState,
} from '../insurance/store/insurance.reducer';
import {
	returnFlightReducer,
	ReturnFlightState,
} from '../return-flight/store/return-flight.reducer';
import { visaReducer } from '../visa/store/visa.reducer';
import { VisaState } from '../visa/store/visa.model';

export interface TravelState {
	flights: FlightsState;
	insurance: InsuranceState;
	returnFlight: ReturnFlightState;
	visa: VisaState;
	emergencyContacts: EmergencyContactState;
}

const reducer = combineReducers<TravelState>({
	flights: flightsReducer,
	insurance: insuranceReducer,
	returnFlight: returnFlightReducer,
	visa: visaReducer,
	emergencyContacts: emergencyContactsReducer,
});

export function travelReducer(state: TravelState, action: Action): TravelState {
	return reducer(state, action);
}
