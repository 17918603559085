import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IAnswer, IQuestionExtended } from '@ccap/interfaces';

@Component({
	selector: 'app-answers',
	templateUrl: 'answers.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswersComponent {
	@Input() public questions?: IQuestionExtended[];

	public get parentsWithAnswerLabel(): IQuestionExtended[] {
		return this.questions
			.filter(this.isQuestionPartOfGroup)
			.filter(this.isQuestionRootAnswerEnabled)
			.filter(this.isQuestionNodeEnabled);
	}

	public answer(question: IQuestionExtended): IAnswer[] | IAnswer {
		return question.answerGroups.length
			? this.groupedAnswer(question)
			: question.answer;
	}

	private isRootQuestionDisabled = (question: IQuestionExtended): boolean => {
		return !question.dependency.visibleWhenRootEquals.includes(
			question.answer as string,
		);
	};

	private isQuestionPartOfGroup = (question: IQuestionExtended): boolean =>
		!!question.group.label;

	private isQuestionRootAnswerEnabled = (
		question: IQuestionExtended,
	): boolean => {
		return question.dependency.type === 'root'
			? this.isRootQuestionDisabled(question)
			: true;
	};

	private isQuestionNodeEnabled = (question: IQuestionExtended): boolean => {
		if (question.dependency.type !== 'node') {
			return true;
		}

		const parentQuestion = this.questions.find(
			(_question: IQuestionExtended) =>
				_question.id === question.dependency.parent,
		);

		return question.dependency.visibleWhenRootEquals.includes(
			parentQuestion.answer as string,
		);
	};

	private groupedAnswer(question: IQuestionExtended): IAnswer[] {
		const groupedQuestions = this.questions.reduce(
			(acc, _question: IQuestionExtended) => {
				if (question.answerGroups.includes(_question.id)) {
					acc.push(_question);
				}
				return acc;
			},
			[],
		);

		return [question, ...groupedQuestions].map(
			(groupedQuestion: IQuestionExtended) => groupedQuestion.answer,
		);
	}
}
