import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	MonoTypeOperatorFunction,
	Observable,
	of,
	pipe,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	map,
	retryWhen,
	shareReplay,
	take,
} from 'rxjs/operators';

import { MonitoringService } from '@ccap/shared/utils';

export const retryCount = 3;

export interface ErrorPayload {
	title?: string;
	content: string;
	persistent?: boolean;
	pauseOnHover?: boolean;
	sticky?: boolean;
}

const retryNonClientErrors = (): MonoTypeOperatorFunction<any> => {
	return pipe(
		retryWhen(errors =>
			errors.pipe(
				concatMap((error, count) => {
					if (count < retryCount && !String(error.status).startsWith('4')) {
						return of(error);
					}

					return throwError(error);
				}),
			),
		),
	);
};

// TODO: these methods has nothing to do with auth, so we can just rename them to the methods of HttpClient
@Injectable({
	providedIn: 'root',
})
export class HttpService {
	public error$?: Observable<ErrorPayload>;

	private errorSubject = new Subject<ErrorPayload>();
	private cache = new Map();

	constructor(
		private http: HttpClient,
		private monitoringService: MonitoringService,
	) {
		this.error$ = this.errorSubject.asObservable();
	}

	public authPost<T = any>(endpoint: string, body?: any): Observable<T> {
		return this.http.post(endpoint, body).pipe(
			retryNonClientErrors(),
			take(1),
			map((res: Record<string, unknown>) => res || body),
			catchError(this.handleError<T>()),
		);
	}

	public authPut<T = any>(endpoint: string, body?: any): Observable<T> {
		return this.http.put<T>(endpoint, body).pipe(
			retryNonClientErrors(),
			take(1),
			map((res: T) => res || body),
			catchError(this.handleError<T>()),
		);
	}

	public authPatch<T = any>(endpoint: string, body?: any): Observable<T> {
		return this.http.patch<T>(endpoint, body).pipe(
			retryNonClientErrors(),
			take(1),
			map((res: T) => res || body),
			catchError(this.handleError<T>()),
		);
	}

	public authDelete<T = any>(endpoint: string): Observable<T> {
		return this.http
			.delete<T>(endpoint)
			.pipe(retryNonClientErrors(), take(1), catchError(this.handleError<T>()));
	}

	public authGet<T = any>(
		endpoint: string,
		args?: { type?: any; disableCache?: boolean },
	): Observable<T> {
		const cachedContent = this.cache.get(endpoint);

		if (cachedContent && !args?.disableCache) {
			return cachedContent;
		} else {
			const newRequest = this.http
				.get<T>(endpoint, {
					responseType: (args && args.type) || 'json',
				})
				.pipe(
					retryNonClientErrors(),
					catchError(this.handleError<T>()),
					shareReplay(1),
				);

			if (!args?.disableCache) {
				this.cache.set(endpoint, newRequest);
			}

			return newRequest;
		}
	}

	private handleError<T>() {
		return (errorResponse: HttpErrorResponse): Observable<T> => {
			const { url, message, status, headers } = errorResponse;

			const error = new Error(message);
			const statusCode = Number.isInteger(status)
				? status.toString()
				: 'No status';

			const traceId =
				headers && headers.get('trace-id')
					? headers.get('trace-id')
					: 'No trace-id';

			this.monitoringService.logError(error, {
				url,
				status: statusCode,
				traceId,
			});

			this.notifyForErrors(errorResponse);

			return throwError(message);
		};
	}

	private notifyForErrors(err: HttpErrorResponse): void {
		let content: string;
		let sticky = false; // todo: add test for sticky
		const authFailureResaon = err.headers?.get('auth-failure-reason');

		if (authFailureResaon) {
			sticky = true;
			content = authFailureResaon;
		} else if (err.status === 0) {
			content =
				'We apologize. There are connectivity issues. Please check your internet connection and then try again. If this issue persists please let us know.';
		} else if (err.status === 401) {
			content =
				'We apologize. Your user session has ended. Please log in again to continue.';
		} else if (err.status === 403) {
			content =
				"We're sorry! Something went wrong while trying to perform this action. If this issue persists please let us know.";
		} else if (err.status >= 400 && err.status < 500) {
			content =
				"We're sorry! Something went wrong while requesting information. Please reach out to us to let us know.";
		} else if (!content && err.status >= 500) {
			content =
				"We're sorry! Something went wrong. Please try again. If this issue persists please let us know.";
		} else {
			content = 'We apologize. Something went wrong. Please try again.';
		}

		this.errorSubject.next({
			content,
			persistent: true,
			sticky,
		});
	}
}
