import { FormControl } from '@angular/forms';
import {
	add,
	endOfDay,
	endOfMonth,
	endOfYear,
	getDay,
	isAfter,
	isBefore,
	startOfDay,
	startOfMonth,
	startOfYear,
} from 'date-fns';

import {
	DatepickerOptions,
	DatepickerOptionsDateRange,
	DatePickerOptionsDayOfWeek,
	DateValue,
	IsoDateTypeFormat,
} from '@ccap/interfaces';
import { DateUtils } from '@ccap/shared/utils';

export const datepickerValidator = ({
	format,
	canBeDisabled,
	dateRange,
	daysDisabled,
}: Partial<DatepickerOptions>) => (
	control: FormControl,
): Record<string, any> => {
	const dateKeys = format.split('-');
	const currentTime = new Date();

	if (!control.value) {
		return { date: true };
	}

	const dateIsDisabled = canBeDisabled && control.value.isCurrent;
	if (dateIsDisabled) {
		return null;
	}

	const dateKeysInvalid = !dateKeys.every(
		(key: string) => !!control.value[key],
	);
	if (dateKeysInvalid) {
		return { date: true };
	}

	if (dateRange) {
		const dateSelectedIsOutOfRange = checkDateRange(
			currentTime,
			control.value,
			dateRange,
			format,
		);
		if (dateSelectedIsOutOfRange) {
			return dateSelectedIsOutOfRange;
		}
	}

	if (daysDisabled) {
		const daySelectedIsDisabled = checkDaysDisabled(
			control.value,
			daysDisabled,
		);
		if (daySelectedIsDisabled) {
			return daySelectedIsDisabled;
		}
	}

	return null;
};

const checkDateRange = (
	currentTime: Date,
	selectedDateValue: DateValue,
	dateRange: DatepickerOptionsDateRange,
	format: IsoDateTypeFormat,
): Record<string, any> | null => {
	const selectedDate = DateUtils.getDateFromDateValue(selectedDateValue);

	const roundMinDate = minRoundingFunctionForDateTypeFormat(format);
	const roundMaxDate = maxRoundingFunctionForDateTypeFormat(format);

	const minDate = dateRange.min
		? roundMinDate(
				add(currentTime, {
					years: dateRange.min.year,
					months: dateRange.min.month,
					days: dateRange.min.day,
				}),
		  )
		: null;

	const maxDate = dateRange.max
		? roundMaxDate(
				add(currentTime, {
					years: dateRange.max.year,
					months: dateRange.max.month,
					days: dateRange.max.day,
				}),
		  )
		: null;

	if (isBefore(selectedDate, minDate)) {
		return {
			dateOutOfRange: { min: minDate, max: maxDate, format },
		};
	}

	if (isAfter(selectedDate, maxDate)) {
		return {
			dateOutOfRange: { max: maxDate, min: minDate, format },
		};
	}

	return null;
};

const minRoundingFunctionForDateTypeFormat = (format: IsoDateTypeFormat) => {
	if (format === 'YYYY') {
		return startOfYear;
	} else if (format === 'YYYY-MM') {
		return startOfMonth;
	}
	return startOfDay;
};

const maxRoundingFunctionForDateTypeFormat = (format: IsoDateTypeFormat) => {
	if (format === 'YYYY') {
		return endOfYear;
	} else if (format === 'YYYY-MM') {
		return endOfMonth;
	}
	return endOfDay;
};

const checkDaysDisabled = (
	selectedDateValue: DateValue,
	daysDisabled: DatePickerOptionsDayOfWeek[],
): Record<string, any> | null => {
	const selectedDate = DateUtils.getDateFromDateValue(selectedDateValue);

	const selectedDayOfWeek = getDay(selectedDate);

	if (daysDisabled.includes(selectedDayOfWeek)) {
		return {
			dayDisabled: { dayOfWeek: selectedDayOfWeek },
		};
	}

	return null;
};
