import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { ILinkData } from '@ccap/interfaces';
import { removeLink } from './links.actions';
import {
	rollbackLinks,
	updateDeclineReasons,
	updateLink,
	updateLinks,
} from './links.actions';

export interface LinksState {
	links: ILinkData[] | undefined;
	declineReasons: any | undefined;
}

const reducer = combineReducers<LinksState>({
	links: createReducer<ILinkData[] | undefined>(
		undefined,
		on(updateLinks, rollbackLinks, (state, { links }) => links),
		on(updateLink, (state, { link: { linkId, ...linksBody } }) =>
			state
				? state.map(link =>
						link.id === linkId ? { ...link, ...linksBody } : link,
				  )
				: state,
		),
		on(removeLink, (state, { linkId }) =>
			state ? state.filter(({ id }) => id !== linkId) : state,
		),
	),
	declineReasons: createReducer<any | undefined>(
		undefined,
		on(updateDeclineReasons, (state, { reasons }) => reasons),
	),
});

export function linksReducer(
	state: LinksState | undefined,
	action: Action,
): LinksState {
	return reducer(state, action);
}
