import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { INotification, NotificationEvent } from './notification.interface';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	public lifeCycle: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private eventBus: Subject<NotificationEvent> =
		new Subject<NotificationEvent>(); // event bus

	// The set syntax binds an object property to a function to be called when there is an attempt to set that property.
	public set(notification: INotification, add: boolean): INotification {
		notification.id = Math.random().toString(36).substring(3);
		this.eventBus.next({ command: 'set', notification, add });
		return notification;
	}

	public getChangeEmitter(): Observable<NotificationEvent> {
		return this.eventBus.asObservable();
	}

	// Action methods
	public success({
		title = '',
		content,
		persistent = false,
		pauseOnHover = true,
		duration = 5000,
	}): INotification {
		return this.set(
			{
				title,
				content,
				type: 'success',
				persistent,
				pauseOnHover,
				duration,
			},
			true,
		);
	}

	public error({
		title = '',
		content,
		persistent = false,
		pauseOnHover = true,
		sticky = false,
	}): INotification {
		return this.set(
			{
				title,
				content,
				type: 'error',
				persistent,
				pauseOnHover,
				sticky,
			},
			true,
		);
	}

	public alert({
		title = '',
		content,
		persistent = false,
		pauseOnHover = true,
	}): INotification {
		return this.set(
			{
				title,
				content,
				type: 'alert',
				persistent,
				pauseOnHover,
			},
			true,
		);
	}
}
