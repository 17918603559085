import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AbstractModalBase } from './abstract-modal-base';
import { ModalRootComponent } from './modal-root.component';

/**
 * @whatItDoes
 * It creates the instance of the ModalRootComponent
 * into which the instance of the ModalMainComponent is added.
 *
 * It also acts as a switchboard for modals.
 */
@Injectable()
export class ModalComponentFactoryService {
	public modals?: AbstractModalBase[];
	private modalRootComponent?: ModalRootComponent;
	private overlayRef?: OverlayRef;

	constructor(private overlay: Overlay) {
		const positionStrategy = this.overlay
			.position()
			.global()
			.centerVertically()
			.centerHorizontally();

		this.overlayRef = this.overlay.create({
			positionStrategy,
		});
	}

	/**
	 * Adds a modal.
	 */
	public addModal(
		component: Type<AbstractModalBase>,
		data?: any,
	): BehaviorSubject<boolean> {
		if (!this.modalRootComponent) {
			this.modalRootComponent = this.createModalRoot(); // Create an instance of modalRootComponent.
		}
		this.modals = this.modalRootComponent.modals; // Populate modals array for access by service call.
		return this.modalRootComponent.addModal(component, data);
	}

	/**
	 * Hide and remove modal from the DOM
	 */
	public removeModal(component: AbstractModalBase): void {
		if (!this.modalRootComponent) {
			return; // early bail
		}
		this.modalRootComponent.removeModal(component);
	}

	public removeAllModals(): void {
		this.modalRootComponent.removeAllModals();
	}

	/**
	 * Create and add the top-level modal root component to the DOM
	 */
	private createModalRoot(): ModalRootComponent {
		const componentPortal = new ComponentPortal(ModalRootComponent);
		const componentRef = this.overlayRef.attach(componentPortal);

		return componentRef.instance;
	}
}
