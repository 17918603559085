import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'privacy-policy',
		loadChildren: () =>
			import('@ccap/au-pair/legal/privacy-policy').then(
				m => m.AuPairLegalPrivacyPolicyModule,
			),
	},
	{
		path: 'legal',
		loadChildren: () =>
			import('@ccap/au-pair/legal/legal-text').then(
				m => m.AuPairLegalLegalTextModule,
			),
	},
];

@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
})
export class AuPairLegalModule {}
