import { Action, createReducer, on } from '@ngrx/store';

import { IAnswers } from '@ccap/interfaces';
import {
	patchExtensionAnswers,
	rollbackExtensionAnswers,
	updateExtensionAnswers,
} from './answers.actions';

export type ExtensionAnswersState = IAnswers | null;

const reducer = createReducer<ExtensionAnswersState>(
	null,
	on(
		updateExtensionAnswers,
		rollbackExtensionAnswers,
		(_, { answers }) => answers,
	),
	on(patchExtensionAnswers, (state, { answers }) => ({ ...state, ...answers })),
);

export function answersReducer(
	state: ExtensionAnswersState,
	action: Action,
): ExtensionAnswersState {
	return reducer(state, action);
}
