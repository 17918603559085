import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2eModule } from '@ccap/e2e';
import { SpinnerModule } from '@ccap/shared/ui/indicators';
import { IdentityComponent } from './identity.component';

@NgModule({
	imports: [CommonModule, SpinnerModule, E2eModule],
	declarations: [IdentityComponent],
	exports: [IdentityComponent],
})
export class IdentityModule {}
