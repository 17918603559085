import { Action, createReducer, on } from '@ngrx/store';

import { IAnswers } from '@ccap/interfaces';
import {
	patchAupairLife,
	rollbackAupairLife,
	updateAupairLife,
} from './aupair-life.actions';

export type AupairLifeState = IAnswers | null;

const reducer = createReducer<AupairLifeState>(
	null,
	on(updateAupairLife, rollbackAupairLife, (_, { aupairLife }) => aupairLife),
	on(patchAupairLife, (state, { aupairLife }) => ({ ...state, ...aupairLife })),
);

export function aupairLifeReducer(
	state: AupairLifeState,
	action: Action,
): AupairLifeState {
	return reducer(state, action);
}
