import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerCheckboxComponent } from './answer-checkbox.component';

@NgModule({
	imports: [CommonModule, SvgModule, PipesModule],
	declarations: [AnswerCheckboxComponent],
	exports: [AnswerCheckboxComponent],
})
export class AnswerCheckboxModule {}
