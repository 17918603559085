import { InjectionToken } from '@angular/core';
import { IConfig, IConfiguration } from '@microsoft/applicationinsights-web';

export type MonitoringConfig = IConfiguration & IConfig;

export const MONITORING_CONFIG = new InjectionToken<MonitoringConfig>(
	'ccap.shared.data-access.monitoring',
);

export const defaultMonitoringConfig: Partial<MonitoringConfig> = {
	enableAutoRouteTracking: true,
};
