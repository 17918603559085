import { CheckboxListWithOtherAnswer } from '@ccap/question-engine/models';
import { mapAnswersToQuestionOptionsLabel } from '@ccap/question-engine/utils';
import { TypeChecker } from '@ccap/shared/utils';
import { SummaryCard } from '../../../../interfaces/summary-cards';

// lower case all from
const toLowerCaseFromIndex = (index: number) => (
	element: string,
	arrayIndex: number,
) => {
	if (arrayIndex >= index) {
		return element.toLowerCase();
	}

	return element;
};

function specialDietAnswer(
	hasSpecialDiet: boolean,
	diet: CheckboxListWithOtherAnswer,
): string {
	if (hasSpecialDiet === false) {
		return 'No Restrictions';
	}

	if (!TypeChecker.isObject(diet) || !TypeChecker.isObject(diet.choices)) {
		return null;
	}

	// TODO: Get this from the question service instead of having it duplicated here
	const dietQuestionOptions = [
		{ label: `Vegetarian`, value: `Vegetarian` },
		{ label: `Vegan`, value: `Vegan` },
		{ label: `Halal`, value: `Halal` },
		{ label: `Kosher`, value: `Kosher` },
		{ label: `Lactose-free`, value: `Lactose Free` },
		{ label: `Gluten-free`, value: `Gluten-free Diet` },
	];

	let selectedChoices = mapAnswersToQuestionOptionsLabel(
		diet.choices,
		dietQuestionOptions,
	)
		.map(({ label }) => label)
		.map(toLowerCaseFromIndex(1))
		.join(', ');

	if (diet.choices.other && diet.other) {
		const otherAnswer = `Other: ${diet.other}`;
		selectedChoices = [selectedChoices, otherAnswer].filter(Boolean).join('. ');
	}
	return selectedChoices;
}

export function specialDietResolver(
	hasSpecialDiet: boolean,
	diet: CheckboxListWithOtherAnswer,
): Record<'specialDiet', SummaryCard> {
	return {
		specialDiet: {
			title: 'Diet',
			icon: 'Diet',
			answer: specialDietAnswer(hasSpecialDiet, diet),
		},
	};
}
