import { getMonth } from 'date-fns';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { DateValue, Option } from '@ccap/interfaces';

export class DateUtils {
	public static months: Option[] = [
		{ label: 'January', value: 'January' },
		{ label: 'February', value: 'February' },
		{ label: 'March', value: 'March' },
		{ label: 'April', value: 'April' },
		{ label: 'May', value: 'May' },
		{ label: 'June', value: 'June' },
		{ label: 'July', value: 'July' },
		{ label: 'August', value: 'August' },
		{ label: 'September', value: 'September' },
		{ label: 'October', value: 'October' },
		{ label: 'November', value: 'November' },
		{ label: 'December', value: 'December' },
	];

	public static get monthsValues(): Option[] {
		return [{ label: 'Month', value: 'Month' }, ...this.months];
	}

	public static getYears(startYear: number, endYear: number): Option[] {
		const years: string[] = [];

		while (startYear >= endYear) {
			years.push((startYear--).toString());
		}

		return years.map((year: string) => ({ label: year, value: year }));
	}

	public static getDayValues(daysInMonth = 31): Option[] {
		const days: Option[] = Array(daysInMonth)
			.fill('')
			.map((x: number, i: number) => (i + 1).toString())
			.map((day: string) => ({ label: day, value: day }));

		return [{ label: 'Day', value: 'Day' }, ...days];
	}

	public static getDateFromDateValue(dateValue: DateValue): Date | null {
		if (every(dateValue, isEmpty)) {
			return null;
		}

		return new Date(
			[dateValue.MM ?? '01', dateValue.DD ?? '01', dateValue.YYYY].join(' '),
		);
	}

	public static calculateAge(
		dateOfBirth: Date,
		currentDate: Date = new Date(),
	): number | null {
		if (!dateOfBirth || !currentDate) {
			return null;
		}

		let years = currentDate.getFullYear() - dateOfBirth.getFullYear();
		const monthDifference = currentDate.getMonth() - dateOfBirth.getMonth();

		if (
			monthDifference < 0 ||
			(monthDifference === 0 && currentDate.getDate() < dateOfBirth.getDate())
		) {
			years--;
		}
		return isNaN(years) ? null : years;
	}

	public static monthNumberToName(monthNumber: number): string {
		if (
			monthNumber === null ||
			monthNumber > 11 ||
			monthNumber < 0 ||
			isNaN(monthNumber)
		) {
			return '';
		}

		const monthNumberNameMap = {
			0: 'January',
			1: 'February',
			2: 'March',
			3: 'April',
			4: 'May',
			5: 'June',
			6: 'July',
			7: 'August',
			8: 'September',
			9: 'October',
			10: 'November',
			11: 'December',
		};

		return monthNumberNameMap[monthNumber];
	}

	public static monthNameToNumber(monthName: string): number {
		const date = new Date(`01 ${monthName} 2020`);
		return getMonth(date);
	}
}
