import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ElementsEffects } from './store/elements.effects';
import {
	auPairElementsReducer,
	auPairElementsStateKey,
} from './store/elements.reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(auPairElementsStateKey, auPairElementsReducer),
		EffectsModule.forFeature([ElementsEffects]),
	],
})
export class AuPairDataAccessElementsModule {}
