import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { APP_DECLARATIONS } from './app.declarations';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.providers';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
	bootstrap: [AppComponent],
	declarations: APP_DECLARATIONS,
	imports: APP_IMPORTS,
	providers: APP_PROVIDERS,
})
export class AppModule {}
