import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { AnswerAddressComponent } from './answer-address.component';

@NgModule({
	imports: [CommonModule, PipesModule],
	declarations: [AnswerAddressComponent],
	exports: [AnswerAddressComponent],
})
export class AnswerAddressModule {}
