import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CheckboxListWithOtherQuestion } from '@ccap/question-engine/models';
import { mapAnswersToQuestionOptionsLabel } from '@ccap/question-engine/utils';

@Component({
	selector: 'app-answer-checkbox-list-with-other',
	templateUrl: 'answer-checkbox-list-with-other.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerCheckboxListWithOtherComponent {
	@Input() public question?: CheckboxListWithOtherQuestion;

	public get selectedChoices(): string {
		const answer = this.question.answer;
		if (!answer || !answer.choices || !this.question.options) {
			return '';
		}

		let selection = mapAnswersToQuestionOptionsLabel(
			answer.choices,
			this.question.options,
		)
			.map(({ label }) => label)
			.join(', ');

		if (answer.choices.other && answer.other) {
			const otherAnswer = `Other: ${answer.other}`;
			selection = [selection, otherAnswer].filter(Boolean).join('. ');
		}
		return selection;
	}
}
