<ccap-content-wrapper>
	<h1>{{ title }}</h1>

	<div [innerHTML]="content | markdown: markedOptions"></div>

	<ccap-language-selector
		[locales]="contentLocales"
		(localeSelect)="onLocaleSelected($event)"
	></ccap-language-selector>
</ccap-content-wrapper>
