import { Routes } from '@angular/router';

import { AuthGuard } from '@ccap/auth';
import { NoContentComponent } from './components/no-content';
import { ProfileWrapperComponent } from './components/profile-wrapper/profile-wrapper.component';
import { ProfileComponent } from './components/profile/profile.component';
import { IntendedProfileGuard } from './core/guards/intended-profile.guard';

export const ROUTES: Routes = [
	{ path: '', redirectTo: 'profile', pathMatch: 'full' },
	{
		path: 'profile',
		canActivate: [AuthGuard],
		component: ProfileWrapperComponent,
		children: [
			{
				path: '',
				canActivate: [IntendedProfileGuard],
				component: ProfileComponent,
			},
			{
				path: ':userId',
				component: ProfileComponent,
			},
		],
	},
	{ path: '**', component: NoContentComponent },
];
