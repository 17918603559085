import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';

import { LazyTabContentDirective } from '../lazy-tab-content/lazy-tab-content.directive';

@Component({
	selector: 'ccap-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
	@Input()
	public title?: string;

	@Input()
	public canClose = false;

	@Output()
	public showLess = new EventEmitter<void>();

	public get contentTemplate(): TemplateRef<HTMLElement> {
		return this.lazyContent || this.projectedContent;
	}

	@ContentChild(LazyTabContentDirective, { read: TemplateRef, static: true })
	private lazyContent?: TemplateRef<HTMLElement> | null;

	@ViewChild(TemplateRef, { read: TemplateRef, static: true })
	private projectedContent?: TemplateRef<HTMLElement>;
}
