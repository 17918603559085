<div class="auth-container">
	<div class="auth-lock-form">
		<div class="auth-lock">
			<div class="auth-logo">
				<img
					class="ccap-logo"
					src="https://shared-assets.culturalcare.com/logo/logo-color.svg"
					alt="Cultural Care Au Pair"
				/>
			</div>

			<div class="auth-title-container u-pt-l">
				<h1 class="ef-h4 u-text-center u-mb-s">{{ title }}</h1>
				<p
					*ngIf="subTitleDescription"
					class="ef-text-ui u-text-center"
					[innerHTML]="subTitleDescription"
				></p>
			</div>

			<div id="root"></div>

			<div
				*ngIf="showSocialMediaLogin$ | async"
				class="auth-link-container ef-text-caption u-text-center"
			>
				<a [attr.href]="socialMediaLoginUrl" class="ef-link"
					>Login with social media account</a
				>
			</div>
		</div>
	</div>
</div>
