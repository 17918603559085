<ng-container
	*ngIf="answers && answers.englishProficiency !== 'Not Set'; else noInterview"
>
	<app-abstract-list-item [labeledFields]="getLabelsForFields(answers)">
	</app-abstract-list-item>
</ng-container>

<ng-template #noInterview>
	<p class="no-answer no-answer--center">No Interview Added</p>
</ng-template>
