import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Address } from '@ccap/interfaces';

export const addressAutocompleteValidator: ValidatorFn = (
	control: AbstractControl,
): ValidationErrors => {
	const errors: ValidationErrors = {};
	const value: Address = control.value;

	if (!value?.street) {
		errors.streetRequired = {
			errorMessage: 'Address street is required!',
		};
	}

	if (!value?.zip) {
		errors.zipRequired = {
			errorMessage: 'Address zip is required!',
		};
	}

	if (!value?.city) {
		errors.cityRequired = {
			errorMessage: 'Address city is required!',
		};
	}

	if (!value?.state) {
		errors.stateRequired = {
			errorMessage: 'Address state is required!',
		};
	}

	if (!value?.countryCode) {
		errors.countryRequired = {
			errorMessage: 'Address country is required!',
		};
	}

	return Object.keys(errors).length ? errors : null;
};
