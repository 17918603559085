import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { SummaryCardsComponent } from './summary-cards.component';

@NgModule({
	imports: [CommonModule, PipesModule, SvgModule],
	declarations: [SummaryCardsComponent],
	exports: [SummaryCardsComponent],
})
export class SummaryCardsModule {}
