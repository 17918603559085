<ng-container *ngIf="inCountry?.transition as transition">
	<div class="in-country__container">
		<div class="in-country transition">
			<div>
				<h4 class="ef-h4">Au Pair in Transition</h4>
			</div>
			<div class="in-country__content">
				<dl>
					<div>
						<dt>Available from</dt>
						<dd>{{ transition.startDate | date: 'dd MMMM yyyy' }}</dd>
					</div>
					<div>
						<dt>Program end date</dt>
						<dd>{{ transition.programEndDate | date: 'dd MMMM yyyy' }}</dd>
					</div>

					<div>
						<dt>Vacation days left</dt>
						<dd>{{ transition.vacationDaysCount }}</dd>
					</div>

					<div>
						<dt>Education balance</dt>
						<dd>
							{{ transition.remainingEducationAllowance }} /
							{{ transition.initialEducationAllowance }} $
						</dd>
					</div>
				</dl>

				<div class="in-country__content-link">
					<app-file-download
						[documentUrl]="transition.document"
						[fileIdentifier]="fileIdentifier"
					>
						<a href="javascript:void(0);"
							>Transition Summary & Benefits (PDF)</a
						>
					</app-file-download>
				</div>
			</div>
		</div>

		<div
			*ngIf="isTransitionDetailsPanelVisible && transition.details"
			class="in-country__details"
		>
			<app-transition-details
				[transitionDetails]="transition.details"
			></app-transition-details>
		</div>

		<ccap-more-less
			*ngIf="transition.details"
			class="in-country__more-less in-country__more-less--transition"
			[inheritColor]="!isTransitionDetailsPanelVisible"
			[showMore]="!isTransitionDetailsPanelVisible"
			(click)="toggleShowTransitionDetails()"
		>
		</ccap-more-less>
	</div>
</ng-container>

<ng-container *ngIf="inCountry?.extension as extension">
	<div class="in-country extension">
		<div>
			<h4 class="ef-h4">Au Pair in Extension</h4>
		</div>
		<div class="in-country__content">
			<dl>
				<div>
					<dt>Starting date</dt>
					<dd>{{ extension.extensionStartingDate | date: 'dd MMMM yyyy' }}</dd>
				</div>
				<div>
					<dt>Duration</dt>
					<dd>{{ extension.extensionLength }}</dd>
				</div>

				<div>
					<dt>Currently cares for</dt>
					<dd
						[innerHTML]="(extension.extensionChildrenCount ?? 0) + ' kid(s)'"
					></dd>
				</div>

				<div>
					<dt>Current location</dt>
					<dd>{{ extension.extensionFamilyLocation }}</dd>
				</div>
			</dl>
		</div>
	</div>

	<ccap-tabs theme="box">
		<ccap-tab title="Extension Details" [canClose]="true">
			<app-extension-details
				[canViewContactDetails]="canViewContactDetails"
				[contactDetails]="contactDetails"
				[extension]="extension"
			></app-extension-details>
		</ccap-tab>

		<ccap-tab
			title="Perspectives"
			*ngIf="
				(hostFamilyReferences && hostFamilyReferences.length > 0) ||
				(lccReferences && lccReferences.length > 0)
			"
			[canClose]="true"
		>
			<app-perspectives
				[hostFamilyReferences]="hostFamilyReferences"
				[lccReferences]="lccReferences"
			>
			</app-perspectives>
		</ccap-tab>

		<ccap-tab
			title="Transition History"
			*ngIf="extension.transitionSummary"
			[canClose]="true"
		>
			<ccap-preserve-newline>
				{{ extension.transitionSummary }}
			</ccap-preserve-newline>
		</ccap-tab>
	</ccap-tabs>
</ng-container>
