import { Injectable } from '@angular/core';
import { NEVER, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MarkAsNotificationService {
	public readonly markAsTouched$: Observable<unknown> = NEVER;
	public readonly markAsDirty$: Observable<unknown> = NEVER;
}
