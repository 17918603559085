<ng-container *ngIf="isSubmitted$ | async; else notSubmitted">
	<ng-container *ngIf="hasResults$ | async; else noResults">
		<app-personality-test-graphs
			[personalityTestResults$]="personalityTestResults$"
		>
		</app-personality-test-graphs>

		<p class="ef-text-para u-text-center u-mb-m">
			<app-file-download
				[documentUrl]="documentUrl"
				[fileIdentifier]="fileIdentifier"
				class="u-mt-l u-mb-l"
			>
				<button efButton [primary]="true" [square]="true" type="button">
					Download personality assessment report
				</button>
			</app-file-download>
			<a
				[attr.href]="overviewURL"
				target="_blank"
				class="link--no-arrow ef-link"
			>
				Understanding the report
			</a>
			<span class="link-metadata">{{ overviewURL | fileExtension }}</span>
		</p>
	</ng-container>
</ng-container>

<ng-template #noResults>
	<p class="ef-text-para u-text-center u-mb-m">
		The personality assessment has been completed, but we are waiting for the
		results.
	</p>
</ng-template>

<ng-template #notSubmitted>
	<p class="ef-text-para u-text-center u-mb-m">
		The personality assessment has not been completed by the au pair.
	</p>
</ng-template>
