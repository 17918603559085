import { InjectionToken } from '@angular/core';

export interface ApiEndpoints {
	contentful: string;
	family?: string;
	pink: string;
	schema: string;
	apollo?: string;
}

export const API_ENDPOINTS = new InjectionToken<ApiEndpoints>(
	'ccap.shared.data-access.api-endpoints',
);

export interface ApiTokens {
	contentful: string;
}

export const API_TOKENS = new InjectionToken<ApiTokens>(
	'ccap.shared.data-access.api-tokens',
);
