import { Pipe, PipeTransform } from '@angular/core';
import lodashGet from 'lodash/get';

export interface Group {
	key: string;
	values: GroupedObject[];
}

export interface GroupedObject extends Record<string, any> {
	indexRef: number;
}

@Pipe({
	name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
	public transform(value: any[], field: string): Group[] {
		const groupedObject = this.groupBy(value, field);

		return Object.keys(groupedObject).map((key: string) => {
			return { key, values: groupedObject[key] };
		});
	}

	private groupBy(items: GroupedObject[], field: string) {
		return items.reduce((accumulator, item, i) => {
			item = { ...item, indexRef: i };
			const valueToGroupBy = lodashGet(item, field);
			if (Array.isArray(accumulator[valueToGroupBy])) {
				accumulator[valueToGroupBy].push(item);
			} else {
				accumulator[valueToGroupBy] = [item] || [];
			}
			return accumulator;
		}, {});
	}
}
