import { ActionReducerMap } from '@ngrx/store';

import {
	auPairFrontPageWidgetsReducer,
	AuPairFrontPageWidgetsState,
} from '@ccap/au-pair/data-access/front-page-widgets';
import { AuPairStatusDto, IBusyStatus } from '@ccap/au-pair/data-access/models';
import { AsyncAccountState } from './account/models/account-state.model';
import { accountReducer } from './account/store/account.reducer';
import {
	aupairLifeReducer,
	AupairLifeState,
} from './aupair-life/store/aupair-life.reducer';
import { busyStatusReducer } from './busy-status/store/reducer';
import {
	contactDetailsReducer,
	ContactDetailsState,
} from './contact-details/store/contact-details.reducer';
import { coursesReducer, CoursesState } from './courses/store/courses.reducer';
import {
	documentsReducer,
	DocumentsState,
} from './documents/store/documents.reducer';
import { healthReducer, HealthState } from './health/store/health.reducer';
import { IdentityModel } from './identity/store/model';
import { identityReducer } from './identity/store/reducer';
import { imageReducer, ImageState } from './image/store/image.reducer';
import { linksReducer, LinksState } from './links/store/links.reducer';
import { parentsReducer, ParentsState } from './parents/store/parents.reducer';
import {
	personalityTestReducer,
	PersonalityTestState,
} from './personality-test/store/personality-test.reducer';
import { questionsReducer } from './questions/store/questions.reducer';
import { QuestionsState } from './questions/store/questions.state';
import {
	referencesReducer,
	ReferencesState,
} from './references/store/references.reducer';
import { statusReducer } from './status/store/reducer';
import { SubSectionModel } from './sub-section/store/model';
import { subSectionReducer } from './sub-section/store/reducer';
import { travelReducer, TravelState } from './travel/store/travel.reducer';
import { videoReducer, VideoState } from './video/store/video.reducer';
import {
	workExperienceReducer,
	WorkExperienceState,
} from './work-experience/store/work-experience.reducer';

export interface IAppState {
	personalityTest: PersonalityTestState;
	subSection: SubSectionModel;
	questions: QuestionsState;
	account: AsyncAccountState;
	links: LinksState;
	status: AuPairStatusDto;
	busyStatus: IBusyStatus;
	identity: IdentityModel;
	documents: DocumentsState;
	health: HealthState;
	video: VideoState;
	image: ImageState;
	aupairLife: AupairLifeState;
	contactDetails: ContactDetailsState;
	references: ReferencesState;
	workExperience: WorkExperienceState;
	travel: TravelState;
	parents: ParentsState;
	courses: CoursesState;
	frontPageWidgets: AuPairFrontPageWidgetsState;
}

export const reducers: ActionReducerMap<IAppState> = {
	personalityTest: personalityTestReducer,
	subSection: subSectionReducer,
	questions: questionsReducer,
	account: accountReducer,
	links: linksReducer,
	status: statusReducer,
	busyStatus: busyStatusReducer,
	identity: identityReducer,
	documents: documentsReducer,
	health: healthReducer,
	video: videoReducer,
	image: imageReducer,
	contactDetails: contactDetailsReducer,
	aupairLife: aupairLifeReducer,
	references: referencesReducer,
	workExperience: workExperienceReducer,
	travel: travelReducer,
	parents: parentsReducer,
	courses: coursesReducer,
	frontPageWidgets: auPairFrontPageWidgetsReducer,
};
