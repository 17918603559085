<ng-container *ngIf="!!profilePhotoUrl; else noPhotoUrl">
	<div
		class="round-container"
		[ngStyle]="pictureSize"
		[class.thick-frame]="thickFrame"
	>
		<div class="round-container__image" [ngStyle]="backgroundImage"></div>
	</div>
</ng-container>

<ng-template #noPhotoUrl>
	<ccap-svg [title]="'profile-picture'" [ngStyle]="pictureSize"> </ccap-svg>
</ng-template>
