import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, first, map } from 'rxjs/operators';

import { HttpService } from '@ccap/shared/data-access';
import { FEATURE_TOGGLE_CONFIG } from '../feature-toggle-injection-token';
import {
	Feature,
	FeatureFlagState,
	FeatureToggleConfig,
	FeatureToggles,
} from './interfaces';

@Injectable({
	providedIn: 'root',
})
export class FeatureToggleResource {
	private endpoint?: string;

	constructor(
		@Inject(FEATURE_TOGGLE_CONFIG) private config: FeatureToggleConfig,
		private httpService: HttpService,
	) {
		this.endpoint = `${config.endpoint}v1.0/FeatureSwitch`;
	}

	public getFeatureFlags(): Observable<FeatureFlagState> {
		return this.chainRequests(this.config.features);
	}

	private chainRequests(flags: FeatureToggles[]): Observable<FeatureFlagState> {
		const observables: Observable<Feature>[] = flags.map(name => {
			const endpointUrl = `${this.endpoint}/${name}`;
			return this.httpService.authGet<Feature>(endpointUrl);
		});

		return forkJoin(observables).pipe(
			defaultIfEmpty([]),
			first(),
			map(results =>
				results.reduce(
					(accum, { featureName, isEnabled }) => ({
						...accum,
						[featureName]: isEnabled,
					}),
					{} as Feature,
				),
			),
		);
	}
}
