import { Action, createReducer, on } from '@ngrx/store';

import { AuPairElementsDto } from '@ccap/interfaces';
import { replaceInArray } from '@ccap/shared/utils';
import { fetchElementsSuccess, fetchElementSuccess } from './elements.actions';

export const auPairElementsStateKey = 'elements';

export type AuPairElementsState = AuPairElementsDto;

const reducer = createReducer<AuPairElementsState>(
	[],
	on(fetchElementsSuccess, (_, { elements }) => elements),
	on(fetchElementSuccess, (state, { element }) => {
		const index = state.findIndex(
			elementToCheck => element.id === elementToCheck.id,
		);

		return replaceInArray(state, index, {
			...state[index],
			...element,
		});
	}),
);

export function auPairElementsReducer(
	state: AuPairElementsState,
	action: Action,
): AuPairElementsState {
	return reducer(state, action);
}
