import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
	public transform(value: any) {
		if (value === 'true' || value === true) {
			return 'Yes';
		} else if (value === 'false' || value === false) {
			return 'No';
		} else {
			return value;
		}
	}
}
