import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[efButtonGroup]',
})
export class ButtonGroupDirective {
	@HostBinding('class.ef-button-group')
	public readonly baseClass = true;

	@HostBinding('class.-condensed')
	@Input()
	public condensed = false;
}
