import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InputModule } from '../input';
import { CheckboxComponent } from './checkbox.component';

@NgModule({
	declarations: [CheckboxComponent],
	exports: [CheckboxComponent],
	imports: [CommonModule, InputModule, FormsModule],
})
export class CheckboxModule {}
