import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2eClassDirective } from './e2e-class.directive';
import { E2eIdDirective } from './e2e-id.directive';
import { SlugifyPipe } from './slugify.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [E2eIdDirective, E2eClassDirective, SlugifyPipe],
	exports: [E2eIdDirective, E2eClassDirective, SlugifyPipe],
})
export class E2eModule {}
