import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';

import {
	getElementLineHeight,
	getNumberOfLinesInElementContent,
} from '@ccap/shared/utils';

@Component({
	selector: 'ccap-more-less-text',
	templateUrl: './more-less-text.component.html',
	styleUrls: ['./more-less-text.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreLessTextComponent implements OnInit, OnDestroy {
	@ViewChild('moreLessTextContentContainer', { static: true })
	public moreLessTextContainer?: ElementRef<HTMLElement>;

	@Input()
	public maxCollapsedLinesToDisplay = 3;

	public shouldCollapse = false;
	public shouldShowFullText = false;
	public elementLineHeight = 0;
	public readonly fadeOutLinesOffset = 1;

	private mutationObserver?: MutationObserver;

	constructor(
		private ngZone: NgZone,
		private changeDetectorRef: ChangeDetectorRef,
		private renderer: Renderer2,
	) {}

	public ngOnInit(): void {
		this.setupMutationObserver();
	}

	public ngOnDestroy(): void {
		this.tearDownMutationObserver();
	}

	public toggleShouldShowFullText(): void {
		this.shouldShowFullText = !this.shouldShowFullText;

		if (this.shouldShowFullText) {
			this.expandText();
		} else {
			this.collapseText();
		}
	}

	private setupMutationObserver(): void {
		this.ngZone.runOutsideAngular(() => {
			this.mutationObserver = new MutationObserver(() => {
				this.ngZone.run(() => {
					this.onMutate();
				});
			});

			this.mutationObserver.observe(this.moreLessTextContainer.nativeElement, {
				characterData: true,
				subtree: true,
			});
		});
	}

	private tearDownMutationObserver(): void {
		this.mutationObserver.disconnect();
	}

	private onMutate(): void {
		this.elementLineHeight = getElementLineHeight(
			this.moreLessTextContainer.nativeElement,
		);
		const numberOfLinesInText = getNumberOfLinesInElementContent(
			this.moreLessTextContainer.nativeElement,
			this.elementLineHeight,
		);

		this.shouldCollapse = numberOfLinesInText > this.maxCollapsedLinesToDisplay;

		if (this.shouldCollapse) {
			this.collapseText();
		}

		this.changeDetectorRef.markForCheck();
	}

	private collapseText(): void {
		this.renderer.setStyle(
			this.moreLessTextContainer.nativeElement,
			'height',
			`${
				this.elementLineHeight *
				(this.maxCollapsedLinesToDisplay + this.fadeOutLinesOffset)
			}px`,
		);
	}

	private expandText(): void {
		this.renderer.removeStyle(
			this.moreLessTextContainer.nativeElement,
			'height',
		);
	}
}
