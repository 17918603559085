import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { HttpService } from '@ccap/shared/data-access';
import { InCountryDto } from './in-country-resource.interface';

@Injectable({
	providedIn: 'root',
})
export class InCountryResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getInCountry(): Observable<InCountryDto> {
		return this.httpService.authGet<InCountryDto>(this.endpoints.inCountry);
	}
}
