import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { AccountResource } from '@ccap/au-pair/data-access/account';
import { UserIdentification } from '../../user-identification';

@Injectable({
	providedIn: 'root',
})
export class IntendedProfileGuard implements CanActivate {
	constructor(
		private router: Router,
		private accountResource: AccountResource,
	) {}

	public canActivate(): Observable<boolean> {
		const userIdentification = new UserIdentification();
		const auPairId = userIdentification.userId();

		if (auPairId) {
			this.router.navigate(['profile', auPairId]);

			return of(false);
		}

		return this.accountResource.getAuPairAccount().pipe(
			tap(({ publicId }) =>
				publicId ? this.router.navigate(['profile', publicId]) : null,
			),
			mapTo(false),
		);
	}
}
