import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { AnswerDropdownModule } from '../answer-dropdown/answer-dropdown.module';
import { AnswerRadioTileModule } from '../answer-radio-tile/answer-radio-tile.module';
import { AnswerAbstractListItemComponent } from './answer-abstract-list-item.component';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		AnswerDropdownModule,
		AnswerRadioTileModule,
	],
	declarations: [AnswerAbstractListItemComponent],
	exports: [AnswerAbstractListItemComponent],
})
export class AnswerAbstractListItemModule {}
