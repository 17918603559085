import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoModule } from '@ccap/shared/ui/core-assets';
import { HeaderModule } from '@ccap/shared/ui/navigation';
import { FooterContainerModule } from '../footer-container';
import { ContentWrapperComponent } from './content-wrapper.component';

@NgModule({
	imports: [CommonModule, HeaderModule, LogoModule, FooterContainerModule],
	declarations: [ContentWrapperComponent],
	exports: [ContentWrapperComponent],
})
export class ContentWrapperModule {}
