import { Action, createReducer, on } from '@ngrx/store';

import { TravelInsuranceDto } from '@ccap/au-pair/data-access/models';
import { updateInsurance } from './insurance.actions';

export type InsuranceState = TravelInsuranceDto | null;

const reducer = createReducer<InsuranceState>(
	null,
	on(updateInsurance, (_, { insurance }) => insurance),
);

export function insuranceReducer(
	state: InsuranceState,
	action: Action,
): InsuranceState {
	return reducer(state, action);
}
