import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarkdownModule } from '@ccap/shared/pipes';
import { ContentWrapperModule } from '../content-wrapper/content-wrapper.module';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';
import { TranslatedContentComponent } from './translated-content.component';

@NgModule({
	imports: [
		CommonModule,
		MarkdownModule,
		ContentWrapperModule,
		LanguageSelectorModule,
	],
	declarations: [TranslatedContentComponent],
	exports: [TranslatedContentComponent],
})
export class TranslatedContentModule {}
