import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconButtonModule } from '@ccap/shared/ui/form';
import { CloseIconComponent } from './close-icon.component';

@NgModule({
	imports: [CommonModule, IconButtonModule],
	declarations: [CloseIconComponent],
	exports: [CloseIconComponent],
})
export class CloseIconModule {}
