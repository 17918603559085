import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { AuPairMetaDataDto } from '@ccap/au-pair/data-access/models';
import { IAnswers } from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class AuPairResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getAupairLife(): Observable<IAnswers> {
		return this.httpService.authGet<IAnswers>(this.endpoints.aupairs);
	}

	public updateAupairLife(aupairLife: Partial<IAnswers>): Observable<IAnswers> {
		return this.httpService.authPatch<IAnswers>(
			this.endpoints.aupairs,
			aupairLife,
		);
	}

	public getMetaData(): Observable<AuPairMetaDataDto> {
		return this.httpService.authGet<AuPairMetaDataDto>(this.endpoints.progress);
	}

	public updateMetaData(
		metaData: Partial<AuPairMetaDataDto>,
	): Observable<void> {
		return this.httpService.authPatch<void>(this.endpoints.progress, metaData);
	}
}
