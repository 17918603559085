import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { InCountryDto } from '../in-country-resource.interface';

export const fetchInCountry = createAction('[InCountry] Get');
export const fetchInCountryFail = createAction(
	'[InCountry] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const updateInCountry = createAction(
	'[InCountry] Update',
	props<{ data: InCountryDto }>(),
);
