import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	ContactDetailsHomeDto,
	ContactDetailsUSADto,
} from '@ccap/au-pair/data-access/models';

export const fetchHomeContactDetails = createAction(
	'[Contact Details] Get Home',
);
export const fetchHomeContactDetailsFail = createAction(
	'[Contact Details] Get Home Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setHomeContactDetails = createAction(
	'[Contact Details] Set Home',
	props<{ contactDetails: Partial<ContactDetailsHomeDto> }>(),
);

export const setHomeContactDetailsFail = createAction(
	'[Contact Details] Set Home Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateHomeContactDetails = createAction(
	'[Contact Details] Update Home',
	props<{ contactDetails: Partial<ContactDetailsHomeDto> }>(),
);

/**
 * Updates the whole state
 */
export const rollbackHomeContactDetails = createAction(
	'[Contact Details] Rollback Home',
	props<{ contactDetails: Partial<ContactDetailsHomeDto> }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchHomeContactDetails = createAction(
	'[Contact Details] Patch Home',
	props<{ contactDetails: Partial<ContactDetailsHomeDto> }>(),
);

// USA Contact Details

export const fetchUSAContactDetails = createAction('[Contact Details] Get USA');
export const fetchUSAContactDetailsFail = createAction(
	'[Contact Details] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setUSAContactDetails = createAction(
	'[Contact Details] Set USA',
	props<{ contactDetails: Partial<ContactDetailsUSADto> }>(),
);

export const setUSAContactDetailsFail = createAction(
	'[Contact Details] Set USA Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updateUSAContactDetails = createAction(
	'[Contact Details] Update USA',
	props<{ contactDetails: Partial<ContactDetailsUSADto> }>(),
);

/**
 * Updates the whole state
 */
export const rollbackUSAContactDetails = createAction(
	'[Contact Details] Rollback USA',
	props<{ contactDetails: Partial<ContactDetailsUSADto> }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchUSAContactDetails = createAction(
	'[Contact Details] Patch USA',
	props<{ contactDetails: Partial<ContactDetailsUSADto> }>(),
);
