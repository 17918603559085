import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonGroupDirective } from './button-group.directive';
import { ButtonDirective } from './button.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [ButtonDirective, ButtonGroupDirective],
	exports: [ButtonDirective, ButtonGroupDirective],
})
export class ButtonModule {}
