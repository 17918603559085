import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnswerSmokingComponent } from './answer-smoking.component';

@NgModule({
	imports: [CommonModule],
	declarations: [AnswerSmokingComponent],
	exports: [AnswerSmokingComponent],
})
export class AnswerSmokingModule {}
