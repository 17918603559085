import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DateValue } from '@ccap/interfaces';
import {
	DateUtils,
	inclusiveGenderByAge,
	BiologicalSex,
} from '@ccap/shared/utils';
import { IdentityModel } from './model';

export const selectIdentity = createFeatureSelector<IdentityModel>('identity');

export const selectIdentityAnswers = createSelector(
	selectIdentity,
	identity => identity?.answers,
);

export const selectIdentityAnswersWithCalculatedAge = createSelector(
	selectIdentityAnswers,
	identityAnswers => {
		if (!identityAnswers) {
			return null;
		}

		let age = null;

		if (identityAnswers.dob) {
			const dateOfBirth = DateUtils.getDateFromDateValue(
				identityAnswers.dob as DateValue,
			);
			age = DateUtils.calculateAge(dateOfBirth);
		}

		return {
			...identityAnswers,
			age,
			gender: inclusiveGenderByAge(
				identityAnswers.gender as BiologicalSex,
				age,
			),
		};
	},
);
