import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Component({
	selector: 'ef-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
	@Input()
	public set name(fullName: string) {
		const [name, namespace] = fullName.split(':');

		this.svgHtml$ = this.iconRegistry.getNamedSvgIcon(name, namespace).pipe(
			map(icon => this.domSanitizer.bypassSecurityTrustHtml(icon.outerHTML)),
			catchError(() => []),
		);
	}

	public svgHtml$?: Observable<SafeHtml>;

	constructor(
		private iconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {}
}
