import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import {
	ContactDetailsHomeDto,
	ContactDetailsUSADto,
} from '@ccap/au-pair/data-access/models';
import {
	patchHomeContactDetails,
	patchUSAContactDetails,
	rollbackHomeContactDetails,
	rollbackUSAContactDetails,
	updateHomeContactDetails,
	updateUSAContactDetails,
} from './contact-details.actions';

export interface ContactDetailsState {
	home: Partial<ContactDetailsHomeDto> | undefined;
	usa: Partial<ContactDetailsUSADto> | undefined;
}

const reducer = combineReducers<ContactDetailsState>({
	home: createReducer<ContactDetailsState['home']>(
		undefined,
		on(
			updateHomeContactDetails,
			rollbackHomeContactDetails,
			(_, { contactDetails }) => contactDetails,
		),
		on(patchHomeContactDetails, (state, { contactDetails }) => ({
			...state,
			...contactDetails,
		})),
	),
	usa: createReducer<ContactDetailsState['usa']>(
		undefined,
		on(
			updateUSAContactDetails,
			rollbackUSAContactDetails,
			(_, { contactDetails }) => contactDetails,
		),
		on(patchUSAContactDetails, (state, { contactDetails }) => ({
			...state,
			...contactDetails,
		})),
	),
});

export function contactDetailsReducer(
	state: ContactDetailsState,
	action: Action,
): ContactDetailsState {
	return reducer(state, action);
}
