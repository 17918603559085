import {
	animate,
	AnimationEvent,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ISectionExtended } from '@ccap/interfaces';
import { ScrollService } from '@ccap/shared/ui';
import { ToggleService } from '../../core/services/toggle.service';

const animationStyles = {
	active: {
		opacity: 1,
		transform: 'scale(1)',
	},
	inactive: {
		opacity: 0,
		transform: 'scale(0)',
		transformOrigin: '50% 0',
	},
	transition: '220ms cubic-bezier(0, 0, .2, 1)',
};

const sectionAnimations = [
	trigger('subSectionActive', [
		transition('false => true', [
			style(animationStyles.inactive),
			animate(animationStyles.transition, style(animationStyles.active)),
		]),
	]),
];

@Component({
	selector: 'app-section',
	templateUrl: 'section.component.html',
	styleUrls: ['section.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: sectionAnimations,
})
export class SectionComponent {
	@Input() public section?: ISectionExtended;
	@ViewChild('sectionElement')
	public sectionElement?: ElementRef;

	constructor(
		private toggleService: ToggleService,
		private scrollService: ScrollService,
	) {}

	public isSectionActive(activeSectionTitle: string): boolean {
		return activeSectionTitle === this.section.title;
	}

	public toggleSection(activeSectionTitle: string) {
		let nextActiveSection = null;

		if (activeSectionTitle !== this.section.title) {
			nextActiveSection = this.section.title;
		}
		this.toggleService.setActiveSection(nextActiveSection);
	}

	public activeSectionTitle$(): BehaviorSubject<string> {
		return this.toggleService.getActiveSection$();
	}

	public onDoneScrollToSection(animationEvent: AnimationEvent) {
		if (animationEvent.fromState !== 'void') {
			this.scrollService.scrollTo(this.sectionElement.nativeElement);
		}
	}
}
