import { createSelector } from '@ngrx/store';

import { getExtension } from '../extension.selectors';

export const getExtensionAnswers = createSelector(
	getExtension,
	({ answers }) => answers,
);

export const getExtensionType = createSelector(
	getExtension,
	({ answers }) => (answers.extensionType || '') as string,
);
