import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Provider } from '@angular/core';

import {
	ApiEndpoints,
	ApiTokens,
	API_ENDPOINTS,
	API_TOKENS,
} from '@ccap/au-pair/data-access';
import { SessionInvalidInterceptor } from '@ccap/auth';
import { CustomDateFormat, CUSTOM_DATE_FORMAT } from '@ccap/shared/pipes';
import { MonitoringConfig, MONITORING_CONFIG } from '@ccap/shared/utils';
import { environment } from '../environments/environment';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { CustomErrorHandler } from './error-handler';

const apiEndpointsFactory = (): ApiEndpoints => ({
	pink: environment.API_ENDPOINT,
	family: environment.API_ENDPOINT_FAMILY,
	schema: environment.SCHEMA_ENDPOINT,
	apollo: environment.APOLLO_ENDPOINT,
	contentful: 'https://cdn.contentful.com/spaces/dae87kefnvx7/entries',
});

const apiTokensFactory = (): ApiTokens => ({
	contentful:
		'2ab911f492e39870c9812df645a8ccfff2efb45407eafc5f1e1c7a9c71600467',
});

const monitoringFactory = (): MonitoringConfig =>
	environment.APP_INSIGHTS_AU_PAIR_PUBLIC_PROFILE;

const customDateFormatFactory = (): CustomDateFormat => 'us';

export const APP_PROVIDERS: Provider[] = [
	{ provide: ErrorHandler, useClass: CustomErrorHandler },
	{ provide: API_ENDPOINTS, useFactory: apiEndpointsFactory, deps: [] },
	{ provide: API_TOKENS, useFactory: apiTokensFactory, deps: [] },
	{ provide: MONITORING_CONFIG, useFactory: monitoringFactory, deps: [] },
	{
		provide: CUSTOM_DATE_FORMAT,
		useFactory: customDateFormatFactory,
		deps: [],
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: SessionInvalidInterceptor,
		multi: true,
	},
	{
		provide: Window,
		useValue: window,
	},
	...APP_RESOLVER_PROVIDERS,
];
