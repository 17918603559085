import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Injectable()
export class ControlToucherService {
	public markAsChecked(control: AbstractControl) {
		if (control instanceof FormArray || control instanceof FormGroup) {
			Object.keys(control.controls).forEach((key: string) => {
				this.markAsChecked(control.controls[key]);
			});
		} else {
			control.markAsTouched();
			control.markAsDirty();
		}
	}
}
