import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ITransitionDetails } from '@ccap/interfaces';

@Component({
	selector: 'app-transition-details',
	templateUrl: './transition-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionDetailsComponent {
	@Input()
	public transitionDetails?: ITransitionDetails;
}
