import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs/operators';

import { MediaResource } from '@ccap/au-pair/data-access/media';
import {
	deleteResourceRemote,
	getAnswers,
	saveAnswersLocal,
	saveMediaFile,
	saveMediaFileMetadata,
	saveMediaFileSuccess,
} from '../../questions/store/questions.actions';
import { IAppState } from '../../reducers';
import {
	addImage,
	deleteImage,
	deleteImageFail,
	fetchImages,
	fetchImagesFail,
	patchImage,
	patchImageMetadata,
	removeImage,
	rollbackImages,
	setImageMetadata,
	setImageMetadataFail,
	updateImages,
	uploadImage,
	uploadImageFail,
} from './image.actions';
import { getImages } from './image.selectors';

@Injectable({ providedIn: 'root' })
export class ImageEffect {
	public getAnswers$ = createEffect(() =>
		this.actions$.pipe(ofType(getAnswers), map(fetchImages)),
	);

	public saveMediaFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveMediaFile),
			filter(({ fileType }) => fileType === 'image'),
			map(({ file, endpointKey }) =>
				uploadImage({ image: file, key: endpointKey }),
			),
		),
	);

	public saveMediaFileMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveMediaFileMetadata),
			filter(({ question }) => question.typeVariations.fileType === 'image'),
			mergeMap(({ answer, question }: any) => [
				setImageMetadata({
					key: answer.key,
					imageId: answer.id,
					metadata: answer.meta,
				}),
				saveAnswersLocal({ questions: [question] }),
			]),
		),
	);

	public deleteResourceRemote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteResourceRemote),
			filter(({ newAnswers: [newAnswer] }) => newAnswer.endpoint === 'image'),
			map(({ newAnswers: [newAnswer], options }: any) =>
				deleteImage({
					key: newAnswer.id,
					imageId: options.resourceId,
				}),
			),
		),
	);

	public fetchImages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchImages),
			switchMap(() =>
				this.mediaResource.getImages().pipe(
					map(images => updateImages({ images })),
					catchError(error => of(fetchImagesFail({ error }))),
				),
			),
		),
	);

	public uploadImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadImage),
			// concatMap fixes race condition on BE side
			concatMap(action => {
				const sameAction$ = this.actions$.pipe(
					ofType(uploadImage),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.mediaResource.uploadImage(action.key, action.image).pipe(
					takeUntil(sameAction$),
					mergeMap(image => [
						addImage({ image }),
						saveMediaFileSuccess({
							questionID: action.key,
							file: image,
						}),
					]),
					catchError(error => of(uploadImageFail({ error }))),
				);
			}),
		),
	);

	public setImageMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setImageMetadata),
			withLatestFrom(this.store.select(getImages)),
			mergeMap(([action, oldImages]) => {
				const sameAction$ = this.actions$.pipe(
					ofType(setImageMetadata),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.mediaResource
					.updateImageMetadata(action.key, action.imageId, action.metadata)
					.pipe(
						takeUntil(sameAction$),
						map(image => patchImage({ image })),
						catchError(error => [
							setImageMetadataFail({ error }),
							rollbackImages({ images: oldImages }),
						]),
						startWith(
							patchImageMetadata({
								imageId: action.imageId,
								metadata: action.metadata,
							}),
						),
					);
			}),
		),
	);

	public deleteImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteImage),
			withLatestFrom(this.store.select(getImages)),
			// concatMap fixes race condition on BE side
			concatMap(([action, oldImages]) => {
				return this.mediaResource.deleteImage(action.key, action.imageId).pipe(
					map(() =>
						removeImage({
							imageId: action.imageId,
						}),
					),
					catchError(error => [
						deleteImageFail({ error }),
						rollbackImages({ images: oldImages }),
					]),
				);
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private mediaResource: MediaResource,
	) {}
}
