<div
	class="tabs"
	[class.tabs--default-theme]="theme === 'default'"
	[class.tabs--box-theme]="theme === 'box'"
>
	<div class="tabs__item-list">
		<ccap-tab-button
			*ngFor="let tab of tabs; index as i; trackBy: trackByIndex"
			[theme]="theme"
			(click)="setActiveTab(i)"
			class="tabs__item"
			[isActive]="activeTabIndex === i"
		>
			{{ tab.title }}
		</ccap-tab-button>
	</div>

	<div class="tabs__content" *ngIf="activeTabIndex !== null">
		<ng-container *ngFor="let tab of tabs; index as i; trackBy: trackByIndex">
			<ng-container *ngIf="activeTabIndex === i">
				<ng-container *ngTemplateOutlet="tab.contentTemplate"> </ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>
