<button
	efButton
	[outlined]="true"
	[square]="true"
	type="button"
	class="hide-for-print"
	(click)="print()"
>
	Print the profile
</button>
