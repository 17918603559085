import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isEqual from 'lodash/isEqual';
import { of } from 'rxjs';
import {
	catchError,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs/operators';

import { HealthResource } from '@ccap/au-pair/data-access/health';
import {
	getAnswers,
	saveAnswersRemote,
} from '../../questions/store/questions.actions';
import { IAppState } from '../../reducers';
import {
	fetchHealth,
	fetchHealthFail,
	patchHealth,
	rollbackHealth,
	setHealth,
	setHealthFail,
	updateHealth,
} from './health.actions';
import { getHealth } from './health.selectors';

@Injectable({ providedIn: 'root' })
export class HealthEffect {
	public getAnswers$ = createEffect(() =>
		this.actions$.pipe(ofType(getAnswers), map(fetchHealth)),
	);

	public saveAnswersRemote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveAnswersRemote),
			filter(({ newAnswers: [question] }) => question.endpoint === 'health'),
			map(({ newAnswers }) => {
				const health = {};
				newAnswers.forEach(({ answer, id }) => (health[id] = answer));
				return setHealth({ health });
			}),
		),
	);

	public fetchHealth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchHealth),
			switchMap(() =>
				this.healthResource.getHealth().pipe(
					map(health => updateHealth({ health })),
					catchError(error => of(fetchHealthFail({ error }))),
				),
			),
		),
	);

	public setHealth$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setHealth),
			withLatestFrom(this.store.select(getHealth)),
			mergeMap(([action, oldHealth]) => {
				const sameAction$ = this.actions$.pipe(
					ofType(setHealth),
					take(1),
					filter(newAction => isEqual(action, newAction)),
				);

				return this.healthResource.updateHealth(action.health).pipe(
					takeUntil(sameAction$),
					map(health => updateHealth({ health })),
					catchError(error => [
						setHealthFail({ error }),
						rollbackHealth({ health: oldHealth }),
					]),
					startWith(patchHealth({ health: action.health })),
				);
			}),
		),
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private healthResource: HealthResource,
	) {}
}
