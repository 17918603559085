import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HealthResource } from '@ccap/au-pair/data-access/health';
import { AuPairResource } from '@ccap/au-pair/data-access/life';
import { IQuestionConditionalAgreement } from '@ccap/interfaces';

@Component({
	selector: 'app-answer-smoking',
	templateUrl: 'answer-smoking.component.html',
	styleUrls: ['answer-smoking.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerSmokingComponent implements OnInit {
	@Input() public question?: IQuestionConditionalAgreement;

	public acceptedDate$?: Observable<string>;
	public auPairName$?: Observable<string>;

	constructor(
		private aupairResource: AuPairResource,
		private healthResource: HealthResource,
	) {}

	public ngOnInit(): void {
		this.auPairName$ = this.aupairResource
			.getAupairLife()
			.pipe(map(({ firstName, lastName }) => `${firstName} ${lastName}`));

		this.acceptedDate$ = this.healthResource
			.getHealth()
			.pipe(
				map(
					({ smokingAgreementAcceptedDateTimeUtc }) =>
						smokingAgreementAcceptedDateTimeUtc,
				),
			);
	}
}
