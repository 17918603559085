import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MoreLessModule } from '../more-less/more-less.module';
import { MoreLessTextComponent } from './more-less-text.component';

@NgModule({
	imports: [MoreLessModule, CommonModule],
	declarations: [MoreLessTextComponent],
	exports: [MoreLessTextComponent],
})
export class MoreLessTextModule {}
