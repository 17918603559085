import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'multiChoiceDict',
})
export class MultiChoiceDictPipe implements PipeTransform {
	public transform(value: Record<string, boolean>): string {
		return Object.keys(value)
			.filter(key => value[key])
			.join(', ');
	}
}
