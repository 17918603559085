import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@ccap/au-pair/data-access';
import { AnswerResource } from '@ccap/au-pair/data-access/answers';
import { ContactDetailsResource } from '@ccap/au-pair/data-access/contact-details';
import { HealthResource } from '@ccap/au-pair/data-access/health';
import { AuPairResource } from '@ccap/au-pair/data-access/life';
import { MediaResource } from '@ccap/au-pair/data-access/media';
import { ParentsResource } from '@ccap/au-pair/data-access/parents';
import { QuestionSchemaResource } from '@ccap/au-pair/data-access/questions';
import {
	ChildcareReferenceResource,
	PersonalReferenceResource,
} from '@ccap/au-pair/data-access/reference';
import { WorkExperienceResource } from '@ccap/au-pair/data-access/work-experience';
import { InCountryDto, InCountryResource } from '@ccap/au-pair/in-country';
import {
	FileType,
	IAnswers,
	IMediaFile,
	IMediaFileExtended,
} from '@ccap/interfaces';
import { HttpService } from '@ccap/shared/data-access';
import {
	IServerResponseMedia,
	IAggregatedContentExtended,
} from '../../../interfaces/aggregator';
import { SummaryCardsAggregated } from '../../../interfaces/summary-cards';

/**
 * These methods will eventually live within their respective feature modules
 */
@Injectable()
export class HttpSandboxService {
	constructor(
		private httpService: HttpService,
		private endpoints: Endpoints,
		private mediaResource: MediaResource,
		private answerResource: AnswerResource,
		private aupairResource: AuPairResource,
		private contactDetailsResource: ContactDetailsResource,
		private healthResource: HealthResource,
		private childcareReferenceResource: ChildcareReferenceResource,
		private personalReferenceResource: PersonalReferenceResource,
		private questionResource: QuestionSchemaResource,
		private parentResource: ParentsResource,
		private experienceResource: WorkExperienceResource,
		private inCountryResource: InCountryResource,
	) {}

	public getMedia(): Observable<IServerResponseMedia> {
		return forkJoin([
			this.mediaResource.getImages(),
			this.mediaResource.getVideos(),
			this.questionResource.getSchema(),
		]).pipe(
			map(
				([imagesResponse, videosResponse, schema]: [
					IMediaFile[],
					IMediaFile[],
					any,
				]) => {
					return {
						response: {
							images: imagesResponse
								.filter((file: IMediaFile) => file.key !== 'profilephoto')
								.map((file: IMediaFile) => ({
									...file,
									type: 'image' as FileType,
								})),
							video: videosResponse.map(
								(videoFile: IMediaFile): IMediaFileExtended => {
									const videoQuestion = Object.keys(schema).find(
										(questionKey: string) =>
											questionKey.toLowerCase() === videoFile.key.toLowerCase(),
									);

									return {
										...videoFile,
										type: 'video',
										question: schema[videoQuestion].content.question,
									};
								},
							),
						},
					};
				},
			),
		);
	}

	public getContent(
		endpointsToExclude: string[],
	): Observable<IAggregatedContentExtended> {
		const exclusions = new Set(endpointsToExclude);
		const endpointCollection = [
			this.questionResource.getSchema(),
			this.personalReferenceResource.getPersonalReferences(),
			this.childcareReferenceResource.getChildcareReferences(),
			this.mediaResource.getImages(),
			this.mediaResource.getVideos(),
			this.answerResource.getInterview(),
			this.aupairResource.getAupairLife(),
			this.parentResource.getParents(),
			this.answerResource.getProfile(),
			this.experienceResource.getWorkExperience(),
			exclusions.has('health') ? of({}) : this.healthResource.getHealth(),
			...(exclusions.has('contactdetails')
				? [of({}), of({})]
				: [
						this.contactDetailsResource.getUSAContactDetails(),
						this.contactDetailsResource.getHomeContactDetails(),
				  ]),
		];

		return forkJoin(endpointCollection).pipe(
			map(res => {
				const [schemaObject, ...rest] = res;
				const schema = Object.keys(schemaObject).map(key => schemaObject[key]);

				const [
					personalReferences,
					childcareReferences,
					images,
					videos,
					interview,
					aupair,
					parents,
					profile,
					workExperience,
					health,
					unitedStatesContactDetails,
					contactDetails,
				]: any[] = rest;

				const groupedImgs = this.groupFilesByKey(images);
				const groupedVids = this.groupFilesByKey(videos);
				const mediaObjects = { ...groupedImgs, ...groupedVids };

				const endpointsData: IAnswers = {
					personalReferences,
					childcareReferences,
					interview,
					...workExperience,
					...aupair,
					...profile,
					parents,
					...(health || {}),
					...(unitedStatesContactDetails || {}),
					...(contactDetails || {}),
				};

				return {
					questions: schema,
					answers: { ...endpointsData, ...mediaObjects },
					profilephoto: images.filter(
						(mediaFile: IMediaFile) => mediaFile.key === 'profilephoto',
					),
				};
			}),
		);
	}

	public getInCountry$(): Observable<InCountryDto> {
		return this.inCountryResource.getInCountry();
	}

	public getDownloadableFile$(endpointUrl: string): Observable<Blob> {
		return this.httpService.authGet(endpointUrl, { type: 'blob' });
	}

	public getSummaryCards$(): Observable<SummaryCardsAggregated> {
		return zip(
			this.answerResource.getProfile(),
			this.aupairResource.getAupairLife(),
			this.childcareReferenceResource.getChildcareReferences(),
			this.answerResource.getInterview(),
		).pipe(
			map(([profileAnswers, answers, childcareReferences, interview]) => {
				const {
					isSpecialNeedsQualified,
					willingToBeSpecialNeedsCarer,
					hasSpecialDiet,
					specialDiet,
					preferredAgeRangeOfChildren,
				} = profileAnswers;

				const {
					drivingFrequency,
					hasDrivingLicense,
					anticipatedDrivingLicenseDate,
					drivingTrainingSinceDate,
					drivingSinceDate,
					drivingTransmission,
					drivingConditions,
					siblings,
					actualGroupArrival,
					latestGroupArrival,
				} = answers;

				const { englishProficiency } = interview;

				return {
					isSpecialNeedsQualified,
					willingToBeSpecialNeedsCarer,
					hasSpecialDiet,
					specialDiet,
					interview,
					childcareReferences,
					drivingFrequency,
					hasDrivingLicense,
					anticipatedDrivingLicenseDate,
					drivingTrainingSinceDate,
					drivingSinceDate,
					drivingTransmission,
					drivingConditions,
					siblings,
					preferredAgeRangeOfChildren,
					actualGroupArrival,
					latestGroupArrival,
					englishProficiency,
				};
			}),
		);
	}

	public getMedicalNote$(): Observable<IMediaFile> {
		// The backend returns an array of documents but we only want to allow the
		// latest uploaded medical note to be downloadable.
		// The last element in the array represents the latest uploaded medical note.
		return this.httpService
			.authGet(`${this.endpoints.documentsByKey}/translatedMedicalNote`)
			.pipe(map((res: IMediaFile[]) => res[res.length - 1]));
	}

	public getRestrictedAccess$(): Observable<string[]> {
		return this.httpService.authGet(this.endpoints.restrictedaccess);
	}

	private groupFilesByKey(files: IMediaFile[]) {
		return files.reduce((acc, file: IMediaFile) => {
			if (!Array.isArray(acc[file.key])) {
				acc[file.key] = [];
			}
			acc[file.key].push(file);

			return acc;
		}, {});
	}
}
