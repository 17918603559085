import {
	IAbstractListValue,
	IAnswer,
	IQuestionExtended,
	IQuestionListItem,
	IQuestionWithValidation,
} from '@ccap/interfaces';
import { QuestionsState } from '../store/questions.state';
import { getValidationErrors } from './questions-validation.utils';

export function getParentQuestion(
	state: QuestionsState,
	question: IQuestionExtended,
	questionsObject: QuestionsState,
): IQuestionWithValidation {
	if (!question.dependency || !question.dependency.parent) {
		return null;
	}

	const parentID = question.dependency.parent;
	const parent = questionsObject[parentID] || state[parentID];

	return parent;
}

export function isQuestionVisible(
	questionToEvaluate: IQuestionExtended,
	dependantQuestionAnswer: IAnswer,
): boolean {
	return questionToEvaluate.dependency.visibleWhenRootEquals.includes(
		dependantQuestionAnswer,
	);
}

export function isQuestionEnabled(
	state: QuestionsState,
	question: IQuestionExtended,
	questionsObject: QuestionsState,
): boolean {
	const parent = getParentQuestion(state, question, questionsObject);

	const isSameQuestion = !parent || question.id === parent.id;

	if (isSameQuestion) {
		return true;
	}

	const isVisible = isQuestionVisible(question, parent.answer);

	if (!!parent && isVisible) {
		return isQuestionEnabled(state, parent, questionsObject);
	}

	return isVisible;
}

export function isQuestionItemVisible(
	state: QuestionsState,
	questionItemId: string,
	currentAnswerIndex: number,
) {
	const { question, nestedQuestion } = getQuestionAndNestedQuestion(
		questionItemId,
		state,
	);

	if (
		!question.answer ||
		!(question.answer as any[]).length ||
		(question.answer as any[]).length === 0 ||
		!(nestedQuestion.dependency && nestedQuestion.dependency.parent)
	) {
		return true;
	}
	const dependantNestedQuestionId = nestedQuestion.dependency.parent;

	const answers = question.answer as IAbstractListValue[];
	const currentAnswer = answers[currentAnswerIndex];
	let dependantAnswer: string;
	try {
		dependantAnswer = currentAnswer[dependantNestedQuestionId].toString();
	} catch (error) {
		return true;
	}

	const isDifferentQuestion =
		dependantNestedQuestionId && questionItemId !== dependantNestedQuestionId;

	return isDifferentQuestion
		? isQuestionVisible(nestedQuestion as IQuestionExtended, dependantAnswer)
		: true;
}

export function getQuestionAndNestedQuestion(
	questionId: string,
	state: QuestionsState,
): { question: IQuestionWithValidation; nestedQuestion?: IQuestionListItem } {
	for (const key in state) {
		if (state[key]) {
			const question = state[key];

			if (question.id === questionId) {
				return {
					question,
				};
			}

			if (
				!question.typeVariations ||
				!question.typeVariations.questionListItems
			) {
				continue;
			}

			for (const nestedQuestion of question.typeVariations.questionListItems) {
				if (nestedQuestion.id === questionId) {
					return {
						question,
						nestedQuestion,
					};
				}
			}
		}
	}

	return {
		question: null,
	};
}

export function getQuestionOrNested(
	questionId: string,
	state: QuestionsState,
): IQuestionWithValidation {
	for (const key in state) {
		if (state[key]) {
			const question = state[key];

			if (question.id === questionId) {
				return question;
			}

			if (
				!question.typeVariations ||
				!question.typeVariations.questionListItems
			) {
				continue;
			}

			for (const nestedQuestion of question.typeVariations.questionListItems) {
				if (nestedQuestion.id === questionId) {
					return nestedQuestion as any;
				}
			}
		}
	}
}

export function getUpdatedParentQuestionWithChild(
	childQuestion: IQuestionWithValidation,
	state: QuestionsState,
) {
	const { question, nestedQuestion } = getQuestionAndNestedQuestion(
		childQuestion.id,
		state,
	);

	const newState = {
		...question,
		typeVariations: {
			...question.typeVariations,
		},
	};

	if (nestedQuestion && newState.typeVariations.questionListItems) {
		newState.typeVariations.questionListItems = newState.typeVariations.questionListItems.map(
			nestedQuest => {
				if (nestedQuest.id === nestedQuestion.id) {
					return childQuestion;
				}

				return nestedQuest;
			},
		);
	}

	return newState;
}

export function getFollowUpChain(
	state: QuestionsState,
	questions: IQuestionWithValidation[],
): IQuestionWithValidation[] {
	if (
		!questions ||
		questions.length === 0 ||
		!questions.some(
			question => question.followups && question.followups.length > 0,
		)
	) {
		return [];
	}

	const followups = questions
		.filter(q => q.followups && q.followups.length > 0)
		.reduce((acc, q) => [...acc, ...q.followups], [])
		.map((key: string) => state[key])
		.filter(Boolean);

	return [...followups, ...getFollowUpChain(state, followups)];
}

export function derivedQuestionState(
	enabled: boolean,
	question: IQuestionWithValidation,
) {
	const errors = enabled ? getValidationErrors(question) : null;

	return {
		valid: !errors,
		enabled,
		errors,
	};
}
