import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractModalBase } from './abstract-modal-base';
import { ModalComponentFactoryService } from './modal-component-factory.service';

/**
 * @whatItDoes
 * Wraps and Exposes the ModalComponentFactoryService,
 * The ModalService class and methods directly receive the requests
 * and then pass the requests to the ModalComponentFactoryService.
 *
 * @howToUse
 * Inject this to the component you want to have an interaction through a modal
 */

@Injectable()
export class ModalService {
	constructor(
		private modalComponentFactoryService: ModalComponentFactoryService,
	) {}

	public openModal(
		component: Type<AbstractModalBase>,
		params?: any,
	): Observable<any> {
		return this.modalComponentFactoryService.addModal(component, params);
	}

	// Allow external call to get all modal components.
	public getModalList(): AbstractModalBase[] {
		return this.modalComponentFactoryService.modals;
	}

	// Allow external call to close modals.
	public clearAllModals() {
		this.modalComponentFactoryService.removeAllModals();
	}
}
