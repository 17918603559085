import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
} from '@angular/core';
import { OnInit } from '@angular/core';
import { noop, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { ProfileService } from '../../../core/services/profile.service';
import { LinkSandboxService } from '../link-sandbox.service';
import { ILinkData, LinkStatus } from '../../../../interfaces/links';

const likeDataToStatus = (like: ILinkData): LinkStatus => {
	return LinkStatus[like.status];
};

@Component({
	selector: 'app-like',
	templateUrl: './like.component.html',
	styleUrls: ['./like.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikeComponent implements OnInit {
	public auPairId = this.profileService.getCurrentProfileId();

	public ready$?: Observable<boolean>;

	public likeData$?: Observable<ILinkData>;
	public likeStatus$?: Observable<LinkStatus>;

	public busy = false;

	constructor(
		private linkSandbox: LinkSandboxService,
		private profileService: ProfileService,
	) {}

	public ngOnInit(): void {
		this.linkSandbox.fetchAuPairLink(this.auPairId);

		this.ready$ = this.linkSandbox
			.getAuPairConnection(this.auPairId)
			.pipe(map(Boolean), distinctUntilChanged());

		this.likeData$ = this.linkSandbox.getAuPairLike(this.auPairId);
		this.likeStatus$ = this.likeData$.pipe(
			map(likeDataToStatus),
			distinctUntilChanged(),
		);
	}

	@HostListener('click')
	public onClick() {
		this.likeToggle();
	}

	private likeToggle(): Promise<void> {
		if (this.busy) {
			return;
		}

		this.busy = true;

		this.likeData$
			.pipe(
				first(),
				switchMap(likeData => {
					const isLikeAllowed = this.linkSandbox.isStatusPermitted(
						likeData.permittedStatuses,
						LinkStatus.liked,
					);

					const isNoStatusAllowed = this.linkSandbox.isStatusPermitted(
						likeData.permittedStatuses,
						LinkStatus.none,
					);

					if (isLikeAllowed) {
						return this.linkSandbox.likeAuPair(this.auPairId);
					}

					if (isNoStatusAllowed) {
						return this.linkSandbox.removeLikeOfAuPair(this.auPairId);
					}

					return [];
				}),
			)
			.subscribe(
				noop,
				() => (this.busy = false),
				() => (this.busy = false),
			);
	}
}
