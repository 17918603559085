import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { EmbassyAppointment } from '@ccap/au-pair/data-access/models';
import { VisaState } from './visa.model';

export const fetchVisa = createAction('[Visa] Get');
export const fetchVisaSuccess = createAction(
	'[Visa] Get Success',
	props<{ visa: VisaState }>(),
);
export const fetchVisaFail = createAction(
	'[Visa] Get Fail',
	props<{ error: HttpErrorResponse }>(),
);

export const setEmbassyAppointment = createAction(
	'[Visa] Set Embassy Appointment',
	props<{ embassyAppointment: EmbassyAppointment }>(),
);
export const setEmbassyAppointmentSuccess = createAction(
	'[Visa] Set Embassy Appointment Success',
	props<{ embassyAppointment: EmbassyAppointment }>(),
);
export const setEmbassyAppointmentFail = createAction(
	'[Visa] Set Embassy Appointment Fail',
	props<{ error: HttpErrorResponse }>(),
);
