import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import {
	PersonalityTestResultsDto,
	PersonalityTestStatusDto,
} from '@ccap/au-pair/data-access/models';

@Component({
	selector: 'app-personality-test-results',
	templateUrl: 'personality-test-results.component.html',
	styleUrls: ['personality-test-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalityTestResultsComponent implements OnInit {
	@Input() public personalityTestStatus$?: Observable<PersonalityTestStatusDto>;
	@Input()
	public personalityTestResults$?: Observable<PersonalityTestResultsDto>;
	@Input() public fileIdentifier = 'personality-test';

	public isSubmitted$ = new BehaviorSubject(false);
	public hasResults$ = new BehaviorSubject(false);
	public overviewURL =
		'http://res.cloudinary.com/culturalcare/image/upload/v1519029126/resources/NeuroColor_Overview.pdf';
	public documentUrl = this.endpoints.personalityTestReport;

	constructor(private endpoints: Endpoints) {}

	public ngOnInit() {
		this.personalityTestStatus$.subscribe(
			(status: PersonalityTestStatusDto) => {
				this.isSubmitted$.next(status.isSubmitted);
				this.hasResults$.next(status.hasResults);
			},
		);
	}
}
