import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PipesModule } from '@ccap/shared/pipes';
import { SvgModule } from '@ccap/shared/ui/core-assets';
import { AnswerTileListHouseholdComponent } from './answer-tile-list-household.component';

@NgModule({
	imports: [CommonModule, SvgModule, PipesModule],
	declarations: [AnswerTileListHouseholdComponent],
	exports: [AnswerTileListHouseholdComponent],
})
export class AnswerTileListHouseholdModule {}
