import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { E2eModule } from '@ccap/e2e';

import { IconModule } from '@ccap/ef-ui/components';
import { ValidationComponent } from './validation.component';

@NgModule({
	imports: [CommonModule, IconModule, E2eModule],
	exports: [ValidationComponent],
	declarations: [ValidationComponent],
})
export class ValidationModule {}
