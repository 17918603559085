import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QuestionAnswerGroupComponent } from './question-answer-group/question-answer-group.component';
import { QuestionAnswerComponent } from './question-answer.component';

@NgModule({
	declarations: [QuestionAnswerComponent, QuestionAnswerGroupComponent],
	exports: [QuestionAnswerComponent, QuestionAnswerGroupComponent],
	imports: [CommonModule],
})
export class QuestionAnswerModule {}
