import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PagesEffects } from './store/pages.effects';
import { auPairPagesReducer, auPairPagesStateKey } from './store/pages.reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(auPairPagesStateKey, auPairPagesReducer),
		EffectsModule.forFeature([PagesEffects]),
	],
})
export class AuPairDataAccessPagesModule {}
