import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { AuthService } from './auth.service';
import { INTENDED_ROUTE_KEY } from './components/auth-callback.component';
import { AuthRoles, userHasRoles } from './auth-role';
import { AuthConfig } from './auth-config-interface';
import { AUTH_CONFIG } from './auth-injection-token';

export const shouldRedirectToMatchLogin = () => {
	return userHasRoles([AuthRoles.HostFamily, AuthRoles.AuPair]);
};

/**
 * Require authentication for route activation. Navigate un-authenticated users to the auth route.
 */
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document,
		@Inject(AUTH_CONFIG) private config: AuthConfig,
	) {
		// Runs only once, even if multiple routes are using this guard, and the user navigates around
		const isAuthenticated = this.authService.isAuthenticated();
		if (isAuthenticated) {
			this.authService.scheduleTokenRefresh();
		}
	}

	public async canActivate(): Promise<boolean> {
		const isAuthenticated = this.authService.isAuthenticated();

		if (isAuthenticated) {
			return true;
		}

		await this.authService.renewToken();

		const isAuthenticatedViaSSO = this.authService.isAuthenticated();

		if (isAuthenticatedViaSSO) {
			return true;
		}

		const params = new URLSearchParams(this.activatedRoute.snapshot.fragment);
		sessionStorage.setItem(INTENDED_ROUTE_KEY, this.document.location.href);

		if (shouldRedirectToMatchLogin()) {
			this.document.location.href = this.config.matchLoginUrl;
		} else {
			this.router.navigate(['auth'], {
				queryParams: {
					error: params.get('error'),
					error_description: params.get('error_description'),
				},
			});
		}

		return false;
	}
}
