import { Action, combineReducers, createReducer, on } from '@ngrx/store';

import { createAsyncReducer, queueReducers } from '@ccap/state';
import {
	AccountDataState,
	AsyncAccountState,
} from '../models/account-state.model';
import {
	createAccountAction,
	createAccountFailAction,
	createAccountSuccessAction,
	getAccountDetailsAction,
	getAccountDetailsFailAction,
	getAccountDetailsSuccessAction,
	resetPasswordRequestAction,
	resetPasswordRequestFailAction,
	resetPasswordRequestSuccessAction,
	updateAccountEmailAction,
	updateAccountEmailFailAction,
	updateAccountEmailSuccessAction,
} from './account.actions';

export const initialAccountState: AccountDataState = {
	accountExists: null,
	isApproved: false,
	publicId: null,
	legacyId: null,
	loginType: null,
};

const asyncReducer = createAsyncReducer<AccountDataState>({
	setLoadingActions: [
		getAccountDetailsAction,
		createAccountAction,
		updateAccountEmailAction,
		resetPasswordRequestAction,
	],

	setLoadedActions: [
		getAccountDetailsSuccessAction,
		createAccountSuccessAction,
		updateAccountEmailSuccessAction,
		resetPasswordRequestSuccessAction,
	],

	setErrorActions: [
		getAccountDetailsFailAction,
		createAccountFailAction,
		updateAccountEmailFailAction,
		resetPasswordRequestFailAction,
	],
});

const reducer = combineReducers<AsyncAccountState>({
	status: state => state,

	error: state => state,

	data: createReducer<AccountDataState>(
		initialAccountState,
		on(getAccountDetailsSuccessAction, (state, action) => ({
			...state,
			...action.payload,
		})),
		on(createAccountSuccessAction, (state, action) => ({
			...state,
			...action.payload,
			accountExists: true,
		})),
	),
});

export function accountReducer(
	state: AsyncAccountState,
	action: Action,
): AsyncAccountState {
	return queueReducers(asyncReducer, reducer)(state, action);
}
