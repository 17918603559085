import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@ccap/au-pair/data-access';
import { HealthDto } from '@ccap/au-pair/data-access/models';
import { HttpService } from '@ccap/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class HealthResource {
	constructor(private httpService: HttpService, private endpoints: Endpoints) {}

	public getHealth(): Observable<HealthDto> {
		return this.httpService.authGet<HealthDto>(this.endpoints.health);
	}

	public updateHealth(health: Partial<HealthDto>): Observable<HealthDto> {
		return this.httpService.authPatch<HealthDto>(this.endpoints.health, health);
	}
}
