import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoModule } from '@ccap/shared/ui/core-assets';
import { NeoHeaderWrapperModule } from '@ccap/shared/ui/layout';
import { HeaderComponent } from './header.component';

@NgModule({
	imports: [CommonModule, LogoModule, NeoHeaderWrapperModule],
	declarations: [HeaderComponent],
	exports: [HeaderComponent],
})
export class HeaderModule {}
