<ng-container
	[ngTemplateOutlet]="sectionTpl"
	[ngTemplateOutletContext]="{
		activeSectionTitle: activeSectionTitle$() | async
	}"
>
</ng-container>

<ng-template #sectionTpl let-activeSectionTitle="activeSectionTitle">
	<section
		class="section__wrapper"
		#sectionElement
		[@subSectionActive]="isSectionActive(activeSectionTitle)"
		(@subSectionActive.done)="onDoneScrollToSection($event)"
	>
		<div
			class="section__link"
			[class.section__link--active]="isSectionActive(activeSectionTitle)"
			(click)="toggleSection(activeSectionTitle)"
		>
			<h4 class="ef-h4">{{ section.title }}</h4>
			<p>{{ section.description.text }}</p>
		</div>

		<div
			[class.section__content--hidden]="!isSectionActive(activeSectionTitle)"
		>
			<ng-content></ng-content>
		</div>
	</section>
</ng-template>
