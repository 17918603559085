import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
	PersonalityTestAnswersDto,
	PersonalityTestResultsDto,
	PersonalityTestStatusDto,
} from '@ccap/au-pair/data-access/models';

/**
 *  Answers
 */

export const fetchPersonalityTestAnswers = createAction(
	'[Personality Test] Get Answers',
);
export const fetchPersonalityTestAnswersFail = createAction(
	'[Personality Test] Get Answers Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Patches values in state **and updates on server**.
 */
export const setPersonalityTestAnswers = createAction(
	'[Personality Test] Set Answers',
	props<{ answers: PersonalityTestAnswersDto }>(),
);

export const setPersonalityTestAnswersFail = createAction(
	'[Personality Test] Set Answers Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePersonalityTestAnswers = createAction(
	'[Personality Test] Update Answers',
	props<{ answers: PersonalityTestAnswersDto }>(),
);

/**
 * Updates the whole state
 */
export const rollbackPersonalityTestAnswers = createAction(
	'[Personality Test] Rollback Answers',
	props<{ answers: PersonalityTestAnswersDto }>(),
);

/**
 * Patches values in state.
 * Does **not** result in an HTTP request
 */
export const patchPersonalityTestAnswers = createAction(
	'[Personality Test] Patch Answers',
	props<{ answers: PersonalityTestAnswersDto }>(),
);

/**
 *  Results
 */

export const fetchPersonalityTestResults = createAction(
	'[Personality Test] Get Results',
);
export const fetchPersonalityTestResultsFail = createAction(
	'[Personality Test] Get Results Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePersonalityTestResults = createAction(
	'[Personality Test] Update Results',
	props<{ results: PersonalityTestResultsDto }>(),
);

/**
 *  Status
 */

export const fetchPersonalityTestStatus = createAction(
	'[Personality Test] Get Status',
);
export const fetchPersonalityTestStatusFail = createAction(
	'[Personality Test] Get Status Fail',
	props<{ error: HttpErrorResponse }>(),
);

/**
 * Updates the whole state
 */
export const updatePersonalityTestStatus = createAction(
	'[Personality Test] Update Status',
	props<{ status: PersonalityTestStatusDto }>(),
);
