import JwtDecode from 'jwt-decode';

export const getUserPropertyFromJwt = (property: string) => {
	try {
		const token = localStorage.getItem('token');
		return JwtDecode(token)[property];
	} catch (e) {
		return [];
	}
};
