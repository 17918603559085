import { Action, createReducer, on } from '@ngrx/store';

import { AuPairFrontPageWidgetsDto } from '@ccap/interfaces';
import { fetchFrontPageWidgetsSuccess } from './front-page-widgets.actions';

export type AuPairFrontPageWidgetsState = AuPairFrontPageWidgetsDto;

const reducer = createReducer<AuPairFrontPageWidgetsState>(
	[],
	on(
		fetchFrontPageWidgetsSuccess,
		(_, { frontPageWidgets }) => frontPageWidgets,
	),
);

export function auPairFrontPageWidgetsReducer(
	state: AuPairFrontPageWidgetsState,
	action: Action,
): AuPairFrontPageWidgetsState {
	return reducer(state, action);
}
