import { Inject, Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

import { DateValue } from '@ccap/interfaces';
import { DateUtils } from '@ccap/shared/utils';
import { CustomDateFormat, CUSTOM_DATE_FORMAT } from './custom-date.config';

@Pipe({
	name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
	private dateValue?: Partial<DateValue>;

	constructor(
		@Inject(CUSTOM_DATE_FORMAT) private dateFormat: CustomDateFormat,
	) {}

	public transform(value: any): string {
		if (this.customDateIsNotValid(value)) {
			return value;
		}

		this.dateValue = value;

		return format(this.dateObject, this.formattedToken);
	}

	private get dateObject() {
		const date = new Date();

		if (this.dateValue.YYYY) {
			date.setFullYear(+this.dateValue.YYYY);
		}

		if (this.dateValue.MM) {
			date.setMonth(DateUtils.monthNameToNumber(this.dateValue.MM));
		}

		if (this.dateValue.DD) {
			date.setDate(+this.dateValue.DD);
		}

		return date;
	}

	private get formattedToken(): string {
		let token: string;

		if (this.dateFormat === 'us') {
			token = `${this.monthToken} ${this.dayToken}${this.usDayYearSeparator} ${this.yearToken}`;
		} else {
			token = `${this.dayToken} ${this.monthToken} ${this.yearToken}`;
		}

		return token.trim();
	}

	private get usDayYearSeparator(): string {
		return this.dateValue.YYYY && this.dateValue.MM && this.dateValue.DD
			? ','
			: '';
	}

	private get yearToken(): string {
		return this.dateValue.YYYY ? 'yyyy' : '';
	}

	private get monthToken(): string {
		return this.dateValue.MM ? 'MMMM' : '';
	}

	private get dayToken(): string {
		return this.dateValue.DD ? 'dd' : '';
	}

	private customDateIsNotValid(value: any) {
		if (!value || (!value.DD && !value.MM && !value.YYYY)) {
			return true;
		}

		return false;
	}
}
