import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
	DatepickerOptions,
	IAnswer,
	IQuestionExtended,
	IQuestionListItem,
	ISavedDateValue,
	Option,
	QuestionType,
} from '@ccap/interfaces';

export interface LabeledField {
	id: string;
	label: string;
	value: IAnswer;
	type: QuestionType;
	options?: Option[] | DatepickerOptions;
}

@Component({
	selector: 'app-answer-abstract-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: '',
})
export class AnswerAbstractListComponent {
	@Input() public question?: IQuestionExtended;

	public answer(): IAnswer {
		return this.question.answer || [];
	}

	public getLabelsForFields(answer: IAnswer): LabeledField[] {
		const standardFields = this.getStandardFields(answer);
		const additionalFields = this.question.typeVariations.additionalFields
			? this.getAdditionalFields(answer)
			: [];
		return [...standardFields, ...additionalFields];
	}

	private getStandardFields(answer: IAnswer): LabeledField[] {
		return this.question.typeVariations.questionListItems.map(
			(questionListItem: IQuestionListItem) => {
				const { id, type, options, content } = questionListItem as any;
				const finalAnswer =
					type === 'datepicker' && options.canBeDisabled
						? this.isCurrentAnswer(answer[id], options)
						: answer[id];

				return {
					id,
					type,
					label: content.label,
					value: finalAnswer,
					options,
				};
			},
		);
	}

	private isCurrentAnswer(answer: ISavedDateValue, options: DatepickerOptions) {
		return answer && answer.isCurrent && options.disableLabel
			? options.disableLabel
			: answer;
	}

	private getAdditionalFields(answer: IAnswer): LabeledField[] {
		return this.question.typeVariations.additionalFields.map(x => {
			return {
				id: x.key,
				label: x.label,
				value: answer[x.key],
				type: null,
			};
		});
	}
}
