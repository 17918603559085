import { Component, HostListener, Input } from '@angular/core';

@Component({
	selector: 'app-navigation-header',
	templateUrl: 'navigation-header.component.html',
	styleUrls: ['navigation-header.component.scss'],
})
export class NavigationHeaderComponent {
	@Input() public firstName?: string;
	@Input() public fullName?: string;
	@Input() public busyStatus?: string;
	@Input() public profilePhotoUrl?: string;
	@Input() public isBusyStatusVisible?: boolean;

	private pixelsFromTop = 0;

	public get hideHeader(): boolean {
		return this.pixelsFromTop < 380;
	}

	@HostListener('window:scroll', ['$event'])
	public onWindowScroll() {
		this.pixelsFromTop = window.scrollY;
	}
}
