import { SummaryCard } from '../../../../interfaces/summary-cards';

function monthYearString(date: Date): string {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return `${months[date.getMonth()]} ${date.getFullYear()}`;
}

function groupArrivalAnswer(earliest: string, latest: string): string {
	if (typeof earliest !== 'string' || typeof latest !== 'string') {
		return null;
	}
	const earliestDate = monthYearString(new Date(earliest));
	const latestDate = monthYearString(new Date(latest));

	return earliestDate === latestDate
		? earliestDate
		: `${earliestDate} to ${latestDate}`;
}

export function groupArrivalResolver(
	earliest: string,
	latest: string,
): Record<'groupArrival', SummaryCard> {
	return {
		groupArrival: {
			title: 'Arrival date',
			icon: 'Airplane Landing',
			answer: groupArrivalAnswer(earliest, latest),
		},
	};
}
